const apiUrl = process.env.REACT_APP_API_URL;

const postRegister = (data, cb) => {
    fetch(`${apiUrl}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default postRegister;
