import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import getAreasBySiteID from '../../../../api/getAreasBySiteID';
import getAssetTypesByAreaID from '../../../../api/getAssetTypesByAreaID';
import postAccount from '../../../../api/postAccount';
import getLubricantByCustomer from '../../../../api/getLubricantByCustomer';
import Context from '../../../../context/Context';
import Select from '../../../../components/Select';
import DateRangePicker from '../../../../components/DateRangePicker';
import Asset from './components/Asset';
import styled from 'styled-components';
import ReactSelect from 'react-select'

const Search = styled.div`
    input.form-control {
        font-weight: 600;
        color: var(--dark) !important;
        background-color: var(--blue-light);
        border-color: var(--blue-light);
    }
`;

const Filters = props => {
    const { setShowFilters, sites, conditionSummary, setConditionSummary } = props;
    const { token } = useContext(Context);
    const [selectedAssets, setSelectedAssets] = useState(conditionSummary.assets ?? []);
    const [selectedRatings, setSelectedRatings] = useState(conditionSummary.ratings ?? []);
    const [site, setSite] = useState(conditionSummary.site);
    const [area, setArea] = useState(conditionSummary.area);
    const [areas, setAreas] = useState([]);
    const [assets, setAssets] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [startDate, setStartDate] = useState(conditionSummary.startDate);
    const [spidNum, setSpidNum] = useState(conditionSummary.spidNum);
    const [spidName, setSpidName] = useState(conditionSummary.spidName);
    const [description, setDescription] = useState(conditionSummary.description);
    const [lubricant, setLubricant] = useState(conditionSummary.lubricant);
    const [lubricants, setLubricants] = useState([]);    

    const handleClear = () => {
        setSelectedAssets([]);
        setSelectedRatings([]);
        setStartDate(new Date());
        setSpidNum('');
        setSpidName('');
        setDescription('');
        handleChangeLubricant();
    };

    const customStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'var(--secondary)',
            boxShadow: 'var(--blue-light) !important',
            '&:hover': {
                boxShadow: 'var(--blue-light) !important'
            }
        }),
        singleValue: styles => ({
            ...styles,
            backgroundColor: 'var(--secondary)',
            color: '#fff',
            fontWeight: 600
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: '#fff'
        }),
        input: styles => ({
            ...styles,
            color: '#fff'
        }),  
        menuList: styles => ({
            ...styles,
            backgroundColor: 'var(--blue-light)'
        }),       
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isFocused ? 'var(--secondary)' : 'var(--blue-light)',
                color: isFocused ? 'white' : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default'           
        })
    };

    const buildAssetsArray = array => (
        array.filter(item => item.AssetTypeName !== '')
            .map(item => (
                { label: item.AssetTypeName, value: item.AssetID }
            ))
            .filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.label === thing.label && t.value === thing.value
                ))
            )
    );

    const buildRatingsArray = array => (
        array.map(item => (
            { label: item.RatingTypeName, value: item.RatingID }
        )).filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.label === thing.label && t.value === thing.value
            ))
        )
    );

    const ratingsArray = [
        {
            RatingTypeName: 'Normal',
            RatingID: 1
        },
        {
            RatingTypeName: 'Caution',
            RatingID: 2
        },
        {
            RatingTypeName: 'Serious',
            RatingID: 3
        },
        {
            RatingTypeName: 'Critical',
            RatingID: 4
        }
    ];

    if (!ratings) {
        setRatings(buildRatingsArray(ratingsArray));
    }

    const handleChangeSite = obj => {
        handleClear();

        setSite(obj ? { name: obj.label, id: obj.value } : null);
        if (obj) {
            setAreas([]);
            setArea(null);
            setAssets([]);
            handleChangeLubricant();
            getAreasBySiteID(token, obj.value, res => {
                const areas = res.map(item => ({
                    name: item.AreaDesc,
                    id: item.AreaEntityID
                }));
                const all = {
                    name: 'All Areas',
                    id: res.map(item => item.AreaEntityID).join(',')
                };
                areas.unshift(all);
                const area = areas[0];

                setAreas(areas);

                if (area) {
                    setArea(area);

                    getAssetTypesByAreaID(token, area.id, res => {
                        setAssets(buildAssetsArray(res));
                    });
                }
            });
        }
    };

    const handleChangeArea = obj => {
        handleClear();        
        setAssets([]);
        handleChangeLubricant()
        setArea(obj ? { name: obj.label, id: obj.value } : null);
        getAssetTypesByAreaID(token, obj.value, res => {
            setAssets(buildAssetsArray(res));
        });
    };

    const handleChangeLubricant = obj => {
        if (obj != null && obj.value === 0) {
            setLubricant(null);
        } else {
            setLubricant(obj ? { name: obj.label, id: obj.value } : null);
        }
    };

    const handleSave = () => {
        const obj = conditionSummary;
        obj.site = site;
        obj.area = area;
        obj.startDate = startDate;
        obj.endDate = new Date(moment(startDate).add(11, 'months'));
        obj.spidNum = spidNum ? spidNum : '';
        obj.spidName = spidName ? spidName : '';
        obj.description = description ? description : '';
        obj.lubricant = lubricant;
        obj.assets = selectedAssets;
        obj.ratings = selectedRatings;        

        setConditionSummary(obj);
        localStorage.setItem('conditionSummary', JSON.stringify(obj));

        props.setAssets(selectedAssets);
        props.setRatings(selectedRatings);

        postAccount(token, {
            UserLastSiteID: site.id,
            UserLastSiteName: site.name,
            UserLastAreaID: area.id,
            UserLastAreaIDs: area.id,
            UserLastAreaName: area.name
        });

        setShowFilters(false);
    };

    const handleUpdateSelectedAssets = label => {
        let items = [...selectedAssets];

        if (items.filter(x => x === label).length > 0) {
            items = items.filter(x => x !== label);
        } else {
            items.push(label);
        }

        setSelectedAssets(items);
    };

    const handleUpdateSelectedRatings = label => {
        let items = [...selectedRatings];

        if (items.filter(x => x === label).length > 0) {
            items = items.filter(x => x !== label);
        } else {
            items.push(label);
        }

        setSelectedRatings(items);
    };

    useEffect(() => {
        if (areas.length === 0) {
            getAreasBySiteID(token, site.id, res => {
                const areas = res.map(item => ({
                    name: item.AreaDesc,
                    id: item.AreaEntityID
                }));
                const all = {
                    name: 'All Areas',
                    id: res.map(item => item.AreaEntityID).join(',')
                };
                areas.unshift(all);
                setAreas(areas);
            });

        }

        if (!assets) {
            getAssetTypesByAreaID(token, area.id, res => {
                setAssets(buildAssetsArray(res));
            });
        }

        if(site.id){
            getLubricantByCustomer(token, site.id, res => {
                const lubricants = res.map(item => ({
                    name: item.Name,
                    id: item.ID
                }))
                setLubricants(lubricants);
            })
        }
    }, [areas, site]); // eslint-disable-line

    return (
        <>
            <h2 className="h3 text-uppercase font-weight-semibold px-2">
                Filters
            </h2>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                    Sites
                </h3>                
                <ReactSelect
                    id="site"
                    actionText="Select a Site"
                    options={ sites.map(item => ({ label: item.name, value: item.id })) }
                    value={ site ? { label: site.name, value: site.id } : null }
                    onChange={ handleChangeSite }
                    disabled={ sites.length === 0 } 
                    styles={customStyles}                   
                />
            </div>

            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                    Areas
                </h3>
                <ReactSelect
                    id="area"
                    actionText="Select an Area"
                    options={ areas.map(item => ({ label: item.name, value: item.id })) }
                    value={ area ? { label: area.name, value: area.id } : null }
                    onChange={ handleChangeArea }
                    disabled={ areas.length === 0 }
                    styles={customStyles}
                />
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <Search className="form-group mb-4">
                    <label
                        className="h4 text-uppercase mb-4"
                        htmlFor="searchterm"
                    >
                         SPID
                    </label>
                    <input
                        type="text"
                        id="searchterm"
                        className="form-control"
                        value={ spidNum }
                        onChange={ e => { setSpidNum(e.target.value) } }
                        autoComplete='off'
                    />
                </Search>
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <Search className="form-group mb-4">
                    <label
                        className="h4 text-uppercase mb-4"
                        htmlFor="spidName"
                    >
                        ID/Number
                    </label>
                    <input
                        type="text"
                        id="spidName"
                        className="form-control"
                        value={ spidName }
                        onChange={ e => { setSpidName(e.target.value) } }
                        autoComplete='off'
                    />
                </Search>
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <Search className="form-group mb-4">
                    <label
                        className="h4 text-uppercase mb-4"
                        htmlFor="spidDescription"
                    >
                        Description/Name
                    </label>
                    <input
                        type="text"
                        id="spidDescription"
                        className="form-control"
                        value={ description }
                        onChange={ e => { setDescription(e.target.value) } }
                        autoComplete='off'
                    />
                </Search>
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                    Lubricants
                </h3>
                <Select
                    id="lubricant"
                    actionText="Select a Lubricant"
                    options={ lubricants.map(item => ({ label: item.name, value: item.id })) }
                    value={ lubricant ? { label: lubricant.name, value: lubricant.id } : null }
                    setValue={ handleChangeLubricant }
                    disabled={ lubricants.length === 0 }
                    />
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                    Start Date
                </h3>
                <DateRangePicker
                    startDate={ new Date(startDate) }
                    setStartDate={ setStartDate }
                />
            </div>

            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-0">
                    Assets
                </h3>
                <p
                    className="mb-4"
                    style={{ fontStyle: 'italic', fontSize: '0.75rem' }}
                >
                    Select the specfic asset(s) to filter data by.
                </p>
                <div className="row mb-n3">
                    {
                        assets ? assets.map((item, index) => {
                            const checked = selectedAssets.filter(x => x === item.label).length > 0;
                            return (
                                <div key={ index } className="col-3 pb-3">
                                    <Asset
                                        label={ item.label }
                                        checked={ checked }
                                        onClick={ handleUpdateSelectedAssets }
                                    />
                                </div>
                            );
                        }) : ''
                    }
                </div>
            </div>

            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-0">
                    Ratings
                </h3>
                <p
                    className="mb-4"
                    style={{ fontStyle: 'italic', fontSize: '0.75rem' }}
                >
                    Select the specific rating(s) to filter data by.
                </p>
                <div className="row mb-n3">
                    {
                        ratings ? ratings.map((item, index) => {
                            const checked = selectedRatings.filter(x => x === item.label).length > 0;
                            return (
                                <div key={ index } className="col-3 pb-3">
                                    <Asset
                                        label={ item.label }
                                        checked={ checked }
                                        onClick={ handleUpdateSelectedRatings }
                                    />
                                </div>
                            );
                        }) : ''
                    }
                </div>
            </div>

            <div
                className="d-flex flex-wrap justify-content-center pt-5"
                style={{ gap: '1rem' }}
            >
                <button
                    className="btn btn-primary px-5 flex-shrink-0"
                    onClick={ handleSave }
                >
                    Save
                </button>
                <button
                    className="btn btn-primary px-5 flex-shrink-0"
                    onClick={ handleClear }
                >
                    Clear
                </button>
            </div>
        </>
    );
};

export default Filters;
