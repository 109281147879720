import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import getRegister from '../../api/getRegister';
import postRegister from '../../api/postRegister';
import Context from '../../context/Context';

const Register = () => {
    const { setPageTitle, showLoadingOverlay } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [account, setAccount] = useState([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const inviteId = params.get('id');
    const password = useRef();
    const confirmPassword = useRef();

    const handleSubmit = e => {
        e.preventDefault();
        showLoadingOverlay(true);

        const data = {
            InvitationCode: inviteId,
            NewPassword: password.current.value,
            ConfirmPassword: confirmPassword.current.value
        }

        postRegister(data, res => {
            showLoadingOverlay(false);

            if (res.Success) {
                setIsSuccess(true);
            } else {
                setErrorMessage(res.Message);
            }
        });
    };

    useEffect(() => {
        setPageTitle('Register');

        getRegister(inviteId, res => {
            if (res.Success) {
                setAccount(res);
            }            
        });
    }, []); // eslint-disable-line

    return (
        <div className="container-fluid">
            <div className="bg-gray-dark rounded p-5" style={{ maxWidth: 500 }}>
                {
                    isSuccess ?                        
                        <>
                            <p>Success!</p>
                            <Link className="btn btn-primary" to="/login">
                                Log In
                            </Link>
                        </>
                        :
                        <form onSubmit={ handleSubmit }>
                            {
                                errorMessage !== '' ?
                                    <p className="font-weight-semibold text-danger">
                                        { errorMessage }
                                    </p> : ''
                            }
                            {/* <div className="form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="FirstName"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="FirstName"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account.FirstName }
                                    disabled
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="LastName"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="LastName"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account.LastName }
                                    disabled
                                />
                            </div> */}
                            <div className="form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="Email"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="Email"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account.Email || 'Loading...' }
                                    disabled
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label
                                    className="text-blue-light font-weight-semibold text-uppercase mb-1"
                                    htmlFor="Password"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="Password"
                                    ref={ password }
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label
                                    className="text-blue-light font-weight-semibold text-uppercase mb-1"
                                    htmlFor="ConfirmPassword"
                                >
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    id="ConfirmPassword"
                                    ref={ confirmPassword }
                                    className="form-control"
                                    required
                                />
                            </div>
                            <button
                                className="btn btn-primary"
                                disabled={ account.length === 0 }
                            >
                                Register
                            </button>
                        </form>
                }
            </div>
        </div>
    );
};

export default Register;
