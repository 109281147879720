import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import parse from 'html-react-parser';
import getAreasBySiteID from '../../api/getAreasBySiteID';
import getConditionSummary from '../../api/getConditionSummary';
import getScenarios from '../../api/getScenarios';
import getJourneyAnalysis from '../../api/getJourneyAnalysis';
import getCriticalReports from '../../api/getCriticalReports';
import Context from '../../context/Context';
import Alert from '../../components/Alert';
import Switch from '../../components/Switch';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import SummaryTable from './components/SummaryTable/SummaryTable';
import Filters from './components/Filters/Filters';
import PrintLabelsBar from './components/PrintLabelsBar';
import ScenarioHeader from './components/ScenarioHeader';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../components/Tooltip';

const ConditionSummary = ({ location }) => {
    const {
        token,
        setPageTitle,
        sites,
        conditionSummary,
        setConditionSummary,
        showLoadingOverlay,
        account
    } = useContext(Context);

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [expandAll, setExpandAll] = useState(true);
    const [includeInactive, setIncludeInactive] = useState(false);
    const [showPrintLabels, setShowPrintLabels] = useState(false);
    const [reportCheckboxes, setShowReportCheckboxes] = useState(false);
    const [site, setSite] = useState({ name: '', id: '' });
    const [groups, setGroups] = useState(null);
    const [months, setMonths] = useState([]);
    const [assetsToShow, setAssetsToShow] = useState([]);
    const [ratingsToShow, setRatingsToShow] = useState([]);
    const [labelsToPrint, setLabelsToPrint] = useState([]);
    const [scenario, setScenario] = useState(null);
    const [scenarioData, setScenarioData] = useState(null);
    const [criticalReports, setCriticalReports] = useState(null);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [openLegendModal, setOpenLegendModal] = useState(false);
    const [groupByArea, setGroupByArea] = useState(true);
    const [groupByComponent, setGroupByComponent] = useState(false);
    const [loaded, setLoaded] = useState(null);
    const [reportsToExport, setReportsToExport] = useState([]);


    const handleExpandAll = () => {
        setExpandAll(!expandAll);
    };

    const handleIncludeInactive = () => {
        const { area, startDate, endDate, spidNum, spidName, description, lubricant } = conditionSummary;

        setIncludeInactive(!includeInactive);
        showLoadingOverlay(true);

        let lubeID = null;
        if (lubricant) {
            lubeID = lubricant.id === 0 ? '0' : lubricant.id;
        }

        getConditionSummary(token, area.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), groupByArea, groupByComponent, !includeInactive, spidNum || '', spidName || '', description || '', lubeID, res => {
            if (res.Groups && res.Groups.length > 0) {
                setMonths(res.Months);
                setGroups(res.Groups);
            } else if (res.Rows && res.Rows.length > 0) {
                setMonths(res.Months);
                setGroups(res.Rows);
            }
            setLoading(false);
            showLoadingOverlay(false);
        });
    };

    const handleComponentSort = () => {
        const { area, startDate, endDate, spidNum, spidName, description, lubricant } = conditionSummary;

        setLoaded(null);
        setGroupByComponent(!groupByComponent);
        showLoadingOverlay(true);

        let lubeID = null;
        if (lubricant) {
            lubeID = lubricant.id === 0 ? '0' : lubricant.id;
        }

        getConditionSummary(token, area.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), groupByArea, !groupByComponent, includeInactive, spidNum || '', spidName || '', description || '', lubeID, res => {
            if (res.Groups && res.Groups.length > 0) {
                setMonths(res.Months);
                setGroups(res.Groups);
            } else if (res.Rows && res.Rows.length > 0) {
                setMonths(res.Months);
                setGroups(res.Rows);
            }

            if (res) {
                setLoaded(true)
            };

            setLoading(false);
            showLoadingOverlay(false);
        });
    };

    const handleAreaSort = () => {
        const { area, startDate, endDate, spidNum, spidName, description, lubricant } = conditionSummary;

        setLoaded(null);
        setGroupByArea(!groupByArea);
        showLoadingOverlay(true);

        let lubeID = null;
        if (lubricant) {
            lubeID = lubricant.id === 0 ? '0' : lubricant.id;
        }

        getConditionSummary(token, area.id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), !groupByArea, groupByComponent, includeInactive, spidNum ? spidNum : "", spidName ? spidName : "", description ? description : "", lubeID, res => {
            if (res.Groups && res.Groups.length > 0) {
                setMonths(res.Months);
                setGroups(res.Groups);
            } else if (res.Rows && res.Rows.length > 0) {
                setMonths(res.Months);
                setGroups(res.Rows);
            }

            if (res) {
                setLoaded(true)
            };

            setLoading(false);
            showLoadingOverlay(false);
        });
    };

    const handleToggleAlertModal = () => {
        if (openAlertModal) {
            setOpenAlertModal(false);

            global.setTimeout(() => {
                setShowAlertModal(false);
            }, 350);
        } else {
            setShowAlertModal(true);

            global.setTimeout(() => {
                setOpenAlertModal(true);
            }, 50);

            global.dataLayer.push({ event: 'critical_rating_alert_view' });
        }
    };

    const handleUpdateDates = dir => {
        const obj = conditionSummary;
        const { area, spidNum, spidName, description, lubricant } = obj;

        if (dir === 'prev') {
            obj.startDate = new Date(moment(obj.startDate).subtract(12, 'months'));;
            obj.endDate = new Date(moment(obj.endDate).subtract(12, 'months'));
        } else if (dir === 'next') {
            obj.startDate = new Date(moment(obj.startDate).add(12, 'months'));;
            obj.endDate = new Date(moment(obj.endDate).add(12, 'months'));
        }

        showLoadingOverlay(true);
        setLoading(true);
        setConditionSummary(obj);
        localStorage.setItem('conditionSummary', JSON.stringify(obj));

        let lubeID = null;
        if (lubricant) {
            lubeID = lubricant.id === 0 ? '0' : lubricant.id;
        }

        getConditionSummary(token, area.id, moment(obj.startDate).format('YYYY-MM-DD'), moment(obj.endDate).format('YYYY-MM-DD'), groupByArea, groupByComponent, includeInactive, spidNum || '', spidName || '', description || '', lubeID, res => {
            if (res.Groups && res.Groups.length > 0) {
                setMonths(res.Months);
                setGroups(res.Groups);
            } else if (res.Rows && res.Rows.length > 0) {
                setMonths(res.Months);
                setGroups(res.Rows);
            }

            setLoading(false);
            showLoadingOverlay(false);
        });
    };

    useEffect(() => {
        let mounted = true;

        setLoading(true);
        showLoadingOverlay(true);

        setPageTitle('Condition Summary');

        if (mounted) {

            const params = new URLSearchParams(location.search);
            const scenarioNumber = params.get('scenario');

            if (scenarioNumber) {
                getScenarios(token, res => {
                    if (res.filter(x => x.ScenarioNumber === +scenarioNumber).length > 0) {
                        const scenario = res.filter(x => x.ScenarioNumber === +scenarioNumber)[0];
                        setScenario(scenario);
                        getConditionSummary(token, scenario.AreaID, moment(scenario.FromDate).format('YYYY-MM-DD'), moment(scenario.ToDate).format('YYYY-MM-DD'), groupByArea, groupByComponent, includeInactive, '', '', '', null, res => {
                            if (res.Groups && res.Groups.length > 0) {
                                setMonths(res.Months);
                                setGroups(res.Groups);
                            } else if (res.Rows && res.Rows.length > 0) {
                                setMonths(res.Months);
                                setGroups(res.Rows);
                            }

                            if (res) {
                                setLoaded(true)
                            };

                            const params = new URLSearchParams();
                            params.set('fromDate', moment(scenario.FromDate).format('YYYY-MM-DD'));
                            params.set('toDate', moment(scenario.ToDate).format('YYYY-MM-DD'));
                            params.set('envClean', scenario.EnvClean);
                            params.set('envControlled', scenario.EnvControlled);
                            params.set('envDusty', scenario.EnvDusty);
                            params.set('envHeated', scenario.EnvHeated);
                            params.set('envOutside', scenario.EnvOutside);
                            params.set('envHumid', scenario.EnvHumid);
                            params.set('envInside', scenario.EnvInside);
                            params.set('envWet', scenario.EnvWet);
                            params.set('envUnderground', scenario.EnvUnderground);
                            params.set('lubeID', scenario.LubeID);
                            params.set('breatherTypeID', scenario.BreatherTypeID);
                            params.set('visualInspectionID', scenario.VisualInspectionID);
                            params.set('spidType', scenario.SPIDTypes);

                            getJourneyAnalysis(token, scenario.AreaID, params.toString(), res => {
                                setScenarioData(res);

                                setLoading(false);
                                showLoadingOverlay(false);
                            });
                        });
                    } else {
                        history.push('/condition-summary');
                    }
                });
            } else if (conditionSummary.area && conditionSummary.area.id) {

                const obj = conditionSummary;
                const { site, area, spidNum, spidName, description, lubricant } = obj;

                setGroups(null);
                setSite(site);

                if (!obj.endDate) {
                    obj.startDate = new Date(moment(new Date()).subtract(11, 'months'));
                    obj.endDate = new Date();

                    setConditionSummary(obj);
                    localStorage.setItem('conditionSummary', JSON.stringify(obj));
                }

                let lubeID = null;
                if (lubricant) {
                    lubeID = lubricant.id === 0 ? '0' : lubricant.id;
                }

                getConditionSummary(token, area.id, moment(obj.startDate).format('YYYY-MM-DD'), moment(obj.endDate).format('YYYY-MM-DD'), groupByArea, groupByComponent, includeInactive, spidNum ? spidNum : "", spidName ? spidName : "", description ? description : "", lubeID, res => {
                    if (res.Groups && res.Groups.length > 0) {
                        setMonths(res.Months);
                        setGroups(res.Groups);
                    } else if (res.Rows && res.Rows.length > 0) {
                        setMonths(res.Months);
                        setGroups(res.Rows);
                    }

                    if (res) {
                        setLoaded(true)
                    };

                    setLoading(false);
                    showLoadingOverlay(false);
                });
            } else if (sites.length > 0) {
                const obj = {
                    site: sites[0],
                    startDate: new Date(moment(new Date()).subtract(11, 'months')),
                    endDate: new Date()
                };

                getAreasBySiteID(token, obj.site.id, res => {
                    // check to see if first time logging in and set to all areas
                    if (conditionSummary.area && (conditionSummary.area === "" || conditionSummary.area.id === "")) {
                        obj.area = { name: 'All Areas', id: res.map(item => item.AreaEntityID).join(',') };
                    } else {
                        obj.area = { name: res[0].AreaDesc, id: res[0].AreaEntityID };
                    }

                    setConditionSummary(obj);
                    localStorage.setItem('conditionSummary', JSON.stringify(obj));
                });
            } else {
                showLoadingOverlay(false);
            }

            getCriticalReports(token, res => {
                setCriticalReports(res);
            });
        }

        return () => {
            mounted = false;
        };
    }, [token, setPageTitle, showLoadingOverlay, location.search, conditionSummary, setConditionSummary, conditionSummary.area, conditionSummary.startDate, conditionSummary.endDate, conditionSummary.spidNum, conditionSummary.spidName, conditionSummary.description, conditionSummary.lubricant]); // eslint-disable-line

    return (
        <div className="container-fluid">
            <div className="overflow-hidden">
                <Helmet>
                    <title>Condition Summary | Trico Orbital Portal</title>
                </Helmet>
                {
                    loading ? '' :
                        <>
                            {
                                scenario ?
                                    <div className="mb-5">
                                        <ScenarioHeader
                                            scenario={scenario}
                                            scenarioData={scenarioData}
                                            title={`Scenario ${scenario.ScenarioNumber}`}
                                            highlightColor={scenario.ScenarioNumber === 1 ? '#bfb4f0' : '#9be2cc'}
                                            headerBackground={scenario.ScenarioNumber === 1 ? 'linear-gradient(135deg, #5C42D0, #7055ED, #957FF2)' : 'linear-gradient(135deg, #3EA37C, #4DD093, #9BE2CC)'}
                                        />
                                    </div>
                                    :
                                    <div className="row mx-n4 mb-5">
                                        <div className="col-8 d-flex flex-column justify-content-between px-4">
                                            <div
                                                className="d-flex align-items-center justify-content-between py-4 px-5 rounded mb-5"
                                                style={{ backgroundColor: '#2e3f5c' }}
                                            >
                                                <div>
                                                    <h2 className="text-uppercase font-weight-semibold">
                                                        {site.name}
                                                    </h2>
                                                    <span className="font-weight-semibold">
                                                        Site ID: {site.id}
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn btn-primary flex-shrink-0 ml-3"
                                                    style={{ width: 145 }}
                                                    onClick={() => {
                                                        setShowFilters(!showFilters);
                                                        setShowPrintLabels(false);
                                                        setShowReportCheckboxes(false);
                                                    }}
                                                    aria-expanded={showFilters}
                                                >
                                                    Filters
                                                </button>

                                                <Panel
                                                    isOpen={showFilters}
                                                    onRequestClose={() => setShowFilters(false)}
                                                >
                                                    <Filters
                                                        setShowFilters={setShowFilters}
                                                        sites={sites}
                                                        conditionSummary={conditionSummary}
                                                        setConditionSummary={setConditionSummary}
                                                        assets={assetsToShow}
                                                        ratings={ratingsToShow}
                                                        setAssets={setAssetsToShow}
                                                        setRatings={setRatingsToShow}
                                                    />
                                                </Panel>
                                            </div>

                                            <div className="d-flex flex-wrap mx-n2 mb-n3">
                                                <Link
                                                    className="btn btn-primary border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                                    style={{
                                                        fontSize: `${15 / 16}rem`,
                                                        lineHeight: 1.2,
                                                        backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                                        maxWidth: 270
                                                    }}
                                                    to="/journey-analysis"
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            backgroundColor: 'rgba(255,255,255,0.15)'
                                                        }}
                                                    >
                                                        <svg width="16" height="18">
                                                            <use xlinkHref="#icon-poll" />
                                                        </svg>
                                                    </span>
                                                    <span>
                                                        Oil Analysis Insights
                                                    </span>
                                                </Link>
                                                
                                                <Link
                                                    className="btn btn-primary border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                                    style={{
                                                        fontSize: `${15 / 16}rem`,
                                                        lineHeight: 1.2,
                                                        backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                                        maxWidth: 220
                                                    }}
                                                    to="/journey-score"
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            backgroundColor: 'rgba(255,255,255,0.15)'
                                                        }}
                                                    >
                                                        <svg width="16" height="18">
                                                            <use xlinkHref="#icon-poll" />
                                                        </svg>
                                                    </span>
                                                    <span>
                                                        Journey Score
                                                    </span>
                                                </Link>

                                                {(account.orbitalRole !== 'READONLY') ?
                                                    <Link
                                                        className="btn btn-info border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                                        style={{
                                                            fontSize: `${15 / 16}rem`,
                                                            lineHeight: 1.2,
                                                            backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                                            maxWidth: 210
                                                        }}
                                                        to="/condition-summary/spid/new"
                                                    >
                                                        <span
                                                            className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                                backgroundColor: 'rgba(255,255,255,0.15)'
                                                            }}
                                                        >
                                                            <svg width="18" height="18">
                                                                <use xlinkHref="#icon-plus-box-multiple" />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            Add New SPID
                                                        </span>
                                                    </Link> : ''}
                                                
                                                {(account.orbitalRole !== 'READONLY') ?
                                                    <Link
                                                        className="btn btn-orange border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                                        style={{
                                                            fontSize: `${15 / 16}rem`,
                                                            lineHeight: 1.2,
                                                            backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                                            maxWidth: 308
                                                        }}
                                                        to="/condition-summary/spid/new?type=rds"
                                                    >
                                                        <span
                                                            className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                                backgroundColor: 'rgba(255,255,255,0.15)'
                                                            }}
                                                        >
                                                            <svg width="18" height="18">
                                                                <use xlinkHref="#icon-plus-box-multiple" />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            Add New <span className="text-nowrap">Reference SPID</span>
                                                        </span>
                                                    </Link> : ''}
                                            </div>
                                        </div>
                                        <div className="col-4 px-4">
                                            {
                                                criticalReports && criticalReports.Count ?
                                                    <>
                                                        <Alert
                                                            type="danger"
                                                            orientation="vertical"
                                                            icon={
                                                                <svg className="text-danger" width="70" height="61">
                                                                    <use xlinkHref="#icon-exclamation-triangle" />
                                                                </svg>
                                                            }
                                                            title="Attention!"
                                                            description={`${criticalReports.Count} samples have a critical rating.`}
                                                            ctaText="Read More"
                                                            ctaOnClick={() => handleToggleAlertModal()}
                                                        />

                                                        {
                                                            showAlertModal ?
                                                                <Modal
                                                                    isOpen={openAlertModal}
                                                                    onRequestClose={() => handleToggleAlertModal()}
                                                                    contentClassName="d-flex flex-column rounded overflow-hidden"
                                                                    contentStyle={{
                                                                        backgroundColor: '#1B273B',
                                                                        boxShadow: '0 0 0.5rem var(--dark)',
                                                                        width: 700,
                                                                        maxHeight: 'calc(100vh - 6rem)'
                                                                    }}
                                                                >
                                                                    <div className="py-4 px-5 mb-0 border-bottom w-100" />
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center flex-wrap overflow-auto py-4 px-5"
                                                                        style={{ minHeight: 100, maxHeight: 500 }}
                                                                    >
                                                                        <div className="flex-grow-1">
                                                                            {criticalReports.Message ? parse(criticalReports.Message.replace(/(?:\r\n|\r|\n)/g, '<br />')) : ''}
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        className="position-absolute border-0 p-0 bg-transparent text-white"
                                                                        style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                                                                        aria-label="close"
                                                                        onClick={() => handleToggleAlertModal()}
                                                                    >
                                                                        <svg width="12" height="12">
                                                                            <use xlinkHref="#icon-close" />
                                                                        </svg>
                                                                    </button>
                                                                </Modal> : null
                                                        }
                                                    </> : ''
                                            }
                                        </div>
                                    </div>
                            }

                            {
                                groups !== null ?
                                    <>
                                        <div className="d-flex align-items-center flex-shrink-0 pl-2 mb-3">
                                            <Switch
                                                id="includeInactive"
                                                label="Include Inactive"
                                                checked={includeInactive}
                                                onChange={handleIncludeInactive}
                                                offColor="#2e3f5c"
                                            />
                                            <Tooltip className="position-relative ml-1">
                                                <TooltipTrigger
                                                    className="bg-transparent border-0 text-primary py-0 px-1"
                                                    aria-label="Help"
                                                >
                                                    <svg width="16" height="16">
                                                        <use xlinkHref="#icon-help-circle-outline" />
                                                    </svg>
                                                </TooltipTrigger>
                                                <TooltipContent position="top">
                                                    Samples are automatically set to inactive after 18 months without sampling activity
                                                </TooltipContent>
                                            </Tooltip>
                                        </div>

                                        {
                                            groupByArea ?
                                                <>
                                                    {
                                                        groups.map((item, index) => (
                                                            <div className="mb-5" key={index}>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between text-dark py-3 pl-5 pr-4"
                                                                    style={{
                                                                        backgroundColor: '#9dcffb',
                                                                        borderTopLeftRadius: '0.25rem',
                                                                        borderTopRightRadius: '0.25rem'
                                                                    }}
                                                                >
                                                                    <h3 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        {item.Name}
                                                                    </h3>
                                                                    {
                                                                        index === 0 ?
                                                                            <div className="d-flex">

                                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                                    <Switch
                                                                                        id="openAllSections"
                                                                                        label="Open All Areas"
                                                                                        checked={expandAll}
                                                                                        onChange={handleExpandAll}
                                                                                    />
                                                                                    <Tooltip className="position-relative ml-1">
                                                                                        <TooltipTrigger
                                                                                            className="bg-transparent border-0 text-primary py-0 px-1"
                                                                                            aria-label="Help"
                                                                                        >
                                                                                            <svg width="16" height="16">
                                                                                                <use xlinkHref="#icon-help-circle-outline" />
                                                                                            </svg>
                                                                                        </TooltipTrigger>
                                                                                        <TooltipContent position="top">
                                                                                            Toggle to expand/collapse all sections.
                                                                                        </TooltipContent>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                                    <Switch
                                                                                        id="areasGroup"
                                                                                        label="Group By Area"
                                                                                        checked={groupByArea}
                                                                                        onChange={() => handleAreaSort()}
                                                                                    />
                                                                                </div>
                                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                                    <Switch
                                                                                        id="componentGroup"
                                                                                        label="Group By Asset"
                                                                                        checked={groupByComponent}
                                                                                        onChange={() => handleComponentSort()}
                                                                                    />
                                                                                </div>
                                                                            </div> : ''
                                                                    }
                                                                </div>
                                                                {
                                                                    groupByComponent ?
                                                                        item.Groups !== null ?
                                                                            <>
                                                                                {
                                                                                    item.Groups.map((groupItem, groupItemIndex) => (
                                                                                        assetsToShow.length === 0 || assetsToShow.includes(groupItem.Name) ?
                                                                                            <SummaryTable
                                                                                                key={`${index}${groupItemIndex}`}
                                                                                                className="bg-gray-dark mb-4"
                                                                                                style={{
                                                                                                    padding: 2,
                                                                                                    borderRadius: index === 0 ? '0 0 0.25rem 0.25rem' : '0.25rem'
                                                                                                }}
                                                                                                index={groupItemIndex}
                                                                                                name={`${groupItem.Name}`}
                                                                                                id={`1${index}${groupItemIndex}`}
                                                                                                data={groupItem.Rows}
                                                                                                months={months}
                                                                                                isExpanded={expandAll}
                                                                                                showPrintLabels={showPrintLabels}
                                                                                                reportCheckboxes={reportCheckboxes}
                                                                                                labelsToPrint={labelsToPrint}
                                                                                                setLabelsToPrint={setLabelsToPrint}
                                                                                                showAssets={assetsToShow}
                                                                                                showRatings={ratingsToShow}
                                                                                                reportsToExport={reportsToExport}
                                                                                                setReportsToExport={setReportsToExport}
                                                                                                conditionSummary={conditionSummary}
                                                                                                setConditionSummary={setConditionSummary}
                                                                                                handleUpdateDates={handleUpdateDates}
                                                                                                setOpenLegendModal={setOpenLegendModal}
                                                                                                openLegendModal={openLegendModal}
                                                                                                setShowPrintLabels={setShowPrintLabels}
                                                                                                setShowReportCheckboxes={setShowReportCheckboxes}
                                                                                            /> : ''
                                                                                    ))
                                                                                }
                                                                            </> : ''
                                                                        :
                                                                        loaded && item.Rows !== null ?
                                                                            <SummaryTable
                                                                                key={`${index}3`}
                                                                                className="bg-gray-dark mb-4"
                                                                                style={{
                                                                                    padding: 2,
                                                                                    borderRadius: index === 0 ? '0 0 0.25rem 0.25rem' : '0.25rem'
                                                                                }}
                                                                                index={index}
                                                                                name=""
                                                                                id={`1${index}`}
                                                                                data={item.Rows}
                                                                                months={months}
                                                                                isExpanded={expandAll}
                                                                                showPrintLabels={showPrintLabels}
                                                                                reportCheckboxes={reportCheckboxes}
                                                                                labelsToPrint={labelsToPrint}
                                                                                setLabelsToPrint={setLabelsToPrint}
                                                                                showAssets={assetsToShow}
                                                                                showRatings={ratingsToShow}
                                                                                reportsToExport={reportsToExport}
                                                                                setReportsToExport={setReportsToExport}
                                                                                handleUpdateDates={handleUpdateDates}
                                                                                setOpenLegendModal={setOpenLegendModal}
                                                                                openLegendModal={openLegendModal}
                                                                                setShowPrintLabels={setShowPrintLabels}
                                                                                setShowReportCheckboxes={setShowReportCheckboxes}
                                                                            /> : ''
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                    {showPrintLabels ? <div style={{ height: 200 }} /> : ''}

                                                    <div className="position-fixed pl-5" style={{
                                                        right: 0,
                                                        bottom: 85,
                                                        left: 110,
                                                        zIndex: 99999,
                                                        visibility: showPrintLabels ? '' : 'hidden',
                                                        transform: showPrintLabels ? '' : 'translateY(100%)',
                                                        transition: 'visibility 300ms ease, transform 300ms ease',
                                                        transitionDelay: showPrintLabels ? '300ms' : ''
                                                    }}>
                                                        <div className="container-fluid">
                                                            <PrintLabelsBar
                                                                setIsVisible={setShowPrintLabels}
                                                                labelsToPrint={labelsToPrint}
                                                                setLabelsToPrint={setLabelsToPrint}
                                                                token={token}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="mb-5">
                                                        <div
                                                            className="d-flex align-items-center justify-content-end text-dark py-3 pl-5 pr-4"
                                                            style={{
                                                                backgroundColor: '#9dcffb',
                                                                borderTopLeftRadius: '0.25rem',
                                                                borderTopRightRadius: '0.25rem'
                                                            }}
                                                        >
                                                            <div className="d-flex">
                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                    <Switch
                                                                        id="openAllSections"
                                                                        label="Open All Areas"
                                                                        checked={expandAll}
                                                                        onChange={handleExpandAll}
                                                                    />
                                                                    <Tooltip className="position-relative ml-1">
                                                                        <TooltipTrigger
                                                                            className="bg-transparent border-0 text-primary py-0 px-1"
                                                                            aria-label="Help"
                                                                        >
                                                                            <svg width="16" height="16">
                                                                                <use xlinkHref="#icon-help-circle-outline" />
                                                                            </svg>
                                                                        </TooltipTrigger>
                                                                        <TooltipContent position="top">
                                                                            Toggle to expand/collapse all sections.
                                                                        </TooltipContent>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                    <Switch
                                                                        id="areasGroup"
                                                                        label="Group By Area"
                                                                        checked={groupByArea}
                                                                        onChange={() => handleAreaSort()}
                                                                    />
                                                                </div>
                                                                <div className="d-flex align-items-center flex-shrink-0 pl-5">
                                                                    <Switch
                                                                        id="componentGroup"
                                                                        label="Group By Asset"
                                                                        checked={groupByComponent}
                                                                        onChange={() => handleComponentSort()}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            groupByComponent && loaded ?
                                                                groups.map((item, index) => (
                                                                    item.Rows ?
                                                                        assetsToShow.length === 0 || assetsToShow.includes(item.Name) ?
                                                                            <SummaryTable
                                                                                key={`${index}4`}
                                                                                className="bg-gray-dark mb-4"
                                                                                style={{
                                                                                    padding: 2,
                                                                                    borderRadius: index === 0 ? '0 0 0.25rem 0.25rem' : '0.25rem'
                                                                                }}
                                                                                index={index}
                                                                                name={`${item.Name}`}
                                                                                id={`1${index}`}
                                                                                data={item.Rows}
                                                                                months={months}
                                                                                isExpanded={expandAll}
                                                                                showPrintLabels={showPrintLabels}
                                                                                reportCheckboxes={reportCheckboxes}
                                                                                labelsToPrint={labelsToPrint}
                                                                                setLabelsToPrint={setLabelsToPrint}
                                                                                showAssets={assetsToShow}
                                                                                showRatings={ratingsToShow}
                                                                                reportsToExport={reportsToExport}
                                                                                setReportsToExport={setReportsToExport}
                                                                                handleUpdateDates={handleUpdateDates}
                                                                                setOpenLegendModal={setOpenLegendModal}
                                                                                openLegendModal={openLegendModal}
                                                                                setShowPrintLabels={setShowPrintLabels}
                                                                                setShowReportCheckboxes={setShowReportCheckboxes}
                                                                            /> : ''
                                                                        : ''
                                                                )) : ''
                                                        }
                                                        {
                                                            loaded && !groupByComponent ?
                                                                <SummaryTable
                                                                    className="bg-gray-dark mb-4"
                                                                    style={{
                                                                        padding: 2,
                                                                        borderRadius: '0 0 0.25rem 0.25rem'
                                                                    }}
                                                                    index="1"
                                                                    name="All Areas"
                                                                    id="1"
                                                                    data={groups}
                                                                    months={months}
                                                                    isExpanded={expandAll}
                                                                    showPrintLabels={showPrintLabels}
                                                                    reportCheckboxes={reportCheckboxes}
                                                                    labelsToPrint={labelsToPrint}
                                                                    setLabelsToPrint={setLabelsToPrint}
                                                                    showAssets={assetsToShow}
                                                                    showRatings={ratingsToShow}
                                                                    reportsToExport={reportsToExport}
                                                                    setReportsToExport={setReportsToExport}
                                                                    handleUpdateDates={handleUpdateDates}
                                                                    setOpenLegendModal={setOpenLegendModal}
                                                                    openLegendModal={openLegendModal}
                                                                    setShowPrintLabels={setShowPrintLabels}
                                                                    setShowReportCheckboxes={setShowReportCheckboxes}
                                                                /> : ''
                                                        }
                                                    </div>

                                                    {showPrintLabels ? <div style={{ height: 200 }} /> : ''}

                                                    <div className="pl-5" style={{
                                                        position: 'fixed',
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 110,
                                                        zIndex: 1070,
                                                        visibility: showPrintLabels ? '' : 'hidden',
                                                        transform: showPrintLabels ? '' : 'translateY(100%)',
                                                        transition: 'visibility 300ms ease, transform 300ms ease',
                                                        transitionDelay: showPrintLabels ? '300ms' : ''
                                                    }}>
                                                        <div className="container-fluid">
                                                            <PrintLabelsBar
                                                                setIsVisible={setShowPrintLabels}
                                                                labelsToPrint={labelsToPrint}
                                                                setLabelsToPrint={setLabelsToPrint}
                                                                token={token}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </> : 'No SPIDs match selected criteria.'
                            }
                        </>
                }
            </div>
        </div>
    );
};

export default ConditionSummary;