import React from 'react';

const FormGroup = props => {
    return (
        <div className={ `form-group ${props.className || ''}` }>
            <div className="row align-items-center mx-n2">
                <div className="col-5 px-2">
                    <div
                        className="font-weight-semibold text-blue-light text-uppercase"
                        style={{ lineHeight: 1.2 }}
                        role="button"
                        onClick={ props.onClick }
                    >
                        { props.label }
                    </div>
                </div>
                <div className="col-7 px-2">
                    <button
                        className="d-flex align-items-center justify-content-between form-control"
                        onClick={ props.onClick }
                        disabled={ props.disabled }
                    >
                        {
                            props.value === null || props.value === '' ? props.placeholder || '' :
                                <span
                                    className="text-truncate font-weight-semibold"
                                    style={{ color: '#A290F3' }}
                                >
                                    { props.value }
                                </span>
                        }
                        {
                            !props.disabled ?
                                <svg className="ml-3 text-primary flex-shrink-0" width="20" height="20">
                                    <use xlinkHref="#icon-arrow-right" />
                                </svg> : ''
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormGroup;
