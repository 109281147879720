import React, { useEffect, useState } from 'react';

const RadioList = props => {
    const { options } = props;
    const [value, setValue] = useState({ label: '', value: '' });

    const handleChange = value => {
        setValue(value);
        if (props.setValue) {
            props.setValue(value);
        }
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div className={ `row row-cols-${props.cols ? props.cols : 1}` }>
            {
                options.map((item, index) => {
                    const id = `${props.id}${index}`;

                    return (
                        <div key={ index } className="col pb-1">
                            <div className="custom-control custom-radio">
                                <input
                                    className="custom-control-input"
                                    id={ id }
                                    type="radio"
                                    name={ props.id }
                                    value={ item.value }
                                    checked={ value !== null && item.value === value.value }
                                    onChange={ () => handleChange({ label: item.label, value: item.value }) }
                                    disabled={ props.disabled }
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={ id }
                                >
                                    { item.label }
                                </label>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default RadioList;
