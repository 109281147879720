import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Context from '../../context/Context';

const PrivacyPolicy = () => {
    const { setPageTitle } = useContext(Context);

    useEffect(() => {
        setPageTitle('Privacy Policy');
    }, [setPageTitle]);

    return (
        <div className="container-fluid">
            <div className="row mx-n4">
                <div className="col-8 px-4 pb-5">
                    <p style={{ textAlign: 'center' }}>Trico Corporation PRIVACY POLICY</p>
                    <p style={{ textAlign: 'center' }}>Last Updated: <strong>April 2021</strong></p>
                    
                    <p>TRICO CORPORATION, Wisconsin corporation, and its affiliates, subsidiaries, divisions and brands (collectively, "Trico,&rdquo; "we," "us," or "our," as the context may require) is the provider of a Client Portal (&ldquo;Portal&rdquo;) and website located at <strong>www.orbital.tricocorp.com</strong> (the "<u>Site</u>"), including all content, functionality, applications and services offered on or through the Portal and Site, and oil testing services Testing Services (collectively, the Portal, Site and Testing Services are referred to herein as the "<u>Services</u>").</p>
                    <p>Trico respects the privacy of its users of the Services (&ldquo;User,&rdquo; "<u>you</u>" or "<u>your</u>," as the context may require) and Trico is committed to protecting your privacy through compliance with this Privacy Policy (this "<u>Policy</u>").</p>
                    <p>This Policy describes (i) the types of information we may collect via the Services or that you may provide when you visit, access, use or otherwise interact with the Services; and (ii) our practices for collecting, using, maintaining, protecting, disclosing and processing that information.</p>
                    
                    <p><strong>1. The Interactions this Policy Covers</strong></p>
                    <p>This Policy applies to both personal and business information (as defined below) Trico may collect in or via the Portal, Site or the Services, and in email, text, and other electronic communications sent through or in connection with the Services.</p>
                    <p>Except as otherwise provided in this Policy, "<u>Information</u>" is information by which you may be personally identified, or that relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with you, such as name, e-mail address or identification number and business information related to the services we provide to you.</p>
                    <p>This Policy <strong>DOES NOT</strong> apply to information that: (i) we collect offline; (ii) is collected through any applications or websites other than the Portal, Site or the Services, including websites you may access through or may be linked to by the Services; and (iii) except as otherwise provided in this Policy, which you provide to or which is collected by any third party.</p>
                    <p>Other websites, applications, and other third parties generally have their own privacy policies, which we encourage you to read before providing information on, to, or through them.</p>
                    <p>Please read this Policy carefully to understand our policies and practices regarding your Information and how we will treat it. By accessing or using the Services, you agree to this Policy and our <Link to="/client-terms-of-use">Portal Terms of Use</Link> (which govern your use of the Portal) (collectively the "<u>User Terms</u>"). If you do not agree with our policies, practices, or terms and conditions contained in the User Terms, please do not use or access this Site.</p>
                    <p>When this Policy refers to "use" of the Site or the Services, the term "use" is intended to be broadly construed to include any direct or indirect access to or use of the Site or the Services or any information or data included on the Site or the Services.</p>
                    
                    <p><strong>2. What types of information do we collect from you?</strong></p>
                    <p>We collect several types of information from and about you as a result of your use of or registration with the Services, and as a result of your communication with us. This information may include:</p>
                    <ul>
                        <li>Information, such as your:
                            <ul>
                                <li>Name</li>
                                <li>Company name</li>
                                <li>Job title</li>
                                <li>Postal address</li>
                                <li>Email address</li>
                                <li>Telephone number</li>
                                <li>Any other categories of Information which you otherwise provide to us on or through the Services or in communications with us regarding the Services including but not limited to:
                                    <ul>
                                        <li>Company profile information</li>
                                        <li>Operational information</li>
                                        <li>Maintenance Information</li>
                                        <li>Costing or budgeting information</li>
                                        <li>Goals</li>
                                        <li>Job Functions</li>
                                        <li>Oil Analysis Information</li>
                                        <li>Purchasing information</li>
                                        <li>Training and Education information</li>
                                        <li>Equipment Information</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>                    
                    <ul>
                        <li>Information which is about you but does not identify you, such as traffic data, location data, logs of the date and time of your Site visits, click-stream data, and other information which has been de-identified or aggregated in a manner where it can no longer be used to identify you ("non-Information");</li>
                    </ul>
                    <ul>
                        <li>Information about your internet connection, the equipment you use to access the Services, and usage details ("<u>Usage Information</u>"); and/or</li>
                    </ul>
                    <ul>
                        <li>Any other form or category of information, including Information, which you publically disclose or share on or through the Services, or disclose to or share with us in any manner.</li>
                    </ul>
                    
                    <p><strong>3. How do we collect information?</strong></p>
                    <p>We collect information, including Information, from and about you:</p>
                    <ul>
                        <li>Directly from you when you provide it to us.</li>
                    </ul>                    
                    <ul>
                        <li>Automatically when you use or access the Services and navigate the Site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies, as described below.</li>
                    </ul>
                    <ul>
                        <li>From third parties.</li>
                    </ul>
                    <p><em>Information you provide to us:</em></p>
                    <p>The information, including Information, we collect when you register with, or use or access the Services, may include:</p>
                    <ul>
                        <li>Information that you provide by filling in forms or fields on the Services or in connection with the Services. This includes information provided at the time of registering an account, scheduling a demo or requesting further services. We may also ask you for certain Information when you report a problem with the Services or otherwise communicate with us via or regarding the Services.</li>
                        <li>Records and copies of your correspondence (including email addresses, home or business addresses and phone numbers), if you contact us.</li>
                        <li>Your search queries on the Services.</li>
                    </ul>
                    <p>We may link some of the information we receive directly from you with information we obtain through other methods and vice versa. This allows us to better understand your interests, needs, and objectives such that we may better serve you and offer you better services. Any such information so linked or combined which meets the definition of Information will be treated as such under this Policy.</p>
                    <p><em>Information we collect automatically</em></p>
                    <p>When you navigate, access, or use the Services, we may use automatic data collection technologies to collect certain information, including:</p>
                    <ul>
                        <li>Usage Details &mdash; When you access and use the Services, we may automatically collect certain details of your access to and use of the Services, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the Services. We may also collect the webpage from which you came immediately before reaching the Services and the webpage to which you went immediately after leaving the Services. We may use this information for system administration and to report aggregate information to our advertisers and business partners.</li>
                        <li>Device Information &mdash; We may collect information about your device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, network information, and, if applicable, the device's telephone number.</li>
                        <li>Location Information &mdash; The Services may collect information about the location of your device. Any such identification is used only in connection with the functionality of the Services and will not be otherwise used, except in the aggregate with the location information from other users.</li>
                    </ul>
                    <p>The information we collect automatically may include Information or we may maintain it or associate it with Information we collect in other ways or receive from third parties.</p>
                    <p>We use "cookies," pixels, web beacons, and other technologies to receive and store certain information when you interact with the Services. We also allow third parties to use cookies on the Services as described in our Cookie Policy. You may refuse the use of cookies by selecting the appropriate settings on your browser; however please note that if you do this you may not be able to use the full functionality of the Services. The technologies we use for automatic information collection may include:</p>
                    <ul>
                        <li>Cookies (or mobile cookies) &mdash; A cookie is a small file placed on your smartphone, tablet, computer, or other device. It may be possible to refuse to accept cookies by activating the appropriate setting on your smartphone, tablet, computer, or other device. However, if you select these setting you may be unable to access certain parts of the Services.</li>
                        <li>Web Beacons &mdash; Portions of the Services and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email and for other related statistics (for example, recording the popularity of certain content and verifying system and server integrity). Web beacons can recognize certain types of information, such as cookies, time and date of a page view, and a description of the page where the Web beacon is placed. We may use Web beacons to compile information regarding use of the Services.</li>
                        <li>Analytics &mdash; We may utilize third party companies and analytics to help us market, sell, and provide information about our products and services; and we provide your Information to these third party companies to assist us in conducting business with you. We may use web analytics tools provided by service providers, including, but not limited to, Google Analytics, which is a third party service that transmits website traffic data to Google servers in the United States. Google Analytics does not identify individual users or associate your IP address with any other data held by Google. We may use reports provided by Google Analytics or other similar service providers, to help us understand traffic on the Services, and web page usage. For more information concerning Google Analytics and how it collects and processes data please see "How Google Uses Data When You Use Our Partners Web Sites or Apps" located at www.google.com/policies/privacy/partners. We may use the information provided by these tools to improve our services and our online advertisements. These tools place session and persistent cookies in your browser to identify you as a unique user the next time you visit, access, or use the Services. Each cookie cannot be used by anyone other than the service provider (e.g., Google Analytics).</li>
                    </ul>
                    <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). At this time, the Services do not recognize automated browser signals regarding tracking mechanisms, such as "do-not-track" instructions. All traffic is treated as equal and processed the same in that we will track users' use of the Services as well as the webpage that the user came from before reaching the Services and the webpage to which he or she went after leaving the Services.</p>
                    <p><em>Information we receive from third parties</em></p>
                    <p>In some cases, we may also leverage third party tools to collect additional information for your profile, or to update existing data and information which you have provided us.</p>
                    <p><em>Other sources</em></p>
                    <p>To the extent permitted by applicable data protection laws, we may also receive Information or draw inferences about you from other sources.</p>
                    
                    <p><strong>4. Information collected by third parties</strong></p>
                    <p>When you use the Services, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
                    <ul>
                        <li>Advertisers, ad networks, and ad servers.</li>
                        <li>Analytics companies.</li>
                        <li>Trade Groups and Other Industry related Organizations</li>
                        <li>Address</li>
                        <li>Job Title</li>
                        <li>Contact information</li>
                    </ul>
                    <p>These third parties may use tracking technologies to collect information about you when you use the Services. The information they collect may be associated with your Information or they may collect information, including Information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
                    <p>We do not directly control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>
                    
                    <p><strong>5. How Trico uses your information</strong></p>
                    <p>We may use or disclose the information we collect, including Information, for one or more of the following purposes:</p>
                    <ul>
                        <li>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to schedule a demo or ask a question about our services, we will use that Information to respond to your inquiry. If you provide your Information to purchase a service, we will use that information to process your payment and facilitate delivery of the service. We may also save your information to facilitate new service orders.</li>
                        <li>To create, maintain, customize, and secure your account with us.</li>
                        <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
                        <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
                        <li>To understand and anticipate your needs and preferences.</li>
                        <li>To ensure the Services are of interest to you (including, customization of advertising and content) and help us better understand your use of the Services.</li>
                        <li>To provide you with promotional materials, newsletters, offers, surveys, invitations to events and personalized information.</li>
                        <li>To personalize your experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through the Services, third-party sites, and via email or text message (with your consent, where required by law).</li>
                        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                        <li>To notify you when updates to the Services and of changes to any products or services we offer or provide though the Services.</li>
                        <li>To help maintain the safety, security, and integrity of the Services, as well as our products, services, databases, other technology assets, and business.</li>
                        <li>For testing, research, analysis, support and product development, including to develop, support and improve the Services and our products and services.</li>
                        <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                        <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Information held by us about our users and/or customers is among the assets transferred.</li>
                        <li>As described to you when collecting your Information, or for any other purpose with your consent.</li>
                    </ul>
                    <p>The Usage Information we collect helps us to improve the Services and to deliver a better and more personalized experience by enabling us to:</p>
                    <ul>
                        <li>Estimate our audience size and usage patterns.</li>
                        <li>Store information about your preferences, allowing us to customize the Services according to your individual interests.</li>
                        <li>Speed up your searches.</li>
                        <li>Recognize you when you use the Services.</li>
                    </ul>

                    <p><strong>6. How Trico discloses your information</strong></p>
                    <p>We may disclose non-Information, including information derived from Information which has been sufficiently aggregated or de-identified such that it is no longer capable of identifying individuals, without restriction.</p>
                    <p>Except as otherwise provided in this Policy, we may disclose Information that we collect or you provide:</p>
                    <ul>
                        <li>To our subsidiaries and affiliates.</li>
                        <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Information held by us about our users is among the assets transferred.</li>
                        <li>To fulfill the purpose for which you provide it.</li>
                        <li>For any other purpose disclosed by us when you provide the information.</li>
                        <li>With your consent.</li>
                        <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                        <li>To enforce our rights arising from any contracts entered into between you and us, and for billing and collection.</li>
                        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers or others.</li>
                    </ul>

                    <p><strong>7. How to opt-out from your subscriptions and communications</strong></p>
                    <p>We strive to provide you with and inform you of choices regarding your information, including Information. This Section 7 describes mechanisms for you to control certain uses and disclosures of your information.</p>
                    <ul>
                        <li>Promotion by the Company &mdash; If you do not want us to use your email address to promote our products or services, you can opt-out by using the "one-click" unsubscribe link.</li>
                        <li>Tracking Technologies &mdash; You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the Services may then be inaccessible or not function properly.</li>
                        <li>Location Information &mdash; You can choose whether or not to allow the Services to collect and use real-time information about your device's location through the device's privacy settings. If you block the use of location information, some parts of the Services may become inaccessible or not function properly.</li>
                    </ul>
                    <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way.</p>
                    
                    <p><strong>8. How does Trico protect your Information?</strong></p>
                    <p>We have implemented reasonable technical, physical and administrative safeguards designed to secure your Information from accidental loss and from unauthorized access, use, alteration, and disclosure. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of the Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Services. The information you share in public areas may be viewed by any user of the Services.</p>
                    <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your Information, we cannot guarantee the security of your Information transmitted through the Services. Any transmission of Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
                    
                    <p><strong>9. Access to your information/your data subject rights.</strong></p>
                    <p>You may also send us an email at <strong>inquiries@tricocorp.com</strong> to request access to, correct or delete any Information that you have provided to us. We cannot delete your Information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
                    
                    <p><strong>10. Changes to this Policy</strong></p>
                    <p>We may update this Policy from time to time. If we make changes to this Policy, we will post the updated Policy on this page with a notice showing the date that this Policy was last updated. You are responsible for periodically visiting this Policy and the User Terms to check for any changes. Your continued use of the Services after we revise this Policy means you accept this Policy as amended.</p>
                    
                    <p><strong>11. How to contact us</strong></p>
                    <p>To ask questions or comment about this Policy and our privacy practices, you may contact us at:&nbsp;</p>
                    <p>Phone:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (<strong>800)558-7008</strong></p>
                    <p>Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>inquiries@tricocorp.com</strong></p>
                    <p>Postal Address:<strong>&nbsp;&nbsp; 1235 Hickory Street, Pewaukee, WI 53072</strong></p>
                    <p>If you need to access this Policy in an alternative format, please contact us by one of the methods described above.</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
