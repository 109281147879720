import React, { useContext, useEffect, useState } from 'react';
import Questions from './components/Questions/Questions';
import Troubleshooting from './components/Troubleshooting/Troubleshooting';
import Context from '../../../../context/Context';
import EmailForm from './components/EmailForm';
import Modal from '../../../../components/Modal';

const ImproveScoreModal = props => {
    const [isOpen, setIsOpen] = useState(false);

    const context = useContext(Context);
    const { account } = context;

    const handleClose = () => {
        setIsOpen(false);
        global.setTimeout(() => {
            props.setIsOpen(false);
        }, 300);
    };

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <Modal
            isOpen={ isOpen }
            onRequestClose={ handleClose }
            contentClassName="d-flex flex-column rounded overflow-hidden"
            contentStyle={{ width: 1080, fontSize: `${14/16}rem` }}
        >

            <div className="position-relative pt-4 px-4">
                <h2 className="border-bottom px-3 pb-4 mb-0">
                    Improve my Journey Score
                </h2>
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    onClick={ handleClose }
                >
                    <svg width="12" height="12">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </div>
            
            <div className="overflow-auto p-4">
                <div className="row">
                {(account.orbitalRole !== 'READONLY')  ?
                        <Questions
                            site={ props.site }
                            stage={props.stage}
                            updateJourneyScore={ props.updateJourneyScore }
                        />
                        :""}
                        <Troubleshooting 
                        stage={props.stage}/>
                </div>
                <EmailForm />
            </div>
            
        </Modal>
    );
};

export default ImproveScoreModal;
