import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

const ScenarioHeader = props => {
    const { scenario, scenarioData, title, highlightColor, headerBackground } = props;
    const [env, setEnv] = useState('');

    useEffect(() => {
        if (scenario) {
            setEnv([
                scenario.EnvClean ? 'Clean' : null,
                scenario.EnvControlled ? 'Controlled' : null,
                scenario.EnvDusty ? 'Dusty' : null,
                scenario.EnvHeated ? 'Heated' : null,
                scenario.EnvOutside ? 'Outside' : null,
                scenario.EnvInside ? 'Inside' : null,
                scenario.EnvHumid ? 'Humid' : null,
                scenario.EnvWet ? 'Wet' : null,
                scenario.EnvUnderground ? 'Underground' : null
            ].filter(x => x !== null).join(', '));
        }
    }, [scenario]);

    return (
        scenario && scenarioData ?
            <div style={{ fontSize: '0.875rem' }}>
                <div
                    className="d-flex align-items-center justify-content-between rounded py-3 px-4 mb-3"
                    style={{ backgroundImage: headerBackground }}
                >
                    <div className="text-uppercase">
                        <h2 className="h3 font-weight-semibold mb-0">
                            { title }
                        </h2>
                        Assets affected: { scenarioData.NumberOfAssets || '00' }
                    </div>
                    <div>
                        <div className="row text-center">
                            <div className="col-6">
                                <span className="d-block h2 font-weight-semibold mb-0">
                                    { scenarioData.NumberOfSamples || '00' }
                                </span>
                                Samples
                            </div>
                            <div className="col-6">
                                <span className="d-block h2 font-weight-semibold mb-0">
                                    { scenarioData.Unknown || '00' }
                                </span>
                                Unknown
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded bg-gray-dark p-3 h-100">
                    <div className="row mx-n2 h-100">
                        <div className="col-7 px-2">
                            <div className="row mx-n2 mb-3">
                                <div className="col-4 px-2">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <h3 className="h6 text-uppercase">
                                            Site
                                        </h3>
                                        { scenario.SiteName }
                                    </div>
                                </div>
                                <div className="col-4 px-2">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <h3 className="h6 text-uppercase">
                                            Area
                                        </h3>
                                        { scenario.AreaName }
                                    </div>
                                </div>
                                <div className="col-4 px-2">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <h3 className="h6 text-uppercase">
                                            Stages
                                        </h3>
                                        { scenario.SPIDTypeNames }
                                    </div>
                                </div>
                            </div>
                            <div className="rounded bg-dark p-3">
                                <h3 className="h6 text-uppercase mb-3">
                                    Weather Conditions
                                </h3>
                                <div
                                    className="row text-center mx-n2"
                                    style={{ fontSize: '0.75rem' }}
                                >
                                    <div className="col px-2">
                                        <span
                                            className="d-block h3 font-weight-semibold mb-0"
                                            style={{ color: highlightColor }}
                                        >
                                            { scenarioData.AverageTemp || '00' }&deg;
                                        </span>
                                        Temperature
                                    </div>
                                    <div className="col px-2">
                                        <span
                                            className="d-block h3 font-weight-semibold mb-0"
                                            style={{ color: highlightColor }}
                                        >
                                            { scenarioData.MinTemp || '00' }&deg;-{ scenarioData.MaxTemp || '00' }&deg;
                                        </span>
                                        High-Low<br /> Temp Change
                                    </div>
                                    <div className="col px-2">
                                        <span
                                            className="d-block h3 font-weight-semibold mb-0"
                                            style={{ color: highlightColor }}
                                        >
                                            { scenarioData.AvgPressure || '00' }&deg;
                                        </span>
                                        Atmospheric<br /> Pressure
                                    </div>
                                    <div className="col px-2">
                                        <span
                                            className="d-block h3 font-weight-semibold mb-0"
                                            style={{ color: highlightColor }}
                                        >
                                            { scenarioData.PrecipAmt || '00' }&rdquo;
                                        </span>
                                        Rain<br /> Volume
                                    </div>
                                    <div className="col px-2">
                                        <span
                                            className="d-block h3 font-weight-semibold mb-0"
                                            style={{ color: highlightColor }}
                                        >
                                            { scenarioData.AverageHumidity || '00' }%
                                        </span>
                                        Humidity
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 px-2">
                            <div className="row mx-n2 h-100">
                                <div className="col-6 px-2">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <h3 className="h6 text-uppercase">
                                            Filters
                                        </h3>
                                        <table
                                            className="text-left w-100"
                                            style={{ fontSize: '0.75rem', tableLayout: 'fixed', lineHeight: 1.2 }}
                                        >
                                            <tbody>
                                                {
                                                    scenario.LubeManufacturerName !== '' ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1 pb-1"
                                                                scope="row"
                                                            >
                                                                Manufacturer:
                                                            </th>
                                                            <td className="align-top font-weight-semibold pl-1 pb-1">
                                                                { scenario.LubeManufacturerName }
                                                            </td>
                                                        </tr> : null
                                                }
                                                {
                                                    scenario.LubeName !== '' ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1 pb-1"
                                                                scope="row"
                                                            >
                                                                Brand Name:
                                                            </th>
                                                            <td className="align-top font-weight-semibold pl-1 pb-1">
                                                                { scenario.LubeName }
                                                            </td>
                                                        </tr> : null
                                                }
                                                {
                                                    scenario.EquipmentTypeName !== '' ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1 pb-1"
                                                                scope="row"
                                                            >
                                                                Equip. type:
                                                            </th>
                                                            <td className="align-bottom font-weight-semibold pl-1 pb-1">
                                                                { scenario.EquipmentTypeName }
                                                            </td>
                                                        </tr> : null
                                                }
                                                {
                                                    scenario.BreatherTypeName !== '' ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1 pb-1"
                                                                scope="row"
                                                            >
                                                                Breather type:
                                                            </th>
                                                            <td className="align-bottom font-weight-semibold pl-1 pb-1">
                                                                { scenario.BreatherTypeName }
                                                            </td>
                                                        </tr> : null
                                                }
                                                {
                                                    scenario.VisualInspectionName !== '' ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1 pb-1"
                                                                scope="row"
                                                            >
                                                                Visual Insp. type:
                                                            </th>
                                                            <td className="align-bottom font-weight-semibold pl-1 pb-1">
                                                                { scenario.VisualInspectionName }
                                                            </td>
                                                        </tr> : null
                                                }
                                                {
                                                    env ?
                                                        <tr>
                                                            <th
                                                                className="font-weight-normal text-blue-light align-top pr-1"
                                                                scope="row"
                                                            >
                                                                Envir:
                                                            </th>
                                                            <td className="align-top font-weight-semibold pl-1">
                                                                { env }
                                                            </td>
                                                        </tr> : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6 px-2">
                                    <div className="rounded bg-dark p-3 h-100">
                                        <h3 className="h6 text-uppercase mb-3">
                                            Date Range
                                        </h3>
                                        <div className="text-center mx-auto" style={{ maxWidth: 150 }}>
                                            <div
                                                className="d-flex align-items-center text-left text-primary border border-primary rounded bg-gray-dark"
                                                style={{ height: 48 }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mx-2"
                                                    style={{
                                                        width: 32,
                                                        height: 32,
                                                        backgroundColor: 'rgba(17,138,247,0.36)'
                                                    }}
                                                >
                                                    <svg width="16" height="15">
                                                        <use xlinkHref="#icon-calendar" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <div
                                                        className="text-white text-uppercase mb-1"
                                                        style={{ fontSize: '0.5rem', lineHeight: 1 }}
                                                    >
                                                        From
                                                    </div>
                                                    <Moment
                                                        className="d-block"
                                                        style={{ lineHeight: 1 }}
                                                        format="MM/DD/YYYY"
                                                    >
                                                        { scenario.FromDate }
                                                    </Moment>
                                                </div>
                                            </div>

                                            <svg className="text-blue-light my-1" width="24" height="24">
                                                <use xlinkHref="#icon-chevron-down" />
                                            </svg>

                                            <div
                                                className="d-flex align-items-center text-left text-primary border border-primary rounded bg-gray-dark"
                                                style={{ height: 48 }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mx-2"
                                                    style={{
                                                        width: 32,
                                                        height: 32,
                                                        backgroundColor: 'rgba(17,138,247,0.36)'
                                                    }}
                                                >
                                                    <svg width="16" height="15">
                                                        <use xlinkHref="#icon-calendar" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <div
                                                        className="text-white text-uppercase mb-1"
                                                        style={{ fontSize: '0.5rem', lineHeight: 1 }}
                                                    >
                                                        To
                                                    </div>
                                                    <Moment
                                                        className="d-block"
                                                        style={{ lineHeight: 1 }}
                                                        format="MM/DD/YYYY"
                                                    >
                                                        { scenario.ToDate }
                                                    </Moment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''
    );
};

export default ScenarioHeader;
