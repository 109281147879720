import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Context from '../../context/Context';

const Logout = () => {
    const {
        setToken,
        setAccount,
        setSites,
        setConditionSummary,
        setPageTitle
    } = useContext(Context);

    useEffect(() => {
        setPageTitle('');
        setToken('');
        setAccount(null);
        setSites([]);
        setConditionSummary(null);
        localStorage.clear();
    }, [setAccount, setConditionSummary, setPageTitle, setSites, setToken]);

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Trico Orbital Portal</title>
            </Helmet>

            <p>You are now logged out.</p>

            <Link
                className="btn btn-primary"
                to="/login"
            >
                Log in
            </Link>
        </div>
    );
};

export default Logout;
