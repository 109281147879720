const apiUrl = process.env.REACT_APP_API_URL;

const postForgotPassword = (data, cb) => {
    fetch(`${apiUrl}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default postForgotPassword;
