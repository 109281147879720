import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Context from '../../context/Context';

const ClientTermsOfUse = () => {
    const { setPageTitle } = useContext(Context);

    useEffect(() => {
        setPageTitle('Client Terms of Use');
    }, [setPageTitle]);

    return (
        <div className="container-fluid">
            <div className="row mx-n4">
                <div className="col-8 px-4 pb-5">
                <p style={{ textAlign: 'center' }}>
                    <strong>Trico Corporation Client Portal</strong><br />
                    <strong>Terms of Use</strong><br />
                    <strong>(Updated February 2021)</strong>
                </p>
                <p>THIS CLIENT PORTAL AGREEMENT (&ldquo;AGREEMENT") IS MADE BY AND BETWEEN TRICO CORPORATION ("TRICO" OR &ldquo;WE&rdquo;) AND EACH REGISTERED TRICO CORPORATION CLIENT (A &ldquo;CLIENT&rdquo;) AND ITS RESPECTIVE AUTHORIZED USER(S) THAT HAVE REGISTERED TO ACCESS AND USE THE PORTAL (HEREIN EACH AND COLLECTIVELY, A "USER", "YOU" OR "YOUR").</p>
                <p>THIS AGREEMENT SHALL BECOME EFFECTIVE ON THE DATE A USER HAS BEEN REGISTERED BY TRICO AND PROVIDED A LOGIN AND PASSWORD TO ACCESS THE PORTAL (AS DEFINED BELOW).&nbsp; BY ACCESSING AND USING THE PORTAL YOU AGREE TO BE BOUND BY THIS AGREEMENT.&nbsp;</p>
                <p>THIS AGREEMENT DOES NOT SUPERSEDE OR MODIFY ANY OTHER PRESENT OR FUTURE AGREEMENT BETWEEN YOU AND TRICO, INCLUDING ANY MASTER SERVICES AGREEMENT, SOFTWARE OR SOFTWARE SERVICES LICENSE AGREEMENT OR APPLICATION END-USER LICENSE AGREEMENT.</p>
                <p><strong>The Portal</strong></p>
                <p>Trico maintains and provides to its Users a portal that may be accessed online via a web browser or via Trico&rsquo;s mobile device application (the &ldquo;Portal&rdquo;) that permits the electronic transfer and storage of documents between Users and Trico (the "Portal") in relation to used oil sampling laboratory services provided by Trico.&nbsp; The Portal also enables Users to access certain documents, data, lab results and related information created or maintained by Trico.&nbsp; The information, documents, and communications on the Portal are provided as a convenient resource to Users only and may be used only for a User&rsquo;s own internal business purposes and informational purposes.</p>
                <p><strong>Mobile Device App</strong></p>
                <p>Your use of the Trico Orbital Mobile Device App (the &ldquo;App&rdquo;) shall be subject to the End-User License Agreement located at: <Link to="/eula">https://orbital.tricocorp.com/eula</Link></p>
                <p><strong>Changes to Agreement</strong></p>
                <p>Trico reserves the right to modify the terms, conditions, and notices under which the Portal is offered. &nbsp;Your continued use of the Portal after the posting of any amended terms and conditions shall constitute your agreement to be bound by any such changes.</p>
                <p><strong>Modifications to Portal, Services and Content</strong></p>
                <p>Trico reserves the right to modify in part or in whole, or temporarily or permanently discontinue the Portal and/or services or any content contained therein for any reason and at any time without prior notice to you.&nbsp; Trico reserves the right to delete, modify or supplement the content of the Portal or the services at any time for any reason without prior notification to you.&nbsp; Trico is not liable to you or any third party for modifications, suspension or discontinuance of the Portal or any services or content offered on the Portal. Continued use of the Portal constitutes acceptance of any modified terms and conditions.</p>
                <p><strong>Maintenance and Availability of the Portal</strong></p>
                <p>If third party software is required for you to access or use the Portal, you must obtain a license of such software at your own expense.</p>
                <p>Trico retains the sole discretion to decide which types of documents may be uploaded, stored or viewed on the Portal. &nbsp;Trico may modify, suspend, discontinue or restrict the use of any portion of the Portal, including the availability of any portion of the documents and content stored on the Portal at any time, without notice or liability.</p>
                <p>Trico will use reasonable efforts to provide 24 hour daily availability of the Portal to Users. However, Trico makes no representations or warranty the Portal will be available all the time or that it will be error-free. You acknowledge and agree that the Portal may, at times, be unavailable due to scheduled or unscheduled maintenance, service upgrades, or other mechanical or electronic failures. &nbsp;</p>
                <p>Trico shall not be responsible for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to the Portal. Trico is not responsible for any problems or technical malfunctions of any telephone or fiber network or lines, computer on-line systems, servers or providers, computer equipment, software, failure of any e-mail to be received by Trico on account of technical problems or traffic congestion on the Internet or at any Portal, or any combination thereof, including any injury or damage to your computer or peripherals related to downloading any materials in from the Portal.</p>
                <p><strong>Confidential Information</strong></p>
                <p>Notwithstanding any existing legal or contractual obligations regarding confidentiality between you and Trico, you undertake to treat all knowledge relating to business secrets, which come into your possession, as confidential. &nbsp;You shall assure that any protected data, which comes into your possession through the use of the Portal, is not transmitted to any unauthorized person. &nbsp;In partial consideration of the opportunity to access the resources of the Portal concerning your account, you agree to maintain the strict confidentiality of access of the Portal and its data to you and your authorized employees and to indemnify and hold harmless Trico and its officers, shareholders, and employees and their heirs, successors, and assigns from and against any and all claims, actions, demands, losses, damages, judgments, costs, and expenses, including without limitation, reasonable attorneys&rsquo; fees and liabilities of every kind that may arise from your or your employees&rsquo; use of the Portal or because of violation of these terms and conditions of use.</p>
                <p><strong>Login Credentials and Security</strong></p>
                <p>Accessing and using the Portal requires the use of a user name and password ("Login Credentials"). &nbsp;Each User is solely responsible for maintaining the confidentiality of its Login Credentials; and each User is responsible for any activities that occur using that User's Login Credentials. &nbsp;You must treat your Login Credentials as confidential and you must not disclose Login Credentials to any third party.</p>
                <p>Trico is not liable for any harm related to the misuse or theft of Login Credentials, disclosure of Login Credentials, or your authorization to allow another person or entity to access and use the Portal using your Login Credentials. You shall immediately notify Trico of any unauthorized use of your username or password and any breach of confidentiality. Until Trico receives this notification from you, you will be held liable for any harm ensuing from the use of your username on Trico&rsquo;s Portal.</p>
                <p>Trico will use its best efforts to make the Portal is secure from unauthorized access. The Portal will require industry standard 128bit encryption on all communications between your device and the Portal server. &nbsp;The Portal server operating system and applications software will be updated and virus-scanned regularly. &nbsp;However, you recognize that no completely secure system for electronic data transfer has yet been devised. &nbsp;TRICO MAKES NO WARRANTY, EXPRESS OR IMPLIED, REGARDING THE EFFICACY OF THE SECURITY OF THE PORTAL AND SHALL NEVER BE LIABLE FOR ANY CLAIMED, ACTUAL, OR CONSEQUENTIAL DAMAGES ARISING FROM ANY BREACH OR ALLEGED BREACH OF SECURITY OF THE CLIENT PORTAL.</p>
                <p>Trico shall have the right to disable any login and/or password at any time, if in our opinion you have failed to comply with any of the provisions of these Terms of Use or if we believe that an unauthorized third party is attempting to access the Portal using your Login Credentials.</p>
                <p>If you have been given access to any part of the Portal by mistake, if you know or suspect that anyone other than you knows your password, or if you become aware of a breach of confidentiality or any unauthorized use of the Portal, you must promptly notify us by email at <a href="emailto:inquiries@tricocorp.com">inquiries@tricocorp.com</a>.&nbsp; Trico&rsquo;s right to suspend or disable access to the Portal does not create an affirmative duty on the part of Trico to monitor who is accessing or attempting to access the Portal and Trico shall not be liable for any unauthorized access of the Portal using your Login Credentials unless such authorized access is based solely on Trico&rsquo;s own negligence or willful action.</p>
                <p><strong>Termination of User Accounts</strong></p>
                <p>You agree to notify Trico immediately if a particular User&rsquo;s access to the Portal should be modified, suspended or terminated. Trico will make every effort to terminate access immediately. &nbsp;However, you cannot be assured that access has been terminated until you receive confirmation of the termination from Trico.</p>
                <p><strong>Lab Testing Services and Lab Report Disclaimer of Warranties; Sole Remedy</strong></p>
                <p>The data contained in any lab report may be outdated or include omissions, miscalculations, inaccuracies or other errors. Accordingly, the Portal, its content and the services are made available to users AS SEEN and AS IS without any warranty of any kind.&nbsp; Trico makes no representations, guarantees, or warranties regarding the accuracy, reliability, availability, or completeness of the content, data, analysis or other information associated with its testing services or the lab reports made available through the Portal.&nbsp;</p>
                <p>Your sole remedy if any lab report contains erroneous, inaccurate or incomplete data is for Trico to re-perform the lab test and generate a new report.</p>
                <p><strong>Portal Disclaimer of Warranties; Sole Remedy</strong></p>
                <p>The information, documents, software and services contained on the Portal may be outdated or include omissions, inaccuracies or other errors. Accordingly, the Portal, its content and the services are made available to users AS SEEN and AS IS without any warranty of any kind.&nbsp; Trico makes no representations, guarantees, or warranties regarding the accuracy, reliability, availability, or completeness of the content of the Portal.</p>
                <p>If you are dissatisfied with any portion of the Portal, or the information, documents, or communications made available through the Portal, or with any of these terms and conditions of use, your sole and exclusive remedy is to cease using the portal and the information, documents, or communications you obtained from the Portal.</p>
                <p><strong>General </strong><strong>Disclaimer of Warranties</strong></p>
                <p><strong>Trico</strong><strong> DOES NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING TRICO&rsquo;S TESTING SERVICES, THE PORTAL OR ANY INFORMATION PROVIDED BY TRICO IN CONNECTION WITH THE LAB TESTING SERVICES OR THIS PORTAL. &nbsp;EXCEPT FOR ANY WARRANTY, CONDITION, REPRESENTATION OR TERM TO THE EXTENT TO WHICH THE SAME CANNOT OR MAY NOT BE EXCLUDED OR LIMITED BY LAW APPLICABLE TO YOU IN YOUR JURISDICTION, Trico MAKES&nbsp; NO WARRANTIES CONDITIONS, REPRESENTATIONS, OR TERMS (EXPRESS OR IMPLIED WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE OR OTHERWISE) AS TO ANY MATTER INCLUDING WITHOUT LIMITATION NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, INTEGRATION, SATISFACTORY QUALITY, OR FITNESS FOR ANY PARTICULAR PURPOSE.&nbsp; Trico DOES NOT WARRANT THAT THE LAB REPORTS, PORTAL OR ANY SOFTWARE USED TO DELIVER ANY SERVICES (INCLUDING THIS PORTAL) WILL BE FREE OF BUGS OR PROGRAM ERRORS.&nbsp; Trico DOES NOT WARRANT THAT THE PORTAL WILL BE FREE FROM VIRUSES.</strong></p>
                <p><strong>LIMITATION OF LIABILITY.&nbsp; </strong></p>
                <p><strong>UNDER NO CIRCUMSTANCES SHALL Trico BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR ANY OTHER PECUNIARY LOSS ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SOFTWARE (INCLUDING, WITHOUT LIMITATION, ANY LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS, DISRUPTION OF BUSINESS, LOSS OF PROFITS, OR ANY OTHER MATTER RELATING TO YOUR USE OR INABILITY TO USE THE SOFTWARE) OR RELATED IN ANY WAY TO THIS EULA OR THE SUBJECT THEREOF, INCLUDING, WITHOUT LIMITATION, ANY CLAIM RELATED TO ALLEGATIONS THAT THE SOFTWARE INFRINGES ANOTHER PARTY'S INTELLECTUAL PROPERTY OR OTHER PROPRIETARY RIGHTS, WHETHER ARISING UNDER THEORIES OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT, OR OTHER THEORY, REGARDLESS WHETHER LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; UNDER NO CIRCUMSTANCES SHALL Trico'S TOTAL LIABILITY TO YOU EXCEED THE LICENSE FEES, IF ANY, ACTUALLY RECEIVED BY Trico FROM YOU HEREUNDER DURING THE CURRENT MONTH OF ANY CLAIM OF LIABILITY LIMITED HEREUNDER.&nbsp; THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SET FORTH IN THIS SECTION SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.</strong>&nbsp; <strong>NO OBLIGATION OR LIABILITY SHALL ARISE FROM Trico' RENDERING OF TECHNICAL OR OTHER ADVICE OR SERVICE IN CONNECTION WITH THIS EULA, INCLUDING, WITHOUT LIMITATION, ADVICE OR SERVICE RELATED TO THE INSTALLATION OR CONFIGURATION OF THE SOFTWARE.</strong>&nbsp; <strong>Some states and jurisdictions do not allow exclusion or limitation of liability for consequential or incidental DAMAGES and so the above limits and exclusions may not apply to you or may be limited by law.</strong></p>
                <p><strong>Client Data Ownership</strong></p>
                <p>Trico expressly acknowledges that Client shall own its data uploaded to the Portal or generated by Trico through its laboratory testing services.&nbsp; Client expressly granted to Trico the right to store, transmit and aggregate Client Data to provide services to you.</p>
                <p><strong>Collection and Aggregation of Data</strong></p>
                <p>Your use of the Portal is subject to Trico&rsquo;s <Link to="/privacy-policy">Privacy Policy</Link>.&nbsp;</p>
                <p>By using the Portal, you authorize Trico to collect and aggregate data derived from the laboratory testing services offered to you (&rdquo;Aggregated Data&rdquo;).&nbsp; You expressly acknowledge that Trico shall own the Aggregated Data and shall have the right to use this Aggregated Data to improve the functions and features of the Portal and Trico&rsquo;s services; and to provide you and other Trico clients with reports, benchmarks and data.</p>
                <p><strong>Third Party Content and Links</strong></p>
                <p>The Portal may contain hyperlinks to Portals or documents controlled by parties other than Trico. Trico is not responsible for and does not endorse or accept responsibility over the contents, opinions expressed or use of any of these third parties. Trico makes no representations or warranties with regard to the products, services or content of any such third parties.</p>
                <p><strong>Intellectual Property Rights</strong></p>
                <p>Except as expressly permitted by applicable law, you may not copy, (except to the extent required in order to use this Portal in accordance with these terms and conditions), store in any medium (including in any other Portal), distribute, transmit, re-transmit, broadcast, modify, or show in public any part of this Portal or systematically extract material from this Portal or any document or electronic communication available through it or in any other way exploit commercially all or any part of this Portal or electronic communication or any document available through it without the prior written permission of Trico.</p>
                <p>As between us and you, we are the owner or the licensee of all intellectual property rights in the Portal (including the App and any software relating to the Portal (&ldquo;Software&rdquo;)) and you do not acquire any right, title or interest in the Portal or Software. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
                <p><strong>General Restrictions</strong></p>
                <p>You shall use the Portal and App only for your own internal business purposes.&nbsp; You shall not: (a) process or permit to be processed in any manner the data or files of any third party through the Software; (b) use the Portal or App in any manner as part of the operation of a service bureau; or (c) allow access to the Portal through any terminals or computers located outside of your location or using your login credentials which have been assigned to you.</p>
                <p>Except to the extent necessary to use the Portal in accordance with these Terms of Use, you must not copy, store in any medium (including in any other Portal), distribute, transmit, re-transmit, broadcast, modify, show in public, systematically extract, or commercially exploit all or any part of the Portal (including Our Documents) without our prior written consent.</p>
                <p>Documents&nbsp;may be confidential and in some cases,&nbsp;privileged. You must not distribute the Documents (other than your material), except to persons authorized to read them. If you believe that you may have been given access to a Document by mistake, you must not download it, copy it, use it for any purpose or disclose its contents to any person.</p>
                <p>You must not use the Portal to circulate, send or distribute any information or data that would be in contravention of any regulations or legislation including regulations or legislation governing financial services, money laundering or anti-terrorism or which is offensive, abusive, indecent, defamatory, obscene or menacing, in breach of confidence or any other laws.</p>
                <p>Trico, or its service provider(s) and supplier(s) own or license the software that is used to maintain and operate the Portal. You shall not copy, modify, download, distribute or decompile (except to the extent permitted by law) the software without Trico&rsquo;s prior written consent.</p>
                <p>Except to the extent required to use the Portal in accordance with this Agreement, you must not copy, store in any medium (including in any other Portal), distribute, transmit, re-transmit, broadcast, modify, or show in public any part of Portal&nbsp;or systematically extract material from Portal or in any way exploit commercially all or any part of the Portal without our prior written permission. This clause applies to Portal itself and not to Client Data.</p>
                <p>You are prohibited from using the Portal to damage, disable, or overburden Trico&rsquo;s servers or network or impair the Portal or interfere with any other party&rsquo;s use of the Portal. &nbsp;Hacking, password mining, or any other means to gain unauthorized access to the Portal, Trico&rsquo;s or other user accounts, computers, or network is prohibited. Posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law is also prohibited. &nbsp;Trico will fully cooperate with any law enforcement authorities or court order requesting or directing this firm to disclose the identity of anyone posting any such information and materials. &nbsp;Trico is an equal opportunity employer and values the diversity of its people.</p>
                <p><strong>Viruses; Malicious Files</strong></p>
                <p>You agree not to misuse the Portal by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to the Portal, the server on which the Portal is stored or any server, computer or database connected to the Portal. You agree not to attack the Portal via a denial-of-service attack or a distributed denial-of service attack.</p>
                <p>Trico will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Portal or to your downloading of any material posted on it, or on any Portal linked to it.</p>
                <p><strong>Term and Termination</strong></p>
                <p>This Agreement and the services contemplated by it may be terminated by either Trico or Client with or without notice at any time. &nbsp;Trico may at any time terminate in whole or in part Trico&rsquo;s Portal without notice or liability.</p>
                <p><strong>Governing Law and Jurisdiction</strong></p>
                <p>This Agreement and your use of the Portal shall be governed and interpreted in accordance with the laws of the United States and the state of Wisconsin.</p>
                <p><strong>Dispute Resolution; Arbitration</strong></p>
                <p>Any dispute of any sort that might arise between Trico and You, including without limitation any matters or disputes relating to or arising from the purchase or use of any product, service, or information offered or made available through Trico, or arising from or relating to any communication between you and Trico or its agents, will be determined by binding arbitration before a single, neutral arbitrator administered in accordance with the Commercial Arbitration Rules of the American Arbitration Association. The place of arbitration will be Waukesha, Wisconsin and the arbitration will be conducted in English. Judgment on any arbitration award may be entered in any court of competent jurisdiction, and the parties intend that it will be enforceable in accordance with the Convention on the Recognition and Enforcement of Foreign Arbitral Awards. Any claim by you with respect to any transaction must be initiated within one year after the cause of action has arisen. Trico and you both agree that either party may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>
                <p><strong>Applicable Law/International Issues</strong></p>
                <p>Information and documents submitted to or stored on the Portal, or services provided by Trico to Users, will be collected, processed, stored, disclosed and disposed of in accordance with applicable U.S. law.&nbsp; If you are a non-U.S. user, you acknowledge and agree that we may collect and use any information uploaded to the Portal, outside your resident jurisdiction.&nbsp; In addition, such information may be stored on servers located outside your resident jurisdiction.&nbsp; U.S. law may not provide the degree of protection for information that is available in other countries. By providing us with information, you acknowledge that you have read this Agreement, understand it, agree to its terms and consent to the transfer of such information outside your resident jurisdiction. If you do not consent to the terms of this Agreement, do not use the Portal.</p>
                <p><strong>Territorial Restrictions on Use&nbsp; </strong></p>
                <p>Client and represents and warrants that it and its Users are familiar with and knowledgeable about all relevant laws, rules, regulations, decrees, federal, state and local, which are now or may become applicable to storage and transmission of data related to the Services provided by Trico, including without limitation, those pertaining to safety, security, export restrictions and environmental protection.</p>
                <p>You shall not access, or allow any third party to access, the Portal in any countries, jurisdiction or territory that is prohibited by law, including but not limited to any country prohibited by the United States Export Administration Act or any other export laws, restrictions or regulations (collectively the "Export Laws"). You represent and warrant that you are not a citizen, or otherwise located within, an embargoed nation (including without limitation Iran, Iraq, Syria, Sudan, Libya, Cuba, North Korea, and Serbia, if those countries are on the then-current list of embargoed nations) and that you are not otherwise prohibited under the Export Laws from access or use of the Portal.&nbsp;</p>
                <p>Client and its Users shall comply with all provisions of any applicable laws, regulations, rules and orders relating to the license herein granted and to the use of the Portal or App.&nbsp; Client and its Users shall not export or re-export the App from the United States ("U.S.") without the prior written approval of Trico and then only in compliance with all applicable laws and export administration regulations of the United States Government.</p>
                <p>If Client or any of its Users take any action contrary to applicable law, including where applicable but not limited to any export control laws of the United States of America, then Client shall bear any penalties or additional costs resulting from such violation and Trico, notwithstanding any other provision to the contrary, shall have the right to terminate this Agreement and Client&rsquo;s and its User&rsquo;s access to the Portal (and all Data accessible through the Portal) without any liability or responsibility for any costs, expenses or damages associated with such termination.</p>
                <p><strong>Injunctive Relief </strong></p>
                <p>You acknowledge and agree that monetary damages alone would not be an adequate remedy in the event of a material breach by you of your obligations under this Agreement and that, in such event, Trico shall be entitled to injunctive relief to require you to comply with your obligations hereunder.&nbsp; Any remedy of Trico under this Agreement shall be cumulative and not exclusive of any other remedy available to Trico under this Agreement, at law or in equity.</p>
                </div>
            </div>
        </div>
    );
};

export default ClientTermsOfUse;
