import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Context from '../../context/Context';
import postResetPassword from '../../api/postResetPassword';

const ChangePassword = () => {
    const { setPageTitle, showLoadingOverlay } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const password = useRef();
    const confirmPassword = useRef();

    const handleSubmit = e => {
        e.preventDefault();
        showLoadingOverlay(true);

        const data = {
            UserID: params.get('userID'),
            PasswordResetToken: params.get('token'),
            NewPassword: password.current.value,
            ConfirmPassword: confirmPassword.current.value
        }

        postResetPassword(data, res => {
            showLoadingOverlay(false);

            if (res.Success) {
                setIsSuccess(true);
            } else {
                setErrorMessage(res.Message);
            }
        });
    };

    useEffect(() => {
        setPageTitle('Reset Password');
    }, []); // eslint-disable-line

    return (
        <div className="container-fluid">
            <div className="bg-gray-dark rounded p-5" style={{ maxWidth: 500 }}>
                {
                    isSuccess ?
                        <>
                            <p>Success!</p>
                            <Link className="btn btn-primary" to="/login">
                                Log In
                            </Link>
                        </>
                        :
                        <form onSubmit={ handleSubmit }>
                            {
                                errorMessage !== '' ?
                                    <p className="font-weight-semibold text-danger">
                                        { errorMessage }
                                    </p> : ''
                            }
                            <div className="form-group mb-4">
                                <label
                                    className="text-blue-light font-weight-semibold text-uppercase mb-1"
                                    htmlFor="Password"
                                >
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="Password"
                                    ref={ password }
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label
                                    className="text-blue-light font-weight-semibold text-uppercase mb-1"
                                    htmlFor="ConfirmPassword"
                                >
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    id="ConfirmPassword"
                                    ref={ confirmPassword }
                                    className="form-control"
                                    required
                                />
                            </div>
                            <button className="btn btn-primary">
                                Reset Password
                            </button>
                        </form>
                }
            </div>
        </div>
    );
};

export default ChangePassword;
