import React, { useContext, useEffect, useState } from 'react';
import ExpandCollapse from './components/ExpandCollapse';
import getContent from '../../../../../../api/getContent';
import getTroubleshootingContent from '../../../../../../api/getTroubleshootingContent';
import Context from '../../../../../../context/Context';

const Troubleshooting = props => {
    const { token } = useContext(Context);
    const [heading, setHeading] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {
        getContent(token, res => {
            setHeading(res.TroubleshootingHeading);
        });
        getTroubleshootingContent(token, props.stage, res => {
            setItems(res.TroubleshootingFAQ);
        });
    }, []); // eslint-disable-line

    return (
        <div className='col-6'>
            <div className="rounded bg-gray-dark p-4">
            <h3 className="h4 font-weight-semibold border-bottom mx-n3 px-3 mb-3 pb-3">
                { heading }
            </h3>
            <div className="mt-4 pt-2">
                {
                    items.map((item, index) => (
                        <div key={ index } className="mb-4">
                            <ExpandCollapse
                                key={ index }
                                index={ index }
                                title={ item.Question }
                                description={ item.Answer }
                            />
                        </div>
                    ))
                }
            </div>
        </div>
        </div>
    );
};

export default Troubleshooting;
