import React, { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../context/Context';
import Modal from '../../components/Modal';
import postChangePassword from '../../api/postChangePassword';

const Account = () => {
    const { setPageTitle, account, token, showLoadingOverlay } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const currentPassword = useRef();
    const newPassword = useRef();
    const confirmPassword = useRef();

    const handleSubmit = e => {
        e.preventDefault();
        showLoadingOverlay(true);

        const data = {
            OldPassword: currentPassword.current.value,
            NewPassword: newPassword.current.value,
            ConfirmPassword: confirmPassword.current.value
        };

        postChangePassword(token, data, res => {
            showLoadingOverlay(false);

            if (res.Success) {
                setIsSuccess(true);
                setErrorMessage('');
                setOpenModal(false);

                global.setTimeout(() => {
                    setIsSuccess(false);
                }, 2000);
            } else {
                setErrorMessage(res.Message);
            }
        });
    };

    useEffect(() => {
        setPageTitle('Account');
    }, [setPageTitle]);

    return (
        <div className="container-fluid">
            <div className="row mx-n4">
                <div className="col-8 px-4 pb-5">
                    <div className="bg-gray-dark rounded p-4">
                        <h2 className="h4 font-weight-semibold text-uppercase pb-4 px-4 mx-n4 mb-4 border-bottom">
                            Edit Account
                        </h2>

                        <div className="row">
                            <div className="col-6 form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="FirstName"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="FirstName"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account ? account.firstName : '' }
                                    disabled
                                />
                            </div>
                            <div className="col-6 form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="LastName"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="LastName"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account ? account.lastName : '' }
                                    disabled
                                />
                            </div>
                            <div className="col-6 form-group mb-4">
                                <label
                                    className="font-weight-semibold text-uppercase mb-1"
                                    style={{ color: '#434F62' }}
                                    htmlFor="Email"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="Email"
                                    className="form-control"
                                    style={{
                                        backgroundColor: '#2E3F5C',
                                        borderColor: '#2E3F5C',
                                        color: 'rgba(255,255,255,0.4)',
                                        opacity: 1
                                    }}
                                    value={ account ? account.email : '' }
                                    disabled
                                />
                            </div>
                            <div className="col-6 form-group mb-4">
                                <label
                                    className={ `${isSuccess ? 'text-success' : 'text-blue-light'} font-weight-semibold text-uppercase mb-1` }
                                    htmlFor="Password"
                                >
                                    Password
                                </label>
                                {
                                    isSuccess ?
                                        <div
                                            className="form-control bg-success d-flex align-items-center justify-content-between w-100 text-white font-weight-normal text-left"
                                            id="Password"
                                            style={{
                                                textTransform: 'none',
                                                pointerEvents: 'none'
                                            }}
                                        >
                                            Successful
                                            <svg width="12" height="9">
                                                <use xlinkHref="#icon-check" />
                                            </svg>
                                        </div>
                                        :
                                        <input
                                            type="password"
                                            id="Password"
                                            className="form-control"
                                            style={{ opacity: 1, cursor: 'pointer' }}
                                            value="xxxxxxxxxx"
                                            readOnly
                                            onClick={ () => setOpenModal(true) }
                                            onKeyPress={ e => {
                                                if (e.key === 'Enter') {
                                                    setOpenModal(true);
                                                }
                                            } }
                                        />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-center pt-4 mt-3" style={{ fontSize: '0.875rem' }}>
                        If you need to change your email or name,<br />
                        please <a className="font-weight-semibold" style={{ textDecoration: 'underline' }} href="mailto:oilanalysiscs@tricocorp.com">
                            contact your administrator
                        </a>.
                    </div>
                </div>
            </div>

            <Modal
                isOpen={ openModal }
                onRequestClose={ () => setOpenModal(false) }
                contentClassName="rounded overflow-auto"
                contentStyle={{
                    backgroundColor: '#0B131F',
                    width: 500,
                    maxHeight: 'calc(100vh - 6rem)'
                }}
            >
                <h3 className="h5 font-weight-light border-bottom p-4 mb-0">
                    Edit Password
                </h3>
                <form className="p-4" onSubmit={ handleSubmit }>
                    {
                        errorMessage !== '' ?
                            <p className="font-weight-semibold text-danger">
                                { errorMessage }
                            </p> : ''
                    }
                    <div className="form-group mb-4">
                        <label
                            className="text-blue-light font-weight-semibold text-uppercase mb-1"
                            style={{ fontSize: '0.875rem' }}
                            htmlFor="CurrentPassword"
                        >
                            Current Password
                        </label>
                        <input
                            type="password"
                            id="CurrentPassword"
                            className="form-control"
                            style={{ backgroundColor: '#151F2F' }}
                            ref={ currentPassword }
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label
                            className="text-blue-light font-weight-semibold text-uppercase mb-1"
                            style={{ fontSize: '0.875rem' }}
                            htmlFor="NewPassword"
                        >
                            New Password
                        </label>
                        <input
                            type="password"
                            id="NewPassword"
                            className="form-control"
                            style={{ backgroundColor: '#151F2F' }}
                            ref={ newPassword }
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label
                            className="text-blue-light font-weight-semibold text-uppercase mb-1"
                            style={{ fontSize: '0.875rem' }}
                            htmlFor="ConfirmPassword"
                        >
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            id="ConfirmPassword"
                            className="form-control"
                            style={{ backgroundColor: '#151F2F' }}
                            ref={ confirmPassword }
                        />
                    </div>
                    <div className="text-center mb-3">
                        <button
                            className="btn btn-primary"
                            style={{ width: 148 }}
                        >
                            Update
                        </button>
                    </div>
                </form>
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    aria-label="close"
                    onClick={ () => setOpenModal(false) }
                >
                    <svg width="12" height="12">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Modal>
        </div>
    );
};

export default Account;
