import React, { useState, useContext } from 'react';
import Context from '../context/Context.js';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import navBg from '../assets/nav-bg.jpg';

const StyledNav = styled.nav`
    position: relative;
    z-index: 1080;
    font-size: ${13/16}rem;

    .nav-toggle {
        position: absolute;
        top: 30px;
        left: 100%;
        width: 40px;
        height: 40px;
        padding: 0;
        margin-left: -2px;
        background-color: #232f44;
    }

    .nav-link {
        position: relative;
        display: block;
        color: #fff;
        height: 72px;
        background-color: transparent;
        border: none;
        transition: color 150ms;

        &:hover {
            text-decoration: none;
            color: #fff !important;

            &::after {
                opacity: 0.45;
            }

            svg {
                color: #fff !important;
            }
        }

        &:focus {
            outline: none;
        }

        &.active {
            &::before {
                opacity: 1;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            opacity: 0;
            background-color: var(--secondary);
            background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.15));
            transition: opacity 150ms;
        }

        &::before {
            left: -1rem;
            width: 0.375rem;
            border-radius: 0 0.25rem 0.25rem 0;
        }

        &::after {
            right: 0;
            left: 0;
            border-radius: 0.25rem;
        }
    }

    .react-slidedown {
        transition-duration: 250ms;
    }
`;

const NavLinkIcon = props => {
    return (
        <span
            className="d-flex align-items-center justify-content-center rounded flex-shrink-0 mr-3"
            style={{
                width: 45,
                height: 45,
                backgroundColor: props.backgroundColor || 'rgba(157,207,251,0.25)'
            }}
        >
            { props.children }
        </span>
    );
};

const Nav = () => {
    const { token } = useContext(Context);
    const [isExpandedClick, setIsExpandedClick] = useState(false);
    const [isExpandedHover, setIsExpandedHover] = useState(false);
    const [lubricantJourneySubNavIsExpanded, expandLubricantJourneySubNav] = useState(false);
    const [testingResourcesSubNavIsExpanded, expandTestingResourcesSubNav] = useState(false);
    const { pathname } = useLocation();
    const showJourneyAnalysis = false;

    const handleLubricantJourneySubNavToggle = () => {
        expandLubricantJourneySubNav(!lubricantJourneySubNavIsExpanded);
    };

    const handleTestingResourcesSubNavToggle = () => {
        expandTestingResourcesSubNav(!testingResourcesSubNavIsExpanded);
    };

    return (
        <StyledNav
            className="flex-shrink-0"
            style={{
                width: isExpandedClick ? 280 : 110,
                transition: 'width 250ms ease'
            }}
        >
            <button
                className="btn nav-toggle text-primary"
                aria-label="Expand/Collapse menu"
                onClick={ () => { setIsExpandedClick(!isExpandedClick) }}
                aria-expanded={ isExpandedClick }
            >
                <svg width="25" height="17">
                    <use xlinkHref={ `#icon-menu${isExpandedClick ? '-open' : ''}` } />
                </svg>
            </button>

            <div
                className="p-3 overflow-hidden"
                style={{
                    position: isExpandedHover ? 'fixed' : 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: '1080',
                    width: isExpandedHover || isExpandedClick ? 280 : 110,
                    height: isExpandedHover ? 'calc(100% - 85px)' : '100%',
                    backgroundColor: '#232f44',
                    transition: 'width 250ms ease'
                }}
                onMouseOver={ () => { setIsExpandedHover(true) }}
                onMouseOut={ () => { setIsExpandedHover(false) }}
            >
                <div
                    className="position-absolute h-100"
                    style={{
                        top: 0,
                        left: 0,
                        width: 280,
                        backgroundImage: `linear-gradient(to bottom, rgba(21,31,47,0.96), rgba(21,31,47,0.96)), url(${navBg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                    }}
                />
                <div className="position-relative">
                    <div
                        className="overflow-hidden"
                        style={{
                            width: isExpandedHover || isExpandedClick ? 209 : 37,
                            marginTop: 20,
                            marginLeft: 20,
                            transition: 'width 250ms ease'
                        }}
                    >
                        <svg width="209" height="30">
                            <use xlinkHref="#logo-orbital" />
                        </svg>
                    </div>

                    <div
                        className="border-bottom border-white pb-4 mb-3"
                        style={{ opacity: 0.15 }}
                    />

                    <NavLink
                        className="nav-link d-flex align-items-center w-100 px-3 my-3"
                        to="/condition-summary"
                    >
                        <span
                            className="position-relative d-flex align-items-center w-100 text-nowrap"
                            style={{ zIndex: 1 }}
                        >
                            <NavLinkIcon>
                                <svg width="22" height="26">
                                    <use xlinkHref="#icon-file-multiple-outline" />
                                </svg>
                            </NavLinkIcon>
                            <span style={{
                                opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                transition: 'opacity 250ms'
                            }}>
                                Condition Summary
                            </span>
                        </span>
                    </NavLink>

                    {
                        showJourneyAnalysis ?
                            <div className="my-3">
                                <button
                                    className={ classNames('nav-link d-flex align-items-center w-100 text-nowrap px-3', {
                                        'active': pathname.includes('journey')
                                    }) }
                                    style={{ color: lubricantJourneySubNavIsExpanded ? '#A290F3' : '' }}
                                    onClick={ handleLubricantJourneySubNavToggle }
                                    aria-expanded={ lubricantJourneySubNavIsExpanded }
                                >
                                    <span
                                        className="position-relative d-flex align-items-center w-100 text-nowrap"
                                        style={{ zIndex: 1 }}
                                    >
                                        <NavLinkIcon>
                                            <svg width="22" height="20">
                                                <use xlinkHref="#icon-chart-bar" />
                                            </svg>
                                        </NavLinkIcon>
                                        <span style={{
                                            opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                            transition: 'opacity 250ms'
                                        }}>
                                            Luricant Journey
                                            <svg
                                                className="text-primary ml-2"
                                                width="18" height="18"
                                                style={{
                                                    transform: lubricantJourneySubNavIsExpanded ? 'rotate(180deg)' : '',
                                                    transition: 'transform 250ms'
                                                }}
                                            >
                                                <use xlinkHref="#icon-chevron-down" />
                                            </svg>
                                        </span>
                                    </span>
                                </button>
                                <SlideDown
                                    className="overflow-hidden"
                                    closed={ !lubricantJourneySubNavIsExpanded }
                                >
                                    <NavLink
                                        className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                        style={{ height: 60, fontSize: `${13/16}rem` }}
                                        to="/journey-analysis"
                                    >
                                        <span
                                            className="position-relative d-flex align-items-center w-100 text-nowrap"
                                            style={{ zIndex: 1 }}
                                        >
                                            <NavLinkIcon backgroundColor="transparent">
                                                <svg width="24" height="24">
                                                    <use xlinkHref="#icon-link" />
                                                </svg>
                                            </NavLinkIcon>
                                            <span style={{
                                                opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                                transition: 'opacity 250ms'
                                            }}>
                                                Journey Analysis
                                            </span>
                                        </span>
                                    </NavLink>
                                    <NavLink
                                        className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                        style={{ height: 60, fontSize: `${13/16}rem` }}
                                        to="/journey-score"
                                    >
                                        <span
                                            className="position-relative d-flex align-items-center w-100 text-nowrap"
                                            style={{ zIndex: 1 }}
                                        >
                                            <NavLinkIcon backgroundColor="transparent">
                                                <svg width="24" height="24">
                                                    <use xlinkHref="#icon-link" />
                                                </svg>
                                            </NavLinkIcon>
                                            <span style={{
                                                opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                                transition: 'opacity 250ms'
                                            }}>
                                                Journey Score
                                            </span>
                                        </span>
                                    </NavLink>
                                </SlideDown>
                            </div>
                            :
                            <NavLink
                                className="nav-link d-flex align-items-center w-100 px-3 my-3"
                                to="/journey-score"
                            >
                                <span
                                    className="position-relative d-flex align-items-center w-100 text-nowrap"
                                    style={{ zIndex: 1 }}
                                >
                                    <NavLinkIcon>
                                        <svg width="22" height="20">
                                            <use xlinkHref="#icon-chart-bar" />
                                        </svg>
                                    </NavLinkIcon>
                                    <span style={{
                                        opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                        transition: 'opacity 250ms'
                                    }}>
                                        Journey Score
                                    </span>
                                </span>
                            </NavLink>
                    }                    

                    <NavLink
                        className="nav-link d-flex align-items-center text-nowrap px-3 my-3"
                        to="/order-supplies"
                    >
                        <span
                            className="position-relative d-flex align-items-center w-100 text-nowrap"
                            style={{ zIndex: 1 }}
                        >
                            <NavLinkIcon>
                                <svg width="22" height="23">
                                    <use xlinkHref="#icon-cart-plus" />
                                </svg>
                            </NavLinkIcon>
                            <span style={{
                                opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                transition: 'opacity 250ms'
                            }}>
                                Order Supplies
                            </span>
                        </span>
                    </NavLink>

                    <div className="my-3">
                        <button
                            className="nav-link d-flex align-items-center w-100 text-nowrap px-3 overflow-hidden"
                            style={{
                                color: testingResourcesSubNavIsExpanded ? '#A290F3' : ''
                            }}
                            onClick={ handleTestingResourcesSubNavToggle }
                            aria-expanded={ testingResourcesSubNavIsExpanded }
                        >
                            <span
                                className="position-relative d-flex align-items-center w-100 text-nowrap"
                                style={{ zIndex: 1 }}
                            >
                                <NavLinkIcon>
                                    <svg width="22" height="24">
                                        <use xlinkHref="#icon-clipboard-text-multiple-outline" />
                                    </svg>
                                </NavLinkIcon>
                                <span style={{
                                    opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                    transition: 'opacity 250ms'
                                }}>
                                    Resources
                                    <svg
                                        className="text-primary ml-2"
                                        width="18" height="18"
                                        style={{
                                            transform: testingResourcesSubNavIsExpanded ? 'rotate(180deg)' : '',
                                            transition: 'transform 250ms'
                                        }}
                                    >
                                        <use xlinkHref="#icon-chevron-down" />
                                    </svg>
                                </span>
                            </span>
                        </button>
                        <SlideDown
                            className="overflow-hidden"
                            closed={ !testingResourcesSubNavIsExpanded }
                        >
                            {token ?
                                <form
                                    method="POST"
                                    action="https://row.ups.com/GuestAccess/Guest/Entry?Company=d702b3ff-5aae-46cd-b6bc-c71ab57bc80e"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <input type="hidden" name="Company" value="VFJJQ08=" />
                                    <button
                                        className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                        style={{ height: 60, fontSize: `${13/16}rem` }}
                                    >
                                        <span
                                            className="position-relative d-flex align-items-center w-100 text-nowrap"
                                            style={{ zIndex: 1 }}
                                        >
                                            <NavLinkIcon backgroundColor="transparent">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M166.9 302.9c-5.2 3.6-32.8 13.2-32.8-19.1V179h-32.9v103.3c0 75.4 80.8 51.5 98.6 39.3V179H167v123.9zM67 73.1v222.5C67 400 142.4 431.8 256 481c113.2-49.2 189-80.8 189-185.4V73.1c-117.1-62-283.8-49.9-378 0zm360.6 222.5c0 87.2-53.6 114.4-171.6 166.5-118.3-52.2-171.7-79.3-171.7-166.5V168.3c103-94.5 233.2-100.7 343.3-90.4v217.7zM216.5 187.4v214.3h32.9v-69.2c24.6 7.4 72.2-2.6 72.2-79.1.1-98-81.2-81.4-105.1-66zm33 118.2v-101c8.5-4.2 38.7-12.8 38.7 49.7-.1 68.3-36.7 52.1-38.7 51.3zm79.6-87.1c.1 47.6 52 42.8 52.6 70.9.6 23.7-30.6 23.2-51.2 4.9v30.3c36.5 21.7 82.5 8.2 83.8-33.7 1.7-51.9-54.5-46.9-53.8-73.7.6-20.4 30.8-20.6 48.8-2.2v-28.6c-28.6-22.1-80.4-9.2-80.2 32.1z" />
                                            </svg>
                                            </NavLinkIcon>
                                            <span style={{
                                                opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                                transition: 'opacity 250ms'
                                            }}>
                                                Bottle Shipping Label
                                            </span>
                                        </span>
                                    </button>
                                </form>
                            :
                                ""
                                    }
                            <a
                                className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                style={{ height: 60, fontSize: `${13/16}rem` }}
                                href="/resources/ReportGuide.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span
                                    className="position-relative d-flex align-items-center w-100 text-nowrap"
                                    style={{ zIndex: 1 }}
                                >
                                    <NavLinkIcon backgroundColor="transparent">
                                        <svg width="24" height="24">
                                            <use xlinkHref="#icon-pdf" />
                                        </svg>
                                    </NavLinkIcon>
                                    <span style={{
                                        opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                        transition: 'opacity 250ms'
                                    }}>
                                        Report Guide
                                    </span>
                                </span>
                            </a>
                            <a
                                className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                style={{ height: 60, fontSize: `${13/16}rem` }}
                                href="/resources/OilSamplingSOP.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span
                                    className="position-relative d-flex align-items-center w-100 text-nowrap"
                                    style={{ zIndex: 1 }}
                                >
                                    <NavLinkIcon backgroundColor="transparent">
                                        <svg width="24" height="24">
                                            <use xlinkHref="#icon-pdf" />
                                        </svg>
                                    </NavLinkIcon>
                                    <span style={{
                                        opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                        transition: 'opacity 250ms'
                                    }}>
                                        Oil Sampling SOPs
                                    </span>
                                </span>
                            </a>
                            <a
                                className="nav-link d-flex align-items-center w-100 text-nowrap px-3"
                                style={{ height: 60, fontSize: `${13/16}rem` }}
                                href="/resources/UnderstandingISOCodes.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span
                                    className="position-relative d-flex align-items-center w-100 text-nowrap"
                                    style={{ zIndex: 1 }}
                                >
                                    <NavLinkIcon backgroundColor="transparent">
                                        <svg width="24" height="24">
                                            <use xlinkHref="#icon-pdf" />
                                        </svg>
                                    </NavLinkIcon>
                                    <span style={{
                                        opacity: isExpandedClick || isExpandedHover ? '' : 0,
                                        transition: 'opacity 250ms'
                                    }}>
                                        Understanding ISO<br /> Codes
                                    </span>
                                </span>
                            </a>
                        </SlideDown>
                    </div>

                    <div
                        className="border-bottom border-white mb-3"
                        style={{ opacity: 0.15 }}
                    />
                </div>
            </div>
        </StyledNav>
    );
};

export default Nav;
