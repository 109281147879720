import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

const Styled = styled.div`
    label {
        font-size: ${9/16}rem;
        font-weight: 600;
        cursor: pointer;
    }

    .react-switch-bg {
        background-image: ${props => props.checked ? 'linear-gradient(135deg, rgba(255,255,255,0.25), rgba(0,0,0,0.15) 50%)' : ''} !important;
    }
`;

const Icon = ({ label, left, right }) => (
    <span
        className="position-absolute text-uppercase text-white"
        style={{
            top: '50%',
            right,
            left,
            fontSize: `${5/16}rem`,
            marginTop: 1,
            transform: 'translateY(-50%)'
        }}
    >
        { label }
    </span>
);

const CustomSwitch = props => (
    <Styled
        className={ `d-flex align-items-center ${props.className || ''}` }
        checked={ props.checked }
    >
        <label
            className="text-uppercase mr-2 mb-0"
            style={{ opacity: props.disabled ? 0.5 : '' }}
            htmlFor={ props.id }
        >
            { props.label }
        </label>
        <Switch
            id={ props.id }
            onChange={ props.onChange }
            checked={ props.checked }
            checkedIcon={ <Icon label="On" left={ `${6/16}rem` } /> }
            uncheckedIcon={ <Icon label="Off" right={ `${6/16}rem` } /> }
            height={ 22 }
            width={ 50 }
            onColor={ props.onColor || '#118af7' }
            offColor={ props.offColor || '#111926' }
            disabled={ props.disabled }
        />
    </Styled>
);

export default CustomSwitch;
