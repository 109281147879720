import React from 'react';
import classNames from 'classnames';

const Alert = props => {
    const { className, icon, title, description, ctaText, ctaOnClick } = props;
    const type = props.type.toLowerCase();
    const orientation = props.orientation || 'vertical';
    const isVertical = orientation === 'vertical';
    const isHorizontal = orientation === 'horizontal';

    return (
        <aside
            className={ classNames(`position-relative d-flex justify-content-between rounded overflow-hidden ${className || ''}`, {
                'flex-column text-center h-100': isVertical
            }) }
            style={{ backgroundColor: '#1b273b' }}
        >
            {
                isHorizontal ?
                    <div
                        className={ classNames('position-absolute h-100', {
                            'bg-danger': type === 'danger',
                            'bg-success': type === 'success'
                        }) }
                        style={{
                            top: 0,
                            left: 0,
                            width: '0.5rem'
                        }}
                    /> : ''
            }
            <div className="d-flex align-items-center justify-content-center h-100 p-4">
                <div
                    className={ isHorizontal ? 'd-flex align-items-center pl-2' : '' }
                    style={{ fontSize: isHorizontal ? '0.875rem' : ''}}
                >
                    {
                        icon ?
                            <div className={ isVertical ? 'mb-4 pb-2' : 'pr-4' }>
                                { icon }
                            </div> : ''
                    }
                    <div>
                        <h3 className={ classNames('h4 text-uppercase', {
                            'mb-0': isHorizontal
                        }) }>
                            { title }
                        </h3>
                        { description }
                    </div>
                </div>
            </div>
            {
                ctaText && ctaText !== '' ?
                    <button
                        className={ classNames('btn btn-lg flex-shrink-0', {
                            'btn-danger': type === 'danger',
                            'btn-success': type === 'success'
                        }) }
                        style={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: isVertical ? 0 : '',
                            borderBottomLeftRadius: isHorizontal ? 0 : ''
                        }}
                        onClick={ ctaOnClick }
                    >
                        { ctaText }
                    </button> : ''
            }
        </aside>
    );
};

export default Alert;
