import React, { useState } from 'react';
import Select from '../../../components/Select';

const PrintLabelsBar = props => {
    const { labelsToPrint, setLabelsToPrint, token } = props;
    const [copies, setCopies] = useState(null);
    const [format, setFormat] = useState(null);
    const labelUrl = process.env.REACT_APP_PRINT_LABELS;
    
    const handleClick = id => {
        setLabelsToPrint([...labelsToPrint].filter(x => x.id !== id));
    };

    const handlePrint = () => {
        const spidlist = labelsToPrint.map(item => item.items).flat().join(',');
        const url = `${labelUrl}?ReportType=${format.value}&spidlist=${spidlist}&copies=${copies}&oauth=${token}`;
        global.open(url, '_blank');
        global.dataLayer.push({ event: 'print_labels' });
    };

    return (
        <div className="text-dark p-4" style={{
            backgroundColor: '#BFB4F0',
            borderRadius: '0.25rem 0.25rem 0 0',
            maxWidth: 1640
        }}>
            <div className="row">
                <div className="col">
                    <h4 className="h6 font-weight-semibold text-uppercase">
                        Selected SPIDs
                    </h4>
                    {
                        labelsToPrint.length === 0 ? '' :
                            <ul className="list-unstyled d-flex flex-wrap m-n1">
                                {
                                    labelsToPrint.map(item => {
                                        const { name, id, items } = item;
                                        return (
                                            <li key={ id } className="p-1">
                                                <button
                                                    className="btn btn-secondary d-flex align-items-center justify-content-between text-left font-weight-semibold border-0 px-3 py-2"
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        borderRadius: '3rem',
                                                        backgroundImage: 'linear-gradient(135deg, transparent, rgba(255, 255, 255, 0.25))'
                                                    }}
                                                    onClick={ () => handleClick(id) }
                                                >
                                                    <span>
                                                        { items.length } { items.length === 1 ? 'SPID' : 'SPIDs' } <span className="mx-1">|</span> { name || '–' }
                                                    </span>
                                                    <svg className="ml-2" width="12" height="12">
                                                        <use xlinkHref="#icon-close" />
                                                    </svg>
                                                </button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                    }
                </div>
                <div className="col">
                    <div className="row mx-n2 mb-n3">
                        <div className="col-xl-6 px-2 pb-3">
                            <h4 className="h6 font-weight-semibold text-uppercase">
                                Copies
                            </h4>
                            <Select
                                id="labelCopies"
                                actionText="Select"
                                options={
                                    [...Array(12)].map((_item, index) => (
                                        { label: index + 1, value: index + 1, checked: index === 0 ? true : false }
                                    ))
                                }
                                value={ copies ? { label: copies, value: copies } : null }
                                setValue={ obj => setCopies(obj.value) }
                                optionsLocation="above"
                                color="var(--dark)"
                                backgroundColor="#A290F3"
                            />
                        </div>
                        <div className="col-xl-6 px-2 pb-3">
                            <h4 className="h6 font-weight-semibold text-uppercase">
                                Format
                            </h4>
                            <Select
                                id="labelStyle"
                                actionText="Select"
                                options={
                                    [
                                        {
                                            label: '2 x 6', value: '2x6', checked: true
                                        },
                                        {
                                            label: 'Avery5160', value: 'Avery5160', checked: false
                                        },
                                        {
                                            label: 'Dymo', value: 'Dymo', checked: false
                                        }
                                    ]
                                }
                                value={ format }
                                setValue={ setFormat }
                                optionsLocation="above"
                                color="var(--dark)"
                                backgroundColor="#A290F3"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div
                        className="d-xl-flex mx-n2 mb-n3"
                        style={{ marginTop: 23 }}
                    >
                        <button
                            className="btn btn-primary d-flex align-items-center mx-2 mb-3 py-0"
                            style={{ height: 44 }}
                            disabled={ labelsToPrint.length === 0 || copies === null || format === null }
                            onClick={ handlePrint }
                        >
                            <span
                                className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-2 ml-n3"
                                style={{
                                    width: 28,
                                    height: 28,
                                    backgroundColor: 'rgba(255,255,255,0.15)'
                                }}
                            >
                                <svg width="12" height="14">
                                    <use xlinkHref="#icon-download" />
                                </svg>
                            </span>
                            Print Labels
                        </button>
                        <button
                            className="btn btn-outline-primary d-flex align-items-center mx-2 mb-3 py-0"
                            style={{ height: 44 }}
                            onClick={ () => props.setIsVisible(false) }
                        >
                            <span
                                className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-2 ml-n3"
                                style={{
                                    width: 28,
                                    height: 28,
                                    backgroundColor: 'rgba(0,108,206,0.15)'
                                }}
                            >
                                <svg width="12" height="14">
                                    <use xlinkHref="#icon-close" />
                                </svg>
                            </span>
                            Exit Print Mode
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrintLabelsBar;