import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../../components/DateRangePicker';
import Panel from '../../../components/Panel';
import RadioList from '../../../components/RadioList';
import { Carousel, CarouselItems, CarouselItem, CarouselPrevButton, CarouselNextButton, CarouselNavButton } from './PanelCarousel';

const ActivityPanel = props => {
    const { isVisible, setIsVisible, maintenanceTypes } = props;
    const [activeSlide, setActiveSlide] = useState(null);
    const [maintenanceDate, setMaintenanceDate] = useState('');
    const [maintenanceType, setMaintenanceType] = useState(null);
    const [disabled, setDisabled] = useState(true);

    const handleUpdate = () => {
        setIsVisible(false);
        setDisabled(true);
        props.setMaintenanceType(maintenanceType);
        props.setMaintenanceDate(maintenanceDate);
    };

    const handleClose = () => {
        setIsVisible(false);
        setDisabled(true);
    };

    useEffect(() => {
        setActiveSlide(props.activeSlide);
        setMaintenanceType(props.maintenanceType);
        setMaintenanceDate(props.maintenanceDate);
    }, [props]);

    useEffect(() => {
        if (maintenanceDate.label !== '' || maintenanceType.label !== '') {
            setDisabled(false);
        }
    }, [maintenanceDate, maintenanceType]);

    return (
        <Panel
            isOpen={isVisible}
            onRequestClose={handleClose}
        >
            <Carousel>
                <div className="d-flex flex-column m-n4 h-100">
                    <div className="d-flex flex-grow text-white">
                        <CarouselNavButton
                            isActive={activeSlide === 1}
                            onClick={() => setActiveSlide(1)}
                        >
                            {activeSlide === 1 ? '1. Maintenance Date' : '1'}
                        </CarouselNavButton>
                        <CarouselNavButton
                            isActive={activeSlide === 2}
                            onClick={() => setActiveSlide(2)}
                        >
                            {activeSlide === 2 ? '2. Maintenance Type' : '2'}
                        </CarouselNavButton>
                    </div>
                    <div className="d-flex flex-column justify-content-between h-100">
                        {
                            activeSlide ?
                                <CarouselItems
                                    activeItem={activeSlide}
                                    setActiveItem={setActiveSlide}
                                >
                                    <CarouselItem index={1}>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Maintenance Date
                                        </h2>
                                        <p>
                                            Choose date of maintenance activity.
                                        </p>
                                        <DateRangePicker
                                            startDate={maintenanceDate.value}
                                            setStartDate={setMaintenanceDate}
                                            showYearsOption={false}
                                            pickerStyle='maintenance'
                                            startOpen={true}
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={2}>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Maintenance Type
                                        </h2>
                                        <p>
                                            Choose type of maintenance activity.
                                        </p>
                                        <RadioList
                                            id="maintenanceTypes"
                                            actionText="Select option"
                                            value={maintenanceType}
                                            setValue={setMaintenanceType}
                                            options={maintenanceTypes}
                                        />
                                    </CarouselItem>
                                </CarouselItems> : ''
                        }
                        <div className="pb-5">
                            <div
                                className="position-relative mt-n3 pt-3"
                                style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #baddfc' }}
                            />
                            <div className="row align-items-center justify-content-center text-center pt-2">
                                <div className="col-4">
                                    <CarouselPrevButton>
                                        <svg width="20" height="20" aria-hidden="true">
                                            <use xlinkHref="#icon-chevron-left" />
                                        </svg>
                                        Prev
                                    </CarouselPrevButton>
                                </div>
                                <div className="col-4">
                                    <button
                                        disabled={disabled}
                                        className="btn btn-primary"
                                        style={{width: 147}}
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </button>
                                </div>
                                <div className="col-4">
                                    <CarouselNextButton>
                                        Next
                                        <svg width="20" height="20" aria-hidden="true">
                                            <use xlinkHref="#icon-chevron-right" />
                                        </svg>
                                    </CarouselNextButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </Panel>
    );
};

export default ActivityPanel;