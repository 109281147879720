import React, { useEffect, useState } from 'react';
import RadioList from '../../../components/RadioList';
import Panel from '../../../components/Panel';
import { Carousel, CarouselItems, CarouselItem, CarouselPrevButton, CarouselNextButton, CarouselNavButton, CarouselGroupNavButton } from './PanelCarousel';

const EquipmentPanel = props => {
    const { isVisible, setIsVisible } = props;
    const [activeSlide, setActiveSlide] = useState(null);
    const [assetType, setAssetType] = useState({ name: '', id: null });
    const [module, setModule] = useState({ name: '', id: null });
    const [component, setComponent] = useState({ name: '', id: null });
    const [manufacturer, setManufacturer] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [reservoirCap, setReservoirCap] = useState({ name: '', id: null });
    const [criticality, setCriticality] = useState({ name: '', id: null });
    const [filtrationMethod, setFiltrationMethod] = useState({ name: '', id: null });
    const [sampleBeforeFilter, setSampleBeforeFilter] = useState('');
    const [filterType, setFilterType] = useState({ name: '', id: null });
    const [sizeFiltered, setSizeFiltered] = useState({ name: '', id: null });
    const [breathers, setBreathers] = useState({ name: '', id: null });
    const [bearingType, setBearingType] = useState({ name: '', id: null });
    const [gearType, setGearType] = useState({ name: '', id: null });
    const [assetTypes, setAssetTypes] = useState([]);
    const [moduleTypes, setModuleTypes] = useState([]);
    const [componentTypes, setComponentTypes] = useState([]);
    const [reservoirCaps, setReservoirCaps] = useState([]);
    const [criticalities, setCriticalities] = useState([]);
    const [filtrationMethods, setFiltrationMethods] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [sizeFilteredTypes, setSizeFilteredTypes] = useState([]);
    const [breatherTypes, setBreatherTypes] = useState([]);
    const [bearingTypes, setBearingTypes] = useState([]);
    const [gearTypes, setGearTypes] = useState([]);

    const handleClose = () => {
        setIsVisible(false);
        props.setAssetType(assetType);
        props.setModule(module);
        props.setComponent(component);
        props.setManufacturer(manufacturer);
        props.setModelNumber(modelNumber);
        props.setSerialNumber(serialNumber);
        props.setReservoirCap(reservoirCap);
        props.setCriticality(criticality);
        props.setFiltrationMethod(filtrationMethod);
        props.setSampleBeforeFilter(sampleBeforeFilter);
        props.setFilterType(filterType);
        props.setSizeFiltered(sizeFiltered);
        props.setBreathers(breathers);
        props.setBearingType(bearingType);
        props.setGearType(gearType)
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setActiveSlide(props.activeSlide);
            setAssetType(props.assetType);
            setModule(props.module);
            setComponent(props.component);
            setManufacturer(props.manufacturer);
            setModelNumber(props.modelNumber);
            setSerialNumber(props.serialNumber);
            setSerialNumber(props.serialNumber);
            setReservoirCap(props.reservoirCap);
            setCriticality(props.criticality);
            setFiltrationMethod(props.filtrationMethod);
            setSampleBeforeFilter(props.sampleBeforeFilter);
            setFilterType(props.filterType);
            setSizeFiltered(props.sizeFiltered);
            setBreathers(props.breathers);
            setBearingType(props.bearingType);
            setGearType(props.gearType);
            setAssetTypes(props.assetTypes);
            setModuleTypes(props.moduleTypes);
            setComponentTypes(props.componentTypes);
            setReservoirCaps(props.reservoirCaps);
            setCriticalities(props.criticalities);
            setFiltrationMethods(props.filtrationMethods);
            setFilterTypes(props.filterTypes);
            setSizeFilteredTypes(props.sizeFilteredTypes);
            setBreatherTypes(props.breatherTypes);
            setBearingTypes(props.bearingTypes);
            setGearTypes(props.gearTypes);
        }

        return () => {
            mounted = false;
        };
    }, [props]);

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <Carousel>
                <div
                    className="d-flex flex-column overflow-hidden m-n4"
                    style={{ height: 'calc(100% + 3rem)' }}
                >
                    <div className="d-flex bg-gray text-white">
                        <CarouselGroupNavButton
                            isActive={ activeSlide > 6 && activeSlide < 13 }
                            onClick={ () => setActiveSlide(7) }
                        >
                            Filtration
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide > 12 }
                            onClick={ () => setActiveSlide(13) }
                        >
                            Bearings &amp; Gears
                        </CarouselGroupNavButton>
                    </div>
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            height: 44,
                            borderBottom: '1px solid rgba(46,63,92,0.4)'
                        }}
                    >
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: activeSlide < 6 ? 0.5 : '' }}
                            aria-label="Previous Section"
                            disabled={ activeSlide < 7 }
                            onClick={
                                () => {
                                    if (activeSlide > 6 && activeSlide < 13) {
                                        setActiveSlide(1);
                                    } else {
                                        setActiveSlide(7);
                                    }
                                }
                            }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-left" />
                            </svg>
                        </button>
                        {
                            activeSlide < 7 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 1 }
                                        onClick={ () => setActiveSlide(1) }
                                    >
                                        { activeSlide === 1 ? '1. Asset Type' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 2 }
                                        onClick={ () => setActiveSlide(2) }
                                    >
                                        { activeSlide === 2 ? '2. Module' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 3 }
                                        onClick={ () => setActiveSlide(3) }
                                    >
                                        { activeSlide === 3 ? '3. Component' : '3' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 4 }
                                        onClick={ () => setActiveSlide(4) }
                                    >
                                        { activeSlide === 4 ? '4. Manufacturer' : '4' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 5 }
                                        onClick={ () => setActiveSlide(5) }
                                    >
                                        { activeSlide === 5 ? '5. Model Number' : '5' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 6 }
                                        onClick={ () => setActiveSlide(6) }
                                    >
                                        { activeSlide === 6 ? '6. Serial Number' : '6' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide > 6 && activeSlide < 14 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 7 }
                                        onClick={ () => setActiveSlide(7) }
                                    >
                                        { activeSlide === 7 ? '1. Reservoir Cap' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 8 }
                                        onClick={ () => setActiveSlide(8) }
                                    >
                                        { activeSlide === 8 ? '2. Criticality' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 9 }
                                        onClick={ () => setActiveSlide(9) }
                                    >
                                        { activeSlide === 9 ? '3. Filtration Method' : '3' }
                                    </CarouselNavButton>
                                    {/* <CarouselNavButton
                                        isActive={ activeSlide === 10 }
                                        onClick={ () => setActiveSlide(10) }
                                    >
                                        { activeSlide === 10 ? '4. Sample Before Filter?' : '4' }
                                    </CarouselNavButton> */}
                                    <CarouselNavButton
                                        isActive={ activeSlide === 10 }
                                        onClick={ () => setActiveSlide(10) }
                                    >
                                        { activeSlide === 10 ? '4. Filter Type' : '4' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 11 }
                                        onClick={ () => setActiveSlide(11) }
                                    >
                                        { activeSlide === 11 ? '5. Size Filtered' : '5' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 12 }
                                        onClick={ () => setActiveSlide(12) }
                                    >
                                        { activeSlide === 12 ? '6. Breathers' : '6' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide > 12 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 13 }
                                        onClick={ () => setActiveSlide(13) }
                                    >
                                        { activeSlide === 13 ? '1. Bearing Type' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 14 }
                                        onClick={ () => setActiveSlide(14) }
                                    >
                                        { activeSlide === 14 ? '2. Gear Type' : '2' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: activeSlide > 13 ? 0.5 : '' }}
                            aria-label="Next Section"
                            disabled={ activeSlide > 12 }
                            onClick={
                                () => {
                                    if (activeSlide < 6) {
                                        setActiveSlide(6);
                                    } else if (activeSlide > 6 && activeSlide < 13) {
                                        setActiveSlide(13);
                                    }
                                }
                            }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-right" />
                            </svg>
                        </button>
                    </div>

                    <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
                        {
                            activeSlide ?
                                <CarouselItems
                                    activeItem={ activeSlide }
                                    setActiveItem={ setActiveSlide }
                                >
                                    <CarouselItem index={ 1 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 1 of 6
                                        </h2>
                                        <p>
                                            What type of asset will be sampled?
                                        </p>
                                        <RadioList
                                            id="assetTypes"
                                            options={ assetTypes }
                                            value={{ label: assetType.name, value: assetType.id }}
                                            setValue={ obj => setAssetType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 2 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 2 of 6
                                        </h2>
                                        <p>
                                            What type of module is this?
                                        </p>
                                        <RadioList
                                            id="moduleTypes"
                                            options={ moduleTypes }
                                            value={{ label: module.name, value: module.id }}
                                            setValue={ obj => setModule({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 3 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 3 of 6
                                        </h2>
                                        <p>
                                            What type of component is this?
                                        </p>
                                        <RadioList
                                            id="componentTypes"
                                            options={ componentTypes }
                                            value={{ label: component.name, value: component.id }}
                                            setValue={ obj => setComponent({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 4 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 4 of 6
                                        </h2>
                                        <label htmlFor="panelManufacturer" className="mb-3">
                                            What is the asset manufacturer?
                                        </label>
                                        <input
                                            type="text"
                                            id="panelManufacturer"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ manufacturer }
                                            onChange={ e => setManufacturer(e.target.value) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 5 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 5 of 6
                                        </h2>
                                        <label htmlFor="panelModelNumber" className="mb-3">
                                            What is the asset model number?
                                        </label>
                                        <input
                                            type="text"
                                            id="panelModelNumber"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ modelNumber }
                                            onChange={ e => setModelNumber(e.target.value) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 6 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Equipment &ndash; 6 of 6
                                        </h2>
                                        <label htmlFor="panelSerialNumber" className="mb-3">
                                            What is the serial number?
                                        </label>
                                        <input
                                            type="text"
                                            id="panelSerialNumber"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ serialNumber }
                                            onChange={ e => setSerialNumber(e.target.value) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 7 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 1 of 6
                                        </h2>
                                        <p>
                                            What is the reservoir capacity?
                                        </p>
                                        <RadioList
                                            id="reservoirCaps"
                                            options={ reservoirCaps }
                                            value={{ label: reservoirCap.name, value: reservoirCap.id }}
                                            setValue={ obj => setReservoirCap({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 8 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 2 of 6
                                        </h2>
                                        <p>
                                            What is the criticality?
                                        </p>
                                        <RadioList
                                            id="criticalities"
                                            options={ criticalities }
                                            value={{ label: criticality.name, value: criticality.id }}
                                            setValue={ obj => setCriticality({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 9 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 3 of 6
                                        </h2>
                                        <p>
                                            What is the filtration method?
                                        </p>
                                        <RadioList
                                            id="filtrationMethods"
                                            options={ filtrationMethods }
                                            value={{ label: filtrationMethod.name, value: filtrationMethod.id }}
                                            setValue={ obj => setFiltrationMethod({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    {/* <CarouselItem index={ 10 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 4 of 7
                                        </h2>
                                        <p>
                                            Sample before filter?
                                        </p>
                                        <RadioList
                                            id="panelSampleBeforeFilter"
                                            options={ [{ label: 'Yes', value: true }, { label: 'No', value: false }] }
                                            value={{ label: sampleBeforeFilter ? 'Yes' : 'No', value: sampleBeforeFilter }}
                                            setValue={ obj => setSampleBeforeFilter(obj.value) }
                                        />
                                    </CarouselItem> */}
                                    <CarouselItem index={ 10 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 4 of 6
                                        </h2>
                                        <p>
                                            What is the filter type?
                                        </p>
                                        <RadioList
                                            id="filterTypes"
                                            options={ filterTypes }
                                            value={{ label: filterType.name, value: filterType.id }}
                                            setValue={ obj => setFilterType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 11 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 5 of 6
                                        </h2>
                                        <p>
                                            Size filtered?
                                        </p>
                                        <RadioList
                                            id="sizeFiltered"
                                            options={ sizeFilteredTypes }
                                            value={{ label: sizeFiltered.name, value: sizeFiltered.id }}
                                            setValue={ obj => setSizeFiltered({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 12 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Filtration &ndash; 6 of 6
                                        </h2>
                                        <p>
                                            Breathers?
                                        </p>
                                        <RadioList
                                            id="breatherTypes"
                                            options={ breatherTypes }
                                            value={{ label: breathers.name, value: breathers.id }}
                                            setValue={ obj => setBreathers({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 13 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Bearings &amp; Gears &ndash; 1 of 2
                                        </h2>
                                        <p>
                                            What is the bearing type?
                                        </p>
                                        <RadioList
                                            id="bearingTypes"
                                            options={ bearingTypes }
                                            value={{ label: bearingType.name, value: bearingType.id }}
                                            setValue={ obj => setBearingType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 14 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Bearings &amp; Gears &ndash; 2 of 2
                                        </h2>
                                        <p>
                                            What is the gear type?
                                        </p>
                                        <RadioList
                                            id="gearTypes"
                                            options={ gearTypes }
                                            value={{ label: gearType.name, value: gearType.id }}
                                            setValue={ obj => setGearType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                </CarouselItems> : ''
                        }

                        <div className="pb-5">
                            <div
                                className="position-relative mt-n3 pt-3"
                                style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #baddfc' }}
                            />
                            <div className="row align-items-center justify-content-center text-center pt-2">
                                <div className="col-4">
                                    <CarouselPrevButton>
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-left" />
                                        </svg>
                                        Prev
                                    </CarouselPrevButton>
                                </div>
                                <div className="col-4">
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: 147 }}
                                            onClick={ handleClose }
                                        >
                                            Done
                                        </button>
                                    </div>
                                <div className="col-4">
                                    <CarouselNextButton>
                                        Next
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-right" />
                                        </svg>
                                    </CarouselNextButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </Panel>
    );
};

export default EquipmentPanel;
