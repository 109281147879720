import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
    position: absolute;
    top: ${props => props.position.includes('bottom') || !props.position ? '2.5rem' : ''};
    right: ${props => props.position.includes('right') ? '-1rem' : ''};
    bottom: ${props => props.position.includes('top') ? '2.5rem' : ''};
    left: ${props => !props.position.includes('right') && !props.position.includes('left') ? '50%' : (props.position.includes('left') ? '-1rem' : '')};
    z-index: 900;
    font-size: 0.875rem;
    width: 250px;
    padding: ${props => props.position.includes('top') ? '1.5rem 1.5rem 2rem' : '2rem 1.5rem 1.5rem'};
    border-radius: 0.25rem;
    background-color: var(--gray-dark);
    box-shadow: 0 3px 10px rgba(17,25,38,0.6);
    color: #fff;
    visibility: ${props => props.isVisible ? '' : 'hidden' };
    opacity: ${props => props.isVisible ? '' : 0 };
    transform: ${props => props.isVisible ? (!props.position.includes('right') && !props.position.includes('left') ? 'translate(-50%,0)' : 'translate(0,0)') : (props => props.position.includes('bottom') || !props.position ? (!props.position.includes('right') && !props.position.includes('left') ? 'translate(-50%,20px)' : 'translate(0,20px)') : (!props.position.includes('right') && !props.position.includes('left') ? 'translate(-50%,-20px)' : 'translate(0,-20px)'))};
    transition: opacity 300ms, visibility 300ms, transform 300ms;

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: ${props => props.position.includes('bottom') || !props.position ? 0 : ''};
        bottom: ${props => props.position.includes('top') ? 0 : ''};
        right: 0;
        left: 0;
        height: 0.5rem;
        background-color: var(--secondary);
        border-radius: ${props => props.position.includes('top') ? '0 0 0.25rem 0.25rem' : '0.25rem 0.25rem 0 0'};
    }

    &::after {
        top: ${props => props.position.includes('bottom') || !props.position ? '-1.75rem' : ''};
        right: ${props => props.position.includes('right') ? '0.75rem' : ''};
        bottom: ${props => props.position.includes('top') ? '-1.75rem' : ''};
        left: ${props => !props.position.includes('right') && !props.position.includes('left') ? '50%' : (props.position.includes('left') ? '0.75rem' : '')};
        border: 1rem solid transparent;
        border-top-color: ${props => props.position.includes('top') ? 'var(--secondary)' : ''};
        border-bottom-color: ${props => props.position.includes('bottom') || !props.position ? 'var(--secondary)' : ''};
        transform: ${props => !props.position.includes('right') && !props.position.includes('left') ? 'translateX(-50%)' : ''};
    }
    &.status-tip{
        transform:${props => props.isVisible ? 'translate(-50%,0)' : 'translate(0,0)' }
    }
`;

const StyledButton = styled.button`
    position: relative;
    z-index: 1010;
`;
const StyledDiv = styled.div`
    position: relative;
    z-index: 1010;
`;
const Context = createContext();

const Tooltip = props => {
    const [isVisible, setIsVisible] = useState(false);
    const node = useRef();

    const handleOutsideClick = e => {
        if (!node.current.contains(e.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    });

    return (
        <Context.Provider value={{ isVisible, setIsVisible }}>
            <div
                ref={ node }
                className="position-relative"
                { ...props }
            >
                { props.children }
            </div>
        </Context.Provider>
    );
};

const TooltipTrigger = props => {
    const { isVisible, setIsVisible } = useContext(Context);

    return (
        <StyledButton
            { ...props }
            onClick={ e => { e.preventDefault(); setIsVisible(!isVisible); } }
        >
            { props.children }
        </StyledButton>
    );
};
const TooltipTriggerHover = props => {
    const { isVisible, setIsVisible } = useContext(Context);

    return (
        <StyledDiv
            { ...props }
            onMouseEnter={ e => { e.preventDefault(); setIsVisible(!isVisible); } }
            onMouseLeave={ e => { e.preventDefault(); setIsVisible(!isVisible); } }
        >
            { props.children }
        </StyledDiv>
    );
};
const TooltipContent = props => {
    const { isVisible } = useContext(Context);

    return (
        <StyledContent
            { ...props }
            isVisible={ isVisible }
            style={{zIndex: 9999, ...props.style}}
        >
            { props.children }
        </StyledContent>
    );
};

export { Tooltip, TooltipTrigger, TooltipTriggerHover, TooltipContent };
