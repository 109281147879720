import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledButton = styled.button`
    &.btn {
        width: 100%;
        height: ${props => isNaN(props.height) || props.height.length === 0 ? props.height : `${props.height}px`};
        text-align: left;
        text-transform: none;
        font-size: 0.875rem;
        font-weight: 400;
        color: ${props => props.color} !important;
        background-color: ${props => props.backgroundColor};
    }
`;

const StyledButtonSelected = styled(StyledButton)`
    &.btn {
        font-weight: 600;
        color: ${props => props.color} !important;
        background-color: ${props => props.backgroundColor};
        background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.15));
    }
`;

const StyledOptions = styled.div`
    position: absolute;
    right: 0;
    bottom: ${props => props.optionsAbove ? '100%' : ''};
    left: 0;
    z-index: 9;
    font-size: 0.875rem;
    transition: opacity 300ms, visibility 300ms, transform 300ms;

    & > div {
        color: ${props => props.color};
        background-color: ${props => props.backgroundColor};
        max-height: 180px;

        ::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    input {
        position: absolute;
        opacity: 0;

        &:checked + label,
        &:focus + label {
            color: ${props => props.highlightColor};
            background-color: ${props => props.highlightBackgroundColor};
            background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.15));
        }
    }

    label {
        position: relative;
        display: block;
        cursor: pointer;
        margin-bottom: 0;
        padding: 0.75rem 1.5rem;

        &:hover {
            color: ${props => props.highlightColor};
            background-color: ${props => props.highlightBackgroundColor};
            background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.15));
        }
    }
`;

const Select = props => {
    const { options, value, setValue } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const node = useRef();
    const optionsAbove = props.optionsLocation === 'above';

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleKeyUp = e => {
        if (e.keyCode === 13 || e.keyCode === 27) {
            setIsExpanded(false);
        }
    };

    const handleOutsideClick = e => {
        if (!node.current.contains(e.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        options.map(item => {
            if (item.checked) {
                setValue({ label: item.label, value: item.value });
            }
        });

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // eslint-disable-line

    return (
        <div
            ref={ node }
            className={ `position-relative ${props.className || ''}` }
        >
            {
                value === null  || value === '' ?
                    <StyledButton
                        className="btn d-flex align-items-center justify-content-between px-3"
                        height={ props.height || '' }
                        color={ props.color || 'var(--primary)' }
                        backgroundColor={ props.backgroundColor || 'var(--blue-light)' }
                        aria-expanded={ isExpanded }
                        onClick={ handleClick }
                        disabled={ props.disabled }
                    >
                        { props.actionText }
                        <svg width="10" height="5">
                            <use xlinkHref={ (isExpanded && !optionsAbove) || (!isExpanded && optionsAbove) ? '#icon-arrow-up' : '#icon-arrow-down' } />
                        </svg>
                    </StyledButton>
                    :
                    <StyledButtonSelected
                        className="btn d-flex align-items-center justify-content-between px-3"
                        height={ props.height || '' }
                        color={ props.highlightColor || '#fff' }
                        backgroundColor={ props.highlightBackgroundColor || 'var(--secondary)' }
                        disabled={ props.disabled }
                        onClick={ handleClick }
                    >
                        <span className="text-truncate">
                            { value.label }
                        </span>
                        <svg width="10" height="5">
                            <use xlinkHref={ (isExpanded && !optionsAbove) || (!isExpanded && optionsAbove) ? '#icon-arrow-up' : '#icon-arrow-down' } />
                        </svg>
                    </StyledButtonSelected>
            }

            <StyledOptions
                className={ classNames('rounded overflow-hidden', {
                    'pt-3': !optionsAbove,
                    'pb-3': optionsAbove
                }) }
                style={{
                    opacity: isExpanded ? '' : 0,
                    visibility: isExpanded ? '' : 'hidden',
                    transform: isExpanded ? '' : (optionsAbove ? 'translateY(-20px)' : 'translateY(20px)')
                }}
                optionsAbove={ optionsAbove }
                color={ props.optionColor || 'var(--dark)' }
                highlightColor={ props.highlightColor || '#fff' }
                backgroundColor={ props.backgroundColor || 'var(--blue-light)' }
                highlightBackgroundColor={ props.highlightBackgroundColor || 'var(--secondary)' }
            >
                <div style={{
                    position: 'absolute',
                    top: !optionsAbove ? '-0.5rem' : '',
                    bottom: optionsAbove ? '-0.5rem' : '',
                    right: '0.625rem',
                    border: '0.75rem solid transparent',
                    borderBottomColor: optionsAbove ? 'transparent' : (props.backgroundColor || 'var(--blue-light)'),
                    borderTopColor: !optionsAbove ? 'transparent' : (props.backgroundColor || 'var(--blue-light)'),
                    background: 'transparent'
                }} />
                <div className="position-relative rounded overflow-auto">
                    {
                        options.map((item, index) => {
                            const id = `${props.id}${index}`;

                            return (
                                <React.Fragment key={ index }>
                                    <input
                                        id={ id }
                                        type="radio"
                                        name={ props.id }
                                        value={ item.value }
                                        checked={ value !== null && item.value === value.value }
                                        onChange={ () => setValue({ label: item.label, value: item.value }) }
                                        onKeyUp={ handleKeyUp }
                                    />
                                    <label
                                        className="text-truncate"
                                        htmlFor={ id }
                                        onClick={ () => { setIsExpanded(false) } }
                                    >
                                        { item.label }
                                    </label>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </StyledOptions>
        </div>
    );
};

export default Select;