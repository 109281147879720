import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Context from '../../context/Context';
import styled from 'styled-components';
import getPowerBIEmbed from '../../api/getPowerBIEmbed';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const Styled = styled.div`
    & > div {
        height: 80vh;
    }
`;

const JourneyAnalysis = () => {
    const {
        token,
        setPageTitle,
        showLoadingOverlay,
    } = useContext(Context);

    const [embedURL, setEmbedUrl] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        setPageTitle('Journey Analysis');
        showLoadingOverlay(true);
    }, []); // eslint-disable-line

    useEffect(() => {
        getPowerBIEmbed(token, res => {
            const reports = res.EmbedReports[0];
            const tokens = res.EmbedToken;
            setEmbedUrl(reports.EmbedUrl);
            setReportId(reports.ReportId);
            setAccessToken(tokens.Token);
            showLoadingOverlay(false);
        });
    }, []); // eslint-disable-line

    return (
        <div className="container-fluid overflow-hidden">
            <Helmet>
                <title>Journey Analysis | Trico Orbital Portal</title>
            </Helmet>

            {
                showLoadingOverlay ?
                    <Styled>
                        <PowerBIEmbed
                            embedConfig={{
                                type: 'report',
                                id: reportId,
                                embedUrl: embedURL,
                                accessToken: accessToken,
                                tokenType: models.TokenType.Embed,
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: true,
                                            visible: true
                                        }
                                    }
                                }
                            }}
                        />
                    </Styled> : ''
            }
        </div>
    );
};

export default JourneyAnalysis;
