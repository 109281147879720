import React, { useState, useEffect } from "react";

const CheckboxList = props => {
    const [value, setValue] = useState(props.value || []);

    const handleChange = item => {
        let items = [...value];

        if (items.filter(x => x.value === item.value).length > 0) {
            items = items.filter(x => x.value !== item.value);
        } else {
            items.push(item);
        }

        setValue(items);
        if (props.setValue) {
            props.setValue(items);
        }
    };

    useEffect(() => {
        if (props.setValue) {
            setValue(props.value);
        }
    }, [props]);

    return (
        <div className={ `row row-cols-${props.cols ? props.cols : 1}` }>
            {
                props.options.map((item, index) => {
                    const id = `${props.id}${index}`;
                    const checked = value.filter(x => x.value === item.value).length > 0;

                    return (
                        <div key={ index } className="col pb-1">
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id={ id }
                                    type="checkbox"
                                    value={ item.value }
                                    checked={ checked }
                                    onChange={ () => handleChange(item) }
                                />
                                <label
                                    className="custom-control-label"
                                    style={{ paddingTop: `${3/16}rem` }}
                                    htmlFor={ id }
                                >
                                    { item.label }
                                </label>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default CheckboxList;
