import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import getJourneyQuestions from '../../../../../../api/getJourneyQuestions';
import postJourneyQuestions from '../../../../../../api/postJourneyQuestions';
import Context from '../../../../../../context/Context';

const Questions = props => {
    const { token, sites, showLoadingOverlay } = useContext(Context);
    const [questions, setQuestions] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [isSaved, setIsSaved] = useState(false);

    const handleChange = (item, answerValue) => {
        const index = questions.findIndex(x => x.QuestionID === item.QuestionID);

        if (item.AllowMultipleSelections) {
            item.Answers = item.Answers.map(x => (
                x.AnswerValue === answerValue ? { ...x, Selected: !x.Selected } : x
            ));
        } else {
            item.Answers.forEach(answer => (
                answer.Selected = answer.AnswerValue === answerValue
            ));
        }

        questions[index] = item;

        setQuestions([...questions]);
    };

    const handleSubmit = e => {
        e.preventDefault();

        const data = questions.map(item => (
            item.AllowMultipleSelections ?
                {
                    QuestionID: item.QuestionID,
                    AnswerArray: item.Answers.filter(x => x.Selected).map(x => x.AnswerValue)
                }
                :
                {
                    QuestionID: item.QuestionID,
                    Answer: item.Answers.filter(x => x.Selected)[0].AnswerValue
                }
        ));

        showLoadingOverlay(true);
        setIsSaved(false);

        postJourneyQuestions(token, accountId, data, () => {
            setIsSaved(true);

            props.updateJourneyScore(() => {
                showLoadingOverlay(false);
            });
        });
    };

    useEffect(() => {
        const id = sites.filter(x => x.id === props.site.id)[0].accountId;
        setAccountId(id);

        getJourneyQuestions(token, id, props.stage, res => {
            setQuestions(res.Questions);
        });
    }, []); // eslint-disable-line

    return (
        questions.length > 0 ?
        <div className='col-6'>
        <div className="rounded bg-gray-dark h-100 p-4">
            <h3 className="font-weight-semibold border-bottom mx-n3 px-3 mb-3 pb-3">
                <span className="d-inline-block" style={{ maxWidth: 380 }}>
                    Provide answers to improve your sample analysis.
                </span>
            </h3>
            <p className="mb-5" style={{ maxWidth: 400 }}>
                The best way to improve your lubrication program and sample analysis is to provide us with as much information as possible about your practices, equipment and capabilities. Your answers are used to calculate your respective stage scores, provide improvement actions and analyze samples.
            </p>
            <form onSubmit={ handleSubmit }>
                {
                    questions.length === 0 ?
                        <div className="pt-4">
                            <div
                                className="d-block spinner-border text-secondary mx-auto mt-5"
                                style={{ width: '3rem', height: '3rem' }}
                                role="status"
                            >
                                <span className="sr-only">
                                    Loading&hellip;
                                </span>
                            </div>
                        </div>
                        :
                        <>
                            {
                                questions.map((item, index) => (
                                    <div key={ index } className="mb-5">
                                        <h4 className="h6 d-flex font-weight-semibold pb-1">
                                            <span className="mr-2">{ index + 1}. </span>
                                            { item.QuestionText }
                                        </h4>
                                        {
                                            item.Answers.map((option, optionIndex) => {
                                                const name = `question-${index + 1}`;
                                                const id = `${name}-${optionIndex + 1}`;

                                                return (
                                                    item.AllowMultipleSelections ?
                                                        <React.Fragment key={ optionIndex }>
                                                            <input
                                                                style={{ position: 'absolute', opacity: 0 }}
                                                                type="checkbox"
                                                                name={ name }
                                                                id={ id }
                                                                value={ option.AnswerValue || '' }
                                                                checked={ option.Selected }
                                                                onChange={ () => handleChange(item, option.AnswerValue) }
                                                            />
                                                            <label
                                                                className={ classNames('btn d-inline-flex align-items-center justify-content-center border-0 font-weight-semibold ml-3 py-1', {
                                                                    'btn-dark text-primary': !option.Selected,
                                                                    'bg-secondary text-white': option.Selected
                                                                }) }
                                                                style={{
                                                                    textTransform: 'none',
                                                                    borderRadius: '2rem',
                                                                    minWidth: 100,
                                                                    minHeight: `${36/16}rem`,
                                                                    backgroundImage: option.Selected ? 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)' : ''
                                                                }}
                                                                htmlFor={ id }
                                                            >
                                                                { option.AnswerText }
                                                            </label>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment key={ optionIndex }>
                                                            <input
                                                                style={{ position: 'absolute', opacity: 0 }}
                                                                type="radio"
                                                                name={ name }
                                                                id={ id }
                                                                value={ option.AnswerValue || '' }
                                                                checked={ option.Selected }
                                                                onChange={ () => handleChange(item, option.AnswerValue) }
                                                                required
                                                            />
                                                            {
                                                                option.AnswerValue ?
                                                                    <label
                                                                        className={ classNames('btn d-inline-flex align-items-center justify-content-center border-0 font-weight-semibold ml-3 py-1', {
                                                                            'btn-dark text-primary': !option.Selected,
                                                                            'bg-secondary text-white': option.Selected
                                                                        }) }
                                                                        style={{
                                                                            textTransform: 'none',
                                                                            borderRadius: '2rem',
                                                                            minWidth: 100,
                                                                            minHeight: `${36/16}rem`,
                                                                            backgroundImage: option.Selected ? 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)' : '',
                                                                            pointerEvents: option.Selected ? 'none' : ''
                                                                        }}
                                                                        htmlFor={ id }
                                                                    >
                                                                        { option.AnswerText }
                                                                    </label>
                                                                    :
                                                                    <label
                                                                        className={ classNames('btn d-inline-flex align-items-center justify-content-center border-0 font-weight-semibold ml-3 py-1', {
                                                                            'btn-dark text-primary': !option.Selected,
                                                                            'bg-secondary text-white': option.Selected
                                                                        }) }
                                                                        style={{
                                                                            textTransform: 'none',
                                                                            borderRadius: '2rem',
                                                                            minWidth: 100,
                                                                            minHeight: `${36/16}rem`,
                                                                            backgroundImage: option.Selected ? 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)' : '',
                                                                            pointerEvents: option.Selected ? 'none' : ''
                                                                        }}
                                                                        htmlFor={ id }
                                                                    >
                                                                        { option.AnswerText }
                                                                    </label>
                                                            }
                                                        </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                ))
                            }

                            <div className="text-center mb-4">
                                <button
                                    className="btn btn-primary"
                                    style={{ minWidth: 125 }}
                                >
                                    Save
                                </button>

                                {
                                    isSaved ?
                                        <p className="font-weight-bold mt-4 mb-n3">
                                            Your answers have been saved!
                                        </p> : ''
                                }
                            </div>
                        </>
                }
            </form>
        </div>
        </div>
        : <></>
    );
};

export default Questions;
