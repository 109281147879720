const apiUrl = process.env.REACT_APP_API_URL;

const getConditionSummary = (token, id, startDate, endDate, sortByArea, sortByComponent, includeInactive, spid, spidName, description, lubricant, cb) => {
    fetch(`${apiUrl}/conditionreportsummary`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            AreaList: id,
            FromDate: startDate,
            ToDate: endDate,
            IncludeInactive: includeInactive,
            GroupArea: sortByArea,
            GroupAsset: sortByComponent,
            SPID: spid,
            SamplePointName: spidName,
            SamplePointDescription: description,
            LubeID: lubricant
        })
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getConditionSummary;