const apiUrl = process.env.REACT_APP_API_URL;

const postEDS = (token, data, cb) => {
    fetch(`${apiUrl}/eds`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default postEDS;
