import React, { useContext, useEffect, useState } from 'react';
import getLubricantBrandNames from '../../../api/getLubricantBrandNames';
import Context from '../../../context/Context';
import RadioList from '../../../components/RadioList';
import Panel from '../../../components/Panel';
import { Carousel, CarouselItems, CarouselItem, CarouselPrevButton, CarouselNextButton, CarouselNavButton, CarouselGroupNavButton } from './PanelCarousel';
import ManufacturerSearch from './ManufacturerSearch';

const LubricantPanel = props => {
    const { token } = useContext(Context);
    const { isVisible, setIsVisible } = props;
    const [activeSlide, setActiveSlide] = useState(null);
    const [Program, setProgram] = useState({ name: '', id: null });
    const [SPIDName, setSPIDName] = useState('');
    const [SPIDDescription, setSPIDDescription] = useState('');
    const [fluidType, setFluidType] = useState({ name: '', id: null });
    const [coolantType, setCoolantType] = useState({ name: '', id: null });
    const [fluidManufacturer, setFluidManufacturer] = useState({ name: '', id: null });
    const [fluidProductTradeName, setFluidProductTradeName] = useState({ name: '', id: null });
    const [specificFluidType, setSpecificFluidType] = useState('');
    const [samplingMethod, setSamplingMethod] = useState({ name: '', id: null });
    const [samplingFrequency, setSamplingFrequency] = useState({ name: '', id: null });
    const [samplingLocation, setSamplingLocation] = useState({ name: '', id: null });
    const [ISOCleanliness1, setISOCleanliness1] = useState('');
    const [ISOCleanliness2, setISOCleanliness2] = useState('');
    const [ISOCleanliness3, setISOCleanliness3] = useState('');
    const [inspectionType, setInspectionType] = useState({ name: '', id: null });
    const [inspectionFrequency, setInspectionFrequency] = useState({ name: '', id: null });
    const [inspector, setInspector] = useState({ name: '', id: null });
    const [notes, setNotes] = useState('');
    const [, setPrograms] = useState([]);
    const [fluidTypes, setFluidTypes] = useState([]);
    const [coolantTypes, setCoolantTypes] = useState([]);
    const [lubricantBrandNames, setLubricantBrandNames] = useState([]);
    const [samplingMethods, setSamplingMethods] = useState([]);
    const [samplingLocations, setSamplingLocations] = useState([]);
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [inspectionTimings, setInspectionTimings] = useState([]);
    const [inspectors, setInspectors] = useState([]);
    const [manufacturerSearchTerm, setManufacturerSearchTerm] = useState('');
    const [lubricantSearchTerm, setLubricantSearchTerm] = useState('');

    const handleClose = () => {
        setIsVisible(false);
        props.setProgram(Program);
        props.setSPIDName(SPIDName);
        props.setSPIDDescription(SPIDDescription);
        props.setFluidType(fluidType);
        props.setCoolantType(coolantType);
        props.setFluidManufacturer(fluidManufacturer);
        props.setSpecificFluidType(specificFluidType);
        props.setFluidProductTradeName(fluidProductTradeName);
        props.setSamplingMethod(samplingMethod);
        props.setSamplingFrequency(samplingFrequency);
        props.setSamplingLocation(samplingLocation);
        props.setISOCleanliness1(ISOCleanliness1);
        props.setISOCleanliness2(ISOCleanliness2);
        props.setISOCleanliness3(ISOCleanliness3);
        props.setInspectionType(inspectionType);
        props.setInspectionFrequency(inspectionFrequency);
        props.setInspector(inspector);
        props.setNotes(notes);
    };

    useEffect(() => {
        setActiveSlide(props.activeSlide);
        setProgram(props.Program);
        setSPIDName(props.SPIDName);
        setSPIDDescription(props.SPIDDescription);
        setFluidType(props.fluidType);
        setCoolantType(props.coolantType);
        setFluidManufacturer(props.fluidManufacturer);
        setSpecificFluidType(props.specificFluidType);
        setFluidProductTradeName(props.fluidProductTradeName);
        setSamplingMethod(props.samplingMethod);
        setSamplingFrequency(props.samplingFrequency);
        setSamplingLocation(props.samplingLocation);
        setISOCleanliness1(props.ISOCleanliness1);
        setISOCleanliness2(props.ISOCleanliness2);
        setISOCleanliness3(props.ISOCleanliness3);
        setInspectionType(props.inspectionType);
        setInspectionFrequency(props.inspectionFrequency);
        setInspector(props.inspector);
        setNotes(props.notes);
        setPrograms(props.programs);
        setFluidTypes(props.fluidTypes);
        setCoolantTypes(props.coolantTypes);
        setLubricantBrandNames(props.lubricantBrandNames);
        setSamplingMethods(props.samplingMethods);
        setSamplingLocations(props.samplingLocations);
        setInspectionTypes(props.inspectionTypes);
        setInspectionTimings(props.inspectionTimings);
        setInspectors(props.inspectors);
        setManufacturerSearchTerm(props.fluidManufacturer.name);
    }, [props]);

    useEffect(() => {
        if (isVisible && fluidManufacturer.id) {
            getLubricantBrandNames(token, fluidManufacturer.id, res => {
                if (Array.isArray(res)) {
                    setLubricantBrandNames(res.map(item => (
                        { label: item.Name, value: item.ID }
                    )));
                }
            });
        }
    }, [fluidManufacturer]); // eslint-disable-line

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <Carousel>
                <div
                    className="d-flex flex-column overflow-hidden m-n4"
                    style={{ height: 'calc(100% + 3rem)' }}
                >
                    <div className="d-flex bg-gray text-white">
                        <CarouselGroupNavButton
                            isActive={ activeSlide < 4 }
                            onClick={ () => setActiveSlide(1) }
                        >
                            SPID
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide > 3 && activeSlide < 9 }
                            onClick={ () => setActiveSlide(4) }
                        >
                            Fluid
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide > 8 && activeSlide < 12 }
                            onClick={ () => setActiveSlide(9) }
                        >
                            Sampling
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide === 12 }
                            onClick={ () => setActiveSlide(12) }
                        >
                            ISO Targets
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide > 12 && activeSlide < 16 }
                            onClick={ () => setActiveSlide(13) }
                        >
                            Inspect
                        </CarouselGroupNavButton>
                        <CarouselGroupNavButton
                            isActive={ activeSlide === 16 }
                            onClick={ () => setActiveSlide(16) }
                        >
                            Notes
                        </CarouselGroupNavButton>
                    </div>
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            height: 44,
                            borderBottom: '1px solid rgba(46,63,92,0.4)'
                        }}
                    >
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: activeSlide < 4 ? 0.5 : '' }}
                            aria-label="Previous Section"
                            disabled={ activeSlide < 4 }
                            onClick={
                                () => {
                                    if (activeSlide > 3 && activeSlide < 9) {
                                        setActiveSlide(1);
                                    } else if (activeSlide > 8 && activeSlide < 12) {
                                        setActiveSlide(4);
                                    } else if (activeSlide === 12) {
                                        setActiveSlide(9);
                                    } else if (activeSlide > 12 && activeSlide < 16) {
                                        setActiveSlide(12);
                                    } else if (activeSlide === 16) {
                                        setActiveSlide(13);
                                    }
                                }
                            }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-left" />
                            </svg>
                        </button>
                        {
                            activeSlide < 4 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 1 }
                                        onClick={ () => setActiveSlide(1) }
                                    >
                                        { activeSlide === 1 ? '1. Program' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 2 }
                                        onClick={ () => setActiveSlide(2) }
                                    >
                                        { activeSlide === 2 ? '2. Name/ID' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 3 }
                                        onClick={ () => setActiveSlide(3) }
                                    >
                                        { activeSlide === 3 ? '3. Description' : '3' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide > 3 && activeSlide < 9 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 4 }
                                        onClick={ () => setActiveSlide(4) }
                                    >
                                        { activeSlide === 4 ? '1. Fluid Type' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 5 }
                                        onClick={ () => setActiveSlide(5) }
                                    >
                                        { activeSlide === 5 ? '2. Coolant Type' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 6 }
                                        onClick={ () => setActiveSlide(6) }
                                    >
                                        { activeSlide === 6 ? '3. Fluid Manufacturer' : '3' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 7 }
                                        onClick={ () => setActiveSlide(7) }
                                    >
                                        { activeSlide === 7 ? '4. Fluid Product Trade Name' : '4' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 8 }
                                        onClick={ () => setActiveSlide(8) }
                                    >
                                        { activeSlide === 8 ? '5. Specific Fluid Type' : '5' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide > 8 && activeSlide < 12 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 9 }
                                        onClick={ () => setActiveSlide(9) }
                                    >
                                        { activeSlide === 9 ? '1. Sampling Method' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 10 }
                                        onClick={ () => setActiveSlide(10) }
                                    >
                                        { activeSlide === 10 ? '2. Sampling Frequency' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 11 }
                                        onClick={ () => setActiveSlide(11) }
                                    >
                                        { activeSlide === 11 ? '3. Sampling Location' : '3' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide === 12 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 12 }
                                        onClick={ () => setActiveSlide(12) }
                                    >
                                        { activeSlide === 12 ? '1. ISO Cleanliness' : '1' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide > 12 && activeSlide < 16 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 13 }
                                        onClick={ () => setActiveSlide(13) }
                                    >
                                        { activeSlide === 13 ? '1. Visual Oil Inspection Type' : '1' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 14 }
                                        onClick={ () => setActiveSlide(14) }
                                    >
                                        { activeSlide === 14 ? '2. Visual Inspection Frequency' : '2' }
                                    </CarouselNavButton>
                                    <CarouselNavButton
                                        isActive={ activeSlide === 15 }
                                        onClick={ () => setActiveSlide(15) }
                                    >
                                        { activeSlide === 15 ? '3. Visual Inspector' : '3' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        {
                            activeSlide === 16 ?
                                <div className="d-flex flex-grow-1">
                                    <CarouselNavButton
                                        isActive={ activeSlide === 16 }
                                        onClick={ () => setActiveSlide(16) }
                                    >
                                        { activeSlide === 16 ? '1. Notes' : '1' }
                                    </CarouselNavButton>
                                </div> : ''
                        }
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: activeSlide > 15 ? 0.5 : '' }}
                            aria-label="Next Section"
                            disabled={ activeSlide > 15 }
                            onClick={
                                () => {
                                    if (activeSlide < 4) {
                                        setActiveSlide(4);
                                    } else if (activeSlide > 3 && activeSlide < 9) {
                                        setActiveSlide(9);
                                    } else if (activeSlide > 8 && activeSlide < 12) {
                                        setActiveSlide(12);
                                    } else if (activeSlide === 12) {
                                        setActiveSlide(13);
                                    } else if (activeSlide > 12 && activeSlide < 16) {
                                        setActiveSlide(16);
                                    }
                                }
                            }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-right" />
                            </svg>
                        </button>
                    </div>

                    <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
                        {
                            activeSlide ?
                                <CarouselItems
                                    activeItem={ activeSlide }
                                    setActiveItem={ setActiveSlide }
                                >
                                    <CarouselItem index={ 1 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            SPID Information &ndash; 1 of 3
                                        </h2>
                                        <p>
                                            {/* Select a program for this SPID. */}
                                            {Program.name ? Program.name : "Undefined"}
                                        </p>
                                        {/* <RadioList
                                            id="program"
                                            options={ programs }
                                            value={{ label: Program.name, value: Program.id }}
                                            setValue={ obj => setProgram({ name: obj.label, id: obj.value }) }
                                            disabled={ props.entityID }
                                        /> */}
                                    </CarouselItem>
                                    <CarouselItem index={ 2 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            SPID Information &ndash; 2 of 3
                                        </h2>
                                        <label htmlFor="panelSPIDName" className="mb-3">
                                            Enter a name/ID for this SPID.
                                        </label>
                                        <input
                                            type="text"
                                            id="panelSPIDName"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ SPIDName }
                                            onChange={ e => {
                                                setSPIDName(e.target.value);
                                            } }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 3 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            SPID Information &ndash; 3 of 3
                                        </h2>
                                        <label htmlFor="panelSPIDDescription" className="mb-3">
                                            Enter a description for this SPID.
                                        </label>
                                        <input
                                            type="text"
                                            id="panelSPIDDescription"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ SPIDDescription }
                                            onChange={ e => {
                                                setSPIDDescription(e.target.value);
                                            } }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 4 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 1 of 5
                                        </h2>
                                        <p>
                                            What type of fluid is this?
                                        </p>
                                        <RadioList
                                            id="fluidTypes"
                                            options={ fluidTypes }
                                            value={{ label: fluidType.name, value: fluidType.id }}
                                            setValue={ obj => setFluidType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 5 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 1 of 3
                                        </h2>
                                        <p>
                                            What type of coolant is this?
                                        </p>
                                        <RadioList
                                            id="coolantTypes"
                                            options={ coolantTypes }
                                            value={{ label: coolantType.name, value: coolantType.id }}
                                            setValue={ obj => setCoolantType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 6 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 2 of 3
                                        </h2>
                                        <p>
                                            Who is the manufacturer of this fluid?
                                        </p>
                                        <ManufacturerSearch
                                            manufacturer={ fluidManufacturer }
                                            setManufacturer={ obj => setFluidManufacturer({ name: obj.name, id: obj.id }) }
                                            searchTerm={ manufacturerSearchTerm }
                                            setSearchTerm={ setManufacturerSearchTerm }
                                            placeholder="Search manufacturers…"
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 7 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 3 of 3
                                        </h2>
                                        <p>
                                            What is the complete trade name of the product?
                                        </p>
                                        {
                                            fluidManufacturer.id ?
                                                <>
                                                    {
                                                        lubricantBrandNames.length > 20 ?
                                                            <>
                                                                <div className="position-relative mb-3">
                                                                    <label htmlFor="lubricantBrandNameSearch" className="sr-only">
                                                                        Search
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="lubricantBrandNameSearch"
                                                                        className="form-control bg-blue-light text-dark border border-primary"
                                                                        style={{ borderRadius: '3rem', paddingLeft: '2.5rem' }}
                                                                        value={ lubricantSearchTerm }
                                                                        onChange={ e => setLubricantSearchTerm(e.target.value) }
                                                                        placeholder="Start typing to narrow results…"
                                                                        autoComplete="off"
                                                                    />
                                                                    <svg
                                                                        className="position-absolute text-primary"
                                                                        style={{ top: 11, left: 12 }}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18" height="18"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="arcs"
                                                                    >
                                                                        <circle cx="11" cy="11" r="8" />
                                                                        <line x1="21" y1="21" x2="16.65" y2="16.65" />
                                                                    </svg>
                                                                </div>
                                                                <RadioList
                                                                    id="lubricantBrandNames"
                                                                    options={ lubricantBrandNames.filter(x => x.label.toLowerCase().includes(lubricantSearchTerm.toLowerCase())) }
                                                                    value={{ label: fluidProductTradeName.name, value: fluidProductTradeName.id }}
                                                                    setValue={ obj => setFluidProductTradeName({ name: obj.label, id: obj.value }) }
                                                                />
                                                            </>
                                                            :
                                                            <RadioList
                                                                id="lubricantBrandNames"
                                                                options={ lubricantBrandNames }
                                                                value={{ label: fluidProductTradeName.name, value: fluidProductTradeName.id }}
                                                                setValue={ obj => setFluidProductTradeName({ name: obj.label, id: obj.value }) }
                                                            />
                                                    }
                                                </>
                                                :
                                                <>
                                                    You must first <button
                                                        className="btn bg-transparent border-0 p-0 text-primary"
                                                        style={{
                                                            textDecoration: 'underline',
                                                            lineHeight: 'inherit',
                                                            fontWeight: 'inherit',
                                                            fontSize: 'inherit',
                                                            textTransform: 'none'
                                                        }}
                                                        onClick={ e => setActiveSlide(6) }
                                                    >
                                                        choose a manufacturer
                                                    </button>.
                                                </>
                                        }
                                    </CarouselItem>
                                    <CarouselItem index={ 8 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 5 of 5
                                        </h2>
                                        <label htmlFor="panelSpecificFluidType" className="mb-3">
                                            What is the specific fluid type?
                                        </label>
                                        <input
                                            type="text"
                                            id="panelSpecificFluidType"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ specificFluidType }
                                            onChange={ e => setSpecificFluidType(e.target.value) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 9 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Sampling Information &ndash; 1 of 3
                                        </h2>
                                        <p>
                                            What is the sampling method for this SPID?
                                        </p>
                                        <RadioList
                                            id="samplingMethods"
                                            options={ samplingMethods }
                                            value={{ label: samplingMethod.name, value: samplingMethod.id }}
                                            setValue={ obj => setSamplingMethod({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 10 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Sampling Information &ndash; 2 of 3
                                        </h2>
                                        <p>
                                            How often will you collect samples for this equipment?
                                        </p>
                                        <RadioList
                                            id="samplingFrequency"
                                            options={ [
                                                { label: 'Monthly', value: 12 },
                                                { label: 'Bimonthly', value: 6 },
                                                { label: 'Quarterly', value: 4 },
                                                { label: 'Semi-Annually', value: 2 },
                                                { label: 'Annually', value: 1 },
                                                { label: 'As Needed', value: 0 }
                                            ] }
                                            value={{ label: samplingFrequency.name, value: samplingFrequency.id }}
                                            setValue={ obj => setSamplingFrequency({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 11 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Sampling Information &ndash; 3 of 3
                                        </h2>
                                        <p>
                                            What is the location of this sample collection point?
                                        </p>
                                        <RadioList
                                            id="samplingLocations"
                                            options={ samplingLocations }
                                            value={{ label: samplingLocation.name, value: samplingLocation.id }}
                                            setValue={ obj => setSamplingLocation({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 12 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            ISO Targets
                                        </h2>
                                        <p>
                                            What are the ISO cleanliness targets?
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <label htmlFor="panelISOCleanliness1" className="sr-only">
                                                ISO Cleanliness 1
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control bg-blue-light text-dark border border-primary text-center px-2"
                                                style={{ width: '3rem' }}
                                                id="panelISOCleanliness1"
                                                value={ ISOCleanliness1 }
                                                onChange={ e => {
                                                    setISOCleanliness1(e.target.value);
                                                } }
                                            />
                                            <div className="mx-3">/</div>
                                            <label htmlFor="panelISOCleanliness2" className="sr-only">
                                                ISO Cleanliness 2
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control bg-blue-light text-dark border border-primary text-center px-2"
                                                style={{ width: '3rem' }}
                                                id="panelISOCleanliness2"
                                                value={ ISOCleanliness2 }
                                                onChange={ e => {
                                                    setISOCleanliness2(e.target.value);
                                                } }
                                            />
                                            <div className="mx-3">/</div>
                                            <label htmlFor="ISOCleanliness3" className="sr-only">
                                                ISO Cleanliness 3
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control bg-blue-light text-dark border border-primary text-center px-2"
                                                style={{ width: '3rem' }}
                                                id="panelISOCleanliness3"
                                                value={ ISOCleanliness3 }
                                                onChange={ e => {
                                                    setISOCleanliness3(e.target.value);
                                                } }
                                            />
                                        </div>
                                    </CarouselItem>
                                    <CarouselItem index={ 13 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Visual Oil Inspection &ndash; 1 of 3
                                        </h2>
                                        <p>
                                            What is the visual oil inspection method?
                                        </p>
                                        <RadioList
                                            id="inspectionTypes"
                                            options={ inspectionTypes }
                                            value={{ label: inspectionType.name, value: inspectionType.id }}
                                            setValue={ obj => setInspectionType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 14 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Visual Oil Inspection &ndash; 2 of 3
                                        </h2>
                                        <p>
                                            How often will the oil be inspected?
                                        </p>
                                        <RadioList
                                            id="inspectionFrequency"
                                            options={ inspectionTimings }
                                            value={{ label: inspectionFrequency.name, value: inspectionFrequency.id }}
                                            setValue={ obj => setInspectionFrequency({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 15 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Visual Oil Inspection &ndash; 3 of 3
                                        </h2>
                                        <p>
                                            Who will perform the inspection?
                                        </p>
                                        <RadioList
                                            id="inspectors"
                                            options={ inspectors }
                                            value={{ label: inspector.name, value: inspector.id }}
                                            setValue={ obj => setInspector({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 16 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Notes
                                        </h2>
                                        <label htmlFor="panelNotes" className="mb-3">
                                            Add notes for other viewers of this record.
                                        </label>
                                        <textarea
                                            id="panelNotes"
                                            className="form-control bg-blue-light border-primary text-dark py-3"
                                            style={{ height: 195, resize: 'none' }}
                                            value={ notes }
                                            onChange={ e => {
                                                setNotes(e.target.value);
                                            } }
                                        />
                                    </CarouselItem>
                                </CarouselItems> : ''
                        }

                        <div className="pb-5">
                            <div
                                className="position-relative mt-n3 pt-3"
                                style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #baddfc' }}
                            />
                            <div className="row align-items-center justify-content-center text-center pt-2">
                                <div className="col-4">
                                    <CarouselPrevButton>
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-left" />
                                        </svg>
                                        Prev
                                    </CarouselPrevButton>
                                </div>
                                <div className="col-4">
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: 147 }}
                                        onClick={ handleClose }
                                    >
                                        Done
                                    </button>
                                </div>
                                <div className="col-4">
                                    <CarouselNextButton>
                                        Next
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-right" />
                                        </svg>
                                    </CarouselNextButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </Panel>
    );
};

export default LubricantPanel;

