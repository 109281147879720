import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import Modal from './Modal';

const Styled = styled.div`
    transition: opacity 250ms ease-out;

    &:hover {
        .btn::after {
            opacity: 0.5;
        }
    }
    
    &:focus-within {
        .btn::after {
            opacity: 0.5;
        }
    }

    .btn {
        position: relative;
        z-index: 1;
        display: flex;
        font-size: ${12/16}rem;
        color: #fff !important;
        text-align: left;
        font-weight: 400;
        text-transform: none;
        background: transparent;
        box-shadow: 0 0 6px rgba(17,25,38,0.87);
        border: none;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            width: 100%;
            height: 100%;
            background-color: #1B273B;
        }

        &::after {
            z-index: -1;
            background: linear-gradient(-45deg, #65B7FF, #118AF7, #025FB2);
            opacity: 0;
            transition: opacity 150ms ease-out;
        }
    }

    time::first-letter {
        text-transform: uppercase;
    }
`;

const Icon = ({ className, severity }) => {
    let backgroundColor = 'rgba(162,144,243,0.15)';

    if (severity === 'Warning') {
        backgroundColor = 'rgba(255,244,95,0.15)';
    } else if (severity === 'Error') {
        backgroundColor = 'rgba(209,60,64,0.15)';
    }

    return (
        <div
            className={ `d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 ${className || ''}` }
            style={{ width: 50, height: 50, backgroundColor }}
        >
            {
                severity === 'Information' ?
                    <svg className="text-secondary" width="24" height="23">
                        <use xlinkHref="#icon-messsage" />
                    </svg> : ''
            }
            {
                severity === 'Warning' ?
                    <svg className="text-warning" width="5" height="24">
                        <use xlinkHref="#icon-exclamation-point" />
                    </svg> : ''
            }
            {
                severity === 'Error' ?
                    <svg className="text-danger mt-n1" width="25" height="21">
                        <use xlinkHref="#icon-exclamation-triangle" />
                    </svg> : ''
            }
        </div>
    );
};

const Notification = props => {
    const { className, severity, title, description, date, markAsRead } = props;
    const node = useRef();
    const [showModal, setShowModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleRemove = () => {
        markAsRead();
        node.current.style.opacity = 0;
    };

    const handleToggleModal = () => {
        if (openModal) {
            setOpenModal(false);            

            global.setTimeout(() => {
                setShowModal(false);
            }, 350);
        } else {
            setShowModal(true);

            global.setTimeout(() => {
                setOpenModal(true);
            }, 50);
        }
    };

    return (
        <>
            <Styled
                ref={ node }
                className={ `position-relative ${className || ''}` }
            >
                <button
                    className="btn py-3 px-4"
                    onClick={ () => handleToggleModal() }
                >
                    <div
                        className={ classNames('position-absolute', {
                            'bg-secondary': severity === 'Information',
                            'bg-warning': severity === 'Warning',
                            'bg-danger': severity === 'Error'
                        }) }
                        style={{ top: 0, bottom: 0, left: 0, width: 6 }}
                    />
                    <Icon className="mr-3" severity={ severity } />
                    <div className="align-self-center">
                        {
                            title !== '' ?
                                <h2
                                    className="font-weight-semibold line-clamp line-clamp--2 mb-0"
                                    style={{ fontSize: `${15/16}rem` }}
                                >
                                    { title }
                                </h2> : ''
                        }
                        <p className="line-clamp line-clamp--2 mb-1">
                            { description.substring(0, 90) }
                        </p>
                        <Moment
                            className="d-block font-weight-bold text-blue-light"
                            style={{ fontSize: `${10/16}rem` }}
                            fromNow
                        >
                            { new Date(date) }
                        </Moment>
                    </div>
                </button>
                <button
                    className="position-absolute text-white bg-transparent p-0 border-0"
                    style={{
                        top: 2,
                        right: 2,
                        zIndex: 1,
                        width: 28,
                        height: 28
                    }}
                    aria-label="Mark as read"
                    onClick={ handleRemove }
                >
                    <svg width="12" height="12">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Styled>

            {
                showModal ?
                    <Modal
                        isOpen={ openModal }
                        onRequestClose={ () => handleToggleModal() }
                        contentClassName="d-flex flex-column rounded overflow-hidden"
                        contentStyle={{
                            backgroundColor: '#1B273B',
                            boxShadow: '0 0 0.5rem var(--dark)',
                            width: 700,
                            maxHeight: 'calc(100vh - 6rem)'
                        }}
                    >
                        {
                            title !== '' ?
                                <h2 className="h3 py-4 px-5 mb-0 border-bottom w-100">
                                    { title }
                                </h2>
                                :
                                <div className="py-4 px-5 mb-0 border-bottom w-100" />
                        }
                        <div
                            className="d-flex align-items-center justify-content-center flex-wrap overflow-auto py-4 px-5"
                            style={{ minHeight: 100, maxHeight: 500 }}
                        >
                            <div className="flex-grow-1">
                                { parse(description.replace(/(?:\r\n|\r|\n)/g, '<br />')) }
                            </div>
                        </div>
                        <button
                            className="position-absolute border-0 p-0 bg-transparent text-white"
                            style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                            aria-label="close"
                            onClick={ () => handleToggleModal() }
                        >
                            <svg width="12" height="12">
                                <use xlinkHref="#icon-close" />
                            </svg>
                        </button>
                    </Modal> : null
            }
        </>
    );
};

export default Notification;
