import React, { useEffect, useState } from 'react';
import Panel from '../../../components/Panel';
import RadioList from '../../../components/RadioList';

const EditSitePanel = props => {
    const { isVisible, setIsVisible, sites } = props;
    const [site, setSite] = useState({ name: '', id: '' });

    const handleClose = () => {
        if (site !== props.site) {
            props.onChange(site);
        }
        setIsVisible(false);
    };

    useEffect(() => {
        setSite(props.site);
    }, [props.site]);

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <div className="p-2">
                <h2 className="h3 text-uppercase font-weight-semibold mb-3">
                    Site
                </h2>
                <p className="mb-4">
                    At which site is this SPID located?
                </p>
                <RadioList
                    id="site"
                    options={ sites.map(item => ({ label: item.name, value: item.id })) }
                    value={{ label: site.name, value: site.id }}
                    setValue={ obj => {
                        setSite({ name: obj.label, id: obj.value });
                    } }
                />
                {/* <div className="text-center mt-4">
                    <button
                        className="btn btn-primary"
                        style={{ width: 147 }}
                        onClick={ handleClose }
                    >
                        Save
                    </button>
                </div> */}
            </div>
        </Panel>
    )
};

export default EditSitePanel;
