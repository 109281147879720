import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import postImproveJourneyScoreForm from '../../../../../api/postImproveJourneyScoreForm';
import Context from '../../../../../context/Context';

const StyledForm = styled.form`
    .form-control {
        padding-right: ${70/16}rem;
        background-color: rgba(0,108,206,0.3);
    }

    button {
        top: 0;
        right: 0;
        z-index: 9;
        width: ${70/16}rem;
        transition: transform 150ms ease;

        &:not(:disabled):hover {
            transform: translateX(5px);
        }
    }
`;

const EmailForm = () => {
    const { token } = useContext(Context);
    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setSubmitting(true);
        const data = { Email: email };
        postImproveJourneyScoreForm(token, data, res => {
            setSubmitting(false);
            setSuccess(res.Success);
        });
        global.dataLayer.push({ event: 'email_journey_score' }); 
    };

    return (
        <div className="border-top pt-4 px-4 mt-5">
            <div className="row mb-4">
                <div className="col-6 pb-4">
                    <h3 className="h4 font-weight-semibold">
                        Can&rsquo;t provide these answers yourself?
                    </h3>
                    You can email another person on your team to fill in the blanks. We&rsquo;ll email them the questions and cc you on their answers.
                </div>
                <div className="col-6 pb-4">
                    <div
                        className="mx-auto"
                        style={{ maxWidth: 384 }}
                    >
                        {
                            success ?
                                <div
                                    className="form-control form-control-lg d-flex align-items-center justify-content-between bg-success"
                                    style={{
                                        maxWidth: 240,
                                        backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)'
                                    }}
                                    role="button"
                                    onClick={ () => { setSuccess(false); setEmail(''); } }
                                >
                                    Success!
                                    <svg width="21" height="16">
                                        <use xlinkHref="#icon-check" />
                                    </svg>
                                </div>
                                :
                                <StyledForm className="position-relative" onSubmit={ handleSubmit }>
                                    <label htmlFor="email" className="sr-only">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control form-control-lg"
                                        id="email"
                                        placeholder="Enter email address"
                                        value={ email }
                                        onChange={ e => setEmail(e.target.value) }
                                        required
                                        disabled={ submitting }
                                    />
                                    <button
                                        className="position-absolute h-100 text-primary bg-transparent border-0 p-0"
                                        aria-label="submit"
                                        disabled={ submitting }
                                    >
                                        {
                                            submitting ?
                                                <span className="spinner-border text-primary" />
                                                :
                                                <svg width="29" height="29">
                                                    <use xlinkHref="#icon-arrow-right" />
                                                </svg>
                                        }
                                    </button>
                                </StyledForm>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailForm;
