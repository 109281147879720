import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../../components/DateRangePicker';
import Panel from '../../../components/Panel';
import Select from '../../../components/Select';

const UpdateActivityPanel = props => {
    const { isVisible, setIsVisible, resetMaintenanceData, maintenanceTypes } = props;
    const [maintenanceDate, setMaintenanceDate] = useState('');
    // const [maintenanceType, setMaintenanceType] = useState('');
    // const [machineHours, setMachineHours] = useState('');
    const handleUpdate = () => {
        setIsVisible(false);
        props.handleUpdateActivity();
    };
    const handleClose = () => {
        setIsVisible(false);
        resetMaintenanceData()

    };
    useEffect(() => {
        setMaintenanceDate(props.maintenanceDate);
    },[]) //eslint-disable-line
    useEffect(() => {
       props.setMaintenanceDate(maintenanceDate)
    },[maintenanceDate]) //eslint-disable-line
    return (
        <Panel
            isOpen={isVisible}
            onRequestClose={handleClose}
        >
            <h2 className="h3 text-uppercase font-weight-semibold px-2">
                Edit Maintenance Instance
            </h2>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                   1. Date
                </h3>
                <i>Adjust date of activity</i>
                <DateRangePicker
                    startDate={maintenanceDate.value || maintenanceDate}
                    setStartDate={setMaintenanceDate}
                    showYearsOption={false}
                    pickerStyle='maintenance'
                    startOpen={false}
                />

            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <h3 className="h4 text-uppercase mb-4">
                  2. Maintenance Type
                </h3>
                <Select
                    id="maintenanceTypes"
                    actionText="Select option"
                    value={props.maintenanceType}
                    setValue={props.setMaintenanceType}
                    options={maintenanceTypes}
                />
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <label
                    htmlFor="MachineHours"
                    className="h4 text-uppercase mb-4"
                    style={{ lineHeight: 1.2 }}
                >
                    3. Machine Hours
                </label>
                <input
                    type="text"
                    className="form-control"
                    style={{
                        fontWeight: 600,
                        backgroundColor: 'var(--blue-light',
                        borderColor: 'var(--blue-light)',
                        color: 'var(--primary)'
                    }}
                    id="MachineHours"
                    value={props.machineHours}
                    placeholder=""
                    onChange={e => {
                        props.setMachineHours(e.target.value);
                    }}
                />
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <label
                    htmlFor="LubeHours"
                    className="h4 text-uppercase mb-4"
                    style={{ lineHeight: 1.2 }}
                >
                    4. Lube Hours
                </label>
                <input
                    type="text"
                    className="form-control"
                    style={{
                        fontWeight: 600,
                        backgroundColor: 'var(--blue-light',
                        borderColor: 'var(--blue-light)',
                        color: 'var(--primary)'
                    }}
                    id="LubeHours"
                    value={props.lubeHours}
                    placeholder=""
                    onChange={e => {
                        props.setLubeHours(e.target.value);
                    }}
                />
            </div>
            <div className="mt-4 pt-4 px-2" style={{ borderTop: '1px solid rgba(46,63,92,0.4)' }}>
                <label
                    htmlFor="Comments"
                    className="h4 text-uppercase mb-4"
                    style={{ lineHeight: 1.2 }}
                >
                    5. Comments <i className="text-lowercase">(optional)</i>
                </label>
                <input
                    type="text"
                    className="form-control"
                    style={{
                        fontWeight: 600,
                        backgroundColor: 'var(--blue-light',
                        borderColor: 'var(--blue-light)',
                        color: 'var(--primary)'
                    }}
                    id="Comments"
                    value={props.comments}
                    placeholder=""
                    onChange={e => {
                        props.setComments(e.target.value);
                    }}
                />
            </div>
            <div className="py-5">
                <div className="row align-items-center justify-content-center text-center pt-2">
                    <div className="col-12">
                        <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => handleUpdate()}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </Panel >
    )
}

export default UpdateActivityPanel;