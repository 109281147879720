import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1080;
    width: 100%;
    height: 100%;
    opacity: ${props => props.isOpen ? '' : 0};
    visibility: ${props => props.isOpen ? '' : 'hidden'};
    transition: opacity 300ms, visibility 300ms;
`;

const StyledContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: calc(100vw - 3rem);
    max-height: calc(100% - 10rem);
    background-color: #0B131F;
    transform: ${props => props.isOpen ? 'translate(-50%,-50%)' : 'translate(-50%,calc(-50% + 50px))'};
    transition: transform 300ms;
    margin-bottom: 5rem;
`;

const Modal = props => {
    const node = useRef();

    const handleEscape = e => {
        let isEscape = false;

        if ('key' in e) {
            isEscape = e.key === 'Escape' || e.key === 'Esc';
        } else {
            isEscape = e.keyCode === 27;
        }

        if (isEscape) {
            props.onRequestClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    });

    return (
        <StyledContainer
            className={ props.containerClassName }
            style={ props.containerStyle }
            isOpen={ props.isOpen }
            aria-modal={ props.isOpen ? true : null }
            role={ props.isOpen ? 'dialog' : null }
            aria-hidden={ props.isOpen ? null : true }
            tabIndex="-1"
            ref={ node }
        >
            <div
                className="position-absolute w-100 h-100"
                onClick={ props.onRequestClose }
            />

            <StyledContent
                className={ props.contentClassName }
                style={ props.contentStyle }
                isOpen={ props.isOpen }
            >
                { props.children }
            </StyledContent>
        </StyledContainer>
    );
};

export default Modal;