import React, { useState, forwardRef, createRef } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const StyledDiv = styled.div`
    .react-datepicker {
        background-color: ${props => props.backgroundColor || 'var(--blue-light)'};
        font-family: inherit;
        border: 1px solid ${props => props.borderColor || 'var(--blue-light)'};
        border-radius: 0.25rem;
        color: ${props => props.color || 'var(--dark)'};

        &__header {
            border-bottom: 0;
            background-color: transparent;
        }

        &__navigation {
            &--previous {
                border-right-color: var(--primary);
            }

            &--next {
                border-left-color: var(--primary);
            }
        }

        &__day-name,
        &__day,
        &__time-name,
        &__current-month,
        &__header,
        &-year-header,
        &__year-select {
            color: inherit;
        }

        &__year-select option {
            color: var(--dark);
        }

        &__day {
            font-size: 0.5625rem;
            line-height: 1.5rem;
            width: 1.5rem;

            &:hover,
            &--selected,
            &--keyboard-selected {
                border-radius: 50%;
            }

            &--keyboard-selected {
                background-color: var(--primary);
            }

            &:hover {
                background-color: #6ab6f9;
                color: #000;
            }

            &--selected,
            &--selected:hover {
                background-color: var(--secondary);
                color: #fff;
            }
        }

        &__month {
            &--selected,
            &--selected:hover {
                background-color: var(--secondary) !important;
                border-radius: 50%;
            }

            .react-datepicker__month-text {
                width: 3rem;
                height: 3rem;
                line-height: 3rem;
                text-transform: uppercase;

                &:hover {
                    border-radius: 50%;
                    background-color: #6ab6f9;
                }
            }
        }

        &__day,
        &__month {
            &--disabled {
                color: #6ab6f9 !important;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        &__day-names {
            margin-top: 0.5rem;

            .react-datepicker__day-name {
                font-size: 0.625rem;
                line-height: 1;
                width: 1.5rem;
                font-weight: 700;
            }
        }

        &__current-month {
            text-transform: uppercase;

            &--hasYearDropdown {
                display: inline-block;
            }
        }

        &__header__dropdown--select {
            display: inline-block;
        }

        &__year-dropdown-container--select {
            margin: 0 -6px 0 0;
        }

        &__year-select {
            border: none;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.875rem;
        }

        &-popper {
            margin-top: 13px;

            .react-datepicker__triangle::before {
                content: none;
            }

            &[data-placement^="top"] .react-datepicker__triangle {
                border-top-color: ${props => props.borderColor || 'var(--blue-light)'};
            }

            &[data-placement^="bottom"] .react-datepicker__triangle {
                border-bottom-color: ${props => props.borderColor || 'var(--blue-light)'};
            }
        }
    }
`;

const CustomDateInput = forwardRef((props, ref) => (
    <button
        className="btn d-flex align-items-center text-left text-primary py-1 px-0"
        style={{
            backgroundColor: props.backgroundColor,
            borderColor: props.borderColor,
            width: 165
        }}
        onClick={props.onClick}
    >
        <span
            className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mx-2"
            style={{
                width: 38,
                height: 38,
                backgroundColor: 'rgba(17,138,247,0.36)'
            }}
        >
            <svg width="19" height="18">
                <use xlinkHref="#icon-calendar" />
            </svg>
        </span>
        <div>
            <span
                className="d-block pt-1"
                style={{
                    fontSize: `${10 / 16}rem`,
                    lineHeight: 1,
                    fontWeight: 400,
                    color: props.color
                }}
            >
                {props.label}
            </span>
            <span className="d-block" style={{ minHeight: '1.5em' }}>
                {props.value}
            </span>
        </div>
    </button>
));

const DateRangePicker = props => {
    const { startDate, setStartDate, endDate, setEndDate } = props;
    const [useMonthYearPicker, setUseMonthYearPicker] = useState(false);
    const ref1 = createRef();
    const ref2 = createRef();

    return (
        <StyledDiv {...props}>
            {
                props.showYearsOption ?
                    <div className="d-flex align-items-center mb-4">
                        <button
                            className={classNames('btn', {
                                'btn-outline-primary': useMonthYearPicker,
                                'btn-secondary': !useMonthYearPicker
                            })}
                            style={{
                                width: 125,
                                backgroundColor: !useMonthYearPicker ? 'var(--secondary)' : '',
                                borderColor: !useMonthYearPicker ? 'var(--secondary)' : ''
                            }}
                            onClick={e => { e.preventDefault(); setUseMonthYearPicker(false); }}
                        >
                            Months
                        </button>
                        <i className="text-uppercase px-4" style={{ fontSize: '0.65rem' }}>
                            or
                        </i>
                        <button
                            className={classNames('btn', {
                                'btn-secondary': useMonthYearPicker,
                                'btn-outline-primary': !useMonthYearPicker
                            })}
                            style={{
                                width: 125,
                                backgroundColor: useMonthYearPicker ? 'var(--secondary)' : '',
                                borderColor: useMonthYearPicker ? 'var(--secondary)' : ''
                            }}
                            onClick={e => { e.preventDefault(); setUseMonthYearPicker(true); }}
                        >
                            Years
                        </button>
                    </div> : ''
            }
            <div className="d-flex align-items-center">
                {
                    endDate ?
                        <>
                            <DatePicker
                                selected={startDate}
                                onChange={date => { setStartDate(date) }}
                                customInput={
                                    <CustomDateInput
                                        ref={ref1}
                                        label="Start Date"
                                        backgroundColor={props.backgroundColor || 'var(--blue-light)'}
                                        borderColor={props.borderColor || 'var(--blue-light)'}
                                        color={props.color || 'var(--dark)'}
                                    />
                                }
                                dateFormat={useMonthYearPicker ? 'MMM yyyy' : 'MM/dd/yyyy'}
                                showMonthYearPicker={useMonthYearPicker}
                                dateFormatCalendar={'MMMM'}
                                showYearDropdown
                                dropdownMode="select"
                            />
                            <div className="px-4">
                                <svg width="24" height="24">
                                    <use xlinkHref="#icon-chevron-right" />
                                </svg>
                            </div>
                            <DatePicker
                                selected={endDate}
                                onChange={date => { setEndDate(date) }}
                                customInput={
                                    <CustomDateInput
                                        ref={ref2}
                                        label="To"
                                        backgroundColor={props.backgroundColor || 'var(--blue-light)'}
                                        borderColor={props.borderColor || 'var(--blue-light)'}
                                        color={props.color || 'var(--dark)'}
                                    />
                                }
                                dateFormat={useMonthYearPicker ? 'MMM yyyy' : 'MM/dd/yyyy'}
                                showMonthYearPicker={useMonthYearPicker}
                                dateFormatCalendar={'MMMM'}
                                showYearDropdown
                                dropdownMode="select"
                                maxDate={props.maxDate || new Date()}
                            />
                        </>
                        :
                        <DatePicker
                            selected={startDate}
                            onChange={date => { setStartDate(date) }}
                            customInput={
                                <CustomDateInput
                                    ref={ref1}
                                    backgroundColor={props.backgroundColor || 'var(--blue-light)'}
                                    borderColor={props.borderColor || 'var(--blue-light)'}
                                    color={props.color || 'var(--dark)'}
                                />
                            }
                            dateFormat={useMonthYearPicker ? 'MMM yyyy' : 'MM/dd/yyyy'}
                            showMonthYearPicker={useMonthYearPicker}
                            dateFormatCalendar={'MMMM'}
                            showYearDropdown
                            dropdownMode="select"
                        />
                }

            </div>
        </StyledDiv>
    );
};

export default DateRangePicker;
