import React from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';

const StyledTable = styled.table`
    width: 100%;

    tr {
        background-color: var(--gray-dark);

        &:nth-child(even) {
            background-color: var(--dark);

            td {
                background-color: var(--dark);
            }
        }
    }

    thead th {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: var(--gray-dark);
        color: var(--blue-light);
        border: 1px solid #2E3F5C;
        border-width: 1px 0;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2;
    }

    th,
    td {
        padding: 0 1rem 0 2rem;

        &:first-child {
            padding-left: 3rem;
        }
    }

    td {
        height: 55px;
        vertical-align: middle;
        background-color: var(--gray-dark);
    }

    .position-sticky {
        z-index: 1020;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -1px;
            width: 1px;
            height: 100%;
            background-color: inherit;
        }
    }
`;

const Table = props => {
    const { columns, data } = props;
    const initialState = { hiddenColumns: ['sortComponent', 'sortSpidName', 'sortSpidDescription', 'sortSamples'] };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data, initialState, autoResetSortBy: false }, useSortBy);

    return (
        <StyledTable { ...getTableProps() }>
            <thead style={{position: 'sticky', top: '0px', margin: '0 0 0 0', zIndex: 1079}}>
                {
                    headerGroups.map(headerGroup => (
                        <tr { ...headerGroup.getHeaderGroupProps() }>
                            {
                                headerGroup.headers.map(column => (
                                    <th
                                        { ...column.getHeaderProps(column.getSortByToggleProps()) }
                                        { ...column.getHeaderProps([{
                                            className: column.className,
                                            style: column.style
                                        }]) }
                                    >
                                        <div className="text-nowrap">
                                            { column.render('Header') }
                                            {
                                                !column.disableSortBy && column.isSorted ? (
                                                    <svg
                                                        className="text-primary ml-1"
                                                        width="14" height="14"
                                                    >
                                                        <use xlinkHref={ `#icon-chevron-${column.isSortedDesc ? 'down' : 'up'}` } />
                                                    </svg>
                                                ) : ''
                                            }
                                        </div>
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr { ...row.getRowProps() }>
                                {
                                    row.cells.map(cell => {
                                        return (
                                            <td { ...cell.getCellProps([{
                                                className: cell.column.className,
                                                style: cell.column.style
                                            }]) }>
                                                { cell.render('Cell') }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </StyledTable>
    );
};

export default Table;