import React, { useContext } from 'react';
import Context from '../context/Context';
import Notifications from './Notifications';
import AccountNav from './AccountNav';

const Header = props => {
    const { pageTitle, token } = useContext(Context);

    return (
        <header { ...props }>
            <div className="container-fluid">
                <div
                    className="d-flex justify-content-between align-items-start"
                    style={{ minHeight: 60 }}
                >
                    <h1
                        className="text-truncate mb-0"
                        style={{ letterSpacing: '0.05em', marginTop: 7 }}
                    >
                        { pageTitle }
                    </h1>
                    {
                        token ?
                            <div className="d-flex align-items-center pl-4">
                                <Notifications />
                                <AccountNav />
                            </div> : ''
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
