const apiUrl = process.env.REACT_APP_API_URL;

const getJourneyScoreData = (token, siteId, areaIds, cb) => {
    fetch(`${apiUrl}/journeyscore/${siteId}?areaList=${areaIds}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getJourneyScoreData;
