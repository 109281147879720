import React from 'react';

const OpenModalButton = ({ type, onClick, title, className }) => (
    <button
        className={ `d-flex align-items-center justify-content-center border-0 bg-transparent p-0 ${className}` }
        style={{ width: 26, height: 26 }}
        onClick={ onClick }
        title={ title }
        aria-label={ title }
    >
        {
            type === 'Normal' ?
                <svg className="text-success" width="18" height="18" aria-hidden="true">
                    <use xlinkHref="#icon-check-square" />
                </svg> : ''
        }

        {
            type === 'Caution' || type === 'Marginal' ?
                <svg className="text-warning" width="24" height="21" aria-hidden="true">
                    <use xlinkHref="#icon-exclamation-square" />
                </svg> : ''
        }

        {
            type === 'Serious' ?
                <svg className="text-orange" width="24" height="24" aria-hidden="true">
                    <use xlinkHref="#icon-exclamation-circle" />
                </svg> : ''
        }

        {
            type === 'Critical' ?
                <svg className="text-danger" width="24" height="21" aria-hidden="true">
                    <use xlinkHref="#icon-exclamation-triangle" />
                </svg> : ''
        }
    </button>
);

export default OpenModalButton;