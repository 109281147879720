import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Context from './context/Context.js';
import Login from './pages/Account/Login';
import Logout from './pages/Account/Logout';
import Register from './pages/Account/Register';
import ForgotPassword from './pages/Account/ForgotPassword';
import ResetPassword from './pages/Account/ResetPassword';
import Account from './pages/Account/Account';
import ConditionSummary from './pages/ConditionSummary/ConditionSummary';
import ConditionReport from './pages/ConditionReport/ConditionReport';
import SPIDDetail from './pages/SPIDDetail/SPIDDetail';
import OrderSupplies from './pages/OrderSupplies/OrderSupplies';
import JourneyAnalysis from './pages/JourneyAnalysis/JourneyAnalysis';
import JourneyScore from './pages/JourneyScore/JourneyScore';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import EULA from './pages/Legal/EULA';
import ClientTermsOfUse from './pages/Legal/ClientTermsOfUse';
import Help from './pages/Legal/Help.js';
import MaintenanceActivity from './pages/MaintenanceActivity/MaintenanceActivity.js';

const Routes = () => {
    const { token } = useContext(Context);

    return (
        <Switch>
            <Route exact path="/login" render={props => (
                <Login {...props} />
            )} />
            
            <Route exact path="/logout" render={props => (
                <Logout {...props} />
            )} />

            <Route exact path="/register" render={props => (
                <Register {...props} />
            )} />

            <Route exact path="/forgot-password" render={props => (
                <ForgotPassword {...props} />
            )} />

            <Route exact path="/reset-password" render={props => (
                <ResetPassword {...props} />
            )} />

            <Route path="/account" render={props => (
                token ? <Account {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route path="/condition-summary/condition-report/:id" render={props => (
                token ? <ConditionReport {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route exact path="/condition-summary/spid/new" render={props => (
                token ? <SPIDDetail {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route exact path="/condition-summary/spid/:id/maintenance-activity" render={props => (
                token ? <MaintenanceActivity {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />
            
            <Route exact path="/condition-summary/spid/:id" render={props => (
                token ? <SPIDDetail {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route path="/condition-summary" render={props => (
                token ? <ConditionSummary {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route exact path="/order-supplies" render={props => (
                token ? <OrderSupplies {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route exact path="/journey-analysis" render={props => (
                token ? <JourneyAnalysis {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />

            <Route path="/journey-score" render={props => (
                token ? <JourneyScore {...props} /> :
                    <Redirect to={ `/login?returnUrl=${props.location.pathname}` } />
            )} />
            
            <Route exact path="/privacy-policy" render={props => (
                <PrivacyPolicy {...props} />
            )} />
            
            <Route exact path="/eula" render={props => (
                <EULA {...props} />
            )} />
            
            <Route exact path="/client-terms-of-use" render={props => (
                <ClientTermsOfUse {...props} />
            )} />
            
            <Route exact path="/help" render={props => (
                <Help {...props} />
            )} />

            <Route render={() => (
                <Redirect to={ token ? '/condition-summary' : '/login' } />
            )} />
        </Switch>
    );
};

export default Routes;
