const apiUrl = process.env.REACT_APP_API_URL;

const getRegister = (id, cb) => {
    fetch(`${apiUrl}/register/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getRegister;
