import React from 'react';
import { useHistory } from 'react-router-dom';

const CopySPIDButton = props => {
    const history = useHistory();

    const handleCopySPID = () => {
        history.push({
            pathname: '/condition-summary/spid/new',
            search: `?copyFrom=${props.entityID}`
        });
    };

    return (
        <button
            className="btn btn-primary border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-0 px-3 mx-2 mb-3"
            style={{
                fontSize: `${15/16}rem`,
                lineHeight: 1.2,
                backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                maxWidth: 185
            }}
            disabled={ props.disabled }
            onClick={ handleCopySPID }
        >
            <span
                className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                style={{
                    width: 40,
                    height: 40,
                    backgroundColor: 'rgba(255,255,255,0.15)'
                }}
            >
                <svg width="16" height="18">
                    <use xlinkHref="#icon-copy" />
                </svg>
            </span>
            <span>
                Copy SPID
            </span>
        </button>
    );
};

export default CopySPIDButton;
