import React, { useState } from 'react';
import parse from 'html-react-parser';
import { SlideDown } from 'react-slidedown';

const ExpandCollapse = props => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div
            className="d-flex mb-4"
            style={{ minHeight: '2.5rem' }}
        >
            <div
                className="text-primary flex-shrink-0"
                style={{ fontSize: '2rem', width: '2.5rem', height: '3rem' }}
                role="button"
                onClick={ () => setIsExpanded(!isExpanded) }
            >
                { isExpanded ? '–' : '+' }
            </div>
            <div>
                <h4 className="h6 mb-0">
                    <button
                        className="bg-transparent border-0 text-white font-weight-semibold text-left pt-0 px-0 pb-3"
                        aria-expanded={ isExpanded }
                        aria-controls={ `tg${props.index}` }
                        onClick={ () => setIsExpanded(!isExpanded) } 
                    >
                        { props.title }
                    </button>
                </h4>
                <SlideDown
                    id={ `tg${props.index}` }
                    style={{
                        fontSize: '0.875rem',
                        transitionDuration: '250ms'
                    }}
                    closed={ !isExpanded }
                >
                    <div
                        className="border-secondary pl-3"
                        style={{ borderLeft: '2px solid' }}
                    >
                        { parse(props.description.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                    </div>
                </SlideDown>
            </div>
        </div>
    );
};

export default ExpandCollapse;
