import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledButton = styled.button`
    position: relative;
    width: 40px;
    height: 44px;
    font-size: ${12/16}rem;
    background: none;
    border: none;
    padding: 0;

    &:not(:disabled):hover,
    &:not(.is-active):focus {
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &.is-active {
        width: 72px;
        color: #A290F3;
        font-weight: 600;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -1px;
            left: 0;
            height: 2px;
            background-color: currentColor;
        }
    }

    &:not(.is-active).text-warning,
    &:not(.is-active).text-orange {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 22px;
            height: 22px;
            margin: -11px 0 0 -11px;
            border: 1px solid currentColor;
            border-radius: 50%;
        }
    }
`;

const SampleNavButton = props => (
    <StyledButton
        className={ classNames(props.className, {
            'd-none': !props.visible,
            'is-active': props.active,
            'text-blue-light': !props.active && props.rating === 'NORMAL',
            'text-warning': !props.active && (props.rating === 'CAUTION' || props.rating === 'MARGINAL'),
            'text-danger': !props.active && props.rating === 'CRITICAL',
            'text-orange': !props.active && props.rating === 'SERIOUS'
        }) }
        style={ props.style }
        onClick={ props.onClick }
        disabled={ props.disabled }
    >
        { props.active ? 'Sample' : '' } { props.children }
        {
            !props.active && props.rating === 'CRITICAL' ?
                <svg
                    className="position-absolute"
                    style={{
                        top: '50%',
                        left: '50%',
                        margin: '-14px 0 0 -14px'
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.176" height="22"
                    viewBox="0 0 27.176 22"
                >
                    <g fill="none">
                        <path d="M12.1 2.411a1.75 1.75 0 012.978 0l10.45 16.92A1.75 1.75 0 0124.039 22H3.138a1.75 1.75 0 01-1.489-2.67z" />
                        <path d="M13.588 2.58a.74.74 0 00-.638.356L2.5 19.856a.735.735 0 00-.017.76.735.735 0 00.655.384h20.9c.398 0 .591-.269.656-.385a.735.735 0 00-.017-.76L14.227 2.937a.74.74 0 00-.639-.356m0-1c.574 0 1.147.277 1.49.83l10.45 16.92c.72 1.166-.119 2.67-1.49 2.67h-20.9c-1.37 0-2.21-1.504-1.49-2.67L12.1 2.41c.342-.553.916-.83 1.49-.83z" fill="currentColor" />
                    </g>
                </svg> : ''
        }
    </StyledButton>
);

export default SampleNavButton;
