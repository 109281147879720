import React from 'react';
import classNames from 'classnames';

const Asset = ({ label, checked, icon, onClick }) => (
    <button
        className="position-relative d-flex flex-column align-items-center justify-content-start text-center text-uppercase font-weight-semibold bg-transparent border-0 w-100 h-100 p-0 mb-0"
        style={{ fontSize: `${10/16}rem` }}
        onClick={ () => onClick(label) }
    >
        <div
            className={ classNames('btn btn-blue-light d-flex align-items-center justify-content-center border-0 rounded-circle stretched-link mx-auto mb-1', {
                'bg-secondary text-white': checked,
                'text-primary': !checked
            }) }
            style={{
                width: 67,
                height: 67,
                backgroundImage: checked ? 'linear-gradient(135deg, transparent, rgba(255,255,255,0.15)' : '',
                boxShadow: checked ? '0 0 5px rgba(6,87,157,0.64)' : ''
            }}
        >
            <svg className="flex-shrink-0" width="32" height="32">
                <use xlinkHref="#icon-tools" />
            </svg>
        </div>
        { label }
    </button>
);

export default Asset;
