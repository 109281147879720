import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import Moment from 'react-moment';
import Context from '../../../../context/Context';
import Table from '../../../../components/Table';
import SampleModal from './components/SampleModal/SampleModal';
import PrevNextButton from './components/PrevNextButton';
import OpenModalButton from './components/OpenModalButton';
import postGenerateReportUrl from '../../../../api/postGenerateReportUrl';
import Modal from '../../../../components/Modal';

const StyledCheckAllLabel = styled.label`
    &::before {
        background-color: transparent;
        border-color: var(--purple);
    }
`;

const SummaryTable = props => {
    const {
        data,
        months,
        showPrintLabels,
        setShowPrintLabels,
        reportCheckboxes,
        setShowReportCheckboxes,
        setLabelsToPrint,
        showAssets,
        showRatings,
        handleUpdateDates,
        openLegendModal,
        setOpenLegendModal,
        setReportsToExport
    } = props;

    const { showLoadingOverlay, token } = useContext(Context);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [checkAllReports, setCheckAllReports] = useState(false);
    const [samples, setSamples] = useState([]);
    const [activeMonth, setActiveMonth] = useState('');
    const [reportList, setReportList] = useState([]);
    const [exportActive, setExportActive] = useState(false);
    const [exportTitle, setExportTitle] = useState('');
    const [tableHeight, setTableHeight] = useState(600);
    const nodePrintAllCheckbox = useRef();
    const nodeExportAllCheckbox = useRef();
    
    const handleSetExportButtonState = (itemChecked = null, title = '') => {
        const activeReportMonth = document.querySelector(`input[name="reportMonth-${props.id}"]:checked`);

        if (itemChecked == null) {
            itemChecked = reportList.length > 0;
        }

        setExportActive(itemChecked && activeReportMonth);
        setExportTitle(title);
    };

    const handleCheckReport = item => {
        let items = [...reportList];

        if (items.filter(x => x.SPID === item.SPID).length > 0) {
            items = items.filter(x => x.SPID !== item.SPID);
        } else {
            items.push(item);
        }

        setReportList(items);

        if (items.length === 0) {
            setReportList(items.filter(x => x.SPID !== item.SPID));
            setCheckAllReports(false);
            handleSetExportButtonState(false);
            return false;
        }

        if (items.length === tableData.length) {
            setCheckAllReports(true);
        } else {
            setCheckAllReports('indeterminate');
        }

        handleSetExportButtonState(true, exportTitle);
    };

    const handleTogglePrintLabelsCheckbox = () => {
        setShowPrintLabels(!showPrintLabels);
    };

    const handleToggleExportReportsCheckbox = () => {
        setShowReportCheckboxes(!reportCheckboxes);
    };

    const formatDate = (strDate) => {
        let date = new Date(strDate);
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        return months[date.getMonth()] + date.getFullYear().toString().substr(-2);
    };

    const handleGenerateReport = monthName => {
        const reports = [];

        if (reportList.filter(x => x.Months.filter(month => month.Name === month))) {
            reportList.forEach(item => {
                const samples = [];
                
                if (monthName === 'Most Recent Sample') {
                    if (item.LatestSample !== null) {
                        item.LatestSample.Samples.map(item => (
                            samples.push({
                                SampleID: item.SampleID,
                                SampleRefID: item.SampleRefID
                            })
                        ));
                    }
                } else {
                    item.Months.filter(x => x.Name === monthName).map(item => (
                        item.Samples.map(item => (
                            samples.push({
                                SampleID: item.SampleID,
                                SampleRefID: item.SampleRefID
                            })
                        )))
                    );
                }

                if (samples.length > 0) {
                    reports.push({
                        SPID: item.SPID,
                        Samples: samples.reverse().flat()
                    });
                }
            });
        }

        setReportsToExport(reports);
        postGenerateReportUrl(token, reports, res => {
            global.open(`${res}&oauth=${token}`, '_blank');
        });

        global.dataLayer.push({ event: 'export_report' });
    };

    const handleCheckAll = () => {
        const { name, id } = props;
        const labelsToPrint = [...props.labelsToPrint].filter(x => x.id !== id);

        if (!checkAll) {
            labelsToPrint.push({
                name,
                id,
                items: data.map(item => item.SPID)
            });
        }

        setLabelsToPrint(labelsToPrint);
        setCheckAll(!checkAll);
    };

    const handleCheckAllReports = () => {
        const arr = [];

        if (!checkAllReports) {
            // eslint-disable-next-line array-callback-return
            data.map(item => {
                if (
                    (showAssets.length === 0 || showAssets.includes(item.Component)) &&
                    (showRatings.length === 0 || (item.LatestSample && showRatings.includes(item.LatestSample.Rating)))
                ) {
                    arr.push(item);
                }
            });
        }

        setReportList(arr);
        setCheckAllReports(!checkAllReports);
        handleSetExportButtonState(!checkAllReports, exportTitle);
    };

    const toggleModal = () => {
        setOpenLegendModal(!openLegendModal);
    };

    const handleCheckItem = useCallback(id => {
        const { name } = props;
        const labelsToPrint = [...props.labelsToPrint];
        let items = [];
        let index = labelsToPrint.findIndex(x => x.id === props.id);

        if (index === -1) {
            labelsToPrint.push({
                name,
                id: props.id,
                items
            });

            index = labelsToPrint.findIndex(x => x.id === props.id);
        }

        items = labelsToPrint[index].items;

        if (items.includes(id)) {
            items = items.filter(item => item !== id);
        } else {
            items.push(id);
        }

        if (items.length === 0) {
            setLabelsToPrint(labelsToPrint.filter(x => x.id !== props.id));
            setCheckAll(false);
            return false;
        }

        if (items.length === data.length) {
            setCheckAll(true);
        } else {
            setCheckAll('indeterminate');
        }

        labelsToPrint[index].items = items;

        setLabelsToPrint(labelsToPrint);
    }, [props, data, setLabelsToPrint]);

    const tableColumns = useMemo(() => {
        const arr = [
            {
                Header: (
                    <div
                        className="position-absolute w-100 h-100"
                        style={{ top: 0, left: 0, backgroundColor: '#bfb4f0' }}
                    >
                        <div
                        className={classNames({
                            'd-flex align-items-center justify-content-center h-100 px-3': showPrintLabels,
                            'd-none': !showPrintLabels
                        })}
                        style={{
                            zIndex: 9,
                            borderTopLeftRadius: props.index === 0 ? '' : '0.25rem'
                        }}
                    >
                        <div className="custom-control custom-checkbox mr-n2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`print-all-${props.id}`}
                                ref={nodePrintAllCheckbox}
                                checked={checkAll === true}
                                onChange={handleCheckAll}
                            />
                            <StyledCheckAllLabel
                                htmlFor={`print-all-${props.id}`}
                                className="custom-control-label"
                            >
                                <span className="sr-only">
                                    Print All Labels
                                </span>
                            </StyledCheckAllLabel>
                        </div>
                    </div>

                        <span className="sr-only">Print Label</span>
                    </div>
                ),
                accessor: 'print',
                className: 'position-sticky shadow-right pl-3',
                style: {
                    left: 0,
                    display: showPrintLabels ? '' : 'none'
                },
                disableSortBy: true
            },
            {
                Header: (
                    <div
                        className="position-absolute w-100 h-100"
                        style={{ top: 0, left: 0, backgroundColor: '#bfb4f0' }}
                    >
                        <div
                            className={classNames({
                                'd-flex align-items-center justify-content-center h-100 px-3': reportCheckboxes,
                                'd-none': !reportCheckboxes
                            })}
                            style={{
                                zIndex: 9,
                                borderTopLeftRadius: props.index === 0 ? '' : '0.25rem'
                            }}
                        >
                            <div className="custom-control custom-checkbox mr-n2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`report-all-${props.id}`}
                                    ref={nodeExportAllCheckbox}
                                    checked={checkAllReports === true}
                                    onChange={e => {handleCheckAllReports()}}
                                />
                                <StyledCheckAllLabel
                                    htmlFor={`report-all-${props.id}`}
                                    className="custom-control-label"
                                >
                                    <span className="sr-only">
                                        Export All Reports
                                    </span>
                                </StyledCheckAllLabel>
                            </div>
                        </div>

                        <span className="sr-only">Export Multiple Reports</span>
                    </div>
                ),
                accessor: 'reports',
                className: 'position-sticky shadow-right pl-3',
                style: {
                    left: 0,
                    display: reportCheckboxes ? '' : 'none'
                },
                disableSortBy: true
            },
            {
                Header: 'SPID',
                accessor: 'spid',
                className: 'pl-5'
            },
            {
                Header: 'Description / Name',
                accessor: 'spidName',
                sortType: (a, b) => {
                    return b.values.sortSpidName > a.values.sortSpidName ? 1 : -1;
                }
            },
            {
                Header: 'Description / Name',
                accessor: 'sortSpidName'
            },
            {
                Header: 'ID / Number',
                accessor: 'spidDescription',
                sortType: (a, b) => {
                    return b.values.sortSpidDescription > a.values.sortSpidDescription ? 1 : -1;
                }
            },
            {
                Header: 'ID / Number',
                accessor: 'sortSpidDescription'
            },
            {
                Header: 'Next Sample Due',
                accessor: 'samples',
                sortType: (a, b) => {
                    return b.values.sortSamples > a.values.sortSamples ? 1 : -1;
                },
                className: 'text-center pr-4'
            },
            {
                Header: 'Next Sample Due',
                accessor: 'sortSamples',
            },
            {
                Header: (
                    reportCheckboxes ?
                        <div
                            className="d-flex flex-column justify-content-center text-dark px-3 mr-n3"
                            style={{
                                gap: '.5rem',
                                backgroundColor: '#bfb4f0',
                                height: 60,
                                marginBlock: '-.5rem',
                                fontSize: '.75rem'
                            }}
                        >
                            <input
                                style={{ transform: 'scale(1.2)' }}
                                type="radio"
                                name={`reportMonth-${props.id}`}
                                id={`MostRecentSample-${props.id}`}
                                onChange={ () => handleSetExportButtonState(null, 'Most Recent Sample') }
                            />
                            <label
                                htmlFor={`MostRecentSample-${props.id}`}
                                className="mb-0"
                            >
                                Most Recent Sample
                            </label>
                        </div> : 'Most Recent Sample'
                ),
                accessor: 'component',
                sortType: (a, b) => new Date(b.values.sortComponent) - new Date(a.values.sortComponent),
                disableSortBy: reportCheckboxes
            },
            {
                Header: 'Most Recent Sample',
                accessor: 'sortComponent'
            }
        ];

        arr.push({
            Header: (
                <PrevNextButton
                    direction="prev"
                    onClick={() => handleUpdateDates('prev')}
                />
            ),
            accessor: 'prev',
            className: 'position-sticky shadow-left pr-2 pl-3',
            style: { right: (55 * 12) + 42 },
            disableSortBy: true
        });

        months.map((item, index) => {
            const rightOffset = 42 + 55 * (11 - index);
            const obj = {
                Header: (
                    reportCheckboxes && item.ReportURL ?
                        <div
                            className="d-flex flex-column justify-content-center text-dark"
                            style={{
                                gap: '.5rem',
                                backgroundColor: '#bfb4f0',
                                height: 60,
                                marginBlock: '-.5rem'
                            }}
                        >
                            <input
                                style={{ transform: 'scale(1.2)' }}
                                type="radio"
                                name={`reportMonth-${props.id}`}
                                id={`${item.Title}-${props.id}`}
                                value={`${item.ReportURL}&oauth=${token}`}
                                onChange={ () => handleSetExportButtonState(null, item.Title)}
                            />
                            <label
                                htmlFor={`${item.Title}-${props.id}`}
                                className="d-inline-block mb-0"
                                style={{ fontSize: `${12 / 16}rem`, width: 55 }}
                            >
                                {item.Title}
                            </label>
                        </div>
                        :
                        <span
                            className="d-inline-block text-blue-light"
                            style={{ fontSize: `${12 / 16}rem`, width: 55 }}
                        >
                            {item.Title}
                        </span>
                ),
                accessor: item.Title,
                className: classNames('position-sticky text-center px-0', {
                    'pr-1': index === months.length - 1
                }),
                style: { right: rightOffset },
                disableSortBy: true
            };

            return arr.push(obj);
        });

        arr.push({
            Header: (
                <PrevNextButton
                    direction="next"
                    onClick={() => handleUpdateDates('next')}
                />
            ),
            accessor: 'next',
            className: 'position-sticky pl-2 pr-3',
            disableSortBy: true,
            style: { right: 0 }
        });

        return arr;
    }, [showPrintLabels, reportCheckboxes, months, handleUpdateDates, token, handleGenerateReport]); // eslint-disable-line react-hooks/exhaustive-deps

    const tableData = useMemo(() => {
        const arr = [];
        const items = props.labelsToPrint.filter(x => x.id === props.id)[0] ?
            props.labelsToPrint.filter(x => x.id === props.id)[0].items : [];

        const handleShowPopUp = (samples, month, rating) => {
            // in certain cases, "samples" will not be an array and needs to be converted.
            showLoadingOverlay(true);
            setSamples(samples);
            setActiveMonth(month);
            setShowPopUp(true);

            const color = () => {
                if (rating === 'Normal') {
                    return 'green';
                } else if (rating === 'Caution' || rating === 'Marginal') {
                    return 'yellow';
                } else if (rating === 'Serious') {
                    return 'orange';
                } else if (rating === 'Critical') {
                    return 'red';
                }
            };

            global.dataLayer.push({ event: `SPID_sample_report_${color()}` });
        };

        data.map((item, index) => {
            const isActive = item.Status === 'Active' ? true : false;
            const checked = reportList.filter(x => x.SPID === item.SPID).length > 0;

            if ((showAssets.length === 0 || showAssets.includes(item.Component)) && (showRatings.length === 0 || (item.LatestSample && showRatings.includes(item.LatestSample.Rating)))) {
                const obj = {
                    print: (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            margin: '0 -1rem 0 -2rem',
                            padding: '0 1rem 0 2rem',
                            backgroundColor: index % 2 === 0 ? '#bfb4f0' : '#A290F3'
                        }}>
                            <div className="custom-control custom-checkbox mr-n2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`print-${item.SPID}`}
                                    checked={items.includes(item.SPID)}
                                    onChange={() => handleCheckItem(item.SPID)}
                                />
                                <label
                                    htmlFor={`print-${item.SPID}`}
                                    className="custom-control-label"
                                >
                                    <span className="sr-only">
                                        Print Label
                                    </span>
                                </label>
                            </div>
                        </div>
                    ),
                    reports: (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            margin: '0 -1rem 0 -2rem',
                            padding: '0 1rem 0 2rem',
                            backgroundColor: index % 2 === 0 ? '#bfb4f0' : '#A290F3'
                        }}>
                            <div className="custom-control custom-checkbox mr-n2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`report-${item.SPID}`}
                                    checked={checked}
                                    onChange={() => {handleCheckReport(item)}}
                                />
                                <label
                                    htmlFor={`report-${item.SPID}`}
                                    className="custom-control-label"
                                >
                                    <span className="sr-only">
                                        Export Multiple Reports
                                    </span>
                                </label>
                            </div>
                        </div>
                    ),
                    spid: (
                        <div style={{ width: 80 }}>
                            <Link
                                className={classNames('text-underline', {
                                    'text-danger': item.IsExpired
                                })}
                                to={`/condition-summary/spid/${item.SPIDEntityID}`}
                            >
                                <span style={{fontStyle: !isActive ? 'italic' : 'normal'}}>{item.SPID}</span>
                            </Link>
                            {
                                item.ExpirationDate ?
                                    <div
                                        className="d-flex align-items-center text-danger"
                                        style={{ fontSize: 9, margin: '-3px 0 -10px' }}
                                    >
                                        <div className="text-nowrap">
                                            {item.IsExpired ? 'Expired' : 'Expires'} <Moment format="M/DD/YYYY">
                                                {item.ExpirationDate}
                                            </Moment>
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    ),
                    spidName: (
                        <span style={{textDecoration: !isActive ? 'line-through' : 'none'}}>{item.SPIDName}</span>
                    ),
                    sortSpidName: (
                        item.SPIDName
                    ),
                    spidDescription: (
                        <span style={{textDecoration: !isActive ? 'line-through' : 'none'}}>{item.SPIDDescription}</span>
                    ),
                    sortSpidDescription: (
                        item.SPIDDescription
                    ),
                    samples: (
                        <div className="mx-auto" style={{ width: 85 }}>
                            { (item.Compliance !== 'N/A') && (item.Compliance !== undefined) ? formatDate(item.Compliance) : 'N/A' }
                        </div>
                    ),
                    sortSamples: (
                        item.Compliance
                    ),
                    module: (
                        item.Module || '–'
                    ),
                    component: (
                        // handleShowPopup expects an array so we need to convert item.LatestSample to an array.
                        item.LatestSample !== null &&
                            <div className="position-relative d-flex align-items-center justify-content-center mx-auto pl-3">
                                <OpenModalButton
                                    className="mr-2 flex-shrink-0 stretched-link"
                                    type={item.LatestSample.Rating}
                                    onClick={() => handleShowPopUp([item.LatestSample], item.LatestSample.Name, item.LatestSample.Rating)}
                                    title={item.LatestSample.Name}
                                />
                                <span style={{ minWidth: 50, textDecoration: isActive ? '': 'line-through' }}>
                                    { item.LatestSample.Name }
                                </span>
                            </div>
                    ),
                    sortComponent: (
                        item.LatestSample !== null &&
                        item.LatestSample.Samples[0].SampleDate
                    ),
                    program: item.Program || '–',
                    prev: '',
                    next: ''
                };

                for (const monthItem of item.Months) {
                    const { Name, Rating } = monthItem;
                    obj[`${Name}`] = Rating ?
                        <OpenModalButton
                            className="mx-auto"
                            type={Rating}
                            onClick={() => handleShowPopUp(item.Months, Name, Rating)}
                            title={Name}
                        /> : '';
                }

                arr.push(obj);
            }
            return arr;
        });

        return arr;
    }, [data, props, handleCheckItem, showLoadingOverlay, showAssets, handleCheckReport, showRatings, reportList, checkAllReports]); // eslint-disable-line

    useEffect(() => {
        setIsExpanded(props.isExpanded);

        let tHeight = 600;
        tableData.length >= 10 ? tHeight = 600 : tHeight = (tableData.length * 55) + 55;
        setTableHeight(tHeight) ;

        nodePrintAllCheckbox.current.indeterminate = checkAll === 'indeterminate';
        nodeExportAllCheckbox.current.indeterminate = checkAllReports === 'indeterminate';

        if (props.labelsToPrint.filter(x => x.name === props.name).length === 0) {
            nodePrintAllCheckbox.current.indeterminate = false;
            setCheckAll(false);
        }

    }, [props.isExpanded, props.labelsToPrint, checkAllReports]); // eslint-disable-line

    return (
        <div
            className={`position-relative ${props.className || ''}`}
            style={props.style || {}}
        >
            <button
                className="btn position-relative d-flex align-items-center justify-content-between w-100 border-0 text-white text-left py-0 pl-5 pr-4"
                style={{ zIndex: 1, height: 60 }}
                onClick={ () =>  setIsExpanded(!isExpanded) }
                aria-expanded={ isExpanded }
            >
                <h4
                    className="h5 font-weight-semibold text-uppercase mb-0"
                    style={{ maxWidth: 'calc(100% - 400px)' }}
                >
                    { props.name || '–' }
                </h4>
                <svg className="text-primary" width="20" height="20" aria-hidden="true">
                    <use xlinkHref={ isExpanded ? '#icon-chevron-up' : '#icon-chevron-down' } />
                </svg>
            </button>
            
            <div
                className={classNames(
                    'ml-5 mr-3 mb-3',
                    isExpanded ? 'd-flex': 'd-none'
                )}
                style={{ gap: '1rem' }}
            >
                <button
                    className={classNames(
                        'btn align-items-center rounded-pill px-3',
                        showPrintLabels ? 'btn-secondary' : 'btn-outline-secondary',
                        isExpanded ? 'd-inline-flex': 'd-none'
                    )}
                    style={{fontSize: '.875rem'}}
                    onClick={handleTogglePrintLabelsCheckbox}
                    disabled={reportCheckboxes}
                >
                    Print Labels
                    {
                        showPrintLabels ?
                            <svg className="ml-2" width="16" height="16" aria-hidden="true">
                                <use xlinkHref="#icon-close" />
                            </svg> : ''
                    }
                </button>
                <button
                    className={classNames(
                        'btn align-items-center rounded-pill px-3',
                        reportCheckboxes ? 'btn-secondary' : 'btn-outline-secondary',
                        isExpanded ? 'd-inline-flex': 'd-none'
                    )}
                    style={{fontSize: '.875rem'}}
                    onClick={handleToggleExportReportsCheckbox}
                    disabled={showPrintLabels}
                >
                    Export Reports
                    {
                        reportCheckboxes ?
                            <svg className="ml-2" width="16" height="16" aria-hidden="true">
                                <use xlinkHref="#icon-close" />
                            </svg> : ''
                    }
                </button>
                <button
                    className={classNames('btn btn-primary rounded-pill px-5 ml-auto', {
                        'd-none': !isExpanded || !reportCheckboxes
                    })}
                    style={{fontSize: '.875rem'}}
                    onClick={() => handleGenerateReport(exportTitle)}
                    disabled= {!exportActive}
                >
                    Export
                </button>
            </div>
            <div
                className={classNames(
                    'ml-5 mb-3',
                    isExpanded && showPrintLabels ? '': 'd-none'
                )}
                style={{fontSize: '.75rem'}}
            >
                <i>Select SPIDs below.</i>
            </div>
            <div
                className={classNames(
                    'ml-5 mb-3',
                    isExpanded && reportCheckboxes ? '': 'd-none'
                )}
                style={{fontSize: '.75rem'}}
            >
                <i>Select SPIDs and month/year from the column on the left.</i>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <div style={{
                    justifyContent: 'space-evenly',
                    display: 'flex'
                }}>
                    <div
                        className={classNames('position-absolute align-items-center', {
                            'd-flex': isExpanded,
                            'd-none': !isExpanded
                        })}
                        style={{
                            top: 0,
                            right: 60,
                            zIndex: 1,
                            fontSize: 12,
                            height: 60
                        }}
                    >
                        <i>Click an item below to view related condition report</i>
                        <button
                            className="d-inline-flex align-items-center bg-transparent border-0 text-primary pl-0 ml-3"
                            onClick={toggleModal}
                            aria-label="More information"
                        >
                            <svg  width="20" height="20" aria-hidden="true">
                                <use xlinkHref="#icon-info-circle" />
                            </svg>
                        </button>
                    </div>

                    <Modal
                        isOpen={openLegendModal}
                        onRequestClose={toggleModal}
                        contentClassName="d-flex flex-column rounded overflow-hidden"
                        contentStyle={{
                            backgroundColor: '#1B273B',
                            boxShadow: '0 0 0.5rem var(--dark)',
                            width: 750,
                            maxHeight: 'calc(100vh - 6rem)'
                        }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center flex-wrap overflow-auto pt-5 pb-4 px-5"
                            style={{ minHeight: 100, maxHeight: 500 }}
                        >
                            <div className="flex-grow-1">
                                <p>
                                    <strong className="text-blue-light">SPID</strong> - Sample Point Identification Number<br />
                                    <strong className="text-uppercase text-blue-light">SPID Name</strong> - Associated Name to the SPID<br />
                                    <strong className="text-uppercase text-blue-light">Module</strong> - Lubricated section of a larger asset. (Ex. Bearing Housing of a Pump)<br />
                                    <strong className="text-uppercase text-blue-light">Component</strong> - Internally lubricated component<br />
                                    <strong className="text-uppercase text-blue-light">Program</strong> - Testing program name for the specific SPID
                                </p>
                                <p>Color-Coded and Shape Oriented icons quickly show Condition Ratings which are one of four levels:</p>
                                <ol>
                                    <li className="mb-1">
                                        <strong className="text-uppercase text-success">Normal</strong> (<svg className="text-success mx-1" width="15" height="15">
                                            <use xlinkHref="#icon-check-square" />
                                        </svg>) Both the lubricant and equipment are in acceptable limits.
                                    </li>
                                    <li className="mb-1">
                                        <strong className="text-uppercase text-warning">Caution</strong> (<svg className="text-warning  mx-1" width="18" height="18">
                                            <use xlinkHref="#icon-exclamation-square" />
                                        </svg>) A condition of the lubricant or equipment s abnormal, but not yet to a level of concern.
                                    </li>
                                    <li className="mb-1">
                                        <strong className="text-uppercase text-orange">Serious</strong> (<svg className="text-orange mx-1" width="18" height="18">
                                            <use xlinkHref="#icon-exclamation-circle" />
                                        </svg>) One or more conditions of the lubricant or equipment is beyond acceptable limits.
                                    </li>
                                    <li>
                                        <strong className="text-uppercase text-danger">Critical</strong> (<svg className="text-danger mx-1" width="15" height="13">
                                            <use xlinkHref="#icon-exclamation-triangle" />
                                        </svg>) One or more conditions of the lubricant or equipment is well beyond acceptable limits, at this point either the lubricant or equipment is compromised.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <button
                            className="position-absolute border-0 p-0 bg-transparent text-white"
                            style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                            aria-label="close"
                            onClick={toggleModal}
                        >
                            <svg width="12" height="12" aria-hidden="true">
                                <use xlinkHref="#icon-close" />
                            </svg>
                        </button>
                    </Modal>
                </div>
            </div>

            <div
                className="overflow-auto"
                style={{ display: isExpanded ? '' : 'none', height: tableHeight + 'px', overflowx: 'hidden' }}
            >
                <Table
                    columns={tableColumns}
                    data={tableData}
                />
            </div>

            {
                samples.length > 0 ?
                    <SampleModal
                        isOpen={showPopUp}
                        setIsOpen={setShowPopUp}
                        samples={samples}
                        activeMonth={activeMonth}
                        setActiveMonth={setActiveMonth}
                    /> : ''
            }
        </div>
    );
};

export default SummaryTable;