const apiUrl = process.env.REACT_APP_API_URL;

const postAccount = (token, data, cb) => {
    fetch(`${apiUrl}/account`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },        
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(res => {
            if (cb) cb(res);
        });
};

export default postAccount;
