const apiUrl = process.env.REACT_APP_API_URL;

const getCriticalReports = (token, cb) => {
    fetch(`${apiUrl}/criticalreports`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getCriticalReports;
