import React, { useEffect, useState } from 'react';
import Panel from '../../../components/Panel';
import RadioList from '../../../components/RadioList';
import CheckboxList from '../../../components/CheckboxList';
import { Carousel, CarouselItems, CarouselItem, CarouselPrevButton, CarouselNextButton, CarouselNavButton } from './PanelCarousel';

const EnvironmentPanel = props => {
    const { isVisible, setIsVisible } = props;
    const [activeSlide, setActiveSlide] = useState(null);
    const [environment, setEnvironment] = useState(null);
    const [finishedProduct, setFinishedProduct] = useState('');
    const [operatingSchedule, setOperatingSchedule] = useState({ name: '', id: null });
    const [operatingTemp, setOperatingTemp] = useState({ name: '', id: null });
    const [environments, setEnvironments] = useState([]);
    const [operatingSchedules, setOperatingSchedules] = useState([]);
    const [operatingTemps, setOperatingTemps] = useState([]);

    const handleClose = () => {
        setIsVisible(false);
        props.setEnvironment(environment);
        props.setFinishedProduct(finishedProduct);
        props.setOperatingSchedule(operatingSchedule);
        props.setOperatingTemp(operatingTemp);
    };

    // const handleSave = () => {
    //     handleClose();
    // };

    useEffect(() => {
        setEnvironments(props.environments.map(item => (
            { label: item, value: item }
        )));
        setOperatingSchedules(props.operatingSchedules);
        setOperatingTemps(props.operatingTemps);
    }, []); // eslint-disable-line

    useEffect(() => {
        setActiveSlide(props.activeSlide);
        setEnvironment(props.environment);
        setFinishedProduct(props.finishedProduct);
        setOperatingSchedule(props.operatingSchedule);
        setOperatingTemp(props.operatingTemp);
    }, [props]);

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <Carousel>
                <div
                    className="d-flex flex-column overflow-hidden m-n4"
                    style={{ height: 'calc(100% + 3rem)' }}
                >
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            height: 44,
                            borderBottom: '1px solid rgba(46,63,92,0.4)'
                        }}
                    >
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: 0.5 }}
                            aria-label="Previous Section"
                            disabled={ true }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-left" />
                            </svg>
                        </button>
                        <div className="d-flex flex-grow-1">
                            <CarouselNavButton
                                isActive={ activeSlide === 1 }
                                onClick={ () => setActiveSlide(1) }
                            >
                                { activeSlide === 1 ? '1. Environment' : '1' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 2 }
                                onClick={ () => setActiveSlide(2) }
                            >
                                { activeSlide === 2 ? '2. Finished Product' : '2' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 3 }
                                onClick={ () => setActiveSlide(3) }
                            >
                                { activeSlide === 3 ? '3. Operating Schedule' : '3' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 4 }
                                onClick={ () => setActiveSlide(4) }
                            >
                                { activeSlide === 4 ? '4. Operating Temperature' : '4' }
                            </CarouselNavButton>
                        </div>
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: 0.5 }}
                            aria-label="Next Section"
                            disabled={ true }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-right" />
                            </svg>
                        </button>
                    </div>

                    <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
                        {
                            activeSlide ?
                                <CarouselItems
                                    activeItem={ activeSlide }
                                    setActiveItem={ setActiveSlide }
                                >
                                    <CarouselItem index={ 1 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Environment &ndash; 1 of 4
                                        </h2>
                                        <p>
                                            What types of environmental elements may impact a sample from this point?
                                        </p>
                                        <CheckboxList
                                            id="environment"
                                            options={ environments }
                                            value={ environments.filter(item => environment[item.value]) }
                                            setValue={ obj => setEnvironment({
                                                Clean: obj.filter(item => item.value === 'Clean').length > 0,
                                                Controlled: obj.filter(item => item.value === 'Controlled').length > 0,
                                                Dusty: obj.filter(item => item.value === 'Dusty').length > 0,
                                                Heated: obj.filter(item => item.value === 'Heated').length > 0,
                                                Humid: obj.filter(item => item.value === 'Humid').length > 0,
                                                Inside: obj.filter(item => item.value === 'Inside').length > 0,
                                                Outside: obj.filter(item => item.value === 'Outside').length > 0,
                                                Underground: obj.filter(item => item.value === 'Underground').length > 0,
                                                Wet: obj.filter(item => item.value === 'Wet').length > 0
                                            }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 2 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Environment &ndash; 2 of 4
                                        </h2>
                                        <label htmlFor="panelFinishedProduct" className="mb-3">
                                            What is the end product being manufactured?
                                        </label>
                                        <input
                                            type="text"
                                            id="panelFinishedProduct"
                                            className="form-control bg-blue-light text-dark border border-primary"
                                            value={ finishedProduct }
                                            onChange={ e => setFinishedProduct(e.target.value) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 3 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Environment &ndash; 3 of 4
                                        </h2>
                                        <p>
                                            What is the operating schedule for this machine?
                                        </p>
                                        <RadioList
                                            id="operatingSchedules"
                                            options={ operatingSchedules }
                                            value={{ label: operatingSchedule.name, value: operatingSchedule.id }}
                                            setValue={ obj => setOperatingSchedule({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 4 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Environment &ndash; 4 of 4
                                        </h2>
                                        <p>
                                            What is the operating temperature for this machine?
                                        </p>
                                        <RadioList
                                            id="operatingTemps"
                                            options={ operatingTemps }
                                            value={{ label: operatingTemp.name, value: operatingTemp.id }}
                                            setValue={ obj => setOperatingTemp({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                </CarouselItems> : ''
                        }

                        <div className="pb-5">
                            <div
                                className="position-relative mt-n3 pt-3"
                                style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #baddfc' }}
                            />
                            <div className="row align-items-center justify-content-center text-center pt-2">
                                <div className="col-4">
                                    <CarouselPrevButton>
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-left" />
                                        </svg>
                                        Prev
                                    </CarouselPrevButton>
                                </div>
                                <div className="col-4">
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: 147 }}
                                            onClick={ handleClose }
                                        >
                                            Done
                                        </button>
                                    </div>
                                <div className="col-4">
                                    <CarouselNextButton>
                                        Next
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-right" />
                                        </svg>
                                    </CarouselNextButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </Panel>
    );
};

export default EnvironmentPanel;
