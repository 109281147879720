import React, { useMemo } from 'react';
import Moment from 'react-moment';
import classNames from 'classnames';
import Table from '../../../components/Table';

const ReportTable = ({ title, data }) => {
    const tableColumns = useMemo(() => {
        const arr = [];

        arr.push({
            Header: title,
            accessor: 'name',
            disableSortBy: true,
            className: 'position-sticky shadow-right pr-4',
            style: { left: 0, textTransform: 'none' }
        });

        if (data.length > 0) {
            const values = data[0].Values;
            if (values) {
                const last = values.length - 1;
                values.map((item, index) => (
                    item.Date ?
                        arr.push({
                            Header: (
                                <div style={{ width: 90 }}>
                                    {
                                        item.Date ?
                                            <Moment
                                                className={ classNames('d-block', {
                                                    'mr-n4 pr-4 border-right': index === last
                                                })}
                                                style={{
                                                    color: '#A290F3',
                                                    paddingTop: '0.75rem',
                                                    paddingBottom: '0.75rem'
                                                }}
                                                format="M/DD/YYYY"
                                            >
                                                { new Date(item.Date) }
                                            </Moment> : ''
                                    }
                                </div>
                            ),
                            accessor: `d${index}`,
                            disableSortBy: true,
                            className: classNames('text-center px-3', {
                                'pl-4': index === 0,
                                'pr-4': index === last
                            })
                        }) : null
                ));
            }
        }

        arr.push(
        {
            Header: <>Arrival<br /> Reference</>,
            accessor: 'arrival',
            disableSortBy: true,
            className: 'text-center pr-4'
        },
        {
            Header: <>Storage<br /> Reference</>,
            accessor: 'storage',
            disableSortBy: true,
            className: 'text-center'
        },
        {
            Header: <>Transfer<br /> Reference</>,
            accessor: 'transfer',
            disableSortBy: true,
            className: 'text-center'
        },
        {
            Header: (
                <span
                    className="d-flex align-items-center justify-content-center px-3 text-dark bg-blue-light"
                    style={{ height: 60 }}
                >
                    Limit:Low
                </span>
            ),
            accessor: 'lowLimit',
            disableSortBy: true,
            className: 'position-sticky shadow-left text-center p-0',
            style: { right: 124 }
        },
        {
            Header: (
                <span
                    className="d-flex align-items-center justify-content-center px-3 text-dark bg-blue-light"
                    style={{ height: 60 }}
                >
                    Limit:High
                </span>
            ),
            accessor: 'highLimit',
            disableSortBy: true,
            className: 'position-sticky text-center p-0',
            style: { right: 0 }
        });

        return arr;
    }, [title, data]);

    const tableData = useMemo(() => {
        const arr = [];

        data.map(item => {
            const obj = {};

            obj.name = item.Name;

            if (item.Values) {
                item.Values.reverse().map((item, index) => (
                    obj[`d${index}`] = item.Value
                ));
            }

            // obj.transfer = (
            //     <a
            //         className="text-success text-underline"
            //         href="/"
            //         onClick={ e => { e.preventDefault(); }}
            //     >
            //         Green CTA
            //     </a>
            // );

            // obj.arrival = (
            //     <button
            //         className="d-flex align-items-center justify-content-center mx-auto border-0 p-0"
            //         style={{ background: 'none', width: 26, height: 26 }}
            //     >
            //         <svg className="text-danger" width="24" height="21">
            //             <use xlinkHref="#icon-exclamation-triangle" />
            //         </svg>
            //     </button>
            // );

            // obj.lowLimit = (
            //     <button
            //         className="d-flex align-items-center justify-content-center mx-auto border-0 p-0"
            //         style={{ background: 'none', width: 26, height: 26 }}
            //     >
            //         <svg className="text-danger" width="24" height="21">
            //             <use xlinkHref="#icon-exclamation-triangle" />
            //         </svg>
            //     </button>
            // );

            // obj.highLimit = (
            //     <button
            //         className="d-flex align-items-center justify-content-center mx-auto border-0 p-0"
            //         style={{ background: 'none', width: 26, height: 26 }}
            //     >
            //         <svg className="text-danger" width="24" height="21">
            //             <use xlinkHref="#icon-exclamation-triangle" />
            //         </svg>
            //     </button>
            // );

            obj.arrival = item.ArrivalRef;
            obj.storage = item.StorageRef;
            obj.transfer = item.TransferRef;
            obj.lowLimit = item.LowLimit;
            obj.highLimit = item.Alarm;

            return arr.push(obj);
        });

        return arr;
    }, [data]);

    return (
        <Table
            columns={ tableColumns }
            data={ tableData }
        />
    );
};

export default ReportTable;
