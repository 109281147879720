import { useContext, useEffect } from 'react';
import getAccount from '../../api/getAccount';
import getSites from '../../api/getSites';
import Context from '../../context/Context';

const Login = props => {
    const { setToken, setAccount, setSites, showLoadingOverlay, setConditionSummary } = useContext(Context);
    const { location, history } = props;
    const params = new URLSearchParams(location.search);
    const returnUrl = params.get('returnUrl') || '/';
    const accessToken = params.get('access_token');
    const expires = params.get('expires');

    useEffect(() => {
        if (accessToken) {
            showLoadingOverlay(true);
            setToken(accessToken);

            const token = {
                value: accessToken,
                expires: new Date(expires)
            };

            localStorage.setItem('token', JSON.stringify(token));

            getAccount(token.value, res => {
                const account = {
                    firstName: res.FullName,
                    email: res.Email,
                    orbitalRole: res.UserOrbitalRole
                };

                const conditionSummary = {
                    area: { name: res.UserLastAreaName, id: res.UserLastAreaIDs },
                    site: { name: res.UserLastSiteName, id: res.UserLastSiteID }
                };

                setAccount(account);
                localStorage.setItem('account', JSON.stringify(account));

                setConditionSummary(conditionSummary);
                localStorage.setItem('conditionSummary', JSON.stringify(conditionSummary));

                getSites(token.value, res => {
                    const sites = res.map(item => ({
                        name: item.CustomerName,
                        id: item.ERPID,
                        accountId: item.CRMAccountID
                    }));

                    setSites(sites);
                    localStorage.setItem('sites', JSON.stringify(sites));
                    showLoadingOverlay(false);
                    history.push(returnUrl);
                });
            });
        } else {
            global.location = `${process.env.REACT_APP_AUTH_URL}?returnUrl=${global.location.origin}/login`;
        }
    }, [accessToken, expires, history, returnUrl, setAccount, setConditionSummary, setSites, setToken, showLoadingOverlay]);

    return null;
};

export default Login;
