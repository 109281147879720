const apiUrl = process.env.REACT_APP_API_URL;

const getHelp = (cb) => {
    fetch(`${apiUrl}/help`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getHelp;
