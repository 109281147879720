import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

const EULA = () => {
    const { setPageTitle } = useContext(Context);

    useEffect(() => {
        setPageTitle('End-User License Agreement');
    }, [setPageTitle]);

    return (
        <div className="container-fluid">
            <div className="row mx-n4">
                <div className="col-8 px-4 pb-5">
                    <p style={{ textAlign: 'center' }}><strong>TRICO APPLICATION END-USER LICENSE AGREEMENT</strong></p>
                    <p>THIS END USER LICENSE AGREEMENT (THIS "EULA") IS A BINDING LEGAL CONTRACT BETWEEN YOU (DEFINED BELOW) AND TRICO CORPORATION AND ITS AFFILIATES (COLLECTIVELY, "TRICO").</p>
                    <p>BY CLICKING THE "ACCEPT" BUTTON OR BY ACCESSING AND/OR USING THE <strong>ORBITAL </strong>SOFTWARE (THE "SOFTWARE" AS FURTHER DEFINED BELOW) YOU (A) ACCEPT THIS EULA AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS, AND (B) REPRESENT AND WARRANT THAT, IF YOU ARE ACTING ON BEHALF OF YOUR ORGANIZATION, YOU HAVE THE RIGHT, POWER AND AUTHORITY TO, AND DO, ACCEPT THESE TERMS AND CONDITIONS ON ITS BEHALF.</p>
                    <ol>
                        <li><u>Definitions</u>. "Software" means (a) all of the contents of the files of software applications including but not limited to (i) the object code only form of Software; (ii) documentation related to the Software provided in connection therewith ("Documentation"); and (b) upgrades, modified versions, updates, additions, and copies of the Software, if any, licensed to you by Trico (collectively, "Updates"). "Use" or "Using" means to access, install, download, copy or otherwise benefit from using the functionality of the Software in accordance with the Documentation.</li>
                        <li><u>Software License</u>. As long as you comply with the terms of this EULA (this "EULA"), Trico grants to you a limited, non-exclusive license to Use the Software for the purposes described in the Documentation for the term of this EULA and the corresponding Portal Terms and Conditions.</li>
                        <li><u>Restrictions on Use</u>. You shall use the Software only for your own internal business purposes of the organization. You shall not: (a) process or permit to be processed in any manner the data or files of any third party through the Software; (b) use the Software in any manner as part of the operation of a service bureau; or (c) allow access to the Software through any terminals or computers located outside of your location or using your login credentials which have been assigned to you.</li>
                        <li><u>Restrictions on Copying/Modification</u>. You shall not use, copy, alter, merge, adapt, modify, enhance, revise, maintain, rent, lease or sublicense any Software licensed hereunder, or any copy thereof, in whole or in part, except as expressly provided in this EULA. You shall not: (a) reverse-engineer, decompile, disassemble or otherwise make any attempt to access the source code of the Software licensed hereunder, in whole or in part - the license granted by this EULA includes no rights in or to the source code version of the Software; (b) modify, or create derivative works based upon any Software licensed hereunder, in whole or in part; (c) except as expressly permitted by the terms of this EULA, copy all or any part of the Software. Any Software product licensed hereunder is licensed as a single product and its component parts may not be separated for use on more than one computer.</li>
                        <li><u>Term and Termination</u>. The Term of this EULA shall commence upon your first Use of the Software in any capacity and shall continue until terminated. This EULA shall automatically terminate upon termination of the Trico Portal Terms and Conditions.</li>
                        <li><u>Termination for Breach.</u> Trico may terminate this EULA immediately if you materially breach any term of this EULA or the Trico Portal Terms and Conditions.</li>
                        <li><u>Termination for Insolvency</u>. This EULA will terminate immediately, and without notice, in the event a petition for relief under any bankruptcy or insolvency law or regulation is filed by or against either party, either party hereto makes an assignment for the benefit of creditors or a receiver is appointed for all or a substantial portion of either party's assets; provided, however, that if such petition, assignment, or appointment is involuntary, such party shall have sixty (60) days to have such petition, assignment, or appointment vacated or dismissed.</li>
                        <li><u>Termination for Convenience</u>. You may terminate this EULA at any time for any reason or no reason at all upon compliance of all of your obligations hereunder. Termination of this EULA by you shall not terminate or cancel any other agreement between You and Trico.</li>
                        <li><u>Actions on Termination</u>. Upon termination, expiration, or cancellation of this EULA, all your right to access and use the Software and Documentation shall cease, the license granted hereunder shall terminate, and you shall immediately discontinue any and all access and use of the Software and Documentation.</li>
                        <li><u>Intellectual Property Rights</u>. You may not copy the Software. Any information supplied by Trico or obtained by you, as permitted hereunder, may only be used by you for the purpose described herein and may not be disclosed to any third party or used to create any software which is substantially similar to the expression of the Software. The Software, including, without limitation, the information included in its modules, procedures, and functions, their arrangement, organization, program logic, and methods of interaction, and all related documentation (including, without limitation, the Documentation), data formats, output formats, and other aspects thereof and their modifications and enhancements, if any, and the know-how embodied in the Software, is confidential and trade secret information (the "Proprietary Information") that is proprietary to and solely owned by Trico, together with all related copyrights and trademarks. You agree to maintain the Proprietary Information in strictest confidence for the benefit of Trico You shall not sell, license, publish, display, distribute, disclose, or otherwise make available or allow to be made available the Proprietary Information to any third party nor use such Proprietary Information except as authorized by this EULA. You shall not disclose or permit the disclosure of any such Proprietary Information, or any flow charts, logic diagrams, user manuals, screenshots, or screens embodying, referencing or summarizing all or any part of such Proprietary Information, to any third party without the prior written consent of Trico</li>
                        <li><u>Transfer and Assignment</u>. You may not share, rent, lease, sublicense or authorize all or any portion of the Software or Documentation, or your login credentials used to access the Software and Documentation, to any third party except as may be expressly permitted herein. You may not sell, assign, or otherwise transfer this EULA to any third party without the prior written consent of Trico</li>
                        <li><strong><u>NO WARRANTY</u>. The Software is being delivered to you "AS IS" and Trico makes no warranty as to its use, AVAILABILITY, or performance, alone or in combination with any system, operating system or third-party software. Trico DOES NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE SOFTWARE. EXCEPT FOR ANY WARRANTY, CONDITION, REPRESENTATION OR TERM TO THE EXTENT TO WHICH THE SAME CANNOT OR MAY NOT BE EXCLUDED OR LIMITED BY LAW APPLICABLE TO YOU IN YOUR JURISDICTION, Trico MAKES NO WARRANTIES CONDITIONS, REPRESENTATIONS, OR TERMS (EXPRESS OR IMPLIED WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE OR OTHERWISE) AS TO ANY MATTER INCLUDING WITHOUT LIMITATION NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, INTEGRATION, SATISFACTORY QUALITY, OR FITNESS FOR ANY PARTICULAR PURPOSE. Trico DOES NOT WARRANT THAT THE SOFTWARE WILL BE FREE OF BUGS OR PROGRAM ERRORS. Trico DOES NOT WARRANT THAT THE SOFTWARE WILL BE FREE FROM VIRUSES.</strong></li>
                        <li><strong><u>LIMITATION OF LIABILITY</u>. UNDER NO CIRCUMSTANCES SHALL Trico BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR ANY OTHER PECUNIARY LOSS ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SOFTWARE (INCLUDING, WITHOUT LIMITATION, ANY LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS, DISRUPTION OF BUSINESS, LOSS OF PROFITS, OR ANY OTHER MATTER RELATING TO YOUR USE OR INABILITY TO USE THE SOFTWARE) OR RELATED IN ANY WAY TO THIS EULA OR THE SUBJECT THEREOF, INCLUDING, WITHOUT LIMITATION, ANY CLAIM RELATED TO ALLEGATIONS THAT THE SOFTWARE INFRINGES ANOTHER PARTY'S INTELLECTUAL PROPERTY OR OTHER PROPRIETARY RIGHTS, WHETHER ARISING UNDER THEORIES OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT, OR OTHER THEORY, REGARDLESS WHETHER LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL Trico'S TOTAL LIABILITY TO YOU EXCEED THE LICENSE FEES, IF ANY, ACTUALLY RECEIVED BY Trico FROM YOU HEREUNDER DURING THE CURRENT MONTH OF ANY CLAIM OF LIABILITY LIMITED HEREUNDER. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SET FORTH IN THIS SECTION SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.</strong> <strong>NO OBLIGATION OR LIABILITY SHALL ARISE FROM Trico' RENDERING OF TECHNICAL OR OTHER ADVICE OR SERVICE IN CONNECTION WITH THIS EULA, INCLUDING, WITHOUT LIMITATION, ADVICE OR SERVICE RELATED TO THE INSTALLATION OR CONFIGURATION OF THE SOFTWARE.</strong> <strong>Some states and jurisdictions do not allow exclusion or limitation of liability for consequential or incidental DAMAGES and so the above limits and exclusions may not apply to you or may be limited by law.</strong></li>
                        <li><u>Export Rules</u>. You agree that the Software will not be accessed, transferred, exported, or re-exported into any country, or to any party, or used in any manner prohibited by the United States Export Administration Act or any other export laws, restrictions or regulations (collectively the "Export Laws"). You represent and warrant that you are not a citizen, or otherwise located within, an embargoed nation (including without limitation Iran, Iraq, Syria, Sudan, Libya, Cuba, North Korea, and Serbia, if those countries are on the then-current list of embargoed nations) and that you are not otherwise prohibited under the Export Laws from receiving or using the Software. All rights to Use the Software are granted on condition that such rights are forfeited if you fail to comply with the terms of this EULA.</li>
                        <li><u>Governing Law</u>. This EULA will be governed by and construed in accordance with the substantive laws in force in the State of Wisconsin. The respective courts of Milwaukee County, Wisconsin shall have non-exclusive jurisdiction over all disputes relating to this EULA. This EULA will not be governed by the conflict of law rules of any jurisdiction or the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded.</li>
                        <li><u>General Provisions</u>. If any part of this EULA is found void and unenforceable, it will not affect the validity of the balance of the EULA, which shall remain valid and enforceable according to its terms. This EULA shall not prejudice the statutory rights of any party dealing as a consumer. This EULA may only be modified by a writing signed by an authorized officer of Trico Updates may be licensed to you by Trico with additional or different terms. This is the entire agreement between Trico and you relating to the Software and it supersedes any prior representations, discussions, undertakings, communications or advertising relating to the Software.</li>
                        <li><u>Severability</u>. The provisions of this EULA and the attached schedules are severable. If any provision of this EULA or any schedule attached hereto is held to be invalid, illegal, or unenforceable, such provision is to that extent to be deemed omitted and not part of this EULA. The validity, legality, or enforceability of the remaining provisions shall in no way be affected or impaired thereby and shall be valid and enforceable to the maximum extent permitted by law.</li>
                        <li><u>Injunctive Relief</u>. You acknowledge and agree that monetary damages alone would not be an adequate remedy in the event of a material breach by you of your obligations under this EULA and that, in such event, Trico shall be entitled to injunctive relief to require you to comply with your obligations hereunder. Any remedy of Trico under this EULA shall be cumulative and not exclusive of any other remedy available to Trico under this EULA, at law or in equity.</li>
                        <li><u>Entire Agreement</u>. This EULA and the MSSA constitutes the exclusive and entire agreement between the parties with respect to the subject matter hereof, and supersedes all prior agreements, negotiations, representations and proposals, written or oral, relating to the subject matter hereof.</li>
                        <li><u>Modification and Wavier</u>. No modification of this EULA and no waiver of any breach of this EULA shall be effective unless in writing and signed by an authorized representative of the party against whom enforcement is sought. No waiver of any breach of this EULA and no course of dealing between the parties shall be construed as a waiver of any subsequent breach of this EULA.</li>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default EULA;
