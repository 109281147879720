import React from 'react';
import classNames from 'classnames';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../../components/Tooltip';

const WearParticleAnalysis = ({ data, metals }) => {
    return (
        data.length > 0 ?
            <div className="bg-gray-dark rounded mb-5">
                <div className="d-flex align-items-center justify-content-between py-4 pl-5 pr-3">
                    <h3 className="h5 font-weight-semibold text-uppercase mb-0">
                        Wear Particle Analysis
                    </h3>
                    <Tooltip className="position-relative">
                        <TooltipTrigger
                            className="btn btn-link bg-transparent border-0 d-flex align-items-center py-1 px-2 mr-n2"
                            style={{ fontSize: '0.875rem', textTransform: 'none', textDecoration: 'none', marginTop: -2, marginBottom: -3 }}
                        >
                            Learn More
                            <svg className="ml-2" width="20" height="20">
                                <use xlinkHref="#icon-info-circle" />
                            </svg>
                        </TooltipTrigger>
                        <TooltipContent position="top">
                            Analytical results based on ferrous, non-ferrous, and other wear particle contaminants. 
                        </TooltipContent>
                    </Tooltip>
                </div>
                <div>
                    <div className="table-wrapper">
                        <div className="row no-gutters pb-4" style={{
                            width: 'calc(100% - 1rem)',
                            minWidth: 1050,
                            maxWidth: 1200
                        }}>
                            {
                                data.map((item, index) => (
                                    <div key={ index } className="col-4">
                                        <table className="w-100">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="border-top border-right border-bottom text-uppercase text-blue-light"
                                                        style={{ height: 60, lineHeight: 1.2 }}
                                                    >
                                                        <div
                                                            className={ index === 0 ? 'pl-5' : 'pl-3' }
                                                            style={{ width: index === 0 ? 120 : 140 }}
                                                        >
                                                            { item.Name }
                                                        </div>
                                                    </th>
                                                    <th className="border-top border-right border-bottom">
                                                        <span className="sr-only">Value</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    item.Rows.map((row, rowIndex) => (
                                                        <tr key={ rowIndex }>
                                                            <th
                                                                scope="row"
                                                                className={ `border-right font-weight-semibold ${index === 0 ? 'pl-5' : 'pl-3'}` }
                                                                style={{ height: 55 }}
                                                            >
                                                                { row.Name }
                                                            </th>
                                                            <td
                                                                className={ classNames('border-right align-middle font-weight-semibold p-0', {
                                                                    'text-info': item.Name === 'Ferrous Wear',
                                                                    'text-secondary': item.Name === 'Nonferrous Wear',
                                                                    'text-primary': item.Name === 'Contaminants',
                                                                }) }
                                                                style={{ fontSize: 14 }}
                                                            >
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div
                                                                        className={ classNames('d-inline-block', {
                                                                            'bg-info': item.Name === 'Ferrous Wear',
                                                                            'bg-secondary': item.Name === 'Nonferrous Wear',
                                                                            'bg-primary': item.Name === 'Contaminants',
                                                                        }) }
                                                                        style={{
                                                                            width: `${+row.Value / 10 * 100}%`,
                                                                            maxWidth: '100%',
                                                                            height: 14,
                                                                            borderRadius: '0 0.25rem 0.25rem 0',
                                                                            backgroundImage: 'linear-gradient(to right, transparent, rgba(255,255,255,0.15)'
                                                                        }}
                                                                    />
                                                                    <div className={ +row.Value > 8 ? 'w-100 text-right px-2' : 'px-2' }>
                                                                        { row.Value }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td className="border-top" style={{ width: 150, opacity: 0.5 }}>
                                                        <div
                                                            className="d-flex justify-content-between text-center"
                                                            style={{
                                                                fontSize: '0.625rem',
                                                                margin: '0 -0.5rem'
                                                            }}
                                                        >
                                                            <span style={{ width: '0.875rem' }}>0</span>
                                                            <span style={{ width: '0.875rem' }}>1</span>
                                                            <span style={{ width: '0.875rem' }}>2</span>
                                                            <span style={{ width: '0.875rem' }}>3</span>
                                                            <span style={{ width: '0.875rem' }}>4</span>
                                                            <span style={{ width: '0.875rem' }}>5</span>
                                                            <span style={{ width: '0.875rem' }}>6</span>
                                                            <span style={{ width: '0.875rem' }}>7</span>
                                                            <span style={{ width: '0.875rem' }}>8</span>
                                                            <span style={{ width: '0.875rem' }}>9</span>
                                                            <span style={{ width: '0.875rem' }}>10</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row align-items-center text-center mx-0 border-top">
                        {
                            metals.map((item, index) => (
                                <div
                                    key={ index }
                                    className="col font-weight-semibold py-4"
                                    style={{
                                        lineHeight: 1.2,
                                        color: item.Present === 'Y' ? '#f4864b' : '#2e3f5c'
                                    }}
                                >
                                    { item.Name }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div> : ''
    );
};

export default WearParticleAnalysis;
