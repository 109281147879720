import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import getAreasBySiteID from '../../api/getAreasBySiteID';
import getPrograms from '../../api/getPrograms';
import getFluidTypes from '../../api/getFluidTypes';
import getCoolantTypes from '../../api/getCoolantTypes';
import getLubricantBrandNames from '../../api/getLubricantBrandNames';
import getSamplingMethods from '../../api/getSamplingMethods';
import getSamplingLocations from '../../api/getSamplingLocations';
import getVisualInspectionTypes from '../../api/getVisualInspectionTypes';
import getVisualInspectionTimings from '../../api/getVisualInspectionTimings';
import getVisualInspectors from '../../api/getVisualInspectionInspectedBy';
import getAssetTypes from '../../api/getAssetTypes';
import getReservoirCaps from '../../api/getReservoirCaps';
import getCriticalities from '../../api/getCriticalities';
import getFiltrationMethods from '../../api/getFiltrationMethods';
import getFilterTypes from '../../api/getFilterTypes';
import getSizeFiltered from '../../api/getSizeFiltered';
import getBreatherTypes from '../../api/getBreatherTypes';
import getBearingTypes from '../../api/getBearingTypes';
import getGearTypes from '../../api/getGearTypes';
import getStages from '../../api/getStages';
import getEDS from '../../api/getEDS';
import postEDS from '../../api/postEDS';
import putEDS from '../../api/putEDS';
import getOperatingSchedules from '../../api/getOperatingSchedules';
import getOperatingTemps from '../../api/getOperatingTemps';
import getModulesByAssetID from '../../api/getModulesByAssetID';
import getComponentByModuleID from '../../api/getComponentByModuleID';
import Context from '../../context/Context';
import RadioList from '../../components/RadioList';
import Modal from '../../components/Modal';
import Alert from '../../components/Alert';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../components/Tooltip';
import PanelToggle from './components/PanelToggle';
import EditSitePanel from './components/EditSitePanel';
import EditAreaPanel from './components/EditAreaPanel';
import LubricantPanel from './components/LubricantPanel';
import EquipmentPanel from './components/EquipmentPanel';
import EnvironmentPanel from './components/EnvironmentPanel';
import ReferencePanel from './components/ReferencePanel';
import CopySPIDButton from './components/CopySPIDButton';
import Select from '../../components/Select';

const SPIDDetail = ({ match, location }) => {
    const context = useContext(Context);
    const { token, setPageTitle, sites, conditionSummary, showLoadingOverlay, account } = context;
    const [type, setType] = useState({ name: '', id: null });
    const [activeSection, setActiveSection] = useState('Lubricant');
    const [lubricantPercentage, setLubricantPercentage] = useState(0);
    const [equipmentPercentage, setEquipmentPercentage] = useState(0);
    const [environmentPercentage, setEnvironmentPercentage] = useState(0);
    const [showSitePanel, setShowSitePanel] = useState(false);
    const [showAreaPanel, setShowAreaPanel] = useState(false);
    const [showLubricantPanel, setShowLubricantPanel] = useState(false);
    const [lubricantPanelActiveSlide, setLubricantPanelActiveSlide] = useState(1);
    const [showEquipmentPanel, setShowEquipmentPanel] = useState(false);
    const [equipmentPanelActiveSlide, setEquipmentPanelActiveSlide] = useState(3);
    const [showEnvironmentPanel, setShowEnvironmentPanel] = useState(false);
    const [environmentPanelActiveSlide, setEnvironmentPanelActiveSlide] = useState(1);
    const [showReferencePanel, setShowReferencePanel] = useState(false);
    const [referencePanelActiveSlide, setReferencePanelActiveSlide] = useState(1);
    const [entityID, setEntityID] = useState(null);
    const [SPID, setSPID] = useState(null);
    const [copiedSPID, setCopiedSPID] = useState(null);
    const [site, setSite] = useState({ name: '', id: null });
    const [area, setArea] = useState({ name: '', id: null });
    const [areas, setAreas] = useState([]);
    const [Program, setProgram] = useState({ name: '', id: null });
    const [SPIDName, setSPIDName] = useState('');
    const [SPIDDescription, setSPIDDescription] = useState('');
    const [fluidType, setFluidType] = useState({ name: '', id: null });
    const [coolantType, setCoolantType] = useState({ name: '', id: null });
    const [fluidManufacturer, setFluidManufacturer] = useState({ name: '', id: null });
    const [fluidProductTradeName, setFluidProductTradeName] = useState({ name: '', id: null });
    const [specificFluidType, setSpecificFluidType] = useState('');
    const [samplingMethod, setSamplingMethod] = useState({ name: '', id: null });
    const [samplingFrequency, setSamplingFrequency] = useState({ name: '', id: null });
    const [samplingLocation, setSamplingLocation] = useState({ name: '', id: null });
    const [ISOCleanliness1, setISOCleanliness1] = useState('');
    const [ISOCleanliness2, setISOCleanliness2] = useState('');
    const [ISOCleanliness3, setISOCleanliness3] = useState('');
    const [inspectionType, setInspectionType] = useState({ name: '', id: null });
    const [inspectionFrequency, setInspectionFrequency] = useState({ name: '', id: null });
    const [inspector, setInspector] = useState({ name: '', id: null });
    const [notes, setNotes] = useState('');
    const [assetType, setAssetType] = useState({ name: '', id: null });
    const [module, setModule] = useState({ name: '', id: null });
    const [component, setComponent] = useState({ name: '', id: null });
    const [manufacturer, setManufacturer] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [reservoirCap, setReservoirCap] = useState({ name: '', id: null });
    const [criticality, setCriticality] = useState({ name: '', id: null });
    const [filtrationMethod, setFiltrationMethod] = useState({ name: '', id: null });
    const [sampleBeforeFilter, setSampleBeforeFilter] = useState(null);
    const [filterType, setFilterType] = useState({ name: '', id: null });
    const [sizeFiltered, setSizeFiltered] = useState({ name: '', id: null });
    const [breathers, setBreathers] = useState({ name: '', id: '' });
    const [bearingType, setBearingType] = useState({ name: '', id: '' });
    const [gearType, setGearType] = useState({ name: '', id: '' });
    const [usingRecycledOil, setUsingRecycledOil] = useState('');
    const [betaRating, setBetaRating] = useState({ name: '', id: '' });
    const [environment, setEnvironment] = useState({
        Clean: false,
        Controlled: false,
        Dusty: false,
        Heated: false,
        Humid: false,
        Inside: false,
        Outside: false,
        Underground: false,
        Wet: false
    });
    const [finishedProduct, setFinishedProduct] = useState('');
    const [operatingSchedule, setOperatingSchedule] = useState({ name: '', id: '' });
    const [operatingTemp, setOperatingTemp] = useState({ name: '', id: '' });
    const [programs, setPrograms] = useState([]);
    const [fluidTypes, setFluidTypes] = useState([]);
    const [coolantTypes, setCoolantTypes] = useState([]);
    const [lubricantBrandNames, setLubricantBrandNames] = useState([]);
    const [samplingMethods, setSamplingMethods] = useState([]);
    const [samplingLocations, setSamplingLocations] = useState([]);
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [inspectionTimings, setInspectionTimings] = useState([]);
    const [inspectors, setInspectors] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [moduleTypes, setModuleTypes] = useState([]);
    const [componentTypes, setComponentTypes] = useState([]);
    const [reservoirCaps, setReservoirCaps] = useState([]);
    const [criticalities, setCriticalities] = useState([]);
    const [filtrationMethods, setFiltrationMethods] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [sizeFilteredTypes, setSizeFilteredTypes] = useState([]);
    const [breatherTypes, setBreatherTypes] = useState([]);
    const [bearingTypes, setBearingTypes] = useState([]);
    const [gearTypes, setGearTypes] = useState([]);
    const [operatingSchedules, setOperatingSchedules] = useState([]);
    const [operatingTemps, setOperatingTemps] = useState([]);
    const [stages, setStages] = useState([]);
    const environments = ['Clean', 'Controlled', 'Dusty', 'Heated', 'Humid', 'Inside', 'Outside', 'Underground', 'Wet'];
    const [preventExit, setPreventExit] = useState(false);
    const history = useHistory();
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSaveFailedModal, setShowSaveFailedModal] = useState(false);
    const [showSaveNewModal, setShowSaveNewModal] = useState(false);
    const node1 = useRef();
    const node2 = useRef();

    const handleChangeStep = name => {
        setActiveSection(name);
        setPageTitle(`SPID Detail: ${name}`);
        document.getElementById('content').scrollTo(0, 0);
    };

    const handleChangeSite = site => {
        setSite(site);
        setAreas([]);
        setArea({ name: '', id: null });

        getAreasBySiteID(token, site.id, res => {
            const areas = res.map(item => ({
                name: item.AreaDesc,
                id: item.AreaEntityID
            }));

            setAreas(areas);

            if (areas.length > 0) {
                setArea(areas[0]);
            }
        });
    };

    const handleChangeArea = area => {
        setArea(area);
    };

    const handleChangeAssetType = () => {
        if (assetType.id) {
            getModulesByAssetID(token, assetType.id, res =>{
                if (Array.isArray(res)) {
                    // if the current module does not exist in the response, clear the current module
                    if (module.id && !res.some(m => m.ID === module.id)) {
                        setModule({ name: '', id: null });
                    }

                    setModuleTypes(res.map(m =>({ label: m.Name, value: m.ID })));
                }
            });
        }
    };

    const handleChangeModuleType = () => {
        // call api to update the module selection ( do logic to enable/disable dropdown)
        getComponentByModuleID(token, assetType.id, module.id, res =>{
            if(Array.isArray(res)){
              if( component.id && !res.some(c => c.ID === component.id)){

                setComponent({ name: '', id: null })
              }
              setComponentTypes(res.map(c =>({ label: c.Name, value: c.ID })))
            }
        });
    };

    const handleShowLubricantPanel = slide => {
        setShowLubricantPanel(true);
        setLubricantPanelActiveSlide(slide);
    };

    const handleShowEquipmentPanel = slide => {
        setShowEquipmentPanel(true);
        setEquipmentPanelActiveSlide(slide);
    };

    const handleShowEnvironmentPanel = slide => {
        setShowEnvironmentPanel(true);
        setEnvironmentPanelActiveSlide(slide);
    };

    const handleShowReferencePanel = slide => {
        setShowReferencePanel(true);
        setReferencePanelActiveSlide(slide);
    };

    const handleSave = () => {
        const data = {
            EntityID: entityID || 0,
            ParentEntityID: area.id,
            EntityDesc: SPIDDescription,
            EntityName: SPIDName,
            SPIDTypeID: type.id,
            ItemID: Program.id,
            FluidType: fluidType.id,
            Coolant: coolantType.id,
            LubeID: fluidProductTradeName.id,
            SpecificFluidType: specificFluidType,
            SamplingMethod: samplingMethod.id,
            Expectation: samplingFrequency.id,
            LocationInSystem: samplingLocation.id,
            IsoCleanliness1: ISOCleanliness1,
            IsoCleanliness2: ISOCleanliness2,
            IsoCleanliness3: ISOCleanliness3,
            VisualOilInspection: inspectionType.id,
            VisualOilInspectionTiming: inspectionFrequency.id,
            PerformedBy: inspector.id,
            Notes: notes,
            AssetType: assetType.id,
            ModuleType: module.id,
            CompType: component.id,
            MachineManufacturer: manufacturer,
            Model: modelNumber,
            SerialNumber: serialNumber,
            ReservoirCapacity: reservoirCap.id,
            Criticality: criticality.id,
            OutfittedForFiltration: filtrationMethod.id,
            IsSampleBeforeFilter: sampleBeforeFilter,
            filterType: filterType.id,
            filterSize: sizeFiltered.id,
            Breathers: breathers.id,
            BearingType: bearingType.id,
            GearType: gearType.id,
            EnvClean: environment.Clean,
            EnvControlled: environment.Controlled,
            EnvDusty: environment.Dusty,
            EnvHeated: environment.Heated,
            EnvHumid: environment.Humid,
            EnvInside: environment.Inside,
            EnvOutside: environment.Outside,
            EnvUnderground: environment.Underground,
            EnvWet: environment.Wet,
            FinishedProducts: finishedProduct,
            OperationSchedule: operatingSchedule.id,
            OilTemp: operatingTemp.id,
            BetaRating: betaRating.id
        };

        showLoadingOverlay(true);

        if (SPID) {
            putEDS(token, data, res => {
                if (res.Success) {
                    setPreventExit(false);
                    setShowSaveModal(true);
                }
                showLoadingOverlay(false);
            });
        } else {
            postEDS(token, data, res => {
                if (res.Success) {
                    setPreventExit(false);
                    setShowSaveNewModal(true);
                    setEntityID(res.EntityID);

                    showLoadingOverlay(false);
                    global.dataLayer.push({ event: 'add_new_spid' });
                }
                else{
                    setShowSaveFailedModal(true);
                    showLoadingOverlay(false);                    
                }
                
            });
        }
    };

    const getPercentage = array => {
        let count = 0;

        array.forEach(item => {
            if (item !== null && ((typeof item === 'string' && item.trim() !== '') || (typeof item === 'object' && Object.values(item).filter(x => x === true).length > 0) || (typeof item === 'boolean' && item !== null) || typeof item === 'number')) {
                count++;
            }
        });

        return ((count / array.length) * 100).toFixed(0)
    };

    global.onbeforeunload = () => {
        if (preventExit) {
            return '';
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // is this an existing SPID or a copy?
        // if neither param exists, it's new
        if (match.params.id || params.get('copyFrom')) {
            const id = match.params.id ? +match.params.id : +params.get('copyFrom');

            showLoadingOverlay(true);

            if (match.params.id) {
                setEntityID(+match.params.id);
            } else if (params.get('copyFrom')) {
                setEntityID(null);
                setSPID(null);
            }

            getEDS(token, id, res => {
                if (res.SPIDType) {
                    if (match.params.id) {
                        setSPID(res.SPID);
                    } else if (params.get('copyFrom')) {
                        setCopiedSPID(res.SPID);
                    }

                    setType({ name: res.SPIDType, id: res.SPIDTypeID });
                    setSite({ name: res.CustName, id: res.SiteID });
                    setArea({ name: res.AreaName, id: res.ParentEntityID });
                    setProgram({ name: res.ItemNumber, id: res.ItemID });

                    if (params.get('copyFrom')) {
                        setSPIDName(`${res.EntityName}-copy`);
                    } else {
                        setSPIDName(res.EntityName);
                    }

                    setSPIDDescription(res.EntityDesc);
                    setFluidType({ name: res.FluidTypeName, id: res.FluidType });
                    setCoolantType({ name: res.CoolantName, id: res.Coolant });
                    setFluidManufacturer({ name: res.LubeMFG, id: res.LubeMFGID });
                    setFluidProductTradeName({ name: res.LubeDescription, id: res.LubeID });
                    setSpecificFluidType(res.SpecificFluidType);
                    setSamplingMethod({ name: res.SamplingMethodName, id: res.SamplingMethod });
                    setSamplingFrequency({ name: res.ExpectationName, id: res.Expectation });
                    setSamplingLocation({ name: res.SampleLocName, id: res.LocationInSystem });
                    setISOCleanliness1(res.IsoCleanliness1);
                    setISOCleanliness2(res.IsoCleanliness2);
                    setISOCleanliness3(res.IsoCleanliness3);
                    setInspectionType({ name: res.VisualOilInspectionName, id: res.VisualOilInspection });
                    setInspectionFrequency({ name: res.VisualOilInspectionTimingName, id: res.VisualOilInspectionTiming });
                    setInspector({ name: res.PerformedByName, id: res.PerformedBy });
                    setNotes(res.Notes);
                    setAssetType({ name: res.AssetTypeName, id: res.AssetType });
                    setModule({ name: res.ModuleTypeName, id: res.ModuleType });
                    setComponent({ name: res.CompTypeName, id: res.CompType });
                    setManufacturer(res.MachineManufacturer);
                    setModelNumber(res.Model);
                    setSerialNumber(res.SerialNumber);
                    setReservoirCap({ name: res.ReservoirCapacityName, id: res.ReservoirCapacity });
                    setCriticality({ name: res.CriticalityName, id: res.Criticality });
                    setFiltrationMethod({ name: res.OutfittedForFiltrationName, id: res.OutfittedForFiltration });
                    setSampleBeforeFilter(res.IsSampleBeforeFilter);
                    setFilterType({ name: res.OilFilterTypeName, id: res.FilterType });
                    setSizeFiltered({ name: res.OilFilterSizeName, id: res.FilterSize });
                    setBreathers({ name: res.BreathersName, id: res.Breathers });
                    setBearingType({ name: res.BearingTypeName, id: res.BearingType });
                    setGearType({ name: res.GearTypeName, id: res.GearType });
                    setEnvironment({
                        Clean: res.EnvClean,
                        Controlled: res.EnvControlled,
                        Dusty: res.EnvDusty,
                        Heated: res.EnvHeated,
                        Humid: res.EnvHumid,
                        Inside: res.EnvInside,
                        Outside: res.EnvOutside,
                        Underground: res.EnvUnderground,
                        Wet: res.EnvWet
                    });
                    setFinishedProduct(res.FinishedProducts);
                    setOperatingSchedule({ name: res.OperationScheduleName, id: res.OperationSchedule });
                    setOperatingTemp({ name: res.OilTempName, id: res.OilTemp });
                    setBetaRating({ name: res.BetaRatingName, id: res.BetaRating });

                    getAreasBySiteID(token, res.SiteID, res => {
                        const areas = res.map(item => ({
                            name: item.AreaDesc,
                            id: item.AreaEntityID
                        }));
                        setAreas(areas);
                        showLoadingOverlay(false);

                        if (params.get('copyFrom')) {
                            setShowCopyModal(true);
                            setPreventExit(true);
                        } else {
                            setShowCopyModal(false);
                            setPreventExit(false);
                        }
                    });
                } else if (res.Message) {
                    alert(res.Message);
                    showLoadingOverlay(false);
                }
            });
        } else {
            setPreventExit(false);

            if (params.get('type') && params.get('type').toLowerCase() === 'rds') {
                setType({ name: 'RDS', id: 1 });
            } else {
                setType({ name: 'EDS', id: 2 });
            }

            setEntityID(null);
            setSPID(null);
            setSite({ name: '', id: null });
            setArea({ name: '', id: null });
            setProgram({ name: '', id: null });
            setSPIDName('');
            setSPIDDescription('');
            setFluidType({ name: '', id: null });
            setCoolantType({ name: '', id: null });
            setFluidManufacturer({ name: '', id: null });
            setFluidProductTradeName({ name: '', id: null });
            setSpecificFluidType('');
            setSamplingMethod({ name: '', id: null });
            setSamplingFrequency({ name: '', id: null });
            setSamplingLocation({ name: '', id: null });
            setISOCleanliness1('');
            setISOCleanliness2('');
            setISOCleanliness3('');
            setInspectionType({ name: '', id: null });
            setInspectionFrequency({ name: '', id: null });
            setInspector({ name: '', id: null });
            setNotes('');
            setAssetType({ name: '', id: null });
            setModule({ name: '', id: null });
            setComponent({ name: '', id: null });
            setManufacturer('');
            setModelNumber('');
            setSerialNumber('');
            setReservoirCap({ name: '', id: null });
            setCriticality({ name: '', id: null });
            setFiltrationMethod({ name: '', id: null });
            setSampleBeforeFilter(null);
            setFilterType({ name: '', id: null });
            setSizeFiltered({ name: '', id: null });
            setBreathers({ name: '', id: null });
            setBearingType({ name: '', id: null });
            setGearType({ name: '', id: null });
            setEnvironment({
                Clean: false,
                Controlled: false,
                Dusty: false,
                Heated: false,
                Humid: false,
                Inside: false,
                Outside: false,
                Underground: false,
                Wet: false
            });
            setFinishedProduct('');
            setOperatingSchedule({ name: '', id: null });
            setOperatingTemp({ name: '', id: null });
            setBetaRating({ name: '', id: null });

            if (conditionSummary.site) {
                setSite(conditionSummary.site);

                if (conditionSummary.area.name !== 'All Areas') {
                    setArea(conditionSummary.area);
                }

                getAreasBySiteID(token, conditionSummary.site.id, res => {
                    const areas = res.map(item => ({
                        name: item.AreaDesc,
                        id: item.AreaEntityID
                    }));

                    setAreas(areas);
                });
            }
        }
    }, [location]); // eslint-disable-line

    useEffect(() => {
        setLubricantPercentage(getPercentage([
            Program.name,
            SPIDName,
            SPIDDescription,
            fluidType.name,
            coolantType.name,
            fluidManufacturer.name,
            fluidProductTradeName.name,
            specificFluidType,
            samplingMethod.name,
            samplingFrequency.name,
            samplingLocation.name,
            ISOCleanliness1,
            inspectionType.name,
            inspectionFrequency.name,
            inspector.name
        ]));

        setEquipmentPercentage(getPercentage([
            assetType.name,
            module.name,
            component.name,
            manufacturer,
            modelNumber,
            reservoirCap.name,
            criticality.name,
            filtrationMethod.name,
            sampleBeforeFilter,
            filterType.name,
            sizeFiltered.name,
            breathers.name,
            bearingType.name,
            gearType.name
        ]));

        setEnvironmentPercentage(getPercentage([
            environment,
            finishedProduct,
            operatingSchedule.name,
            operatingTemp.name
        ]));
    }, [
        Program.name,
        SPIDName,
        SPIDDescription,
        fluidType.name,
        coolantType,
        fluidManufacturer,
        specificFluidType,
        fluidProductTradeName,
        samplingMethod,
        samplingFrequency,
        samplingLocation,
        ISOCleanliness1,
        ISOCleanliness2,
        ISOCleanliness3,
        inspectionType,
        inspectionFrequency,
        inspector,
        assetType,
        module,
        component,
        manufacturer,
        modelNumber,
        reservoirCap,
        criticality,
        filtrationMethod,
        sampleBeforeFilter,
        filterType,
        sizeFiltered,
        breathers,
        bearingType,
        gearType,
        environment,
        finishedProduct,
        operatingSchedule,
        operatingTemp
    ]);

    useEffect(() => {
        setPageTitle('SPID Detail');

        if (type.id) {
            if (type.id === 2) {
                setPageTitle(`SPID Detail: ${activeSection}`);
            } else {
                setPageTitle('SPID Detail: Reference');
            }

            getFilterTypes(token, res => {
                if (Array.isArray(res)) {
                    setFilterTypes(res.filter(item => item.Active).map(item => (
                        { label: item.Name, value: item.ID }
                    )));
                }
            });

            getSizeFiltered(token, res => {
                if (Array.isArray(res)) {
                    setSizeFilteredTypes(res.filter(item => item.Active).map(item => (
                        { label: item.Name, value: item.ID }
                    )));
                }
            });

            if (type.id === 2) {
                getPrograms(token, res => {
                    if (Array.isArray(res)) {
                        setPrograms(res.map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getFluidTypes(token, res => {
                    if (Array.isArray(res)) {
                        setFluidTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getCoolantTypes(token, res => {
                    if (Array.isArray(res)) {
                        setCoolantTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getSamplingMethods(token, res => {
                    if (Array.isArray(res)) {
                        setSamplingMethods(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getSamplingLocations(token, res => {
                    if (Array.isArray(res)) {
                        setSamplingLocations(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getVisualInspectionTypes(token, res => {
                    if (Array.isArray(res)) {
                        setInspectionTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getVisualInspectionTimings(token, res => {
                    if (Array.isArray(res)) {
                        setInspectionTimings(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getVisualInspectors(token, res => {
                    if (Array.isArray(res)) {
                        setInspectors(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getAssetTypes(token, res => {
                    if (Array.isArray(res)) {
                        setAssetTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });
                
                if (assetType.id) {
                    getModulesByAssetID(token, assetType.id, res =>{
                        if(Array.isArray(res)){
                            setModuleTypes(res.map(m =>({ label: m.Name, value: m.ID })))
                        }
                    });
                }

                if (module.id) {
                    getComponentByModuleID(token, module.id, assetType.id, res =>{
                        if(Array.isArray(res)){
                            setComponentTypes(res.map(m =>({ label: m.Name, value: m.ID })))
                        }
                    });
                }

                getReservoirCaps(token, res => {
                    if (Array.isArray(res)) {
                        setReservoirCaps(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getCriticalities(token, res => {
                    if (Array.isArray(res)) {
                        setCriticalities(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getFiltrationMethods(token, res => {
                    if (Array.isArray(res)) {
                        setFiltrationMethods(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getBreatherTypes(token, res => {
                    if (Array.isArray(res)) {
                        setBreatherTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getBearingTypes(token, res => {
                    if (Array.isArray(res)) {
                        setBearingTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getGearTypes(token, res => {
                    if (Array.isArray(res)) {
                        setGearTypes(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getOperatingSchedules(token, res => {
                    if (Array.isArray(res)) {
                        setOperatingSchedules(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });

                getOperatingTemps(token, res => {
                    if (Array.isArray(res)) {
                        setOperatingTemps(res.filter(item => item.Active).map(item => (
                            { label: item.Name, value: item.ID }
                        )));
                    }
                });
            } else {
                getStages(token, res => {
                    if (Array.isArray(res)) {
                        setStages(res.map(item => (
                            { label: item.Name, value: item.SPIDTypeID }
                        )));
                    }
                });
            }
        }
    }, [type]); // eslint-disable-line

    useEffect(() => {
        if (fluidManufacturer.id) {
            getLubricantBrandNames(token, fluidManufacturer.id, res => {
                if (Array.isArray(res)) {
                    setLubricantBrandNames(res.map(item => (
                        { label: item.Name, value: item.ID }
                    )));
                }
            });
        }
    }, [fluidManufacturer]); // eslint-disable-line

    useEffect(() =>{
        if(assetType.id && assetType.id >= 0){
            handleChangeAssetType();
        }
    }, [assetType]); // eslint-disable-line

    useEffect(() => {
        if ((module.id  && module.id >= 0) && (assetType.id && assetType.id >= 0)) {
          handleChangeModuleType();
        }
    }, [module]); // eslint-disable-line

    return (
        <div className="container-fluid overflow-hidden">
            <Helmet>
                <title>SPID Detail | Trico Orbital Portal</title>
            </Helmet>

            <Prompt
                when={preventExit}
                message="Are you sure you want to leave? Changes you made may not be saved."
            />

            {
                type.name === '' ? '' :
                    <div className="row mx-n4">
                        <div className="col-7 px-4 pb-5">
                            <div className="d-flex mb-3">
                                <Link
                                    className="d-flex align-items-center text-uppercase"
                                    to="/condition-summary"
                                >
                                    <svg width="16" height="16">
                                        <use xlinkHref="#icon-chevron-left" />
                                    </svg>
                                    Back
                                </Link>
                            </div>
                            <div className="d-flex mx-n2 mb-n3 pb-5">
                                {
                                    entityID && (account.orbitalRole !== 'READONLY')  ?
                                        <>
                                            <CopySPIDButton
                                                entityID={entityID}
                                                SPID={SPID}
                                                disabled={!entityID}
                                            />
                                            <Link
                                                className="btn btn-info border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                                style={{
                                                    fontSize: `${15 / 16}rem`,
                                                    lineHeight: 1.2,
                                                    backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                                    maxWidth: type.name === 'EDS' ? 210 : 315
                                                }}
                                                to={`/condition-summary/spid/new${type.name === 'EDS' ? '' : '?type=rds'}`}
                                            >
                                                <span
                                                    className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                        backgroundColor: 'rgba(255,255,255,0.15)'
                                                    }}
                                                >
                                                    <svg width="16" height="18">
                                                        <use xlinkHref="#icon-plus-box-multiple" />
                                                    </svg>
                                                </span>
                                                <span>
                                                    Add New {type.name === 'EDS' ? '' : 'Reference'} SPID
                                                </span>
                                            </Link>
                                        </> : ''
                                }
                                <Link className="btn btn-secondary border-0 d-flex align-items-center text-left flex-grow-1 flex-shrink-1 px-3 mx-2 mb-3"
                                    style={{
                                        fontSize: `${15 / 16}rem`,
                                        lineHeight: 1.2,
                                        backgroundImage: 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25)',
                                        maxWidth: 280
                                    }}
                                    to={`/condition-summary/spid/${entityID}/maintenance-activity`}>

                                    <span
                                        className="d-flex align-items-center justify-content-center rounded-circle flex-shrink-0 mr-3 ml-n1 my-2"
                                        style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: 'rgba(255,255,255,0.15)'
                                        }}
                                    >
                                        <svg className="flex-shrink-0" width="19" height="19">
                                            <use xlinkHref="#icon-tools" />
                                        </svg>
                                    </span>
                                    <span>
                                        Maintenance Activity
                                    </span>
                                </Link>
                            </div>
                            <div className="bg-gray-dark rounded overflow-hidden mb-5">
                                <div className="d-flex">
                                    {
                                        SPID ?
                                            <h2
                                                className="bg-gray d-flex justify-content-center align-items-center text-center font-weight-semibold text-uppercase flex-shrink-0 mb-0 py-4 px-3"
                                                style={{ minWidth: 157 }}
                                            >
                                                SPID<br /> {SPID}
                                            </h2> : ''
                                    }
                                    <div
                                        className="d-flex flex-column justify-content-center w-100 py-4 pl-4 px-3"
                                        style={{ fontSize: `${18 / 16}rem` }}
                                        ref={node1}
                                        onClick={e => {
                                            if (node1.current.contains(e.target)) {
                                                setPreventExit(true);
                                            }
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-start pb-3">
                                            <div className="d-flex">
                                                <strong
                                                    className="font-weight-semibold text-uppercase flex-shrink-0 text-right pr-3"
                                                    style={{ width: 73 }}
                                                >
                                                    Site:
                                                </strong>
                                                {site.name}
                                            </div>
                                            {(account.orbitalRole !== 'READONLY')  ?
                                            <button
                                                className="btn btn-link py-0 px-2 mr-n2 ml-2 flex-shrink-0 bg-transparent border-0"
                                                style={{ marginTop: 3 }}
                                                aria-label="Change site"
                                                onClick={() => setShowSitePanel(true)}
                                            >
                                                <svg width="21" height="15">
                                                    <use xlinkHref="#icon-edit" />
                                                </svg>
                                            </button>
                                              : ""}
                                            <EditSitePanel
                                                isVisible={showSitePanel}
                                                setIsVisible={setShowSitePanel}
                                                site={site}
                                                sites={sites}
                                                onChange={handleChangeSite}
                                            />

                                        </div>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div className="d-flex">
                                                <strong
                                                    className="font-weight-semibold text-uppercase flex-shrink-0 text-right pr-3"
                                                    style={{ width: 73 }}
                                                >
                                                    Area:
                                                </strong>
                                                {area.name}
                                            </div>
                                            {(account.orbitalRole !== 'READONLY')  ?
                                            <button
                                                className="btn btn-link py-0 px-2 mr-n2 ml-2 flex-shrink-0 bg-transparent border-0"
                                                style={{ marginTop: 3 }}
                                                aria-label="Change area"
                                                onClick={() => setShowAreaPanel(true)}
                                                disabled={areas.length === 0}
                                            >
                                                <svg width="21" height="15">
                                                    <use xlinkHref="#icon-edit" />
                                                </svg>
                                            </button>
                                            :""}
                                            <EditAreaPanel
                                                isVisible={showAreaPanel}
                                                setIsVisible={setShowAreaPanel}
                                                area={area}
                                                areas={areas}
                                                onChange={handleChangeArea}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                type.name === 'EDS' ?
                                    <>
                                        {
                                            activeSection === 'Lubricant' ?
                                                <>
                                                    <div ref={node2} onClick={e => {
                                                        if (node2.current.contains(e.target)) {
                                                            setPreventExit(true);
                                                        }
                                                    }}>
                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        SPID Information
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="Program"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2, pointerEvents: 'none' }}
                                                                            >
                                                                                1. Program
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="Program"
                                                                                value={Program.name}
                                                                                placeholder=""
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="SPIDName"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                2. Description / Name
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="SPIDName"
                                                                                value={SPIDName}
                                                                                placeholder=""
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                onChange={e => {
                                                                                    setSPIDName(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="SPIDDescription"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                            >
                                                                                3. ID / Number
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="SPIDDescription"
                                                                                value={SPIDDescription}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                placeholder=""
                                                                                onChange={e => {
                                                                                    setSPIDDescription(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Fluid Information
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                {/* <PanelToggle
                                                                    label="1. Fluid Type"
                                                                    value={fluidType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    onClick={() => handleShowLubricantPanel(4)}
                                                                /> */}
                                                                <PanelToggle
                                                                    label="1. Coolant Type"
                                                                    value={coolantType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    onClick={() => handleShowLubricantPanel(5)}
                                                                />
                                                                <PanelToggle
                                                                    label="2. Fluid Manufacturer"
                                                                    value={fluidManufacturer.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    onClick={() => handleShowLubricantPanel(6)}
                                                                />
                                                                <PanelToggle
                                                                    label="3. Fluid Product Trade Name"
                                                                    value={fluidProductTradeName.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(7)}
                                                                />
                                                                {fluidManufacturer.name === '' || fluidProductTradeName.name === '' ?
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="specificFluidType"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                4. Specific Fluid Type (If lubricant not listed enter here)
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="specificFluidType"
                                                                                value={specificFluidType}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                placeholder=""
                                                                                onChange={e => setSpecificFluidType(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Sampling Information
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <PanelToggle
                                                                    label="1. Sampling Method"
                                                                    value={samplingMethod.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(9)}
                                                                />
                                                                <PanelToggle
                                                                    label="2. Sampling Frequency"
                                                                    value={samplingFrequency.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(10)}
                                                                />
                                                                <PanelToggle
                                                                    label="3. Sampling Location"
                                                                    value={samplingLocation.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(11)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        ISO Targets
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <div
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                1. ISO Cleanliness
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <label htmlFor="ISOCleanliness1" className="sr-only">
                                                                                    ISO Cleanliness 1
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control font-weight-semibold text-center px-2"
                                                                                    style={{ color: '#A290F3', width: '3rem' }}
                                                                                    id="ISOCleanliness1"
                                                                                    value={ISOCleanliness1}
                                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                    maxLength="3"
                                                                                    onChange={e => setISOCleanliness1(e.target.value)}
                                                                                />
                                                                                <div className="mx-3">/</div>
                                                                                <label htmlFor="ISOCleanliness2" className="sr-only">
                                                                                    ISO Cleanliness 2
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control font-weight-semibold text-center px-2"
                                                                                    style={{ color: '#A290F3', width: '3rem' }}
                                                                                    id="ISOCleanliness2"
                                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                    value={ISOCleanliness2}
                                                                                    maxLength="3"
                                                                                    onChange={e => setISOCleanliness2(e.target.value)}
                                                                                />
                                                                                <div className="mx-3">/</div>
                                                                                <label htmlFor="ISOCleanliness3" className="sr-only">
                                                                                    ISO Cleanliness 3
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control font-weight-semibold text-center px-2"
                                                                                    style={{ color: '#A290F3', width: '3rem' }}
                                                                                    id="ISOCleanliness3"
                                                                                    value={ISOCleanliness3}
                                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                    maxLength="3"
                                                                                    onChange={e => setISOCleanliness3(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Visual Oil Inspection
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <PanelToggle
                                                                    label="1. Visual Oil Inspection Type"
                                                                    value={inspectionType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(13)}
                                                                />
                                                                <PanelToggle
                                                                    label="2. Visual Inspection Frequency"
                                                                    value={inspectionFrequency.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(14)}
                                                                />
                                                                <PanelToggle
                                                                    label="3. Visual Inspector"
                                                                    value={inspector.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowLubricantPanel(15)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Notes
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <div className="form-group mb-4">
                                                                    <label
                                                                        className="text-blue-light font-weight-semibold mb-3"
                                                                        htmlFor="notes"
                                                                    >
                                                                        Add notes for other viewers of this record (optional)
                                                                    </label>
                                                                    <textarea
                                                                        id="notes"
                                                                        className={`form-control py-3 ${notes === '' ? '' : 'font-weight-semibold'}`}
                                                                        style={{
                                                                            height: 195,
                                                                            resize: 'none',
                                                                            color: '#A290F3'
                                                                        }}
                                                                        placeholder="Notes…"
                                                                        disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                        value={notes}
                                                                        onChange={e => setNotes(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <LubricantPanel
                                                            isVisible={showLubricantPanel}
                                                            setIsVisible={setShowLubricantPanel}
                                                            activeSlide={lubricantPanelActiveSlide}
                                                            Program={Program}
                                                            setProgram={setProgram}
                                                            SPIDName={SPIDName}
                                                            setSPIDName={setSPIDName}
                                                            SPIDDescription={SPIDDescription}
                                                            setSPIDDescription={setSPIDDescription}
                                                            fluidType={fluidType}
                                                            setFluidType={setFluidType}
                                                            coolantType={coolantType}
                                                            setCoolantType={setCoolantType}
                                                            fluidManufacturer={fluidManufacturer}
                                                            setFluidManufacturer={setFluidManufacturer}
                                                            specificFluidType={specificFluidType}
                                                            setSpecificFluidType={setSpecificFluidType}
                                                            fluidProductTradeName={fluidProductTradeName}
                                                            setFluidProductTradeName={setFluidProductTradeName}
                                                            samplingMethod={samplingMethod}
                                                            setSamplingMethod={setSamplingMethod}
                                                            samplingFrequency={samplingFrequency}
                                                            setSamplingFrequency={setSamplingFrequency}
                                                            samplingLocation={samplingLocation}
                                                            setSamplingLocation={setSamplingLocation}
                                                            ISOCleanliness1={ISOCleanliness1}
                                                            setISOCleanliness1={setISOCleanliness1}
                                                            ISOCleanliness2={ISOCleanliness2}
                                                            setISOCleanliness2={setISOCleanliness2}
                                                            ISOCleanliness3={ISOCleanliness3}
                                                            setISOCleanliness3={setISOCleanliness3}
                                                            inspectionType={inspectionType}
                                                            setInspectionType={setInspectionType}
                                                            inspectionFrequency={inspectionFrequency}
                                                            setInspectionFrequency={setInspectionFrequency}
                                                            inspector={inspector}
                                                            setInspector={setInspector}
                                                            notes={notes}
                                                            setNotes={setNotes}
                                                            programs={programs}
                                                            fluidTypes={fluidTypes}
                                                            coolantTypes={coolantTypes}
                                                            lubricantBrandNames={lubricantBrandNames}
                                                            samplingMethods={samplingMethods}
                                                            samplingLocations={samplingLocations}
                                                            inspectionTypes={inspectionTypes}
                                                            inspectionTimings={inspectionTimings}
                                                            inspectors={inspectors}
                                                            entityID={entityID}
                                                        />
                                                    </div>

                                                    <div className="border-top pt-4 pb-5">
                                                        <p className="text-center mb-4">
                                                            Excellent! You&rsquo;ve reached the end of Section 1!
                                                        </p>
                                                        {
                                                            SPID ?
                                                                <div className="row align-items-center">
                                                                    <div className="col-4" />
                                                                    <div className="col-4 text-center">
                                                                    {(account.orbitalRole !== 'READONLY')  ?
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                            onClick={() => handleSave()}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                        :""}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Equipment')}
                                                                        >
                                                                            Next
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-right" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="text-center">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                        onClick={() => handleChangeStep('Equipment')}
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                </div>
                                                        }
                                                    </div>
                                                </> : ''
                                        }

                                        {
                                            activeSection === 'Equipment' ?
                                                <>
                                                    <div ref={node2} onClick={e => {
                                                        if (node2.current.contains(e.target)) {
                                                            setPreventExit(true);
                                                        }
                                                    }}>
                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Equipment
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                            <div  className="form-group mb-4">
                                                                <div className="row align-items-center mx-n2">
                                                                    <div className="col-5 px-2">
                                                                        <label
                                                                            htmlFor="manufacturer"
                                                                            className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                            style={{ lineHeight: 1.2 }}
                                                                        >
                                                                            1. Asset Type
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-7 px-2">
                                                                        <Select
                                                                                id="asset type"
                                                                                actionText="Select"
                                                                                options={ assetTypes }
                                                                                value={
                                                                                    assetType.id >= 0 ? { label: assetType.name, value: assetType.id } : null
                                                                                }
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                setValue={ obj => setAssetType({name: obj.label, id: obj.value}) }
                                                                                backgroundColor="#2E3F5C"
                                                                                optionColor="#fff"
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                <div  className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="manufacturer"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                2. Module
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <Select
                                                                                disabled={assetType.id < 0 || account.orbitalRole === 'READONLY' ? true : false}
                                                                                id="module"
                                                                                actionText={assetType.id < 0 ? 'Select an Asset Type' : 'Select'}
                                                                                options={moduleTypes}
                                                                                value={module.name !== '' ? { label: module.name, value: module.id } : null}
                                                                                setValue={obj => setModule({name: obj.label, id: obj.value})}
                                                                                backgroundColor="#2E3F5C"
                                                                                optionColor="#fff"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div  className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="manufacturer"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{lineHeight: 1.2}}
                                                                            >
                                                                                3. Component
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <Select
                                                                                disabled={!module.id < 0 || account.orbitalRole === 'READONLY' ? true : false}
                                                                                id="component"
                                                                                actionText={!module.id < 0 ? 'Select a module' : 'Select'}
                                                                                options={componentTypes}
                                                                                value={component.name !== '' ? { label: component.name, value: component.id } : null}
                                                                                setValue={obj => setComponent({ name: obj.label, id: obj.value })}
                                                                                backgroundColor="#2E3F5C"
                                                                                optionColor="#fff"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="manufacturer"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                4. Manufacturer
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="manufacturer"
                                                                                value={manufacturer}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                placeholder=""
                                                                                onChange={e => setManufacturer(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="modelNumber"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                5. Model Number
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="modelNumber"
                                                                                value={modelNumber}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                placeholder=""
                                                                                onChange={e => setModelNumber(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="serialNumber"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                6. Serial Number
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                style={{ color: '#A290F3' }}
                                                                                id="serialNumber"
                                                                                value={serialNumber}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                placeholder=""
                                                                                onChange={e => setSerialNumber(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Filtration
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <PanelToggle
                                                                    label="1. Reservoir capacity"
                                                                    value={reservoirCap.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(7)}
                                                                />
                                                                <PanelToggle
                                                                    label="2. Criticality"
                                                                    value={criticality.name}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(8)}
                                                                />
                                                                <PanelToggle
                                                                    label="3. Filtration method"
                                                                    value={filtrationMethod.name}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(9)}
                                                                />
                                                                {/* <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <div
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                4. Sample before filter?
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <RadioList
                                                                                id="sampleBeforeFilter"
                                                                                options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                                                                value={{ label: sampleBeforeFilter ? 'Yes' : 'No', value: sampleBeforeFilter }}
                                                                                setValue={obj => setSampleBeforeFilter(obj.value)}
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                cols="auto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <PanelToggle
                                                                    label="4. Filter type"
                                                                    value={filterType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(10)}
                                                                />
                                                                <PanelToggle
                                                                    label="5. Size filtered"
                                                                    value={sizeFiltered.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(11)}
                                                                />
                                                                <PanelToggle
                                                                    label="6. Breathers"
                                                                    value={breathers.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(12)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Bearings &amp; Gears
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <PanelToggle
                                                                    label="1. Bearing type"
                                                                    value={bearingType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(13)}
                                                                />
                                                                <PanelToggle
                                                                    label="2. Gear type"
                                                                    value={gearType.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEquipmentPanel(14)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <EquipmentPanel
                                                            isVisible={showEquipmentPanel}
                                                            setIsVisible={setShowEquipmentPanel}
                                                            activeSlide={equipmentPanelActiveSlide}
                                                            module={module}
                                                            setModule={setModule}
                                                            assetType={assetType}
                                                            setAssetType={setAssetType}
                                                            component={component}
                                                            setComponent={setComponent}
                                                            manufacturer={manufacturer}
                                                            setManufacturer={setManufacturer}
                                                            modelNumber={modelNumber}
                                                            setModelNumber={setModelNumber}
                                                            serialNumber={serialNumber}
                                                            setSerialNumber={setSerialNumber}
                                                            reservoirCap={reservoirCap}
                                                            setReservoirCap={setReservoirCap}
                                                            criticality={criticality}
                                                            setCriticality={setCriticality}
                                                            filtrationMethod={filtrationMethod}
                                                            setFiltrationMethod={setFiltrationMethod}
                                                            sampleBeforeFilter={sampleBeforeFilter}
                                                            setSampleBeforeFilter={setSampleBeforeFilter}
                                                            filterType={filterType}
                                                            setFilterType={setFilterType}
                                                            sizeFiltered={sizeFiltered}
                                                            setSizeFiltered={setSizeFiltered}
                                                            breathers={breathers}
                                                            setBreathers={setBreathers}
                                                            bearingType={bearingType}
                                                            setBearingType={setBearingType}
                                                            gearType={gearType}
                                                            setGearType={setGearType}
                                                            assetTypes={assetTypes}
                                                            moduleTypes={moduleTypes}
                                                            componentTypes={componentTypes}
                                                            reservoirCaps={reservoirCaps}
                                                            criticalities={criticalities}
                                                            filtrationMethods={filtrationMethods}
                                                            filterTypes={filterTypes}
                                                            sizeFilteredTypes={sizeFilteredTypes}
                                                            breatherTypes={breatherTypes}
                                                            bearingTypes={bearingTypes}
                                                            gearTypes={gearTypes}
                                                        />
                                                    </div>

                                                    <div className="border-top pt-4 pb-5">
                                                        <p className="text-center mb-4">
                                                            Excellent! You&rsquo;ve reached the end of Section 2!
                                                        </p>
                                                        {
                                                            SPID ?
                                                                <div className="row align-items-center">
                                                                    <div className="col-4 text-right">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Lubricant')}
                                                                        >
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-left" />
                                                                            </svg>
                                                                            Prev
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4 text-center">
                                                                    {(account.orbitalRole !== 'READONLY')  ?
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                            onClick={() => handleSave()}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                        :""}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Environment')}
                                                                        >
                                                                            Next
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-right" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row align-items-center">
                                                                    <div className="col-4 text-right">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Lubricant')}
                                                                        >
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-left" />
                                                                            </svg>
                                                                            Prev
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4 text-center">
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={() => handleChangeStep('Environment')}
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4" />
                                                                </div>
                                                        }
                                                    </div>
                                                </> : ''
                                        }

                                        {
                                            activeSection === 'Environment' ?
                                                <>
                                                    <div ref={node2} onClick={e => {
                                                        if (node2.current.contains(e.target)) {
                                                            setPreventExit(true);
                                                        }
                                                    }}>
                                                        <div className="bg-gray-dark rounded mb-5">
                                                            <div className="py-4 px-3 border-bottom">
                                                                <div className="d-flex justify-content-between align-items-start pl-3">
                                                                    <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                                        Environment
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                                <PanelToggle
                                                                    label="1. Environment"
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    value={
                                                                        environments.map(item => (
                                                                            environment[item] ? item : null
                                                                        )).filter(item => item !== null).join(', ')
                                                                    }
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEnvironmentPanel(1)}
                                                                />
                                                                <div className="form-group mb-4">
                                                                    <div className="row align-items-center mx-n2">
                                                                        <div className="col-5 px-2">
                                                                            <label
                                                                                htmlFor="finishedProduct"
                                                                                className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                                style={{ lineHeight: 1.2 }}
                                                                            >
                                                                                2. Finished Product
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-7 px-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control font-weight-semibold"
                                                                                disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                                style={{ color: '#A290F3' }}
                                                                                id="finishedProduct"
                                                                                value={finishedProduct}
                                                                                placeholder=""
                                                                                onChange={e => setFinishedProduct(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <PanelToggle
                                                                    label="3. Operating Schedule"
                                                                    value={operatingSchedule.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEnvironmentPanel(3)}
                                                                />
                                                                <PanelToggle
                                                                    label="4. Operating Temp"
                                                                    value={operatingTemp.name}
                                                                    placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                                    disabled={(account.orbitalRole !== 'READONLY') ? false : true}
                                                                    className="mb-4"
                                                                    onClick={() => handleShowEnvironmentPanel(4)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <EnvironmentPanel
                                                            isVisible={showEnvironmentPanel}
                                                            setIsVisible={setShowEnvironmentPanel}
                                                            activeSlide={environmentPanelActiveSlide}
                                                            environment={environment}
                                                            setEnvironment={setEnvironment}
                                                            finishedProduct={finishedProduct}
                                                            setFinishedProduct={setFinishedProduct}
                                                            operatingSchedule={operatingSchedule}
                                                            setOperatingSchedule={setOperatingSchedule}
                                                            operatingTemp={operatingTemp}
                                                            setOperatingTemp={setOperatingTemp}
                                                            environments={environments}
                                                            operatingSchedules={operatingSchedules}
                                                            operatingTemps={operatingTemps}
                                                        />
                                                    </div>

                                                    <div className="text-center border-top pt-4 pb-5">
                                                        <p className="mb-4">
                                                            Excellent! You&rsquo;ve reached the end of Section 3!
                                                        </p>
                                                        {
                                                            SPID ?
                                                                <div className="row align-items-center justify-content-center">
                                                                    <div className="col-4 text-right">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Equipment')}
                                                                        >
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-left" />
                                                                            </svg>
                                                                            Prev
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4 text-center">
                                                                    {(account.orbitalRole !== 'READONLY')  ?
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                            onClick={() => handleSave()}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                            onClick={() => history.push("/condition-summary")}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                    <div className="col-4" />
                                                                </div>
                                                                :
                                                                <div className="row align-items-center justify-content-center">
                                                                    <div className="col-4 text-right">
                                                                        <button
                                                                            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                                            style={{
                                                                                textDecoration: 'underline',
                                                                                fontSize: `${12 / 16}rem`
                                                                            }}
                                                                            onClick={() => handleChangeStep('Equipment')}
                                                                        >
                                                                            <svg width="20" height="20">
                                                                                <use xlinkHref="#icon-chevron-left" />
                                                                            </svg>
                                                                            Prev
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4 text-center">
                                                                    {(account.orbitalRole !== 'READONLY')  ?
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            onClick={() => handleSave()}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ width: 147 }}
                                                                            disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                                            onClick={() => history.push("/condition-summary")}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                    <div className="col-4" />
                                                                </div>
                                                        }
                                                        <div className="mt-4">
                                                            <Link
                                                                className="text-uppercase"
                                                                style={{ textDecoration: 'underline' }}
                                                                to="/condition-summary"
                                                            >
                                                                Return to Condition Summary
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </> : ''
                                        }
                                    </>
                                    :
                                    <>
                                        <div ref={node2} onClick={e => {
                                            if (node2.current.contains(e.target)) {
                                                setPreventExit(true);
                                            }
                                        }}>
                                            <div className="bg-gray-dark rounded mb-5">
                                                <div className="py-4 px-3 border-bottom">
                                                    <div className="d-flex justify-content-between align-items-start pl-3">
                                                        <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                            SPID Information
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="pt-4 pb-5 px-3 mx-3">
                                                    <div className="form-group mb-4">
                                                        <div className="row align-items-center mx-n2">
                                                            <div className="col-5 px-2">
                                                                <label
                                                                    htmlFor="SPIDName"
                                                                    className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                    style={{ lineHeight: 1.2 }}
                                                                >
                                                                    1. Description / Name
                                                                </label>
                                                            </div>
                                                            <div className="col-7 px-2">
                                                                <input
                                                                    type="text"
                                                                    className="form-control font-weight-semibold"
                                                                    style={{ color: '#A290F3' }}
                                                                    id="SPIDName"
                                                                    value={SPIDName}
                                                                    placeholder=""
                                                                    onChange={e => setSPIDName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <div className="row align-items-center mx-n2">
                                                            <div className="col-5 px-2">
                                                                <label
                                                                    htmlFor="SPIDDescription"
                                                                    className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                    style={{ lineHeight: 1.2 }}
                                                                >
                                                                    2. ID / Number
                                                                </label>
                                                            </div>
                                                            <div className="col-7 px-2">
                                                                <input
                                                                    type="text"
                                                                    className="form-control font-weight-semibold"
                                                                    style={{ color: '#A290F3' }}
                                                                    id="SPIDDescription"
                                                                    value={SPIDDescription}
                                                                    placeholder=""
                                                                    onChange={e => setSPIDDescription(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <div className="row align-items-center mx-n2">
                                                            <div className="col-5 px-2">
                                                                <div
                                                                    className="font-weight-semibold text-blue-light text-uppercase mb-0"
                                                                    style={{ lineHeight: 1.2 }}
                                                                >
                                                                    3. Sample Point Type
                                                                </div>
                                                            </div>
                                                            <div className="col-7 px-2">
                                                                <RadioList
                                                                    id="stages"
                                                                    options={stages.filter(item => item.value !== 2)}
                                                                    value={{ label: type.name, value: type.id }}
                                                                    setValue={obj => setType({ name: obj.label, id: obj.value })}
                                                                    cols="auto"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bg-gray-dark rounded mb-5">
                                                <div className="py-4 px-3 border-bottom">
                                                    <div className="d-flex justify-content-between align-items-start pl-3">
                                                        <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                            Fluid Information
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="pt-4 pb-5 px-3 mx-3">
                                                    <PanelToggle
                                                        label="1. Fluid Manufacturer"
                                                        value={fluidManufacturer.name}
                                                        placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                        className="mb-4"
                                                        onClick={() => handleShowReferencePanel(1)}
                                                    />
                                                    <PanelToggle
                                                        label="2. Fluid Product Trade Name"
                                                        value={fluidProductTradeName.name}
                                                        placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                        className="mb-4"
                                                        onClick={() => handleShowReferencePanel(2)}
                                                    />
                                                    {/* <div className="form-group mb-4">
                                                        <div className="row align-items-center mx-n2">
                                                            <div className="col-5 px-2">
                                                                <div
                                                                    className="font-weight-semibold text-blue-light text-uppercase"
                                                                    style={{ lineHeight: 1.2 }}
                                                                >
                                                                    3. Using Recycled Oil?
                                                                </div>
                                                            </div>
                                                            <div className="col-7 px-2">
                                                                <RadioList
                                                                    id="recylcledOil"
                                                                    options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                                                    value={{ label: usingRecycledOil, value: usingRecycledOil }}
                                                                    setValue={obj => setUsingRecycledOil(obj.value)}
                                                                    cols="auto"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <PanelToggle
                                                        label="3. Filter type"
                                                        value={filterType.name}
                                                        placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                        className="mb-4"
                                                        onClick={() => handleShowReferencePanel(3)}
                                                    />
                                                    <PanelToggle
                                                        label="4. Size filtered"
                                                        value={sizeFiltered.name}
                                                        placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                        className="mb-4"
                                                        onClick={() => handleShowReferencePanel(4)}
                                                    />
                                                    <PanelToggle
                                                        label="5. Beta Rating"
                                                        value={betaRating.name}
                                                        placeholder={(account.orbitalRole !== 'READONLY') ? "Select…" : "No selection"}
                                                        className="mb-4"
                                                        onClick={() => handleShowReferencePanel(5)}
                                                    />
                                                </div>
                                            </div>

                                            <ReferencePanel
                                                isVisible={showReferencePanel}
                                                setIsVisible={setShowReferencePanel}
                                                activeSlide={referencePanelActiveSlide}
                                                fluidManufacturer={fluidManufacturer}
                                                setFluidManufacturer={setFluidManufacturer}
                                                fluidProductTradeName={fluidProductTradeName}
                                                setFluidProductTradeName={setFluidProductTradeName}
                                                usingRecycledOil={usingRecycledOil}
                                                setUsingRecycledOil={setUsingRecycledOil}
                                                filterType={filterType}
                                                setFilterType={setFilterType}
                                                sizeFiltered={sizeFiltered}
                                                setSizeFiltered={setSizeFiltered}
                                                betaRating={betaRating}
                                                setBetaRating={setBetaRating}
                                                lubricantBrandNames={lubricantBrandNames}
                                                filterTypes={filterTypes}
                                                sizeFilteredTypes={sizeFilteredTypes}
                                            />
                                        </div>

                                        <div className="text-center pb-5">
                                            <p className="mb-4">
                                                <button
                                                    className="btn btn-primary"
                                                    style={{ width: 147 }}
                                                    onClick={() => handleSave()}
                                                    disabled={area.id === null || SPIDName === '' || SPIDDescription === ''}
                                                >
                                                    Save
                                                </button>
                                            </p>
                                            <Link
                                                className="text-uppercase"
                                                style={{ textDecoration: 'underline' }}
                                                to="/condition-summary"
                                            >
                                                Return to Condition Summary
                                            </Link>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="col-5 px-4">
                            {
                                type.name === 'EDS' ?
                                    <div className="bg-gray-dark rounded mb-5">
                                        <div className="p-3 mx-3 border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                    SPID Profile
                                                </h2>
                                                <Tooltip className="position-relative flex-shrink-0 ml-2">
                                                    <TooltipTrigger
                                                        className="bg-transparent border-0 text-primary py-0 px-1"
                                                        aria-label="What is this?"
                                                    >
                                                        <svg width="16" height="16">
                                                            <use xlinkHref="#icon-help-circle-outline" />
                                                        </svg>
                                                    </TooltipTrigger>
                                                    <TooltipContent position="bottom" style={{ width: 300 }}>
                                                        Indication of percent complete for SPID profile information based on lubricant, equipment, and environment. Complete each of these sections as completely as possible for Orbital to be able to dimensionally analyze your SPID results with previous results, reference sample data, lubricatn data, equipment data, and environmental data to provid you the most accurate outputs.
                                                    </TooltipContent>
                                                </Tooltip>
                                            </div>
                                            {
                                                area.id === null || SPIDName === '' || SPIDDescription === '' ? '' :
                                                    <p
                                                        className="mb-n1"
                                                        style={{ fontSize: '0.875rem', fontStyle: 'italic' }}
                                                    >
                                                        Click on a progress chart below to switch sections.
                                                    </p>
                                            }
                                        </div>
                                        <div className="py-4 px-3 mx-3">
                                            <div className="row mx-n2">
                                                <div className="col-4 px-2">
                                                    <div
                                                        className={classNames('btn d-block border-0 font-weight-normal p-3 pb-4', {
                                                            'bg-blue-light text-dark': activeSection === 'Lubricant',
                                                            'btn-dark': activeSection !== 'Lubricant'
                                                        })}
                                                        style={{ pointerEvents: activeSection === 'Lubricant' ? 'none' : '' }}
                                                        role="button"
                                                        tabIndex="0"
                                                        onClick={() => handleChangeStep('Lubricant')}
                                                    >
                                                        <h3
                                                            className="h6 text-uppercase text-truncate mb-3 mx-n2"
                                                            style={{ fontSize: 'clamp(0.75rem,1vw,1rem)' }}
                                                        >
                                                            Lubricant
                                                        </h3>
                                                        <div className="mx-auto mb-3" style={{ maxWidth: 94 }}>
                                                            <CircularProgressbar
                                                                value={lubricantPercentage}
                                                                text={`${lubricantPercentage}%`}
                                                                styles={
                                                                    buildStyles({
                                                                        textSize: '1.5rem',
                                                                        textColor: '#006CCE',
                                                                        trailColor: '#2E3F5C',
                                                                        pathColor: '#006CCE'
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 px-2">
                                                    <div
                                                        className={classNames('btn d-block border-0 font-weight-normal p-3 pb-4', {
                                                            'bg-blue-light text-dark': activeSection === 'Equipment',
                                                            'btn-dark': activeSection !== 'Equipment'
                                                        })}
                                                        style={{
                                                            pointerEvents: activeSection === 'Equipment' || (area.id === null || SPIDName === '' || SPIDDescription === '') ? 'none' : '',
                                                            opacity: area.id === null || SPIDName === '' || SPIDDescription === '' ? 0.5 : ''
                                                        }}
                                                        role="button"
                                                        tabIndex="0"
                                                        onClick={() => handleChangeStep('Equipment')}
                                                    >
                                                        <h3
                                                            className="h6 text-uppercase text-truncate mb-3 mx-n2"
                                                            style={{ fontSize: 'clamp(0.75rem,1vw,1rem)' }}
                                                        >
                                                            Equipment
                                                        </h3>
                                                        <div className="mx-auto mb-3" style={{ maxWidth: 94 }}>
                                                            <CircularProgressbar
                                                                value={equipmentPercentage}
                                                                text={`${equipmentPercentage}%`}
                                                                styles={
                                                                    buildStyles({
                                                                        textSize: '1.5rem',
                                                                        textColor: '#FA888D',
                                                                        trailColor: '#2E3F5C',
                                                                        pathColor: '#FA888D'
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 px-2">
                                                    <div
                                                        className={classNames('btn d-block border-0 font-weight-normal p-3 pb-4', {
                                                            'bg-blue-light text-dark': activeSection === 'Environment',
                                                            'btn-dark': activeSection !== 'Environment'
                                                        })}
                                                        style={{
                                                            pointerEvents: activeSection === 'Environment' || (area.id === null || SPIDName === '' || SPIDDescription === '') ? 'none' : '',
                                                            opacity: area.id === null || SPIDName === '' || SPIDDescription === '' ? 0.5 : ''
                                                        }}
                                                        role="button"
                                                        tabIndex="0"
                                                        onClick={() => handleChangeStep('Environment')}
                                                    >
                                                        <h3
                                                            className="h6 text-uppercase text-truncate mb-3 mx-n2"
                                                            style={{ fontSize: 'clamp(0.75rem,1vw,1rem)' }}
                                                        >
                                                            Environment
                                                        </h3>
                                                        <div className="mx-auto mb-3" style={{ maxWidth: 94 }}>
                                                            <CircularProgressbar
                                                                value={environmentPercentage}
                                                                text={`${environmentPercentage}%`}
                                                                styles={
                                                                    buildStyles({
                                                                        textSize: '1.5rem',
                                                                        textColor: activeSection === 'Environment' ? '#439F57' : '#9BE2CC',
                                                                        trailColor: '#2E3F5C',
                                                                        pathColor: '#9BE2CC'
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="bg-gray-dark rounded p-4 mb-5">
                                            <div className="px-2">
                                                <h2 className="h4 font-weight-semibold text-uppercase mb-4">
                                                    What is a reference SPID?
                                                </h2>
                                                A reference sample is a sample of an oil before it is put into service. This provides a snapshot of the makeup of the oil to compare against when analyzing the in-service or used oil. This comparison is important to understand what has changed with the used oil, and, more importantly, what is going on with the equipment.
                                                Reference samples come from three distinct stages of The Journey of the Lubricant® – arrival, storage, and transfer.                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
            }

            <Modal
                isOpen={showCopyModal}
                onRequestClose={() => setShowCopyModal(false)}
                contentClassName="rounded bg-transparent"
                contentStyle={{ width: 450, boxShadow: '0 0 0.5rem var(--dark)' }}
            >
                <Alert
                    type="success"
                    icon={
                        <svg className="text-success" width="57" height="67" aria-hidden="true">
                            <use xlinkHref="#icon-copy-success" />
                        </svg>
                    }
                    title="Success!"
                    description={
                        <p className="mb-0">
                            You&rsquo;ve successfully copied SPID {copiedSPID}.
                        </p>
                    }
                    ctaOnClick={() => setShowCopyModal(false)}
                    ctaText="Continue"
                />
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    aria-label="close"
                    onClick={() => setShowCopyModal(false)}
                >
                    <svg width="12" height="12" aria-hidden="true">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Modal>

            <Modal
                isOpen={showSaveModal}
                onRequestClose={() => setShowSaveModal(false)}
                contentClassName="rounded bg-transparent"
                contentStyle={{ width: 450, boxShadow: '0 0 0.5rem var(--dark)' }}
            >
                <Alert
                    type="success"
                    icon={
                        <svg className="text-success" width="57" height="45" aria-hidden="true">
                            <use xlinkHref="#icon-check" />
                        </svg>
                    }
                    title="Success!"
                    description=""
                    ctaOnClick={() => setShowSaveModal(false)}
                    ctaText="Continue"
                />
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    aria-label="close"
                    onClick={() => setShowSaveModal(false)}
                >
                    <svg width="12" height="12" aria-hidden="true">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Modal>

            <Modal
                isOpen={showSaveNewModal}
                onRequestClose={() => {
                    setShowSaveNewModal(false);
                    history.push(`/condition-summary/spid/${entityID}`);
                }}
                contentClassName="rounded bg-transparent"
                contentStyle={{ width: 450, boxShadow: '0 0 0.5rem var(--dark)' }}
            >
                <Alert
                    type="success"
                    icon={
                        <svg className="text-success" width="57" height="45">
                            <use xlinkHref="#icon-check" />
                        </svg>
                    }
                    title="Success!"
                    description={
                        <p className="mb-0">
                            The sample point has been successfully added.
                        </p>
                    }
                    ctaOnClick={() => {
                        setShowSaveNewModal(false);
                        history.push(`/condition-summary/spid/${entityID}`);
                    }}
                    ctaText="Continue"
                />
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    aria-label="close"
                    onClick={() => {
                        setShowSaveNewModal(false);
                        history.push(`/condition-summary/spid/${entityID}`);
                    }}
                >
                    <svg width="12" height="12" aria-hidden="true">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Modal>

            <Modal
                isOpen={showSaveFailedModal}
                onRequestClose={() => setShowSaveFailedModal(false)}
                contentClassName="rounded bg-transparent"
                contentStyle={{ width: 450, boxShadow: '0 0 0.5rem var(--dark)' }}
            >
                <Alert
                    type="danger"
                    icon={
                        <svg className="text-success" width="57" height="45" aria-hidden="true">
                            <use xlinkHref="#icon-exclamation-triangle" />
                        </svg>
                    }
                    title="Error!"
                    description="We are unable to process your request. Please resubmit and try again."
                    ctaOnClick={() => setShowSaveFailedModal(false)}
                    ctaText="Continue"
                />
                <button
                    className="position-absolute border-0 p-0 bg-transparent text-white"
                    style={{ top: 0, right: 0, width: '3rem', height: '3rem' }}
                    aria-label="close"
                    onClick={() => setShowSaveFailedModal(false)}
                >
                    <svg width="12" height="12" aria-hidden="true">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>
            </Modal>
        </div>
    );
};

export default SPIDDetail;