const apiUrl = process.env.REACT_APP_API_URL;

const getFilterTypes = (token, cb) => {
    fetch(`${apiUrl}/filtertypes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(res => {
            cb(res);
        });
};

export default getFilterTypes;
