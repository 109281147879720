import React, { useContext, useEffect, useState, useRef } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Context from '../context/Context';

const StyledNav = styled.nav`
    position: absolute;
    top: 50px;
    right: -13px;
    background-color: #1B273B;
    font-size: ${15/16}rem;
    box-shadow: 0 0.25rem 0.75rem rgba(17,25,38,0.6);
    opacity: ${props => props.isExpanded ? '' : 0};
    visibility: ${props => props.isExpanded ? '' : 'hidden'};
    transform: ${props => props.isExpanded ? '' : 'translateY(20px)'};
    transition: opacity 300ms, visibility 300ms, transform 300ms;

    &::before {
        content: '';
        position: absolute;
        right: 14px;
        top: -20px;
        border: 10px solid transparent;
        border-bottom-color: #1B273B;
    }

    a {
        min-width: 200px;

        &:hover {
            text-decoration: none;
            background-color: var(--secondary);
            background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.15));
        }
    }
`;

const AccountNav = () => {
    const { account } = useContext(Context);
    const node = useRef();
    const [isExpanded, setIsExpanded] = useState(false);
    let greeting = 'Good';
    const today = new Date()
    const currentHour = today.getHours();
    const authUrl = process.env.REACT_APP_AUTH_URL;

    if (currentHour < 12) {
        greeting += ' morning';
    } else if (currentHour < 18) {
        greeting += ' afternoon';
    } else {
        greeting += ' evening';
    }

    if (account && account.firstName) {
        greeting += `, ${account.firstName}`;
    }

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOutsideClick = e => {
        if (!node.current.contains(e.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    });

    return (
        <div className="position-relative" ref={ node }>
            <button
                className="text-white d-flex align-items-center font-weight-normal bg-transparent border-0 p-0 my-n1"
                style={{ outline: 'none' }}
                onClick={ handleToggle }
            >
                <span className="text-truncate">
                    { greeting }
                </span>
                <svg
                    className="text-primary ml-3"
                    width="22" height="22"
                    aria-hidden="true"
                >
                    <use xlinkHref="#icon-chevron-down" />
                </svg>
            </button>
            <StyledNav isExpanded={ isExpanded }>
                <a
                    className="d-flex align-items-center text-white py-3 px-4"
                    href={`${authUrl.replace('/auth','')}/resetpassword?returnUrl=${global.location.origin}`}
                >
                    <svg className="mr-4" width="20" height="20" aria-hidden="true">
                        <use xlinkHref="#icon-user-outline" />
                    </svg>
                    Reset Password
                </a>
                <a
                    className="d-flex align-items-center text-white py-3 px-4"
                    href={`${authUrl.replace('/auth','')}/logout?returnUrl=${global.location.origin}/logout`}
                >
                    <svg className="mr-4" width="22" height="20" aria-hidden="true">
                        <use xlinkHref="#icon-logout" />
                    </svg>
                    Log Out
                </a>
                {/* <Link
                    className="d-flex align-items-center text-white py-3 px-4"
                    to="/logout"
                >
                    <svg className="mr-4" width="22" height="20">
                        <use xlinkHref="#icon-logout" />
                    </svg>
                    Log Out
                </Link> */}
            </StyledNav>
        </div>
    );
}

export default AccountNav;
