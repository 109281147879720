import React from 'react';
import classNames from 'classnames';

const PrevNextButton = props => (
    <button
        className={ `btn bg-dark border-0 p-0 ${props.className || ''}` }
        style={ props.style }
        onClick={ props.onClick }
        disabled={ props.disabled }
    >
        <span
            className={ classNames('d-flex align-items-center justify-content-center', {
                'bg-dark': props.disabled
            }) }
            style={{ width: 18, height: 18 }}
        >
            <svg className="text-primary" width="12" height="12">
                <use xlinkHref={ `#icon-chevron-${props.direction === 'next' ? 'right' : 'left'}` } />
            </svg>
        </span>
    </button>
);

export default PrevNextButton;
