import React, { useRef } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Styled = styled.div`
    .react-tabs {
        &__tab-list {
            display: flex;
            border-bottom: none;
            margin: 0 -0.5rem 0.5rem;
        }

        &__tab {
            bottom: 0;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            border-radius: 3rem;
            padding: 0.5rem 1rem;
            margin: 0 0.5rem 0.5rem;
            min-height: 40px;
            font-weight: 600;
            border: none;
            text-align: center;
            line-height: 1.2;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus::after {
                content: none;
            }

            &:not([aria-disabled="true"]):hover,
            &--selected {
                background-color: var(--orange);
                background-image: linear-gradient(to right, transparent, rgba(255,255,255,0.15));
                color: #fff;
            }

            &:nth-child(2) {
                &:not([aria-disabled="true"]):hover,
                &.react-tabs__tab--selected {
                    background-color: var(--danger);
                }
            }
        }
    }
`;

const AnalystSummary = props => {
    const { actionsNeeded, areasOfConcern, consequencesOfInaction, dataOfConcern } = props;
    const node = useRef();

    return (
        <>
            <div ref={ node } />
            <aside
                className="bg-gray-dark rounded pt-4 px-4 mb-5 overflow-auto scrollbar-sm"
                style={{
                    fontSize: '0.875rem',
                    maxHeight: 'calc(100vh - 180px)'
                }}
            >
                {
                    areasOfConcern.length > 0 ?
                        <div className="pb-4" style={{ maxWidth: 410 }}>
                            <h3 className="h4 text-orange text-uppercase font-weight-semibold mb-3">
                                Areas of Concern
                            </h3>
                            <div className="row mx-n2">
                                {
                                    areasOfConcern.map((item, index) => (
                                        <div
                                            key={ index }
                                            className="col-3 px-2 pb-3"
                                            style={{ fontSize: '0.75rem' }}
                                        >
                                            <div className="text-center" style={{
                                                fontSize: 'clamp(0.625rem,0.8vw,0.75rem)',
                                                maxWidth: 77
                                            }}>
                                                <figure
                                                    className="embed-responsive embed-responsive-1by1 rounded bg-white overflow-hidden mb-1"
                                                >
                                                    <div className="d-flex align-items-center justify-content-center embed-responsive-item p-1">
                                                        <img
                                                            className="img-fluid"
                                                            src={ item.Url }
                                                            alt=""
                                                        />
                                                    </div>
                                                </figure>
                                                { item.Name }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> : ''
                }

                {
                    dataOfConcern.length > 0 ?
                        <div className="pb-4">
                            <h3 className="h4 text-orange text-uppercase font-weight-semibold mb-3">
                                Data of Concern
                            </h3>
                            <ul className="pl-4 mb-0">
                                {
                                    dataOfConcern.map((item, index) => (
                                        <li key={ index } className="mb-1">
                                            { item }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div> : ''
                }

                {
                    actionsNeeded.length > 0 ?
                        <>
                            {
                                consequencesOfInaction.length > 0 ?
                                    <Styled className="pb-4">
                                        <p><i>Select option for next steps</i></p>
                                        <Tabs>
                                            <TabList>
                                                <Tab>Actions Needed</Tab>
                                                <Tab>Consequences</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <ul className="pl-4 mb-0">
                                                    {
                                                        actionsNeeded.map((item, index) => (
                                                            <li key={ index } className="mb-1">
                                                                { item }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </TabPanel>
                                            <TabPanel>
                                                <ul className="pl-4 mb-0">
                                                    {
                                                        consequencesOfInaction.map((item, index) => (
                                                            <li key={ index } className="mb-1">
                                                                { item }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </TabPanel>
                                        </Tabs>
                                    </Styled>
                                    :
                                    <div className="pb-4">
                                        <h3 className="h4 text-orange text-uppercase font-weight-semibold mb-3">
                                            Actions Needed
                                        </h3>
                                        <ul className="pl-4 mb-0">
                                            {
                                                actionsNeeded.map((item, index) => (
                                                    <li key={ index } className="mb-1">
                                                        { item }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                            }
                        </> : ''
                }
            </aside>
        </>
    );
};

export default AnalystSummary;
