const apiUrl = process.env.REACT_APP_API_URL;

const postMarkAlertAsRead = (token, id, cb = () => null) => {
    fetch(`${apiUrl}/alerts/${id}/markasread`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res.Success) {
                cb();
            }
        });
};

export default postMarkAlertAsRead;
