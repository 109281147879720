import React, { useState, useContext, useEffect } from "react";
import parse from 'html-react-parser';
import { SlideDown } from "react-slidedown";
import Context from "../../context/Context";
import getHelp from "../../api/getHelp";

const ExpandCollapse = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="d-flex" key={`${props.heading}${props.index}`}>
    <div
      className="text-primary flex-shrink-0"
      style={{
        fontSize: "2rem",
        width: "2.5rem",
        height: "3rem",
      }}
      role="button"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded ? "–" : "+"}
    </div>
    <div>
      <h4 className="h6">
        <button
          className="bg-transparent border-0 text-white font-weight-semibold text-left pt-0 px-0 py-3"
          aria-expanded={isExpanded}
          aria-controls={`tg1${props.index}`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
            {props.heading}
        </button>
      </h4>
      <SlideDown
        id={`tg1${props.index}`}
        style={{
          fontSize: "0.875rem",
          transitionDuration: "250ms",
        }}
        closed={!isExpanded}
      >
        <div
          className="border-secondary pl-3"
          style={{ borderLeft: "2px solid" }}
        >
             { parse(props.content.replace(/(?:\r\n|\r|\n)/g, '<br />')) }
        </div>
      </SlideDown>
    </div>
  </div>
  )
}

const Help = () => {
  const { setPageTitle } = useContext(Context);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getHelp(res => {
      setCategories(res.Categories);
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    setPageTitle("Help");
  }, [setPageTitle]);

  return (
    <div 
    style={{
      display: 'block',
      width: 300,
    }}>
      {categories.map((category, index) => {
        return (
          <div className="d-flex mb-4 flex-column mt-4" style={{ minHeight: "2.5rem"}} key={`${category.Name}${index}`}>
            <h1>{category.Name}</h1>
            {category.Topics.map((topic, index) => {
              return (
                <ExpandCollapse 
                  key={index}
                  index={index}
                  heading={topic.Heading}
                  content={topic.Content}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Help;
