import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Moment from 'react-moment';
import GaugeChart from 'react-gauge-chart';
import classNames from 'classnames';
import getConditionReport from '../../api/getConditionReport';
import Context from '../../context/Context';
import ReportTable from './components/ReportTable';
import AnalystSummary from './components/AnalystSummary';
import WearParticleAnalysis from './components/WearParticleAnalysis';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../components/Tooltip';

const ExpandCollapseTable = props => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <section className={props.className}>
            <button
                className="btn position-relative d-flex align-items-center justify-content-between w-100 border-0 text-white text-left py-0 pl-5 pr-4"
                style={{ zIndex: 1020, height: 60 }}
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
            >
                <h3 className="h5 font-weight-semibold text-uppercase mb-0">
                    {props.title}
                </h3>
                <svg className="text-primary" width="20" height="20" aria-hidden="true">
                    <use xlinkHref={isExpanded ? '#icon-chevron-up' : '#icon-chevron-down'} />
                </svg>
            </button>

            {
                props.tooltipContent ?
                    <div
                        className="position-absolute d-flex align-items-center"
                        style={{
                            top: 0,
                            right: 60,
                            height: 60,
                            zIndex: 9999
                        }}
                    >
                        <Tooltip className="position-relative">
                            <TooltipTrigger
                                className="btn btn-link bg-transparent text-decoration-none text-decoration-none border-0 d-flex align-items-center py-1 px-2"
                                style={{
                                    fontSize: '.875rem',
                                    textTransform: 'none'
                                }}
                            >
                                Learn More
                                <svg className="ml-2" width="20" height="20" aria-hidden="true">
                                    <use xlinkHref="#icon-info-circle" />
                                </svg>
                            </TooltipTrigger>
                            <TooltipContent position="top right" style={{ width: 400 }}>
                                {props.tooltipContent}
                            </TooltipContent>
                        </Tooltip>
                    </div> : ''
            }

            <div
                className="table-wrapper"
                style={{ display: isExpanded ? '' : 'none' }}
            >
                {props.children}
            </div>
        </section>
    );
};

const ConditionReport = props => {
    const { token, setPageTitle, showLoadingOverlay } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [viscosity, setViscosity] = useState([]);
    const [physChem, setPhysChem] = useState([]);
    const [particulates, setParticulates] = useState([]);
    const [traceElements, setTraceElements] = useState([]);
    const [wearParticleAnalysis, setWearParticleAnalysis] = useState([]);
    const [showWPA, setShowWPA] = useState(false);
    const [WPAMetals, setWPAMetals] = useState([]);
    const [images, setImages] = useState([]);
    const [actionsNeeded, setActionsNeeded] = useState([]);
    const [areasOfConcern, setAreasOfConcern] = useState([]);
    const [consequencesOfInaction, setConsequencesOfInaction] = useState([]);
    const [dataOfConcern, setDataOfConcern] = useState([]);
    const [SPIDType, setSPIDType] = useState([]);
    const { match, history } = props;

    useEffect(() => {
        showLoadingOverlay(true);

        setPageTitle('Condition Report');

        getConditionReport(token, match.params.id, res => {
            if (!res.Header) {                
                history.push('/condition-summary');
                return;
            }

            setLoading(false);
            showLoadingOverlay(false);
            setHeader(res.Header);
            setRatings(res.Ratings.reverse());
            setViscosity(res.Viscosity);
            setPhysChem(res.PhysChem);
            setParticulates(res.Particulates);
            setTraceElements(res.TraceElements);
            setWearParticleAnalysis(res.WearParticleAnalysis);
            setShowWPA(res.ShowWPAMetals === 'Y');
            setWPAMetals(res.WPAMetals);
            setImages(res.WPAImages);
            setAreasOfConcern(res.AreasOfConcern);
            setActionsNeeded(res.ActionsNeeded);
            setConsequencesOfInaction(res.ConsequencesOfInaction);
            setDataOfConcern(res.DataOfConcern);
            setSPIDType(res.SPIDType);
        });
    }, [history, token, match.params.id, setPageTitle, setHeader, setRatings, setViscosity, showLoadingOverlay]);

    return (
        <div className="container-fluid overflow-hidden">
            {
                loading ? '' :
                    <div className="row mx-n4">
                        <Helmet>
                            <title>Condition Report | Trico Orbital Portal</title>
                        </Helmet>

                        <div className="col-12 px-4">
                            <section className="bg-gray-dark rounded overflow-hidden mb-5">
                                <h2 className="bg-gray font-weight-semibold text-uppercase py-3 px-5 mb-0">
                                    SPID {header.SPID} &ndash; {SPIDType === 'EDS' ? 'Application & Lifecycle' : 'Arrival, Storage, Transfer'}
                                </h2>

                                <div className="font-weight-semibold py-4 px-5">
                                    <div className="row">
                                        <div className="col">
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-1">
                                                    {header.AreaEntityName}
                                                </li>
                                                <li className="mb-1">
                                                    {header.CityState}
                                                </li>
                                                <li className="text-uppercase mb-1">
                                                    {header.AreaEntityDescription}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col">
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-1">
                                                    <span className="text-uppercase">Report Date: </span>
                                                    {
                                                        header.CompletedDT ?
                                                            <Moment format="M/DD/YYYY">
                                                                {header.CompletedDT}
                                                            </Moment> : ''
                                                    }
                                                </li>
                                                <li className="mb-1">
                                                    <span className="text-uppercase">Analyst: </span>
                                                    {header.CompletedByName}
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            header.ReportURL ?
                                                <div className="col-auto ml-auto">
                                                    <a
                                                        className="btn btn-sm btn-outline-primary d-inline-flex align-items-center text-left"
                                                        href={ `${header.ReportURL}&oauth=${token}` }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        onClick={() => {
                                                            global.dataLayer.push({
                                                                event: 'download_spid_report',
                                                                spid: header.SPID
                                                            });
                                                        }}
                                                    >
                                                        <span
                                                            className="d-flex align-items-center justify-content-center rounded-circle mr-2 ml-n2 flex-shrink-0"
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                backgroundColor: 'rgba(17,138,347,0.15)'
                                                            }}
                                                        >
                                                            <svg width="10" height="12" aria-hidden="true">
                                                                <use xlinkHref="#icon-file-download-outline" />
                                                            </svg>
                                                        </span>
                                                        Download Report
                                                    </a>
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </section>

                            <section className="bg-gray-dark rounded overflow-hidden mb-5">
                                <AnalystSummary
                                    actionsNeeded={actionsNeeded}
                                    areasOfConcern={areasOfConcern}
                                    consequencesOfInaction={consequencesOfInaction}
                                    dataOfConcern={dataOfConcern}
                                />
                            </section>

                            <section className="bg-gray-dark rounded overflow-hidden mb-5">
                                <div className="row no-gutters">
                                    <div className="col">
                                        <div className="bg-secondary py-3 px-5">
                                            <h3 className="h5 font-weight-semibold text-uppercase mb-0">
                                                Sample Information
                                            </h3>
                                        </div>
                                        <div className="position-relative py-4 px-5">
                                            <div className="d-flex mb-2">
                                                <div style={{ lineHeight: 1 }}>
                                                    <span className="text-uppercase" style={{ fontSize: `${10 / 16}rem` }}>
                                                        Taken:
                                                    </span>
                                                    <div className="d-flex align-items-center">
                                                        <div className="mr-2" style={{ fontSize: 40, color: '#a290f3' }}>
                                                            <Moment format="DD">{header.SampleDate}</Moment>
                                                        </div>
                                                        <div className="text-uppercase">
                                                            <Moment format="MMM">{header.SampleDate}</Moment><br />
                                                            <Moment format="YYYY">{header.SampleDate}</Moment>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-4 px-4 mx-2">
                                                    <svg width="18" height="18" aria-hidden="true">
                                                        <use xlinkHref="#icon-chevron-right" />
                                                    </svg>
                                                </div>
                                                <div style={{ lineHeight: 1 }}>
                                                    <span className="text-uppercase" style={{ fontSize: `${10 / 16}rem` }}>
                                                        Received:
                                                    </span>
                                                    <div className="d-flex align-items-center">
                                                        <div className="mr-2" style={{ fontSize: 40, color: '#a290f3' }}>
                                                            <Moment format="DD">{header.SampleReceivedDT}</Moment>
                                                        </div>
                                                        <div className="text-uppercase">
                                                            <Moment format="MMM">{header.SampleReceivedDT}</Moment><br />
                                                            <Moment format="YYYY">{header.SampleReceivedDT}</Moment>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <table style={{ lineHeight: 1.2 }}>
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Test Suite:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.TestSuite}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            CTNL#:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.SampleNumber}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Asset:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.Asset}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3"
                                                            scope="row"
                                                        >
                                                            SPID#:
                                                        </th>
                                                        <td className="align-top">
                                                            <span className="mr-2">
                                                                {header.SPID}
                                                            </span> {header.ExpirationDate === '' ? '' : <i className="d-inline-block text-danger">
                                                                Expires <Moment format="MM/DD/YYYY">{header.ExpirationDate}</Moment>
                                                            </i>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div
                                                className="position-absolute border-right my-4 mr-4"
                                                style={{ top: 0, right: 0, bottom: 0 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h3 className="h5 font-weight-semibold text-uppercase bg-secondary py-3 px-3 mb-0">
                                            Asset Information
                                        </h3>
                                        <div className="py-4 px-3">
                                            <table style={{ lineHeight: 1.2 }}>
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Name:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.SPIDDescription}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Description:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.SPIDLocationID}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Lubricant:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.Lubricant}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Reserv Cap:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.ReserveCap}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3 pb-2"
                                                            scope="row"
                                                        >
                                                            Lube Time:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.LubeHours}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className="text-uppercase font-weight-normal align-top pr-3"
                                                            scope="row"
                                                        >
                                                            Mach Hrs:
                                                        </th>
                                                        <td className="align-top">
                                                            {header.MachineHours}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="bg-gray-dark rounded mb-5">
                                <div className="d-flex justify-content-between py-3 pl-5 pr-3">
                                    <h3 className="text-uppercase mb-0 font-weight-semibold">
                                        Overall Rating for Sample
                                    </h3>
                                    <Tooltip className="position-relative">
                                        <TooltipTrigger
                                            className="btn btn-link bg-transparent  border-0 d-flex align-items-center py-1 px-2 mr-n2"
                                            style={{
                                                fontSize: '.875rem',
                                                textTransform: 'none'
                                            }}
                                        >
                                            Learn More
                                            <svg className="ml-2" width="20" height="20" aria-hidden="true">
                                                <use xlinkHref="#icon-info-circle" />
                                            </svg>
                                        </TooltipTrigger>
                                        <TooltipContent position="top right" style={{ width: 600 }}>
                                            <p>Radial Gauge Icons quickly show Condition Ratings for SPID based on the most recent sample dates. Condition Ratings are one of four levels:</p>
                                            <ol>
                                                <li className="mb-2">
                                                    <strong className="text-uppercase text-success">Normal</strong> - Both the lubricant and equipment are in acceptable limits.
                                                </li>
                                                <li className="mb-2">
                                                    <strong className="text-uppercase text-warning">Caution</strong> - A condition of the lubricant or equipment s abnormal, but not yet to a level of concern.
                                                </li>
                                                <li className="mb-2">
                                                    <strong className="text-uppercase text-orange">Serious</strong> - One or more conditions of the lubricant or equipment is beyond acceptable limits.
                                                </li>
                                                <li className="mb-2">
                                                    <strong className="text-uppercase text-danger">Critical</strong> - One or more conditions of the lubricant or equipment is well beyond acceptable limits, at this point either the lubricant or equipment is compromised.
                                                </li>
                                            </ol>
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                                <div className="table-wrapper" style={{ minHeight: 180 }}>
                                    <table className="w-100">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="font-weight-semibold text-uppercase text-blue-light text-right text-nowrap bg-gray-dark position-sticky shadow-right text-nowrap py-1 pr-3 pl-5"
                                                    style={{
                                                        left: 0,
                                                        border: '1px solid #2E3F5C',
                                                        borderWidth: '1px 0',
                                                        width: 220
                                                    }}
                                                    scope="row"
                                                >
                                                    Sample Date:
                                                </th>
                                                {
                                                    ratings.map((item, index) => (
                                                        item.Date ?
                                                            <th
                                                                key={index}
                                                                className={classNames('font-weight-semibold text-center py-1', {
                                                                    'text-danger': item.Value === 'Critical',
                                                                    'text-warning': item.Value === 'Marginal' || item.Value === 'Caution',
                                                                    'text-orange': item.Value === 'Serious'
                                                                })}
                                                                style={{ border: '1px solid #2E3F5C', borderWidth: '1px 0' }}
                                                                scope="col"
                                                            >
                                                                <Moment format="M/DD/YYYY">
                                                                    {new Date(item.Date)}
                                                                </Moment>
                                                            </th>
                                                            :
                                                            <th
                                                                key={index}
                                                                style={{ border: '1px solid #2E3F5C', borderWidth: '1px 0' }}
                                                                scope="col"
                                                            />
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th
                                                    className="font-weight-semibold text-uppercase text-blue-light text-right align-top bg-gray-dark position-sticky shadow-right text-nowrap pt-3 pr-3"
                                                    style={{ left: 0, width: 220 }}
                                                    scope="row"
                                                >
                                                    Rating:
                                                </th>
                                                {
                                                    ratings.map((item, index) => {
                                                        let rating = 0;
                                                        if (item.Value === 'Normal') {
                                                            rating = 0;
                                                        } else if (item.Value === 'Marginal' || item.Value === 'Caution') {
                                                            rating = 2;
                                                        } else if (item.Value === 'Serious') {
                                                            rating = 3;
                                                        } else if (item.Value === 'Critical') {
                                                            rating = 4;
                                                        }

                                                        return (
                                                            item.Date ?
                                                                <td key={index} className="text-center pt-3">
                                                                    <GaugeChart
                                                                        className="mx-auto"
                                                                        style={{ width: 200 }}
                                                                        id={`rating-${index}`}
                                                                        nrOfLevels={4}
                                                                        colors={['#5fcb77', '#ffe05f', '#e4773d', '#d13c40']}
                                                                        needleColor="#dce2e4"
                                                                        needleBaseColor="#dce2e4"
                                                                        hideText={true}
                                                                        percent={rating / 4}
                                                                        cornerRadius={0}
                                                                        arcPadding={0.03}
                                                                        arcWidth={0.075}
                                                                    />
                                                                    <span
                                                                        className={classNames('text-uppercase font-weight-bold', {
                                                                            'text-success': item.Value === 'Normal',
                                                                            'text-warning': item.Value === 'Marginal' || item.Value === 'Caution',
                                                                            'text-orange': item.Value === 'Serious',
                                                                            'text-danger': item.Value === 'Critical'
                                                                        })}
                                                                        style={{ fontSize: `${12 / 16}rem` }}
                                                                    >
                                                                        {item.Value || <>&nbsp;</>}
                                                                    </span>
                                                                </td>
                                                                :
                                                                <td key={index}>
                                                                    <div style={{ width: 0 }} />
                                                                </td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            {
                                viscosity.length ?
                                    <ExpandCollapseTable
                                        className="position-relative bg-gray-dark rounded mb-5"
                                        title="Viscosity (ASTM D445)"
                                        tooltipContent="Viscosity test result values for the most recent sample dates. Viscosity is the single most important property of a lubricant. A reduction in viscosity usually indicates the system has been contaminated with a solvent or refrigerant fluid. An increase in viscosity normally is traced to mixture with a high viscosity product, contamination, or lubricant oxidation."
                                    >
                                        <ReportTable
                                            title={
                                                <div style={{
                                                    width: 150,
                                                    wordBreak: 'break-word',
                                                    whiteSpace: 'normal'
                                                }}>
                                                    Viscosity
                                                </div>
                                            }
                                            data={viscosity}
                                        />
                                    </ExpandCollapseTable> : ''
                            }

                            {
                                physChem.Sections && physChem.Sections.length ?
                                    <ExpandCollapseTable
                                        className="position-relative bg-gray-dark rounded mb-5"
                                        title="Physical/Chemical Properties"
                                    >
                                        {
                                            physChem.Sections.map((item, index) => (
                                                <ReportTable
                                                    key={index}
                                                    title={
                                                        <div style={{
                                                            width: 150,
                                                            wordBreak: 'break-word',
                                                            whiteSpace: 'normal'
                                                        }}>
                                                            {item.Name}
                                                        </div>
                                                    }
                                                    data={item.Rows}
                                                />
                                            ))
                                        }
                                    </ExpandCollapseTable> : ''
                            }

                            {
                                particulates.Sections && particulates.Sections.length ?
                                    <ExpandCollapseTable
                                        className="position-relative bg-gray-dark rounded mb-5"
                                        title="Particulates"
                                    >
                                        {
                                            particulates.Sections.map((item, index) => (
                                                <ReportTable
                                                    key={index}
                                                    title={
                                                        <div style={{
                                                            width: 150,
                                                            wordBreak: 'break-word',
                                                            whiteSpace: 'normal'
                                                        }}>
                                                            {item.Name}
                                                        </div>
                                                    }
                                                    data={item.Rows}
                                                />
                                            ))
                                        }
                                    </ExpandCollapseTable> : ''
                            }

                            {
                                traceElements.Sections && traceElements.Sections.length ?
                                    <ExpandCollapseTable
                                        className="position-relative bg-gray-dark rounded mb-5"
                                        title="Trace Elements by ICP (ASTM 5185)"
                                        tooltipContent="Trace Elements test values for the most recent sample dates. Trace elements are useful for identifying contamination, comfirming additive content, and indicating system wear."
                                    >
                                        {
                                            traceElements.Sections.map((item, index) => (
                                                <ReportTable
                                                    key={index}
                                                    title={
                                                        <div className="my-n2" style={{ width: 150 }}>
                                                            <div style={{
                                                                width: 130,
                                                                wordBreak: 'break-word',
                                                                whiteSpace: 'normal'
                                                            }}>
                                                                {item.Name}
                                                            </div>
                                                        </div>
                                                    }
                                                    data={item.Rows}
                                                />
                                            ))
                                        }
                                    </ExpandCollapseTable> : ''
                            }

                            {
                                showWPA ?
                                    <WearParticleAnalysis
                                        data={wearParticleAnalysis}
                                        metals={WPAMetals}
                                    /> : ''
                            }

                            {
                                images.length > 0 ?
                                    <section className="mb-4">
                                        <div className="row">
                                            {
                                                images.map((item, index) => (
                                                    <div key={index} className="col-6 pb-4">
                                                        <div className="bg-gray-dark h-100">
                                                            <figure className="embed-responsive embed-responsive-16by9 mb-0">
                                                                <a href={item.FilePath} target="_blank" rel="noopener noreferrer">
                                                                    <img
                                                                        className="embed-responsive-item fit-cover"
                                                                        loading="lazy"
                                                                        src={item.FilePath}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </figure>
                                                            <div className="p-4">
                                                                <h3 className="h4 font-weight-semibold mb3">
                                                                    Image #{index + 1} - {item.Magnification}
                                                                </h3>
                                                                {item.ImageComment}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </section> : ''
                            }
                        </div>
                        <div className="col-12 px-4">
                            {/* <div className="mb-5">
                                <Alert
                                    className="mb-3"
                                    type="danger"
                                    orientation="horizontal"
                                    icon={
                                        <svg className="text-danger" width="58" height="51">
                                            <use xlinkHref="#icon-exclamation-triangle" />
                                        </svg>
                                    }
                                    title="Red Alert!"
                                    description="This red box is not good. Proceed with caution."
                                    ctaText="CTA"
                                />
                                <Alert
                                    className="mb-3"
                                    type="danger"
                                    orientation="horizontal"
                                    icon={
                                        <svg className="text-danger" width="58" height="51">
                                            <use xlinkHref="#icon-exclamation-triangle" />
                                        </svg>
                                    }
                                    title="Expired!"
                                    description="Sit elit proident commodo quis esse sunt aute dolore ipsum proident quis commodo anim sit."
                                />
                                <div className="text-center mt-4 mb-n2">
                                    <button className="btn btn-link text-danger py-0 px-1">
                                        Load More Alerts
                                    </button>
                                </div>
                            </div> */}


                        </div>
                    </div>
            }
        </div>
    );
};

export default ConditionReport;