import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledButton = styled.button`
    height: 40px;
    width: 80px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0;
    border: none;
    background: none;
    font-size: ${12/16}rem;
    color: var(--blue-light);
    border-radius: 0.25rem 0.25rem 0 0;

    &:not(:disabled):hover,
    &:focus {
        color: #A290F3;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        color: var(--gray);
    }

    &.is-active {
        font-weight: 700;
        color: #A290F3;
        background-color: #1B273B;
    }
`;

const MonthNavButton = props => (
    <StyledButton
        className={ classNames({
            'is-active': props.active
        }) }
        style={ props.style }
        onClick={ props.onClick }
        disabled={ props.disabled }
    >
        { props.children }
    </StyledButton>
);

export default MonthNavButton;
