import React, { useEffect, useMemo, useState, useContext } from 'react';
import Moment from 'react-moment';
import Context from '../../../context/Context';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
const StyledMore = styled.div`
    position: absolute;
    height: auto;
    width:200px;
    z-index: 1;
    background-color: #1B273B;
    box-shadow: 0 0.25rem 0.75rem rgba(17,25,38,0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms, transform 300ms;
    &.expanded{
        opacity: 1;
        visibility: visible;
    }
    .btn.btn-dropdown{
        font-weight: normal;    
        font-size: ${15 / 16}rem;

        &:hover{
            background-color:#1B273B;
            border: none;
            text-decoration:underline !important;
            color: var(--blue)!important;
        }
    }
`
const StyledTable = styled.table`
    width: 100%;

    tr {
        background-color: var(--gray-dark);

        &:nth-child(even) {
            background-color: var(--dark);

            td {
                background-color: var(--dark);
            }
        }
    }

    thead th {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: var(--gray-dark);
        color: var(--blue-light);
        border: 1px solid #2E3F5C;
        border-width: 1px 0;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2;
    }

    th,
    td {
        padding: 0 1rem 0 2rem;
        cursor: pointer;
        &:first-child {
            padding-left: 3rem;
        }
    }

    td {
        height: 55px;
        vertical-align: middle;
        background-color: var(--gray-dark);
    }

    .position-sticky {
        z-index: 1020;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -1px;
            width: 1px;
            height: 100%;
            background-color: inherit;
        }
    }
`;

const Table = props => {
    const { columns, data } = props;   
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data, autoResetSortBy: false }, useSortBy);

    return (
        <StyledTable {...getTableProps()}>
            <thead>
                {
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps([{
                                            className: column.className,
                                            style: column.style
                                        }])}
                                    >
                                        <div className="text-nowrap">
                                            {column.render('Header')}
                                            {
                                                column.isSorted ? (
                                                    <svg
                                                        className="text-primary ml-1"
                                                        width="14" height="14"
                                                    >
                                                        <use xlinkHref={`#icon-chevron-${column.isSortedDesc ? 'down' : 'up'}`} />
                                                    </svg>
                                                ) : ''
                                            }
                                        </div>
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps([{
                                                className: cell.column.className,
                                                style: cell.column.style
                                            }])}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </StyledTable>
    );
};

const ActivityTable = props => {
    const { data } = props;
    const [isExpanded, setIsExpanded] = useState('');
    const context = useContext(Context);
    const { account } = context;

    const handleUpdateActivity = (item) => {
        props.setMaintenanceDate({ label: item.MaintenanceDate, value: item.MaintenanceDate });
        props.setMaintenanceType({ label: item.MaintenanceTypeName, value: item.MaintenanceTypeID });
        props.setMaintenanceID(item.ID)
        props.setMachineHours(item.MachineHours);
        props.setLubeHours(item.LubeHours);
        props.setComments(item.Comment);
        props.handleUpdateActivityPanel(item);
    }
    
    const tableColumns = useMemo(() => {
        const arr = [];
        arr.push(
            {
                Header: (
                    <div
                        className="position-absolute w-100 h-100"
                        style={{ top: 0, left: 0, backgroundColor: '#bfb4f0' }}
                    >
                        ID
                    </div>
                ),
                accessor: 'maintenanceID',
                disableSortBy: true,
                className: 'd-none'
            },
            {
                Header: <>Date</>,
                accessor: 'date',
                disableSortBy: false,
                className: ''
            },
            {
                Header: <>Maintenance Type</>,
                accessor: 'type',
                disableSortBy: false,
                className: ''
            },
            {
                Header: <>Machine<br /> Hours</>,
                accessor: 'machinehours',
                disableSortBy: false,
                className: 'text-center'
            },
            {
                Header: <>Lube<br /> Hours</>,
                accessor: 'lubehours',
                disableSortBy: false,
                className: 'text-center',
                style: { right: 124 }
            },
            {
                Header: <>Comment</>,
                accessor: 'comment',
                disableSortBy: true,
                className: '',
                style: { right: 0 }
            },
            {
                Header: <></>,
                accessor: 'more',
                disableSortBy: true,
                className: '',
                style: { right: 0 }
            }
        );

        return arr;
    }, []);
    const handleToggle = item => {
        if (isExpanded !== item.ID) {
            setIsExpanded(item.ID)
        } else {
            setIsExpanded('')
        }
    }
    const handleOutsideClick = e => {
        if (isExpanded) {
           setIsExpanded('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    });

    const tableData = useMemo(() => {
        const arr = [];

        data.map(item => {
            const obj = {};

            obj.date = <Moment
                style={{
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem'
                }}
                format="M/DD/YYYY"
            >
                {new Date(item.MaintenanceDate)}
            </Moment>;
            obj.type = item.MaintenanceTypeName;
            obj.machinehours = item.MachineHours;
            obj.lubehours = item.LubeHours;
            obj.comment = item.Comment;
            obj.maintenanceID = item.ID;
            obj.more =
                <>
                {(account.orbitalRole !== 'READONLY')  ?
                    <button className="btn border-0" onClick={() => handleToggle(item)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9.25964" cy="2.50964" r="1.75964" fill="#118AF7" />
                            <circle cx="9.25964" cy="9.02893" r="1.75964" fill="#118AF7" />
                            <circle cx="9.25964" cy="15.5482" r="1.75964" fill="#118AF7" />
                        </svg>
                    </button>
                    :""}
                    <StyledMore className={`${isExpanded === item.ID ? "expanded" : ""}`}>                          
                            <button className="d-flex flex-row align-items-center btn btn-dropdown border-0 text-white w-100" onClick={() => handleUpdateActivity(item)}> 
                                <svg width="21" height="15">
                                    <use xlinkHref="#icon-edit" />
                                </svg>
                                <span className="ml-3">Edit/Update</span>
                            </button>
                            <button className="d-flex flex-row align-items-center btn btn-dropdown border-0 text-white w-100" onClick={() => props.handleDeleteActivity(item)}>
                                <div className="rounded-circle">
                                    <svg width="21" height="14">
                                        <use xlinkHref="#icon-close" />
                                    </svg>
                                </div>
                                <span className="ml-3">Delete</span>
                            </button>
                    </StyledMore>
                </>
            return arr.push(obj);
        });

        return arr;
    }, [data, isExpanded]); //eslint-disable-line

    return (
        <div
            className="position-relative bg-gray-dark mb-4"
            style={{
                padding: 2,
                borderRadius: '0.25rem 0.25rem 0 0'
            }}
        >
            <div
                className="position-relative d-flex align-items-center justify-content-between w-100 border-0 text-white text-left py-0 px-4"
                style={{ zIndex: 1020, height: 60 }}
            >
                <h4 className="h5 font-weight-semibold text-uppercase mb-0 mr-4">
                    Maintenance Activity Log
                </h4>
                {(account.orbitalRole !== 'READONLY')  ?
                    <i style={{ fontSize: 12 }}>
                        Click icons for additional info
                    </i>
                :""}
            </div>
            <div className='overflow-auto'>
                <Table
                    columns={tableColumns}
                    data={tableData}
                />
            </div>

        </div>
    );
};

export default ActivityTable;
