import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import getAreasBySiteID from '../../api/getAreasBySiteID';
import getJourneyScoreData from '../../api/getJourneyScoreData';
import Context from '../../context/Context';
import Select from '../../components/Select';
import ImproveScoreModal from './components/ImproveScoreModal/ImproveScoreModal';

const JourneyScore = () => {
    const { setPageTitle, token, sites, showLoadingOverlay } = useContext(Context);
    const [showModal, setShowModal] = useState(false);
    const [site, setSite] = useState(null);
    const [totalScore, setTotalScore] = useState(null);
    const [arrivalScore, setArrivalScore] = useState(null);
    const [storageScore, setStorageScore] = useState(null);
    const [transferScore, setTransferScore] = useState(null);
    const [applicationScore, setApplicationScore] = useState(null);
    const [lifecycleScore, setLifecycleScore] = useState(null);
    const [animateScores, setAnimateScores] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleChangeSite = (obj, cb = () => null) => {
        setSite(obj ? { name: obj.label, id: obj.value } : null);

        if (obj) {
            const sideId = obj.value;

            showLoadingOverlay(true);

            getAreasBySiteID(token, sideId, res => {
                const areaIds = res.map(item => item.AreaEntityID).join();

                getJourneyScoreData(token, sideId, areaIds, res => {
                    if (res && res[0]) {
                        const data = res[0];
                        const totalScore = Math.round(data.TotalScore * 100);
                        const arrivalScore = Math.round(data.ArrivalTotalScore * 100);
                        const storageScore = Math.round(data.StorageTotalScore * 100);
                        const transferScore = Math.round(data.TransferTotalScore * 100);
                        const applicationScore = Math.round(data.ApplicationTotalScore * 100);
                        const lifecycleScore = Math.round(data.LifecycleTotalScore * 100);

                        setTotalScore(totalScore);
                        setArrivalScore(arrivalScore);
                        setStorageScore(storageScore);
                        setTransferScore(transferScore);
                        setApplicationScore(applicationScore);
                        setLifecycleScore(lifecycleScore);
                        showLoadingOverlay(false);
                        setAnimateScores(true);

                        global.dataLayer.push({ event: 'view_journey_score' });
                    } else {
                        setTotalScore(null);
                        setArrivalScore(0);
                        setStorageScore(0);
                        setTransferScore(0);
                        setApplicationScore(0);
                        setLifecycleScore(0);
                        showLoadingOverlay(false);
                        setAnimateScores(false);
                    }

                    cb();
                });
            });
        }
    };

    const handleChangeModal = content => {
        setModalContent(content);
        setShowModal(true);
    };

    useEffect(() => {
        setPageTitle('Journey Score');
    });

    return (
        <div className="container-fluid overflow-hidden">
            <Helmet>
                <title>Journey Score | Trico Orbital Portal</title>
            </Helmet>
            <div className="row mx-n4">
                <div className="col-8 px-4">
                    <div className="bg-gray-dark rounded py-5 px-4 mb-3">
                        <div className="d-flex mx-auto" style={{ maxWidth: 800 }}>
                            {
                                totalScore !== null ?
                                    <div className="text-center pr-4">
                                        <div className="position-relative" style={{ marginBottom: '2rem' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="266" height="147" viewBox="0 0 266.27 133.018">
                                                <g transform="translate(-427.978 -247.654)">
                                                    <path
                                                        d="M589.489,759.9a122.514,122.514,0,0,1,245.028,0h10.621a133.135,133.135,0,0,0-266.27,0Z"
                                                        transform="translate(-150.89 -379.223)"
                                                        fill="url(#g986h45y)"
                                                    />
                                                </g>
                                            </svg>
                                            <div style={{
                                                position: 'absolute',
                                                top: 7,
                                                left: 0,
                                                width: 266,
                                                height: 266,
                                                border: '10px solid transparent',
                                                transform: `rotate(${animateScores ? totalScore / 100 * 177 : 0}deg)`,
                                                transition: 'transform 500ms'
                                            }}>
                                                <svg
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: 1,
                                                        transform: 'translate(-50%,-70%)'
                                                    }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="14"
                                                    viewBox="0 0 13.868 10.307"
                                                >
                                                    <g transform="rotate(-139 128.34 456.627)" fill="#fff">
                                                        <path d="M515.149 718.134a.5.5 0 01.498.594l-1.214 6.305a.5.5 0 01-.874.227l-5.206-6.205a.5.5 0 01.375-.82z" />
                                                        <circle cx="1.837" cy="1.837" r="1.837" transform="translate(517.737 713.184)" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <span
                                                className="position-absolute h1 font-weight-bold mb-0"
                                                style={{
                                                    bottom: -15,
                                                    left: '50%',
                                                    fontSize: '4.75rem',
                                                    transform: 'translateX(-50%)'
                                                }}
                                            >
                                                {totalScore}
                                            </span>
                                        </div>
                                    </div> : ''
                            }

                            <div className="d-flex align-items-center flex-grow-1">
                                <div className="w-100 mx-auto" style={{ maxWidth: 440 }}>
                                    <p className="text-uppercase mb-2">
                                        {site ? 'Selected Site' : 'Select a Site'}
                                    </p>
                                    <Select
                                        id="site"
                                        actionText="Select"
                                        options={sites.map(item => ({ label: item.name, value: item.id }))}
                                        value={site ? { label: site.name, value: site.id } : null}
                                        setValue={handleChangeSite}
                                        disabled={sites.length === 0}
                                        height={50}
                                        backgroundColor="var(--dark)"
                                        optionColor="#fff"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        totalScore !== null ?
                            <div className="row mx-n2">
                                {
                                    [
                                        {
                                            name: 'Arrival',
                                            score: arrivalScore
                                        },
                                        {
                                            name: 'Storage',
                                            score: storageScore
                                        },
                                        {
                                            name: 'Transfer',
                                            score: transferScore
                                        },
                                        {
                                            name: 'Application',
                                            score: applicationScore
                                        },
                                        {
                                            name: 'Lifecycle',
                                            score: lifecycleScore
                                        }
                                    ].map(item => (
                                        <div className="col px-2" key={item.name}>
                                            <div className="bg-gray-dark rounded text-center pt-4 pb-4 px-3">
                                                <h2
                                                    className="h6 font-weight-semibold text-uppercase mb-4 mx-n3"
                                                    style={{ fontSize: `clamp(0.875rem,1vw,1rem)` }}
                                                >
                                                    {item.name}
                                                </h2>
                                                <div className="position-relative mx-auto" style={{ maxWidth: 170 }}>
                                                    <svg
                                                        className="img-fluid"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 266.27 133.018"
                                                    >
                                                        <g transform="translate(-427.978 -247.654)">
                                                            <path
                                                                d="M589.489,759.9a122.514,122.514,0,0,1,245.028,0h10.621a133.135,133.135,0,0,0-266.27,0Z"
                                                                transform="translate(-150.89 -379.223)"
                                                                fill="url(#kl34563v)"
                                                            />
                                                        </g>
                                                    </svg>
                                                    {
                                                        item.score !== null ?
                                                            <div style={{ fontSize: '0.5rem' }}>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: '100%',
                                                                    height: '200%',
                                                                    fontSize: 'clamp(0.125rem,0.5vw,0.5rem)',
                                                                    border: '1em solid transparent',
                                                                    transform: `rotate(${animateScores ? (item.score === 0 ? 1 : item.score / 100 * 178) : 1}deg)`,
                                                                    transition: 'transform 500ms'
                                                                }}>
                                                                    <svg
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: 0,
                                                                            transform: 'translate(-50%,-50%)'
                                                                        }}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14" height="10"
                                                                        viewBox="0 0 13.868 10.307"
                                                                    >
                                                                        <g transform="rotate(-139 128.34 456.627)" fill="#fff">
                                                                            <path d="M515.149 718.134a.5.5 0 01.498.594l-1.214 6.305a.5.5 0 01-.874.227l-5.206-6.205a.5.5 0 01.375-.82z" />
                                                                            <circle cx="1.837" cy="1.837" r="1.837" transform="translate(517.737 713.184)" />
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                <span
                                                                    className="position-absolute font-weight-bold"
                                                                    style={{
                                                                        bottom: '-0.25em',
                                                                        left: '50%',
                                                                        fontSize: `2.5rem`,
                                                                        lineHeight: 1,
                                                                        transform: 'translateX(-50%)'
                                                                    }}
                                                                >
                                                                    <span style={{ fontSize: `clamp(1.5rem,2.5vw,${48 / 16}rem)` }}>
                                                                        {item.score}
                                                                    </span>
                                                                </span>
                                                            </div> : null
                                                    }
                                                </div>
                                                <button
                                                    className="mt-4 position-relative btn btn-sm p-1 p-md-2 btn-primary d-inline-flex align-items-center"
                                                    onClick={() => handleChangeModal(item.name)}
                                                >
                                                    Improve Score
                                                </button>
                                            </div>

                                        </div>
                                    ))
                                }
                            </div> : null
                    }
                </div>
                <div className="col-4 px-4">
                    <div className="bg-gray-dark rounded p-4 h-100" style={{ minHeight: 700 }}>
                        <h2 className="h3 font-weight-semibold text-uppercase mb-3">
                            What is this?
                        </h2>
                        <p>The Journey Score is a scoring model associated with the stages of the Journey of the Lubricant and based upon your current practices against best practices.</p>
                    </div>
                </div>
            </div>

            {
                showModal ?
                    <ImproveScoreModal
                        isOpen={showModal}
                        setIsOpen={setShowModal}
                        site={site}
                        stage={modalContent}
                        updateJourneyScore={cb => handleChangeSite({ label: site.name, value: site.id }, cb)}
                    /> : ''
            }

            <svg style={{ width: 0, height: 0, overflow: 'hidden' }}>
                <defs>
                    <linearGradient id="g986h45y" x1="0" y1="1" x2="1" y2="1">
                        <stop offset="0" stopColor="#f8738d" />
                        <stop offset="0.25" stopColor="#7055ed" />
                        <stop offset="0.75" stopColor="#118af7" />
                        <stop offset="1" stopColor="#7cd5a8" />
                    </linearGradient>
                    <linearGradient id="kl34563v" x1="0" y1="1" x2="1" y2="1">
                        <stop offset="0" stopColor="#d13c40" />
                        <stop offset="0.25" stopColor="#e4773d" />
                        <stop offset="0.75" stopColor="#ffe05f" />
                        <stop offset="1" stopColor="#5fcb77" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default JourneyScore;
