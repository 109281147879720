import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import getLubricantManufacturers from '../../../api/getLubricantManufacturers';
import Context from '../../../context/Context';

const StyledButton = styled.button`
    display: block;
    color: inherit;
    padding: 0;
    width: 100%;
    border: none;
    height: 42px;
    color: ${props => props.selected ? '#fff' : '' };
    font-weight: ${props => props.selected ? 600 : '' };
    background-color: ${props => props.selected ? 'var(--secondary)' : 'transparent' };
    background-image: ${props => props.selected ? 'linear-gradient(135deg, transparent, rgba(255,255,255,0.25))' : 'linear-gradient(135deg, transparent, transparent)' };
    transition: background 150ms ease-in-out, color 150ms ease-in-out;
    pointer-events: ${props => props.selected ? 'none' : '' };

    &:focus,
    &:hover {
        color: #fff;
        background-color: var(--secondary);
        background-image: linear-gradient(135deg, transparent, rgba(255,255,255,0.25));
    }

    &:focus {
        outline: none;
    }
`;

const ManufacturerSearch = props => {
    const { placeholder } = props;
    const { token } = useContext(Context);
    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturer, setManufacturer] = useState({ name: '', id: null });
    const [searchTerm, setSearchTerm] = useState(props.searchTerm);

    useEffect(() => {
        setManufacturer(props.manufacturer);
        setSearchTerm(props.searchTerm);
    }, [props]); // eslint-disable-line

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchTerm.trim() === '') {
                setManufacturers([]);
            } else {
                getLubricantManufacturers(token, searchTerm, res => {
                    const manufacturers = res.map(item => (
                        { name: item.Name, id: item.EntityID }
                    ));
                    setManufacturers(manufacturers);
                });
            }
        }, 250);

        return () => clearTimeout(timer);
    }, [searchTerm, setManufacturers]); // eslint-disable-line

    return (
        <>
            <div className="position-relative">
                <label htmlFor="fluidManufacturerSearch" className="sr-only">
                    Search
                </label>
                <input
                    type="text"
                    id="fluidManufacturerSearch"
                    className="form-control bg-blue-light text-dark border border-primary"
                    style={{ borderRadius: '3rem', paddingLeft: '2.5rem' }}
                    value={ searchTerm }
                    onChange={ e => setSearchTerm(e.target.value) }
                    placeholder={ placeholder }
                    autoComplete="off"
                />
                <svg
                    className="position-absolute text-primary"
                    style={{ top: 11, left: 12 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18" height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="arcs"
                >
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                </svg>
            </div>
            {
                searchTerm.length === 0 ? '' :
                    <>
                        {
                            manufacturers.length > 0 ?
                                <div
                                    className="mt-3 rounded overflow-auto scrollbar-sm"
                                    style={{ maxHeight: 42 * 4, backgroundColor: 'var(--blue-light)' }}
                                >
                                    {
                                        manufacturers.map((item, index) => (
                                            <StyledButton
                                                key={ index }
                                                className="d-flex align-items-center justify-content-start text-truncate px-3"
                                                selected={ manufacturer && manufacturer.name === item.name }
                                                onClick={() => {
                                                    setManufacturer(item);
                                                    props.setManufacturer(item);
                                                    props.setSearchTerm(searchTerm);
                                                }}
                                            >
                                                { item.name }
                                            </StyledButton>
                                        ))
                                    }
                                </div>
                                :
                                <div className="mt-3">

                                </div>
                        }
                    </>
            }
        </>
    );
};

export default ManufacturerSearch;
