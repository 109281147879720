import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, useLocation, useHistory, } from 'react-router-dom';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { useIdleTimer } from 'react-idle-timer';
import getSites from './api/getSites';
import Context from './context/Context';
import Header from './layout/Header';
import Nav from './layout/Nav';
import Footer from './layout/Footer';
import GlobalLoader from './components/GlobalLoader';
import Routes from './Routes';
import './styles/app.css';

const ScrollToTop = ({ refs }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        refs.forEach((ref) => {
            ref.current.scrollTo(0, 0);
        });
    }, [pathname]); // eslint-disable-line

    return null;
};

const IdleTimer = ({ state }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    useIdleTimer({
        timeout: 1000 * 60 * 60, // 1 hour
        onIdle: () => {
            if (pathname !== "/login") {
                history.push(`/login?returnUrl=${pathname}`);
                state.setToken("");
                state.setAccount(null);
                state.setSites([]);
                state.setConditionSummary(null);
                localStorage.clear();
            }
        },
        debounce: 500,
    });

    return null;
};

const App = () => {
    const [initializing, setInitializing] = useState(true);
    const [token, setToken] = useState("");
    const [account, setAccount] = useState(null);
    const [sites, setSites] = useState([]);
    const [conditionSummary, setConditionSummary] = useState({});
    const [scenario1, setScenario1] = useState(null);
    const [scenario2, setScenario2] = useState(null);
    const [pageTitle, setPageTitle] = useState("");
    const [loadingOverlay, showLoadingOverlay] = useState(false);
    const node = useRef();

    const initialState = {
        token,
        setToken,
        account,
        setAccount,
        sites,
        setSites,
        conditionSummary,
        setConditionSummary,
        scenario1,
        setScenario1,
        scenario2,
        setScenario2,
        pageTitle,
        setPageTitle,
        loadingOverlay,
        showLoadingOverlay,
    };
    
    useEffect(() => {
        ReactGA.initialize('UA-212127623-1', {
            titleCase: false
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        const account = JSON.parse(localStorage.getItem('account'));
        const conditionSummary = JSON.parse(localStorage.getItem('conditionSummary'));
        const sites = JSON.parse(localStorage.getItem('sites'));

        if (token) {
            const date = new Date();
            const expireDate = new Date(token.expires);
            if (date < expireDate) {
                setToken(token.value);
            }
        }

        if (account) {
            setAccount(account);
        }

        if (conditionSummary) {
            setConditionSummary(conditionSummary);
        }

        if (sites) {
            setSites(sites);
            setInitializing(false);
        } else if (token) {
            getSites(token.value, (res) => {
                const sites = res.map((item) => ({
                    name: item.CustomerName,
                    id: item.ERPID,
                    accountId: item.CRMAccountID,
                }));

                setSites(sites);
                localStorage.setItem("sites", JSON.stringify(sites));

                setInitializing(false);
            });
        } else {
            setInitializing(false);
        }
    }, []);

    return initializing ? null : (
        <HelmetProvider>
            <Context.Provider value={initialState}>
                <Router>
                    <IdleTimer state={initialState} />

                    <ScrollToTop refs={[node]} />

                    <a className="sr-only sr-only-focusable skip-link" href="#content">
                        Skip to content
                    </a>

                    <div
                        className="d-flex bg-dark text-white overflow-auto"
                        style={{ 
                            height: '100vh',
                            minHeight: '-webkit-fill-available',
                            paddingBottom: 85
                        }}
                    >
                        {/* hide nav for app Help page */}
                        { pageTitle !== 'Help' ? <Nav /> : '' }

                        <div className="d-flex flex-column flex-grow-1 overflow-auto">
                            {/* hide header for app Help page */}
                            {pageTitle !== 'Help' ? (
                                <Header
                                    className="position-relative pl-5 pb-5"
                                    style={{
                                        zIndex: 1060,
                                        paddingTop: 20,
                                        paddingRight: '2.7%',
                                    }}
                                />
                            ) : ''}

                            <main
                                ref={node}
                                id="content"
                                className="position-relative flex-grow-1 overflow-auto pl-5"
                                style={{
                                    paddingRight: '2.7%',
                                    paddingBottom: '85px'
                                }}
                            >
                                    {/* change page width for app Help page */}
                                    <div style={{ minWidth: pageTitle !== 'Help' ? 1200 : 0 }}>
                                        <Routes />
                                    </div>
                            </main>
                        </div>
                    </div>

                    <Footer />
                </Router>

                <GlobalLoader loading={loadingOverlay} />
            </Context.Provider>
        </HelmetProvider>
    );
};

export default App;
