import React, { useContext, useEffect, useState } from 'react';
import getContent from '../api/getContent';
import Context from '../context/Context';
import parse from 'html-react-parser';

const footerContainerStyle = {
    width: '100%',
    height: '85px',
    paddingLeft: '125px',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '.7rem',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center'
};

const footerLogoStyle = {
    paddingTop: '10px',
    paddingLeft: '30px',
    paddingBottom: '10px',
    paddingRight: '50px',
    marginRight: 'auto'
};

const footerAddressStyle = {
    borderRight: 'solid 1px white',
    marginTop: '15px',
    paddingLeft: '10px',
    marginBottom: '15px',
    paddingRight: '50px'
};

const footerPhoneStyle = {
    paddingTop: '10px',
    paddingLeft: '50px',
    paddingBottom: '10px',
    marginRight: '10%'
};

const Footer = () => {
    const { token } = useContext(Context);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        getContent(token, res => {
            setAddress(res.Address);
            setPhone(res.Phone);
        });
    }, []); // eslint-disable-line

    return (
        <footer
            className="position-absolute d-flex w-100"
            style={{ bottom: 0, left: 0, zIndex: 9999 }}
        >
            <div style={footerContainerStyle}>
                <div style={footerLogoStyle}>
                    <svg width="145" height="33" viewBox="0 0 265 107" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <g clipPath="url(#clip0_147_50)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M264.32 75.36L76.3899 106.57L18.4099 75.16L264.32 75.36Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 22.94V9.97H54.59V22.94H35.61V70.22H18.98V22.94H0Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M53.6799 70.22V9.97H89.1999C96.0699 9.97 100.77 11.81 104.75 15.83C113.24 24.37 110.44 37.5 99.8699 41.94C105.02 43.95 108.27 48.38 108.99 55.58L109.99 64.94L110.08 65.78L110.17 66.79C110.44 68.21 110.89 69.21 111.61 70.22H94.7999C93.8899 69.63 93.4399 68.96 93.3599 67.62L93.1799 65.86L92.3699 57.67C92.3699 57 92.2799 56.33 92.0999 55.74C91.0199 50.38 88.9399 48.54 83.3299 47.79C82.6999 47.71 81.9799 47.63 81.2499 47.63H70.3199V70.22H53.6899H53.6799ZM84.2299 36.66C84.6799 36.66 85.2199 36.58 85.7699 36.5C89.8299 35.91 91.9099 34.15 92.6399 30.8C93.1799 28.13 92.2799 25.53 90.4699 23.94C89.0299 22.68 87.2199 22.1 84.1499 22.1H70.3199V36.66H84.2399H84.2299Z" fill="white"/>
                            <path d="M131.31 9.97H114.68V70.22H131.31V9.97Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M197.1 31.31H180.02C180.02 30.97 180.02 30.72 179.93 30.47C178.85 24.36 172.61 20.43 164.48 22.1C158.88 23.27 154.63 27.96 153.27 36.58C153 38.84 152.91 41.02 153.18 43.27C154.36 52.23 158.78 57 164.12 57.91C171.8 59.25 176.68 56.83 179.3 51.05C179.84 49.72 180.11 48.37 180.2 46.95H197.47C197.38 48.12 197.1 49.38 196.83 50.63C193.12 65.86 179.39 73.98 160.77 71.3C144.32 68.87 132.39 52.47 136.37 32.98C139.17 19.76 148.75 10.89 161.22 8.95999C172.88 7.19999 181.73 9.62999 188.42 15.41C193.3 19.59 196.28 25.2 197.09 31.31H197.1Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M233.06 62.55C243.13 62.55 250.29 53.2 250.29 41.75H250.28C250.28 33.7361 241.169 17.1266 236.092 8.49168C252.69 10.0959 264.53 22.3161 264.53 40.1C264.53 61.69 247.08 75.07 224.85 71.23C209.94 68.63 199.64 56.59 199.64 40.1C199.64 23.61 209.94 11.56 224.85 8.96999C226.253 8.72692 227.638 8.55256 229 8.44466C223.481 16.7148 213.85 32.4749 213.85 41.75C213.85 53.2 222.99 62.55 233.06 62.55Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M250.29 40.74C250.29 52.19 243.13 61.54 233.06 61.54C222.99 61.54 213.85 52.19 213.85 40.74C213.85 27.74 232.77 2 232.77 2C232.77 2 250.28 29.63 250.28 40.74H250.29Z" stroke="white" strokeWidth="3.4" strokeMiterlimit="2.61"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_147_50">
                                <rect width="264.53" height="106.57" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div style={footerAddressStyle}>
                    {parse(address)}
                </div>
                <div style={footerPhoneStyle}>
                    {parse(phone)}
                </div>
            </div>
        </footer>
    );
};

export default Footer;