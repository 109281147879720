import React, { useEffect, useState } from 'react';
import Panel from '../../../components/Panel';
import RadioList from '../../../components/RadioList';

const EditAreaPanel = props => {
    const { isVisible, setIsVisible, areas } = props;
    const [area, setArea] = useState({ name: '', id: '' });

    const handleClose = () => {
        if (area !== props.area) {
            props.onChange(area);
        }
        setIsVisible(false);
    };

    useEffect(() => {
        setArea(props.area);
    }, [props.area]);

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <div className="p-2">
                <h2 className="h3 text-uppercase font-weight-semibold mb-3">
                    Area
                </h2>
                <p className="mb-4">
                    At which area is this SPID located?
                </p>
                <RadioList
                    id="area"
                    options={ areas.map(item => ({ label: item.name, value: item.id })) }
                    value={{ label: area.name, value: area.id }}
                    setValue={ obj => {
                        setArea({ name: obj.label, id: obj.value });
                    } }
                />
                {/* <div className="text-center mt-4">
                    <button
                        className="btn btn-primary"
                        style={{ width: 147 }}
                        onClick={ handleClose }
                    >
                        Save
                    </button>
                </div> */}
            </div>
        </Panel>
    )
};

export default EditAreaPanel;
