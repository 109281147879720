import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import suppliesList from './suppliesList.json'
import Context from '../../context/Context';
import postOrderSuppliesForm from '../../api/postOrderSuppliesForm';
import RadioList from '../../components/RadioList';
import CheckboxList from '../../components/CheckboxList';
import { Tooltip, TooltipTrigger, TooltipContent } from '../../components/Tooltip';

const mediaUrl = process.env.REACT_APP_MEDIA_URL;

const Checkbox = props => {
    const { name, description, price, onChange, image, className } = props;
    const [checked, setChecked] = useState(props.checked);
    const [quantity, setQuantity] = useState(props.quantity);

    const handleUpdateQuantity = e => {
        setQuantity(+e.target.value);
        onChange(+e.target.value);
    };

    const handleChecked = () => {
        setQuantity(checked ? 0 : 1);
        setChecked(!checked);
        onChange(checked ? 0 : 1);
    };

    return (
        <div className={`row mx-n2 ${className || ''}`}>
            {
                image ?
                    <div className="col-3 px-2">
                        <img
                            className="d-block img-thumbnail w-100 mb-3"
                            loading="lazy"
                            src={`${mediaUrl}${image}`}
                            alt=""
                        />
                    </div> : null
            }

            <div className="col">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`${name}-checkbox`}
                        type="checkbox"
                        value={price}
                        checked={checked}
                        onChange={handleChecked}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={`${name}-checkbox`}
                    >
                        {name}
                    </label>
                    <div className="mb-2">
                        Price: {price ? `$${price.toFixed(2)}` : 'No Charge'}
                        {
                            description ?
                                <div className="mt-1" style={{ fontSize: '0.875rem' }}>
                                    {description}
                                </div> : ''
                        }
                    </div>
                    {
                        name !== "Include bottle labels with my order" && name !== "Include bottles labels only for specific pieces of equipment" ?
                            <div className="d-flex align-items-center">
                                <label
                                    className="mr-2 mb-0"
                                    style={{ cursor: checked ? '' : 'default' }}
                                    htmlFor={`${name}-qty`}
                                >
                                    Quantity:
                                </label>
                                <select
                                    name={`${name}-qty`}
                                    id={`${name}-qty`}
                                    className="form-control"
                                    style={{ width: 'auto' }}
                                    onChange={handleUpdateQuantity}
                                    value={quantity}
                                    disabled={!checked}
                                >
                                    {
                                        [...Array(11)].map((item, index) => (
                                            <option value={index} key={index}>
                                                { index}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div> : ''
                    }
                </div>
            </div>
        </div>
    );
};

const OrderSupplies = () => {
    const { setPageTitle, token, showLoadingOverlay } = useContext(Context);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [activeStep, setActiveStep] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [receiverFirstName, setReceiverFirstName] = useState('');
    const [receiverLastName, setReceiverLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [plantName, setPlantName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setShipState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('United States');
    const [acceptsUSMail, setAcceptsUSMail] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const [availableDays, setAvailableDays] = useState([]);
    const [availableTimes, setAvailableTimes] = useState('');
    const [automaticOrder, setAutomaticOrder] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [purchaseOrderNum, setPurchaseOrderNum] = useState('');
    const [shippingInstructions, setShippingInstructions] = useState('');
    const [shippingAcctNumber, setShippingAcctNumber] = useState('');
    const [comments, setComments] = useState('');
    const form = useRef();

    const handleChangeStep = step => {
        setActiveStep(step);
        document.getElementById('content').scrollTo(0, 0);
    };

    const handleSubmit = e => {
        e.preventDefault();

        const data = {
            FirstName: firstName,
            LastName: lastName,
            ReceiverFirstName: receiverFirstName,
            ReceiverLastName: receiverLastName,
            Email: email,
            PhoneNumber: phone,
            CompanyName: companyName,
            PlantName: plantName,
            AddressLine1: addressLine1,
            AddressLine2: addressLine2,
            City: city,
            State: state,
            PostalCode: zipCode,
            Country: country,
            AcceptsUSMail: acceptsUSMail,
            Recommendations: recommendation,
            AvailableDays: availableDays.join(','),
            AvailableTimes: availableTimes,
            AutomaticOrder: automaticOrder,
            PurchaseOrderNumber: purchaseOrderNum,
            PaymentType: paymentType,
            ShippingInstructions: shippingInstructions,
            ShippingAccountNumber: shippingAcctNumber,
            Comments: comments,
            Products: products.filter(item => item.quantity > 0).map(item => (
            {
                Name: item.name,
                Quantity: item.quantity,
                PriceEach: item.price
            }
            )),
            TotalPrice: total
        };

        showLoadingOverlay(true);

        postOrderSuppliesForm(token, data, res => {
            showLoadingOverlay(false);
            handleChangeStep(4);
        });

        global.dataLayer.push({ event: 'order_supplies_form_fill' }); 
    };

    const handleUpdateTotal = (name, qty) => {
        let total = 0;
        const items = [...products];
        const item = items.filter(item => item.name === name)[0];

        item.quantity = qty;
        items[items.indexOf(item)] = item;

        items.forEach(item => {
            total += item.price * item.quantity
        });

        setProducts(items);
        setTotal(total);
    };

    useEffect(() => {
        const items = suppliesList.map(item => (
            item.items.map(item => (
                {
                    name: item.name,
                    price: item.price,
                    quantity: 0
                }
            ))
        )).flat();

        setProducts(items);

        setPageTitle('Oil Sampling Supplies Order Form');
    }, []); // eslint-disable-line

    const supplies = suppliesList.map((item, index) => (
        <div className="mb-5 pb-5 border-bottom" key={index}>
            <div className="row mx-n2 mb-4">
                {
                    item.imageUrl ?
                        <div className="col-3 px-2">
                            <img
                                className="img-thumbnail w-100"
                                loading="lazy"
                                src={`${mediaUrl}${item.imageUrl}`}
                                alt=""
                            />
                        </div> : null
                }
                <div className="col px-2">
                    <h3 className="h5 font-weight-bold">
                        { item.name }
                    </h3>
                    { item.description }
                </div>
            </div>
            <div className="mb-n3">
                {
                    item.items.map((item, itemIndex) => {
                        const checked = products.filter(x => x.quantity > 0).filter(x => x.name === item.name).length > 0;
                        const quantity = products.length > 0 ? products.filter(x => x.name === item.name)[0].quantity : 0;

                        return (
                            <React.Fragment key={ itemIndex }>
                                <Checkbox
                                    className="mb-3"
                                    name={ item.name }
                                    description={ item.description }
                                    price={ item.price }
                                    image={ item.image }
                                    checked={ checked }
                                    quantity={ quantity }
                                    onChange={ qty => handleUpdateTotal(item.name, qty) }
                                />
                            </React.Fragment>
                        );
                    })
                }
            </div>
        </div>
    ));

    return (
        <div className="container-fluid overflow-hidden">
            <Helmet>
                <title>Oil Sampling Supplies Order Form | Trico Orbital Portal</title>
            </Helmet>

            <form ref={ form } onSubmit={ handleSubmit }>
                <div className="row mx-n4">
                    <div className="col-8 px-4 pb-5">
                        {
                            activeStep === 1 ?
                                <>
                                    <div className="bg-gray-dark rounded mb-5">
                                        <div className="py-4 px-3 border-bottom">
                                            <div className="d-flex justify-content-between align-items-start pl-3">
                                                <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                    Personal Information
                                                </h2>
                                            </div>
                                        </div>

                                        <div className="pt-4 pb-5 px-3 mx-3">
                                            <div className="row mx-n2 mb-4">
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-first-name" className="mb-1">
                                                        * First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-first-name"
                                                        value={firstName}
                                                        onChange={e => setFirstName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-last-name" className="mb-1">
                                                        * Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-last-name"
                                                        value={lastName}
                                                        onChange={e => setLastName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-recipient-first-name" className="mb-1">
                                                        Receiver&rsquo;s First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-recipient-first-name"
                                                        value={receiverFirstName}
                                                        onChange={e => setReceiverFirstName(e.target.value)}
                                                    />
                                                    <small>
                                                        If different from above
                                                    </small>
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-recipient-last-name" className="mb-1">
                                                        Receiver&rsquo;s Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-recipient-last-name"
                                                        value={receiverLastName}
                                                        onChange={e => setReceiverLastName(e.target.value)}
                                                    />
                                                    <small>
                                                        If different from above
                                                    </small>
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-email-address" className="mb-1">
                                                        * Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="os-email-address"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-phone-number" className="mb-1">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        id="os-phone-number"
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-company-name" className="mb-1">
                                                        * Company Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-company-name"
                                                        value={companyName}
                                                        onChange={e => setCompanyName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-plant-name" className="mb-1">
                                                        Plant Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-plant-name"
                                                        value={plantName}
                                                        onChange={e => setPlantName(e.target.value)}
                                                    />
                                                    <small>If applicable</small>
                                                </div>
                                                <div className="d-flex align-items-center col-12 px-2 my-4">
                                                    <h3 className="h5 mb-0">
                                                        Shipping Address
                                                    </h3>
                                                    <Tooltip className="position-relative ml-1">
                                                        <TooltipTrigger
                                                            className="bg-transparent border-0 text-primary py-0 px-1"
                                                            aria-label="Help"
                                                        >
                                                            <svg width="16" height="16">
                                                                <use xlinkHref="#icon-info-circle" />
                                                            </svg>
                                                        </TooltipTrigger>
                                                        <TooltipContent position="top">
                                                            The shipping address should be the physical address where the order can be delivered. No PO box is accepted. List any other shipping instructions in the &ldquo;Additional Comments&rdquo; field on the last page.
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-street-address" className="mb-1">
                                                        * Street Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-street-address"
                                                        value={addressLine1}
                                                        onChange={e => setAddressLine1(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-6 form-group px-2">
                                                    <label htmlFor="os-street-address-2" className="mb-1">
                                                        Street Address Line 2
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-street-address-2"
                                                        value={addressLine2}
                                                        onChange={e => setAddressLine2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-4 form-group px-2">
                                                    <label htmlFor="os-city" className="mb-1">
                                                        * City
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-city"
                                                        value={city}
                                                        onChange={e => setCity(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-2 form-group px-2">
                                                    <label htmlFor="os-state" className="mb-1">
                                                        * State
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-state"
                                                        value={state}
                                                        onChange={e => setShipState(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-2 form-group px-2">
                                                    <label htmlFor="os-zip-code" className="mb-1">
                                                        * Zip Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="os-zip-code"
                                                        value={zipCode}
                                                        onChange={e => setZipCode(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-4 form-group px-2">
                                                    <label htmlFor="os-country" className="mb-1">
                                                        * Country
                                                    </label>
                                                    <select
                                                        id="os-country"
                                                        className="form-control scrollbar-sm"
                                                        onChange={ e => setCountry(e.target.value)}
                                                        value={ country}
                                                        selected={ country }
                                                        required
                                                    >
                                                        <option disabled value="">Please select&hellip;</option>
                                                        <option value="United States">United States</option>
                                                        <option value="Afghanistan">Afghanistan</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="The Bahamas">The Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="Brunei">Brunei</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Curacao">Curacao</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands">Falkland Islands</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="The Gambia">The Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Iran">Iran</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jersey">Jersey</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="North Korea">North Korea</option>
                                                        <option value="South Korea">South Korea</option>
                                                        <option value="Kosovo">Kosovo</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Laos">Laos</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libya">Libya</option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macau">Macau</option>
                                                        <option value="Macedonia">Macedonia</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Micronesia">Micronesia</option>
                                                        <option value="Moldova">Moldova</option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montenegro">Montenegro</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Nagorno-Karabakh">Nagorno-Karabakh</option>
                                                        <option value="Namibia">Namibia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherlands">Netherlands</option>
                                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Turkish Republic of Northern Cyprus">Turkish Republic of Northern Cyprus</option>
                                                        <option value="Northern Mariana">Northern Mariana</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Palestine">Palestine</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn Islands">Pitcairn Islands</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Republic of the Congo">Republic of the Congo</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russia">Russia</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Saint Barthelemy">Saint Barthelemy</option>
                                                        <option value="Saint Helena">Saint Helena</option>
                                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                        <option value="Saint Lucia">Saint Lucia</option>
                                                        <option value="Saint Martin">Saint Martin</option>
                                                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Serbia">Serbia</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra Leone">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="Somaliland">Somaliland</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="South Ossetia">South Ossetia</option>
                                                        <option value="South Sudan">South Sudan</option>
                                                        <option value="Spain">Spain</option>
                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Svalbard">Svalbard</option>
                                                        <option value="eSwatini">eSwatini</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Syria">Syria</option>
                                                        <option value="Taiwan">Taiwan</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania">Tanzania</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Timor-Leste">Timor-Leste</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Transnistria Pridnestrovie">Transnistria Pridnestrovie</option>
                                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                        <option value="Tristan da Cunha">Tristan da Cunha</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Vatican City">Vatican City</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Vietnam">Vietnam</option>
                                                        <option value="British Virgin Islands">British Virgin Islands</option>
                                                        <option value="Isle of Man">Isle of Man</option>
                                                        <option value="US Virgin Islands">US Virgin Islands</option>
                                                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                        <option value="Western Sahara">Western Sahara</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-12 pt-4 px-2">
                                                    <div className="d-flex align-items-center mb-2">
                                                        Does this address accept US Mail?
                                                        <Tooltip className="position-relative ml-1">
                                                            <TooltipTrigger
                                                                className="bg-transparent border-0 text-primary py-0 px-1"
                                                                aria-label="Help"
                                                            >
                                                                <svg width="16" height="16">
                                                                    <use xlinkHref="#icon-info-circle" />
                                                                </svg>
                                                            </TooltipTrigger>
                                                            <TooltipContent position="top">
                                                                Answer this question if you are ordering labels only with no other supplies. This order will be sent to you in the mail.
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </div>
                                                    <RadioList
                                                        id="AcceptsUSMail"
                                                        options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                                        value={{ label: acceptsUSMail, value: acceptsUSMail }}
                                                        setValue={obj => setAcceptsUSMail(obj.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary"
                                            style={{ minWidth: 147 }}
                                            onClick={ e => {
                                                if (form.current.checkValidity()) {
                                                    e.preventDefault();
                                                    handleChangeStep(2);
                                                }
                                            } }
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </> : ''
                        }

                        {
                            activeStep === 2 ?
                                <>
                                    <div className="bg-gray-dark rounded mb-5">
                                        <div className="py-4 px-3 border-bottom">
                                            <div className="d-flex justify-content-between align-items-start pl-3">
                                                <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                    Sampling Supplies</h2>
                                            </div>
                                        </div>
                                        <div className="pt-4 pb-5 px-3 mx-3">
                                            { supplies }
                                            <p className="h4 text-uppercase mb-0">
                                                Total: <b>${total.toFixed(2)}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-4 text-right">
                                            <button
                                                className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                style={{
                                                    textDecoration: 'underline',
                                                    fontSize: `${12/16}rem`
                                                }}
                                                onClick={ e => {
                                                    e.preventDefault();
                                                    handleChangeStep(1);
                                                } }
                                            >
                                                <svg width="20" height="20">
                                                    <use xlinkHref="#icon-chevron-left" />
                                                </svg>
                                                Back
                                            </button>
                                        </div>
                                        <div className="col-4 text-center">
                                            <button
                                                className="btn btn-primary ml-2"
                                                style={{ minWidth: 147 }}
                                                onClick={ e => {
                                                    e.preventDefault();
                                                    handleChangeStep(3);
                                                } }
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        <div className="col-4" />
                                    </div>
                                </> : ''
                        }

                        {
                            activeStep === 3 ?
                                <>
                                    <div className="bg-gray-dark rounded mb-5">
                                        <div className="py-4 px-3 border-bottom">
                                            <div className="d-flex justify-content-between align-items-start pl-3">
                                                <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                                    Shipping Information
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="pt-4 pb-5 px-3 mx-3">
                                            <div className="mb-4">
                                                <div className="mb-4">
                                                    <div className="d-flex align-items-center mr-n3">
                                                        <img
                                                            className="mr-3"
                                                            style={{ maxWidth: 200, maxHeight: 200 }}
                                                            src={`${mediaUrl}/orbital/media/order-supplies-form/gearbox-kit-set-up-georgia-pacific-drywall-plant-5c6b113c5890c9-27127832.jpg`}
                                                            alt=""
                                                        />
                                                        <img
                                                            className="mr-3"
                                                            style={{ maxWidth: 200, maxHeight: 200 }}
                                                            src={`${mediaUrl}/orbital/media/order-supplies-form/hydraulic-unit-breather-kit-set-up-with-gearbox-kit-georgia-pacific-drywall-plant-5c6b12571c2492-04253269.jpg`}
                                                            alt=""
                                                        />
                                                        <img
                                                            className="mr-3"
                                                            style={{ maxWidth: 200, maxHeight: 200 }}
                                                            src={`${mediaUrl}/orbital/media/order-supplies-form/siemens-gearbox-with-closed-llg-ex-breather-halton-region-waste-water-5c6b12fbe36662-44265315.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <p>Hydraulic Reservoirs / Gearboxes / Custom Combinations - Trico offers dozens of standard and customized packages that close up critical equipment. These kits include Sampling Ports, LLG's, Quick Disconnect for Filtration access, and Desiccant Breathers for proper breathing.</p>
                                            </div>

                                            <div className="form-group">
                                                <p className="mb-2">
                                                    Would you like someone to contact you to help make recommendations for your applications?
                                                </p>
                                                <RadioList
                                                    id="recommendations"
                                                    options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                                    value={{ label: recommendation, value: recommendation }}
                                                    setValue={obj => setRecommendation(obj.value)}
                                                />
                                            </div>

                                            {
                                                recommendation === 'Yes' ?
                                                    <div className="form-group">
                                                        <p className="mb-2">
                                                            Select days you are available:
                                                        </p>
                                                        <CheckboxList
                                                            id="dayofWeek"
                                                            value={ availableDays.map(item => ({label: item, value: item})) }
                                                            options={[
                                                                { label: 'Monday', value: 'Monday' },
                                                                { label: 'Tuesday', value: 'Tuesday' },
                                                                { label: 'Wednesday', value: 'Wednesday' },
                                                                { label: 'Thursday', value: 'Thursday' },
                                                                { label: 'Friday', value: 'Friday' }
                                                            ]}
                                                            setValue={ arr => setAvailableDays(arr.map(item => item.value)) }
                                                        />
                                                        <label className="mt-2 mb-1" htmlFor="availableTimes">
                                                            List times you are available:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="availableTimes"
                                                            value={availableTimes}
                                                            onChange={e => setAvailableTimes(e.target.value)}
                                                        />
                                                    </div> : ''
                                            }

                                            <div className="form-group">
                                                <p className="mb-2">
                                                    For bottle orders: Would you like this to be an automatic recurring order?
                                                </p>
                                                <RadioList
                                                    id="automaticOrder"
                                                    options={[{ label: 'No', value: 'No' }, { label: 'Yes, send bottles each month', value: 'Monthly' }, { label: 'Yes, send bottles each quarter', value: 'Quarterly' }, { label: 'Yes, send a year\'s supply', value: 'Yearly' }]}
                                                    value={{ label: automaticOrder, value: automaticOrder }}
                                                    setValue={(obj) => setAutomaticOrder(obj.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p>
                                                    Payment Type <br />
                                                    <small>Order Total: ${total.toFixed(2)}</small>
                                                </p>
                                                <RadioList
                                                    id="paymentType"
                                                    options={
                                                        total > 0 ?
                                                            [
                                                                { label: 'Call me for a credit card', value: 'Call me for a credit card' },
                                                                { label: 'Use our current oil analysis purchase order', value: 'Oil Analysis Purchase Order' },
                                                                { label: 'Use our purchase order number', value: 'Purchase Order' }
                                                            ]
                                                            :
                                                            [
                                                                { label: 'No Charge', value: 'No Charge' },
                                                                { label: 'Call me for a credit card', value: 'Call me for a credit card' },
                                                                { label: 'Use our current oil analysis purchase order', value: 'Oil Analysis Purchase Order' },
                                                                { label: 'Use our purchase order number', value: 'Purchase Order' }
                                                            ]
                                                    }
                                                    value={{ label: paymentType, value: paymentType }}
                                                    setValue={(obj) => setPaymentType(obj.value)}
                                                />
                                            </div>

                                            {
                                                paymentType === 'Purchase Order' ?
                                                    <div className="form-group w-100 col-6 pl-0">
                                                        <label htmlFor="purchaseOrder">
                                                            Purchase Order Number
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="purchaseOrder"
                                                            style={{ height: '2rem' }}
                                                            value={purchaseOrderNum}
                                                            onChange={e => setPurchaseOrderNum(e.target.value)} />
                                                    </div> : ''
                                            }

                                            <div className="form-group">
                                                <p className="mb-2">
                                                    Shipping Instructions
                                                </p>
                                                <RadioList
                                                    id="shippingInstructions"
                                                    options={
                                                        total > 0 ?
                                                            [
                                                                { label: 'Add shipping charge to the order', value: 'Add shipping charge to the order' },
                                                                { label: 'Use our Fedex shipping account number listed below', value: 'Fedex' },
                                                                { label: 'Use our UPS shipping account number listed below', value: 'UPS' }
                                                            ]
                                                            :
                                                            [
                                                                { label: 'Free Shipping', value: 'Free Shipping' },
                                                                { label: 'Add shipping charge to the order', value: 'Add shipping charge to the order' },
                                                                { label: 'Use our Fedex shipping account number listed below', value: 'Fedex' },
                                                                { label: 'Use our UPS shipping account number listed below', value: 'UPS' }
                                                            ]
                                                    }
                                                    value={{ label: shippingInstructions, value: shippingInstructions }}
                                                    setValue={(obj) => setShippingInstructions(obj.value)}
                                                />
                                            </div>
                                            {
                                                shippingInstructions === 'Fedex' || shippingInstructions === 'UPS' ?
                                                    <div className="form-group w-100 col-6 pl-0">
                                                        <label htmlFor="comments">
                                                            Shipping Account Number
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="shippingAcctNum"
                                                            style={{ height: '2rem' }}
                                                            value={shippingAcctNumber}
                                                            onChange={e => setShippingAcctNumber(e.target.value)}
                                                        />
                                                    </div> : ''
                                            }

                                            <div className="form-group">
                                                <label htmlFor="comments" className="mb-1">
                                                    Additional Comments
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    type="textarea"
                                                    id="comments"
                                                    style={{ height: '7rem' }}
                                                    value={ comments }
                                                    onChange={ e => setComments(e.target.value) }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-4 text-right">
                                            <button
                                                className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
                                                style={{
                                                    textDecoration: 'underline',
                                                    fontSize: `${12/16}rem`
                                                }}
                                                onClick={ e => {
                                                    e.preventDefault();
                                                    handleChangeStep(2);
                                                } }
                                            >
                                                <svg width="20" height="20">
                                                    <use xlinkHref="#icon-chevron-left" />
                                                </svg>
                                                Back
                                            </button>
                                        </div>
                                        <div className="col-4 text-center">
                                            <button
                                                className="btn btn-primary ml-2"
                                                style={{ minWidth: 147 }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-4" />
                                    </div>
                                </> : ''
                        }

                        {
                            activeStep === 4 ?
                                <div className="bg-gray-dark rounded text-center py-5 px-4 mb-5">
                                    Thank you, we've received your request. Someone from our team will contact you soon.
                                </div> : ''
                        }
                    </div>
                </div>
            </form>
        </div >
    );
};

export default OrderSupplies;
