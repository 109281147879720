import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Context = createContext();

const Carousel = props => {
    const [totalItems, setTotalItems] = useState(0);
    const [activeItem, setActiveItem] = useState(1);

    const initialState = {
        totalItems, setTotalItems,
        activeItem, setActiveItem
    };

    return (
        <Context.Provider value={ initialState }>
            { props.children }
        </Context.Provider>
    );
};

const CarouselItems = props => {
    const { setTotalItems, activeItem, setActiveItem } = useContext(Context);

    useEffect(() => {
        setTotalItems(props.children.length);
        setActiveItem(props.activeItem);
    }, [setActiveItem, props.activeItem]); // eslint-disable-line

    useEffect(() => {
        setActiveItem(activeItem);
        props.setActiveItem(activeItem);
    }, [setActiveItem, activeItem]); // eslint-disable-line

    return (
        <div className="flex-grow-1">
            <div
                className="h-100"
                style={{
                    transform: `translateX(${(activeItem - 1) * -100}%)`,
                    transition: 'transform 300ms'
                }}
            >
                <div className="d-flex align-items-start justify-content-start h-100" >
                    { props.children }
                </div>
            </div>
        </div>
    );
};

const CarouselItem = ({ children, index }) => {
    const { activeItem } = useContext(Context);
    const node = useRef();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const isActive = index === activeItem;

        setIsActive(isActive);
    }, [activeItem, index]);

    return (
        <div
            ref={ node }
            className="p-4 h-100 w-100"
            style={{
                flex: '0 0 100%',
                visibility: isActive ? '' : 'hidden',
                transition: `visibility 300ms ${isActive ? '' : '300ms'}`
            }}
            tabIndex={ isActive ? null : -1 }
        >
            <div className="px-2">
                { children }
            </div>
        </div>
    );
};

const CarouselPrevButton = ({ children }) => {
    const { activeItem, setActiveItem } = useContext(Context);

    return (
        <button
            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
            style={{
                textDecoration: 'underline',
                fontSize: `${12/16}rem`,
                opacity: activeItem === 1 ? 0 : '',
                transition: 'opacity 150ms'
            }}
            onClick={ () => setActiveItem(activeItem - 1) }
            disabled={ activeItem === 1 }
        >
            { children }
        </button>
    );
};

const CarouselNextButton = ({ children }) => {
    const { totalItems, activeItem, setActiveItem } = useContext(Context);

    return (
        <button
            className="text-primary text-uppercase border-0 bg-transparent font-weight-semibold"
            style={{
                textDecoration: 'underline',
                fontSize: `${12/16}rem`,
                opacity: activeItem === totalItems ? 0 : '',
                transition: 'opacity 150ms'
            }}
            onClick={ () => setActiveItem(activeItem + 1) }
            disabled={ activeItem === totalItems }
        >
            { children }
        </button>
    );
};

const StyledNavButton = styled.button`
    position: relative;
    height: 44px;
    max-width: ${props => props.isActive ? '' : '70px'};
    color: var(--dark);
    font-size: 0.875rem;

    &:hover,
    &:focus {
        color: var(--secondary);
    }

    &:focus {
        outline: none;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: ${props => props.isActive ? 'currentColor' : 'transparent'}
    }
    &.nextbackbtn{
        color: var(--blue);
        text-decoration: underline;
        &:disabled{
            cursor: auto;
            color: rgba(17, 138, 247, 0.45);
        }
        &:hover{
            color: rgba(17, 138, 247, 0.45);
        }
        &::after{
            height: 0;
        }
    }
`;

const CarouselNavButton = props => (
    <StyledNavButton
        className={ classNames('bg-transparent border-0 font-weight-semibold py-0 px-3', {
            'text-secondary': props.isActive && !props.btnStyle,
            'flex-grow-1': !props.isActive && !props.btnStyle,
            'nextbackbtn mx-3' : props.btnStyle === 'nextback'
        }) }
        disabled={props.btnStyle === 'nextback' ? !props.isActive : ""}
        isActive={ props.isActive }
        onClick={ props.onClick }
    >
        { props.children }
    </StyledNavButton>
);

const StyledGroupNavButton = styled.button`
    &.btn {
        font-size: 0.75rem;
        height: 3rem;
        color: rgba(186,221,252,0.35);

        &:hover {
            background-color: var(--gray-dark);
            color: rgba(186,221,252,0.5);
        }
    }
`;

const CarouselGroupNavButton = props => (
    <StyledGroupNavButton
        className={ classNames('btn border-0 rounded-0 py-0 px-3 flex-grow-1', {
            'text-dark': props.isActive
        }) }
        style={{ backgroundColor: props.isActive ? '#baddfc' : '' }}
        onClick={ props.onClick }
    >
        { props.children }
    </StyledGroupNavButton>
);


export {
    Carousel,
    CarouselItems,
    CarouselItem,
    CarouselPrevButton,
    CarouselNextButton,
    CarouselNavButton,
    CarouselGroupNavButton
};
