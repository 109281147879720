import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Context from '../../context/Context';
import getEDS from '../../api/getEDS';
import getMaintenanceActivityBySPIDEID from '../../api/getMaintenanceActivityBySPIDEID';
import getMaintenancetypes from '../../api/getMaintenanceTypes';
import postMaintenanceActivity from '../../api/postMaintenanceActivity';
import putMaintenanceActivity from '../../api/putMaintenanceActivity';
import deleteMaintenanceActivity from '../../api/deleteMaintenanceActivity';
import ActivityTable from './components/ActivityTable';
import ActivityPanel from './components/ActivityPanel';
import UpdateActivityPanel from './components/UpdateActivityPanel';
import DateRangePicker from '../../components/DateRangePicker';
import Select from '../../components/Select';
import moment from 'moment';

const MaintenanceActivity = ({ match, location }) => {
    const context = useContext(Context);
    const { token, setPageTitle, showLoadingOverlay, account } = context;
    const [SPID, setSPID] = useState(null);
    const [site, setSite] = useState({ name: '', id: null });
    const [area, setArea] = useState({ name: '', id: null });
    const [entityID, setEntityID] = useState(null);
    const [data, setData] = useState([]);
    const [maintenanceTypes, setMaintenanceTypes] = useState([]);
    const [maintenanceDate, setMaintenanceDate] = useState(new Date());
    const [maintenanceType, setMaintenanceType] = useState('');
    const [machineHours, setMachineHours] = useState('');
    const [lubeHours, setLubeHours] = useState('');
    const [comments, setComments] = useState('');
    const [maintenanceID, setMaintenanceID] = useState('');
    const [showActivityPanel, setShowActivityPanel] = useState(false);
    const [showUpdateActivityPanel, setShowUpdateActivityPanel] = useState(false);
    const [activityToUpdate, setActivityToUpdate] = useState(null);
    const [activityActiveSlide, setActivityActiveSlide] = useState(null); // eslint-disable-line
    const [disabledAddBtn, setDisabledAddABtn] = useState(true);
    
    const obj = {
        ID: maintenanceID,
        SPIDEntityID: entityID,
        MaintenanceDate: moment(maintenanceDate).format('YYYY-MM-DD'),
        MaintenanceTypeID: maintenanceType.value,
        MaintenanceTypeName: maintenanceType.value,
        MachineHours: machineHours,
        LubeHours: lubeHours,
        Comment: comments
    };

    useEffect(() => {
        setPageTitle('Maintenance Activity');
        const params = new URLSearchParams(location.search);
        
        if (match.params.id) {
            setSPID(match.params.id);
        }

        getMaintenancetypes(token, res => {
            if (Array.isArray(res)) {
                setMaintenanceTypes(res.filter(item => item.Active).map(item => (
                    { label: item.Name, value: item.ID}
                )));
            }
        });

        // is this an existing SPID or a copy?
        // if neither param exists, it's new
        if (match.params.id || params.get('copyFrom')) {
            const id = match.params.id ? +match.params.id : +params.get('copyFrom');

            showLoadingOverlay(true);

            if (match.params.id) {
                setEntityID(+match.params.id);
            } else if (params.get('copyFrom')) {
                setEntityID(null);
                setSPID(null);
            }

            getEDS(token, id, res => {
                if (res.SPIDType) {
                    if (match.params.id) {
                        setSPID(res.SPID);
                    }
                    setSite({ name: res.CustName, id: res.SiteID });
                    setArea({ name: res.AreaName, id: res.ParentEntityID });
                } else if (res.Message) {
                    alert(res.Message);
                }
                showLoadingOverlay(false)
            });

            getMaintenanceActivityBySPIDEID(token, id, res => {
                setData(res)
            });
        }
    }, []); //eslint-disable-line

    const resetMaintenanceData = () => {
        setMaintenanceID('')
        setMaintenanceDate(new Date());
        setMaintenanceType('');
        setMachineHours('');
        setLubeHours('');
        setComments('');
    };

    const handleAddActivity = () => {
        postMaintenanceActivity(token, obj, res => {
            if (res.Success) {
                getMaintenanceActivityBySPIDEID(token, entityID, res => {
                    setData(res)
                    resetMaintenanceData()
                })
            }
        });

        global.dataLayer.push({ event: 'add_new_maintenance_form_fill' });
    };

    const handleDeleteActivity = (item) => {
        deleteMaintenanceActivity(token, item.ID, res => {
            if (res.Success) {
                getMaintenanceActivityBySPIDEID(token, entityID, res => {
                    setData(res)
                })
            }
        })
    };

    const handleUpdateActivityPanel = (item) => {
        setActivityToUpdate(item)
        setMaintenanceDate(new Date(item.MaintenanceDate))
        setShowUpdateActivityPanel(true)
    };

    const handleUpdateActivity = () => {
        obj.MaintenanceDate = maintenanceDate;
        putMaintenanceActivity(token, obj, res => {
            if (res.Success) {
                getMaintenanceActivityBySPIDEID(token, entityID, res => {
                    setData(res)
                    resetMaintenanceData()
                })
            }
        })
    };

    useEffect(() => {
        if (maintenanceDate.value !== '' && maintenanceType.value !== '' && machineHours !== '' && lubeHours !== '') {
            setDisabledAddABtn(false);
        }
    }, [maintenanceDate, maintenanceType, machineHours, lubeHours]);

    return (
        <div className="container-fluid overflow-hidden">
            <Helmet>
                <title>Maintenance Activity | Trico Orbital Portal</title>
            </Helmet>

            <div className="row">
                <div className="col-7 px-4 pb-2">
                    <div className="bg-gray-dark rounded overflow-hidden mb-5">
                        <div className="d-flex">
                            <h2
                                className="bg-gray d-flex justify-content-center align-items-center text-center font-weight-semibold text-uppercase flex-shrink-0 mb-0 py-4 px-3"
                                style={{ minWidth: 157 }}
                            >
                                SPID<br /> {SPID}
                            </h2>

                            <div
                                className="d-flex flex-column justify-content-center w-100 py-4 pl-4 px-3"
                                style={{ fontSize: `${18 / 16}rem` }}
                            >
                                <div className="d-flex justify-content-between align-items-start pb-3">
                                    <div className="d-flex">
                                        <strong
                                            className="font-weight-semibold text-uppercase flex-shrink-0 text-right pr-3"
                                            style={{ width: 73 }}
                                        >
                                            Site:
                                        </strong>
                                        {site.name}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className="d-flex">
                                        <strong
                                            className="font-weight-semibold text-uppercase flex-shrink-0 text-right pr-3"
                                            style={{ width: 73 }}
                                        >
                                            Area:
                                        </strong>
                                        {area.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ActivityTable
                        data={data}
                        maintenanceDate={maintenanceDate}
                        setMaintenanceID={setMaintenanceID}
                        maintenanceID={maintenanceID}
                        setMaintenanceDate={setMaintenanceDate}
                        maintenanceType={maintenanceType}
                        setMaintenanceType={setMaintenanceType}
                        machineHours={machineHours}
                        setMachineHours={setMachineHours}
                        lubeHours={lubeHours}
                        setLubeHours={setLubeHours}
                        comments={comments}
                        setComments={setComments}
                        handleDeleteActivity={handleDeleteActivity}
                        showUpdateActivityPanel={showUpdateActivityPanel}
                        setActivityToUpdate={setActivityToUpdate}
                        handleUpdateActivityPanel={handleUpdateActivityPanel}
                    />
                </div>

                {
                    account.orbitalRole !== 'READONLY'  ?
                        <div className="col-5 px-4">
                            <div className="bg-gray-dark rounded mb-5" style={{minHeight: 700}}>
                                <div className="p-3 mx-3 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h2 className="h4 font-weight-semibold text-uppercase mb-0">
                                            Add New Maintenance
                                        </h2>
                                    </div>
                                    <i style={{fontSize: 12}}>
                                        To switch section click progress chart below
                                    </i>
                                </div>

                                <div className="py-4 px-3 mx-3">
                                    <div className="d-flex flex-column">
                                        <div className="bg-gray-dark rounded mb-5">
                                            <div className="pt-4 pb-5 px-3 mx-3">
                                                <div className="mb-4">
                                                    <p
                                                        className="font-weight-semibold text-blue-light text-uppercase"
                                                        style={{lineHeight: 1.2}}
                                                    >
                                                        1. Maintenance Date
                                                    </p>
                                                    <DateRangePicker
                                                        startDate={maintenanceDate}
                                                        setStartDate={setMaintenanceDate}
                                                        showYearsOption={false}
                                                        pickerStyle="filters"
                                                        startOpen={false}
                                                        backgroundColor="var(--gray-dark)"
                                                        borderColor="var(--primary)"
                                                        color="#fff"
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <p
                                                        className="font-weight-semibold text-blue-light text-uppercase"
                                                        style={{ lineHeight: 1.2 }}
                                                    >
                                                        2. Maintenance Type
                                                    </p>
                                                    <Select
                                                        id="site"
                                                        actionText="Select"
                                                        options={ maintenanceTypes }
                                                        value={ maintenanceType ?? null }
                                                        setValue={ setMaintenanceType }
                                                        backgroundColor="#2E3F5C"
                                                        optionColor="#fff"
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="MachineHours"
                                                        className="font-weight-semibold text-blue-light text-uppercase mb-3"
                                                        style={{ lineHeight: 1.2 }}
                                                    >
                                                        3. Machine Hours
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control font-weight-semibold"
                                                        style={{ color: '#A290F3' }}
                                                        id="MachineHours"
                                                        value={machineHours}
                                                        placeholder=""
                                                        onChange={e => {
                                                            setMachineHours(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="LubeHours"
                                                        className="font-weight-semibold text-blue-light text-uppercase mb-3"
                                                        style={{ lineHeight: 1.2 }}
                                                    >
                                                        4. Lube Hours
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control font-weight-semibold"
                                                        style={{ color: '#A290F3' }}
                                                        id="LubeHours"
                                                        value={lubeHours}
                                                        placeholder=""
                                                        onChange={e => {
                                                            setLubeHours(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="Comments"
                                                        className="font-weight-semibold text-blue-light text-uppercase mb-3"
                                                        style={{ lineHeight: 1.2 }}
                                                    >
                                                        5. Comments <i className="text-lowercase">(optional)</i>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control font-weight-semibold"
                                                        style={{ color: '#A290F3' }}
                                                        id="Comments"
                                                        value={comments}
                                                        placeholder=""
                                                        onChange={e => {
                                                            setComments(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="text-center">
                                                    <button
                                                        className="btn btn-primary text-uppercase"
                                                        onClick={() => handleAddActivity()}
                                                        disabled={disabledAddBtn}
                                                    >
                                                        Add Activity
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''
                }
                {
                    showUpdateActivityPanel ?
                        <UpdateActivityPanel
                            isVisible={showUpdateActivityPanel}
                            setIsVisible={setShowUpdateActivityPanel}
                            activityToUpdate={activityToUpdate}
                            setMaintenanceID={setMaintenanceID}
                            maintenanceID={maintenanceID}
                            setMaintenanceDate={setMaintenanceDate}
                            maintenanceDate={maintenanceDate}
                            setMaintenanceType={setMaintenanceType}
                            maintenanceType={maintenanceType}
                            maintenanceTypes={maintenanceTypes}
                            setMachineHours={setMachineHours}
                            machineHours={machineHours}
                            setLubeHours={setLubeHours}
                            lubeHours={lubeHours}
                            setComments={setComments}
                            comments={comments}
                            handleUpdateActivity={handleUpdateActivity}
                            resetMaintenanceData={resetMaintenanceData}
                        /> : <></>
                }
                {
                    showActivityPanel ?
                        <ActivityPanel
                            isVisible={showActivityPanel}
                            setIsVisible={setShowActivityPanel}
                            activeSlide={activityActiveSlide}
                            setMaintenanceDate={setMaintenanceDate}
                            maintenanceDate={maintenanceDate}
                            maintenanceTypes={maintenanceTypes}
                            setMaintenanceType={setMaintenanceType}
                            maintenanceType={maintenanceType}
                        /> : <></>
                }

                <div className='col-7 text-center mb-5 border-top pt-4 pb-5'>
                    <Link to='' className='pt-3 text-underline text-uppercase'>Back to SPID Detail</Link>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceActivity;