import React, { useCallback, useContext, useState, useEffect } from 'react';
import Moment from 'react-moment';
import classNames from 'classnames';
import GaugeChart from 'react-gauge-chart';
import { Link } from 'react-router-dom';
import getSampleSummary from '../../../../../../api/getSampleSummary';
import getSampleActionsNeeded from '../../../../../../api/getSampleActionsNeeded';
import Context from '../../../../../../context/Context';
import Switch from '../../../../../../components/Switch';
import PrevNextButton from '../../components/PrevNextButton';
import MonthNavButton from './components/MonthNavButton';
import SampleNavButton from './components/SampleNavButton';

const SampleModal = props => {
    const { token, showLoadingOverlay } = useContext(Context);
    const { isOpen, setIsOpen } = props;
    const [showAll, setShowAll] = useState(false);
    const [activeMonth, setActiveMonth] = useState('');
    const [ratings, setRatings] = useState([]);
    const [samples, setSamples] = useState([]);
    const [activeSample, setActiveSample] = useState(0);
    const [sampleID, setSampleID] = useState('');
    const [actions, setActions] = useState('');
    const getSamples = useCallback(month => (
        props.samples
            .filter(item => item.Name === month)[0].Samples
            .map(item => item.SampleID).join(',')
    ), [props.samples]);

    const handleShowAll = () => {
        setShowAll(!showAll);
    };

    const handleChangeMonth = name => {
        setActiveSample(0);
        setActiveMonth(name);
        props.setActiveMonth(name);

        getSampleSummary(token, getSamples(name), res => {
            setSamples(res);
            setSampleID(res.SampleID)
        });
    };

    const handleClose = () => {
        setIsOpen(false);
        global.setTimeout(() => {
            setActiveMonth('');
            setSamples([]);
            setShowAll(false);
            setRatings([]);
        }, 300);
    };

    useEffect(() => {
        if (isOpen) {
            setActiveMonth(props.activeMonth);

            getSampleSummary(token, getSamples(props.activeMonth), res => {
                setSamples(res);
                if (res[0]) {
                    setRatings([
                        { label: 'Particulates', rating: Math.max(res[0].PC4Score, res[0].PC6Score, res[0].PC14Score) },
                        { label: 'Water', rating: res[0].KFScore },
                        { label: 'Physical/Chemical', rating: res[0].MaxAnalystChemScore }
                    ]);
                }

                showLoadingOverlay(false);
                getSampleActionsNeeded(token, res[0].SampleID, res => {
                    setActions(res);
                })
            });
                
        }
    }, [getSamples, isOpen, props.activeMonth, showLoadingOverlay, sampleID, token]);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1080,
                opacity: isOpen && (samples === null || samples.length > 0) ? '' : 0,
                visibility: isOpen && (samples === null || samples.length > 0) ? '' : 'hidden',
                transition: 'opacity 300ms, visibility 300ms'
            }}
            aria-modal={ isOpen && (samples === null || samples.length > 0) ? true : null }
            role={ isOpen && (samples === null || samples.length > 0) ? 'dialog' : null }
            tabIndex="-1"
        >
            <div
                className="position-absolute w-100 h-100"
                onClick={ handleClose }
            />

            <div
                className="position-absolute"
                style={{
                    top: '50%',
                    left: '50%',
                    transform: isOpen && (samples === null || samples.length > 0) ?
                        'translate(-50%,-50%)' : 'translate(-50%,calc(-50% + 50px))',
                    transition: 'transform 300ms'
                }}
            >
                <button
                    className="position-absolute btn btn-danger d-flex align-items-center justify-content-center rounded-circle p-0 mt-n3 mr-n3"
                    style={{
                        top: 0,
                        right: 0,
                        zIndex: 1,
                        width: 35,
                        height: 35
                    }}
                    onClick={ handleClose }
                >
                    <svg width="12" height="12">
                        <use xlinkHref="#icon-close" />
                    </svg>
                </button>

                <div
                    className="d-flex rounded scrollbar-sm"
                    style={{
                        fontSize: `${14/16}rem`,
                        backgroundColor: '#1B273B',
                        boxShadow: '0 0 0.5rem var(--dark)',
                        width: 800,
                        maxWidth: 'calc(100vw - 3rem)',
                        maxHeight: 'calc(100vh - 10rem)',
                        overflow: 'auto hidden'
                    }}
                >
                    {
                        samples === null || samples.length === 0 ?
                            <div className="h5 d-flex align-items-center justify-content-center text-center font-weight-bold text-danger p-5 mb-0">
                                <svg className="mr-2 text-orange" width="24" height="24">
                                    <use xlinkHref="#icon-exclamation-circle" />
                                </svg>
                                Error: Unable to retrieve sample!
                            </div>
                            :
                            <div
                                className="d-flex flex-column flex-shrink-0 overflow-hidden"
                                style={{ width: 800 }}
                            >
                                <div>
                                    <div
                                        className="d-flex pr-5"
                                        style={{
                                            backgroundColor: '#131C2B',
                                            height: 38
                                        }}
                                    >
                                        {
                                            props.samples.map((item, index) => {
                                                const name = item.Name;
                                                const active = activeMonth === name;
                                                const disabled = props.samples.filter(item => item.Name === name)[0].Samples.length === 0;

                                                return (
                                                    <MonthNavButton
                                                        key={ index }
                                                        style={{ pointerEvents: active ? 'none' : '' }}
                                                        active={ active }
                                                        disabled={ disabled }
                                                        onClick={ () => { handleChangeMonth(name) } }
                                                    >
                                                        { name }
                                                    </MonthNavButton>
                                                );
                                            })
                                        }
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-between border-bottom"
                                        style={{ height: 45 }}
                                    >
                                        <div
                                            className="d-flex align-items-center justify-content-between flex-grow-1 pr-1"
                                            style={{ maxWidth: 600 }}
                                        >
                                            <PrevNextButton
                                                className="mr-2 ml-3"
                                                direction="prev"
                                                disabled={ activeSample === 1 || samples.length <= 12 || showAll }
                                                onClick={ () => setActiveSample(activeSample - 1) }
                                            />
                                            <div className="d-flex align-items-center flex-grow-1">
                                                {
                                                    samples.map((item, index) => {
                                                        return (
                                                            <SampleNavButton
                                                                key={ index }
                                                                style={{ pointerEvents: activeSample === index ? 'none' : '' }}
                                                                onClick={ () => setActiveSample(index) }
                                                                active={ activeSample === index && !showAll }
                                                                visible={
                                                                    activeSample <= 12 ? index < 12 :
                                                                        index < activeSample && index >= activeSample - 12
                                                                }
                                                                disabled={ showAll }
                                                                rating={ item.OverallRating }
                                                            >
                                                                { index + 1 }
                                                            </SampleNavButton>
                                                        );
                                                    })
                                                }
                                            </div>
                                            {
                                                <PrevNextButton
                                                    className="ml-2 mr-3"
                                                    direction="next"
                                                    disabled={ activeSample === samples.length || samples.length <= 12 || showAll }
                                                    onClick={ () => setActiveSample(activeSample + 1) }
                                                />
                                            }
                                        </div>
                                        <Switch
                                            className="pr-3 flex-shrink-0"
                                            id="showAll"
                                            label="See All Samples"
                                            checked={ showAll }
                                            onChange={ handleShowAll }
                                            onColor="#5fcb77"
                                        />
                                    </div>
                                </div>
                                <div className="overflow-auto flex-grow-1">
                                    {
                                        samples.length === 0 ? '' :
                                            samples.map((item, index) => (
                                                <div
                                                    key={ index }
                                                    className={ classNames('text-left p-4', {
                                                        'd-none': !(activeSample === index || showAll),
                                                        'd-block': activeSample === index || showAll,
                                                        'pt-5': showAll && index > 0
                                                    }) }
                                                    style={{ backgroundColor: showAll && index % 2 === 1 ? '#151F2F' : '' }}
                                                >
                                                    <div className="row ml-0 pb-4">
                                                        <div className="col pl-4">
                                                            <div className="d-inline-flex mb-3 align-items-center">
                                                                {
                                                                    item.OverallRating === 'NORMAL' ?
                                                                        <svg className="mr-2 text-success" width="18" height="18" aria-hidden="true">
                                                                            <use xlinkHref="#icon-check-square" />
                                                                        </svg> : ''
                                                                }

                                                                {
                                                                    item.OverallRating === 'CAUTION' || item.OverallRating === 'MARGINAL' ?
                                                                        <svg className="mr-2 text-warning " width="24" height="24" aria-hidden="true">
                                                                            <use xlinkHref="#icon-exclamation-square" />
                                                                        </svg> : ''
                                                                }

                                                                {
                                                                    item.OverallRating === 'SERIOUS' ?
                                                                        <svg className="mr-2 text-orange" width="24" height="24" aria-hidden="true">
                                                                            <use xlinkHref="#icon-exclamation-circle" />
                                                                        </svg> : ''
                                                                }

                                                                {
                                                                    item.OverallRating === 'CRITICAL' ?
                                                                        <svg className="mr-2 text-danger" width="24" height="21" aria-hidden="true">
                                                                            <use xlinkHref="#icon-exclamation-triangle" />
                                                                        </svg> : ''
                                                                }

                                                                <h4 className="text-uppercase font-weight-semibold mb-0">
                                                                    SPID ID {item.SPID} – Sample {index + 1} of {samples.length}
                                                                </h4>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="pr-3 border-right h-100">
                                                                        <h5 className="text-blue-light text-uppercase font-weight-semibold">
                                                                            SPID Details
                                                                        </h5>
                                                                        Customer: { item.Customer }<br />
                                                                        Area: { item.AreaName }<br />
                                                                        Asset: { item.Asset }<br />
                                                                        Module: { item.Module }<br />
                                                                        Program: { item.TestSuite }<br />
                                                                        SPID Name: { item.SPIDLocationID }<br />
                                                                        SPID Description: { item.SPIDDescription }
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="border-right h-100">
                                                                        <h5 className="text-blue-light text-uppercase font-weight-semibold">
                                                                            Lab Results
                                                                        </h5>
                                                                        <table className="w-auto">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th
                                                                                        className="font-weight-normal"
                                                                                        scope="row"
                                                                                    >
                                                                                        Sample ID:
                                                                                    </th>
                                                                                    <td className="pl-3 pr-0">
                                                                                        { item.SampleID }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th
                                                                                        className="font-weight-normal"
                                                                                        scope="row"
                                                                                    >
                                                                                        Sample Date:
                                                                                    </th>
                                                                                    <td className="pl-3 pr-0">
                                                                                        <Moment format="M/DD/YYYY">
                                                                                            { item.SampleDate }
                                                                                        </Moment>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th
                                                                                        className="font-weight-normal"
                                                                                        scope="row"
                                                                                    >
                                                                                        Report Date:
                                                                                    </th>
                                                                                    <td className="pl-3 pr-0">
                                                                                        <Moment format="M/DD/YYYY">
                                                                                            { item.LabsComplete }
                                                                                        </Moment>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="pr-2">
                                                                            Actions Needed:
                                                                            <div className="scrollbar-sm overflow-auto" style={{ maxHeight: 200 }}>
                                                                                { actions }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <Link
                                                                className="btn btn-sm btn-primary d-flex align-items-center text-left w-100 mb-3"
                                                                to={ `/condition-summary/condition-report/${item.SampleID}` }
                                                            >
                                                                <span
                                                                    className="d-flex align-items-center justify-content-center rounded-circle mr-2 ml-n2"
                                                                    style={{
                                                                        width: 24,
                                                                        height: 24,
                                                                        backgroundColor: 'rgba(255,255,255,0.15)'
                                                                    }}
                                                                >
                                                                    <svg width="10" height="12">
                                                                        <use xlinkHref="#icon-file-chart" />
                                                                    </svg>
                                                                </span>
                                                                View History
                                                            </Link>
                                                            <a
                                                                className="btn btn-sm btn-outline-primary d-flex align-items-center text-left w-100"
                                                                href={ `${item.ReportURL}&oauth=${token}` }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => {
                                                                    global.dataLayer.push({
                                                                        event: 'download_spid_report',
                                                                        spid: item.SPID
                                                                    });
                                                                }}
                                                            >
                                                                <span
                                                                    className="d-flex align-items-center justify-content-center rounded-circle mr-2 ml-n2 flex-shrink-0"
                                                                    style={{
                                                                        width: 24,
                                                                        height: 24,
                                                                        backgroundColor: 'rgba(17,138,347,0.15)'
                                                                    }}
                                                                >
                                                                    <svg width="10" height="12">
                                                                        <use xlinkHref="#icon-file-download-outline" />
                                                                    </svg>
                                                                </span>
                                                                Download Report
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="border-top p-4">
                                                        <h5 className="text-blue-light text-uppercase font-weight-semibold mb-0">
                                                            Report Ratings
                                                        </h5>
                                                        <div className="row">
                                                            {
                                                                ratings.map((item, index) => (
                                                                    item.rating <= 4 ?
                                                                        <div
                                                                            key={ index }
                                                                            className="pt-4 col-4 text-center"
                                                                        >
                                                                            <h6
                                                                                className={ classNames('text-uppercase font-weight-semibold', {
                                                                                    'text-danger': item.rating === 4
                                                                                }) }
                                                                                style={{ fontSize: 'inherit' }}
                                                                            >
                                                                                { item.label }
                                                                            </h6>
                                                                            <GaugeChart
                                                                                className="mx-auto"
                                                                                style={{ width: 200 }}
                                                                                id={ `rating-${index}` }
                                                                                nrOfLevels={ 4 }
                                                                                colors={ ['#5fcb77', '#ffe05f', '#e4773d', '#d13c40'] }
                                                                                needleColor="#dce2e4"
                                                                                needleBaseColor="#dce2e4"
                                                                                hideText={ true }
                                                                                percent={ item.rating / 4 }
                                                                                cornerRadius={ 0 }
                                                                                arcPadding={ 0.03 }
                                                                                arcWidth={ 0.075 }
                                                                            />
                                                                            <span
                                                                                className={ classNames('text-uppercase font-weight-bold', {
                                                                                    'text-success': item.rating === 0,
                                                                                    'text-warning': item.rating === 2,
                                                                                    'text-orange': item.rating === 3,
                                                                                    'text-danger': item.rating === 4
                                                                                }) }
                                                                                style={{ fontSize: `${12/16}rem`}}
                                                                            >
                                                                                { item.rating === 0 ? 'Normal' : '' }
                                                                                { item.rating === 2 ? 'Caution' : '' }
                                                                                { item.rating === 3 ? 'Serious' : '' }
                                                                                { item.rating === 4 ? 'Critical' : '' }
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            key={ index }
                                                                            className="pt-4 col-4 text-center"
                                                                            style={{ opacity: 0.6, filter: 'grayscale(1)' }}
                                                                        >
                                                                            <h6
                                                                                className="text-uppercase font-weight-semibold"
                                                                                style={{ fontSize: 'inherit' }}
                                                                            >
                                                                                { item.label }
                                                                            </h6>
                                                                            <GaugeChart
                                                                                className="mx-auto"
                                                                                style={{ width: 200 }}
                                                                                id={ `rating-${index}` }
                                                                                nrOfLevels={ 4 }
                                                                                colors={ ['#5fcb77', '#ffe05f', '#e4773d', '#d13c40'] }
                                                                                needleColor="#dce2e4"
                                                                                needleBaseColor="#dce2e4"
                                                                                hideText={ true }
                                                                                percent={ 0 }
                                                                                cornerRadius={ 0 }
                                                                                arcPadding={ 0.03 }
                                                                                arcWidth={ 0.075 }
                                                                            />
                                                                            <span
                                                                                className="text-uppercase font-weight-bold"
                                                                                style={{ fontSize: `${12/16}rem`}}
                                                                            >
                                                                                Not Tested
                                                                            </span>
                                                                        </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SampleModal;