import React, { useEffect, useRef } from 'react';

const Panel = props => {
    const { isOpen, onRequestClose } = props;
    const node = useRef();

    const handleEscape = e => {
        let isEscape = false;

        if ('key' in e) {
            isEscape = e.key === 'Escape' || e.key === 'Esc';
        } else {
            isEscape = e.keyCode === 27;
        }

        if (isOpen && isEscape) {
            props.onRequestClose();
        }
    };

    const handleOutsideClick = e => {
        if (isOpen && !node.current.contains(e.target)) {
            onRequestClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscape);
        };
    });

    return (
        <div
            ref={ node }
            className="text-dark"
            style={{
                position: 'fixed',
                top: 125,
                right: 0,
                bottom: 85,
                zIndex: 1080,
                width: 520,
                backgroundColor: '#baddfc',
                visibility: isOpen ? '' : 'hidden',
                transform: isOpen ? '' : 'translateX(100%)',
                transition: 'visibility 300ms ease, transform 300ms ease'
            }}
            aria-hidden={ !isOpen ? 'true' : null }
            tabIndex="-1"
        >
            <button
                className="btn position-absolute rounded-circle text-primary p-0 border-0"
                style={{
                    top: '-0.75rem',
                    left: '-0.75rem',
                    zIndex: 1,
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    width: '1.5rem',
                    height: '1.5rem',
                    lineHeight: '1.5rem'
                }}
                onClick={ onRequestClose }
            >
                <svg width="12" height="12">
                    <use xlinkHref="#icon-close" />
                </svg>
            </button>

            <div
                className="position-absolute w-100 h-100 p-4"
                style={{ top: 0, left: 0, overflowX: 'hidden', overflowY: 'auto' }}
            >
                { props.children }
            </div>
        </div>
    );
};

export default Panel