import React from 'react';

const GlobalLoader = ({ loading }) => {
    return (
        <div
            className="position-fixed d-flex align-items-center justify-content-center w-100 h-100"
            style={{
                top: 0,
                left: 0,
                zIndex: 9999,
                visibility: loading ? '' : 'hidden',
                opacity: loading ? '' : 0,
                transition: 'opacity 150ms, visibility 150ms'
            }}
        >
            <div
                className="position-absolute w-100 h-100 bg-dark"
                style={{ top: 0, left: 0, opacity: 0.8 }}
            />
            <div
                className="position-relative spinner-border text-secondary"
                style={{ width: '8rem', height: '8rem' }}
                role="status"
            >
                <span className="sr-only">
                    Loading&hellip;
                </span>
            </div>
        </div>
    );
};

export default GlobalLoader;
