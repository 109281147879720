import React, { useContext, useEffect, useState, useRef } from 'react';
import Notification from '../components/Notification';
import styled from 'styled-components';
import Context from '../context/Context';
import getAlerts from '../api/getAlerts';
import markAsRead from '../api/postMarkAlertAsRead';

const StyledNav = styled.nav`
    top: 70px;
    right: 20px;
    width: 390px;
    background-color: #131C2B;
    font-size: ${15/16}rem;
    box-shadow: 0 0.25rem 0.75rem rgba(17,25,38,0.6);
    transition: opacity 300ms, visibility 300ms, transform 300ms;

    &::before {
        content: '';
        position: absolute;
        right: 14px;
        top: -20px;
        border: 10px solid transparent;
        border-bottom-color: #131C2B;
    }
`;

const Notifications = () => {
    const { token } = useContext(Context);
    const node = useRef();
    const [isExpanded, setIsExpanded] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);

        if (isExpanded) {
            global.setTimeout(() => {
                setShowAll(false);
            }, 300);
        }
    };

    const handleOutsideClick = e => {
        if (!node.current.contains(e.target)) {
            setIsExpanded(false);
            
            global.setTimeout(() => {
                setShowAll(false);
            }, 300);
        }
    };

    const setAlerts = () => {
        getAlerts(token, res => {
            const data = res.filter(x => x.DateRead === null);
            setData([]);
            setData(data);
            setTotal(data.length);
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    });

    useEffect(() => {
        setAlerts();

        const interval = setInterval(() => {
            setAlerts();
        }, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line

    useEffect(() => {
        if (total === 0) {
            setIsExpanded(false);
        }
    }, [total]);

    return (
        <div
            className="position-relative px-4 mr-4"
            style={{
                zIndex: 999,
                border: '1px solid #2E3F5C',
                borderWidth: '0 1px'
            }}
            ref={ node }
        >
            <button
                className="position-relative text-white font-weight-normal border-0 p-0"
                style={{
                    background: 'none',
                    outline: 'none',
                    width: 40,
                    height: 40,
                    margin: '10px 0'
                }}
                disabled={ total === 0 }
                onClick={ handleToggle }
            >
                <svg width="28" height="33">
                    <use xlinkHref="#icon-bell-outline" />
                </svg>
                {
                    total > 0 ?
                        <span
                            className="position-absolute rounded-circle"
                            style={{
                                top: -3,
                                right: -1,
                                width: 20,
                                height: 20,
                                backgroundColor: 'var(--info)',
                                fontSize: '0.875rem'
                            }}
                        >
                            { total }
                        </span> : ''
                }
            </button>

            <StyledNav
                className="position-absolute"
                style={{
                    opacity: isExpanded ? '' : 0,
                    visibility: isExpanded ? '' : 'hidden',
                    transform: isExpanded ? '' : 'translateY(20px)'
                }}
            >
                <div
                    className="overflow-auto pt-3 px-3 scrollbar-sm"
                    style={{ maxHeight: 720 }}
                >
                    {
                        data.map((item, index) => (
                            showAll || (!showAll && index < 4) ?
                                <Notification
                                    key={ index }
                                    className="mb-3"
                                    severity={ item.Severity }
                                    title={ item.Title }
                                    description={ item.Message }
                                    date={ item.DateCreated }
                                    markAsRead={ () => {
                                        setTotal(total - 1);
                                        markAsRead(token, item.UserAlertID, () => setAlerts());
                                    } }
                                /> : ''
                        ))
                    }
                    {
                        total > 4 && !showAll ?
                            <div className="text-center pb-3">
                                <button
                                    className="btn btn-link py-0 px-1"
                                    style={{ fontSize: `${10/16}rem` }}
                                    onClick={ () => setShowAll(true) }
                                >
                                    Load More
                                </button>
                            </div> : ''
                    }
                </div>
            </StyledNav>
        </div>
    );
}

export default Notifications;
