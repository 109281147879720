import React, { useContext, useEffect, useState } from 'react';
import Context from '../../context/Context';
import postForgotPassword from '../../api/postForgotPassword';

const ForgotPassword = () => {
    const { setPageTitle, showLoadingOverlay } = useContext(Context);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [username, setUsername] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        setErrorMessage('');

        showLoadingOverlay(true);

        postForgotPassword({ UserName: username }, res => {
            if (res.error_description) {
                setErrorMessage(res.error_description);
                showLoadingOverlay(false);
                return false;
            }

            if (res.Success) {
                setMessage(res.Message);
                showLoadingOverlay(false);
            }
        });
    };

    useEffect(() => {
        setPageTitle('Forgot Password');
    }, [setPageTitle]);

    return (
        <div className="container-fluid">
            <div
                className="bg-gray-dark rounded p-5"
                style={{ maxWidth: 500 }}
            >
                {
                    message !== '' ?
                        <p>{ message }</p> : ''
                }
                <form onSubmit={ handleSubmit }>
                    <div className="form-group mb-4">
                        <label
                            className="text-blue-light font-weight-semibold text-uppercase mb-1"
                            htmlFor="username"
                        >
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="form-control"
                            value={ username }
                            onChange={ e => { setUsername(e.target.value) } }
                            required
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary">
                            Reset Password
                        </button>
                    </div>
                    {
                        errorMessage !== '' ?
                            <p className="font-weight-bold text-danger text-center mt-4 mb-n4">
                                { errorMessage }
                            </p> : ''
                    }
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
