import React, { useContext, useEffect, useState } from 'react';
import Context from '../../../context/Context';
import RadioList from '../../../components/RadioList';
import Panel from '../../../components/Panel';
import ManufacturerSearch from './ManufacturerSearch';
import getLubricantBrandNames from '../../../api/getLubricantBrandNames';
import { Carousel, CarouselItems, CarouselItem, CarouselPrevButton, CarouselNextButton, CarouselNavButton } from './PanelCarousel';


const ReferencePanel = props => {
    const { token } = useContext(Context);
    const { isVisible, setIsVisible } = props;
    const [activeSlide, setActiveSlide] = useState(null);
    const [fluidManufacturer, setFluidManufacturer] = useState({ name: '', id: null });
    const [fluidProductTradeName, setFluidProductTradeName] = useState({ name: '', id: null });
    const [lubricantBrandNames, setLubricantBrandNames] = useState([]);
    const [usingRecycledOil, setUsingRecycledOil] = useState('');
    const [filterType, setFilterType] = useState({ name: '', id: null });
    const [sizeFiltered, setSizeFiltered] = useState({ name: '', id: null });
    const [betaRating, setBetaRating] = useState({ name: '', id: null });
    const [filterTypes, setFilterTypes] = useState([]);
    const [sizeFilteredTypes, setSizeFilteredTypes] = useState([]);
    const [manufacturerSearchTerm, setManufacturerSearchTerm] = useState('');

    const handleClose = () => {
        setIsVisible(false);
        props.setFluidManufacturer(fluidManufacturer);
        props.setFluidProductTradeName(fluidProductTradeName);
        props.setUsingRecycledOil(usingRecycledOil);
        props.setFilterType(filterType);
        props.setSizeFiltered(sizeFiltered);
        props.setBetaRating(betaRating);
    };

    useEffect(() => {
        setActiveSlide(props.activeSlide);
        setManufacturerSearchTerm(props.fluidManufacturer.name);
        setFluidManufacturer(props.fluidManufacturer);
        setFluidProductTradeName(props.fluidProductTradeName);
        setUsingRecycledOil(props.usingRecycledOil);
        setFilterType(props.filterType);
        setSizeFiltered(props.sizeFiltered);
        setBetaRating(props.betaRating);
        setLubricantBrandNames(props.lubricantBrandNames);
        setFilterTypes(props.filterTypes);
        setSizeFilteredTypes(props.sizeFilteredTypes);
        setBetaRating(props.betaRating);
    }, [props]);

    useEffect(() => {
        if (isVisible && fluidManufacturer.id) {
            getLubricantBrandNames(token, fluidManufacturer.id, res => {
                if (Array.isArray(res)) {
                    setLubricantBrandNames(res.map(item => (
                        { label: item.Name, value: item.ID }
                    )));
                }
            });
        }
    }, [fluidManufacturer]); // eslint-disable-line

    return (
        <Panel
            isOpen={ isVisible }
            onRequestClose={ handleClose }
        >
            <Carousel>
                <div
                    className="d-flex flex-column overflow-hidden m-n4"
                    style={{ height: 'calc(100% + 3rem)' }}
                >
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            height: 44,
                            borderBottom: '1px solid rgba(46,63,92,0.4)'
                        }}
                    >
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: 0.5 }}
                            aria-label="Previous Section"
                            disabled={ true }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-left" />
                            </svg>
                        </button>
                        <div className="d-flex flex-grow-1">
                            <CarouselNavButton
                                isActive={ activeSlide === 1 }
                                onClick={ () => setActiveSlide(1) }
                            >
                                { activeSlide === 1 ? '1. Fluid Manufacturer' : '1' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 2 }
                                onClick={ () => setActiveSlide(2) }
                            >
                                { activeSlide === 2 ? '2. Fluid Product Trade Name' : '2' }
                            </CarouselNavButton>
                            {/* <CarouselNavButton
                                isActive={ activeSlide === 3 }
                                onClick={ () => setActiveSlide(3) }
                            >
                                { activeSlide === 3 ? '3. Using Recycled Oil?' : '3' }
                            </CarouselNavButton> */}
                            <CarouselNavButton
                                isActive={ activeSlide === 3 }
                                onClick={ () => setActiveSlide(3) }
                            >
                                { activeSlide === 3 ? '3. Filter Type' : '3' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 4 }
                                onClick={ () => setActiveSlide(4) }
                            >
                                { activeSlide === 4 ? '4. Size Filtered' : '4' }
                            </CarouselNavButton>
                            <CarouselNavButton
                                isActive={ activeSlide === 5 }
                                onClick={ () => setActiveSlide(5) }
                            >
                                { activeSlide === 5 ? '5. Beta Rating' : '5' }
                            </CarouselNavButton>
                        </div>
                        <button
                            className="text-primary bg-transparent border-0 p-0 flex-shrink-0"
                            style={{ width: 44, height: 44, opacity: 0.5 }}
                            aria-label="Next Section"
                            disabled={ true }
                        >
                            <svg width="16" height="16">
                                <use xlinkHref="#icon-chevron-right" />
                            </svg>
                        </button>
                    </div>

                    <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
                        {
                            activeSlide ?
                                <CarouselItems
                                    activeItem={ activeSlide }
                                    setActiveItem={ setActiveSlide }
                                >
                                    <CarouselItem index={ 1 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 1 of 5
                                        </h2>
                                        <p>
                                            Who is the manufacturer of this fluid?
                                        </p>
                                        <ManufacturerSearch
                                            manufacturer={ fluidManufacturer }
                                            setManufacturer={ obj => setFluidManufacturer({ name: obj.name, id: obj.id }) }
                                            searchTerm={ manufacturerSearchTerm }
                                            setSearchTerm={ setManufacturerSearchTerm }
                                            placeholder="Search manufacturers…"
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 2 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 2 of 5
                                        </h2>
                                        <p>
                                            What is the complete trade name of the product?
                                        </p>
                                        {
                                            fluidManufacturer.id ?
                                                <RadioList
                                                    id="lubricantBrandNames"
                                                    options={ lubricantBrandNames }
                                                    value={{ label: fluidProductTradeName.name, value: fluidProductTradeName.id }}
                                                    setValue={ obj => setFluidProductTradeName({ name: obj.label, id: obj.value }) }
                                                />
                                                :
                                                <>
                                                    You must first <button
                                                        className="btn bg-transparent border-0 p-0 text-primary"
                                                        style={{
                                                            textDecoration: 'underline',
                                                            lineHeight: 'inherit',
                                                            fontWeight: 'inherit',
                                                            fontSize: 'inherit',
                                                            textTransform: 'none'
                                                        }}
                                                        onClick={ e => setActiveSlide(1) }
                                                    >
                                                        choose a manufacturer
                                                    </button>.
                                                </>
                                        }
                                    </CarouselItem>
                                    {/* <CarouselItem index={ 3 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 3 of 6
                                        </h2>
                                        <p>
                                            Using recycled oil?
                                        </p>
                                        <RadioList
                                            id="panelRecylcledOil"
                                            options={ [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }] }
                                            value={ { label: usingRecycledOil, value: usingRecycledOil } }
                                            setValue={ obj => setUsingRecycledOil(obj.value) }
                                        />
                                    </CarouselItem> */}
                                    <CarouselItem index={ 3 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 3 of 5
                                        </h2>
                                        <p>
                                            What is the filter type?
                                        </p>
                                        <RadioList
                                            id="filterTypes"
                                            options={ filterTypes }
                                            value={{ label: filterType.name, value: filterType.id }}
                                            setValue={ obj => setFilterType({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 4 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 4 of 5
                                        </h2>
                                        <p>
                                            What is the size filtered?
                                        </p>
                                        <RadioList
                                            id="sizeFiltered"
                                            options={ sizeFilteredTypes }
                                            value={{ label: sizeFiltered.name, value: sizeFiltered.id }}
                                            setValue={ obj => setSizeFiltered({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                    <CarouselItem index={ 5 }>
                                        <h2 className="h5 font-weight-semibold text-uppercase mb-4">
                                            Fluid Information &ndash; 5 of 5
                                        </h2>
                                        <p>
                                            What is the beta rating?
                                        </p>
                                        <RadioList
                                            id="betaRating"
                                            options={ [1,2,10,20,50,75,100,200,1000,2000].map((item, index) => ({ label: item, value: index + 1})) }
                                            value={{ label: betaRating.name, value: betaRating.id }}
                                            setValue={ obj => setBetaRating({ name: obj.label, id: obj.value }) }
                                        />
                                    </CarouselItem>
                                </CarouselItems> : ''
                        }

                        <div className="pb-5">
                            <div
                                className="position-relative mt-n3 pt-3"
                                style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #baddfc' }}
                            />
                            <div className="row align-items-center justify-content-center text-center pt-2">
                                <div className="col-4">
                                    <CarouselPrevButton>
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-left" />
                                        </svg>
                                        Prev
                                    </CarouselPrevButton>
                                </div>
                                <div className="col-4">
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: 147 }}
                                        onClick={ handleClose }
                                    >
                                        Done
                                    </button>
                                </div>
                                <div className="col-4">
                                    <CarouselNextButton>
                                        Next
                                        <svg width="20" height="20">
                                            <use xlinkHref="#icon-chevron-right" />
                                        </svg>
                                    </CarouselNextButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </Panel>
    );
};

export default ReferencePanel;
